import React from 'react';
import ReactDOM from 'react-dom';
import cn from './SideMenuRight.module.scss';

type SideMenuRightProps = {
    children: React.ReactNode;
}

export class SideMenuRight extends React.Component<SideMenuRightProps> {
    root = document.getElementById('menu-root');
    el: HTMLElement =  document.createElement('div');
    constructor(props: SideMenuRightProps) {
        super(props);
        this.el.classList.add(cn.leftMenu);
    }

    componentDidMount() {
        document.getElementById('menu-root')!.appendChild(this.el)
    }

    componentWillUnmount() {
        document.getElementById('menu-root')!.removeChild(this.el);
    }

    render() {
        return ReactDOM.createPortal(
            this.props.children,
            this.el
        );
    }
}
