import { Reaction } from './Reaction';
import { makeObservable, observable } from 'mobx';
import { QuickReplies } from '../QuickReply';

export abstract class FileLikeReaction extends Reaction {
    static className = 'FileLikeReaction';
    @observable url: string = '';
    @observable caption?: string;
    @observable quickReplies: QuickReplies = new QuickReplies();

    constructor(id: string, name: string) {
        super(id, name);
        makeObservable(this);
    }
}
