import { Node } from '../Node';
import { makeObservable, observable } from 'mobx';
import { State } from '../State';

export class Reaction extends Node {
    static className = 'Reaction';
    next: State | Reaction | null = null;
    @observable id: string = '';
    @observable name: string = '';

    constructor(id: string, name: string) {
        super();
        makeObservable(this);
        this.id = id;
        this.name = name;
    }
}
