import camelCase from 'lodash/camelCase';

export const camelCaseKeys = (obj: any): object => {
    const newObj: any = {};

    Object.keys(obj)
        .forEach(key => {
            if (Array.isArray(obj[key])) {
                // eslint-disable-next-line @typescript-eslint/no-use-before-define
                newObj[camelCase(key)] = processArray(obj[key]);
            } else if (typeof obj[key] === 'object' && obj[key] !== null) {
                newObj[camelCase(key)] = camelCaseKeys(obj[key]);
            } else {
                newObj[camelCase(key)] = obj[key];
            }
        });

    return newObj;
};

function processArray(arr: any[]) {
    return arr.map(item => {
        if (typeof item === 'object' && !Array.isArray(item) && item !== null) {
            return camelCaseKeys(item);
        }
        return item;
    });
}
