import React from 'react';
import { IObservableArray, makeObservable, observable } from 'mobx';
import { Input } from 'antd';
import { observer } from 'mobx-react';
import CloseIcon from 'mdi-react/CloseIcon';
import cnCommon from '@/common/scss/form.module.scss';
import cn from './EntityMultiInputs.module.scss';
import cnEntity from '../EntityCommon.module.scss';

export interface MultiInput { name: string, value: string }

interface EntityMultiInputsProps {
    label: string;
    onChange: (modelToSave: Array<string[]> | Record<string, string>) => void;
    modelToSave: Array<string[]> | Record<string, string>;
    isArraysFormat?: boolean;
    disabled: boolean;
}

@observer
export class EntityMultiInputs extends React.Component<EntityMultiInputsProps > {
    @observable inputs: IObservableArray<any> = observable([{
        name: '',
        value: ''
    }]);

    constructor(props: EntityMultiInputsProps) {
        super(props);
        makeObservable(this);
        this.onChangeProps();
    }

    UNSAFE_componentWillReceiveProps(props: EntityMultiInputsProps) {
        this.onChangeProps(props);
    }

    onChangeProps(props?: EntityMultiInputsProps) {
        if (!props) {
            props = this.props;
        }

        if (!props.modelToSave) {
            return;
        }

        if (Array.isArray(props.modelToSave))
            this.inputs.replace((props.modelToSave as Array<string[]>).map<MultiInput>(model => {
                return { name: model[0], value: model[1] };
            }));
        else
            this.inputs.replace(Object.keys(props.modelToSave as Record<string, string>).map((key: string) => {
                // @ts-ignore
                return { name: key, value: props.modelToSave[key] }
            }));
    }

    onChange = () => {
        if (this.props.isArraysFormat)
            this.props.onChange(this.inputs.map(input => [input.name, input.value]));
        else
            this.props.onChange(this.inputs.reduce((curr, prev) => { curr[prev.name] = prev.value; return curr; }, {}))
    };

    renderInputs = (input: any, i: number) => {
        return <div className={cnCommon.formField} key={i}>
            <span className={cnCommon.formFieldLabel}>
                { i === 0 ? this.props.label : '' }
                </span>
                <Input disabled={this.props.disabled} className={cn.formWithCloseInput}
                       value={input.name}
                       onChange={e => { input.name = e.target.value; this.onChange(); }}
                       placeholder='name'/>
                <Input disabled={this.props.disabled} placeholder='value'
                       value={input.value}
                       onChange={e => { input.value = e.target.value; this.onChange(); }}/>
            { !this.props.disabled && this.inputs.length > 1 && <span onClick={this.removeInput(input)}><CloseIcon className={cnEntity.formCloseIcon}/></span> }
        </div>
    };

    removeInput = (input: MultiInput) => () => {
        const i = this.inputs.find(_input => _input === input);
        if (i)
            this.inputs.remove(i);
    };

    pushCleanInput = () => {
        this.inputs.push({
            name: '',
            value: ''
        });
    };

    render() {
        return <div className={cn.wrapper}>
            { this.inputs.map(this.renderInputs) }
            <span className={cnEntity.addInput} onClick={this.pushCleanInput}>+ Add</span>
        </div>
    }
}
