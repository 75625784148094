import { action, computed, makeObservable, observable, reaction } from 'mobx';

import { API } from '@/common/api';
import { TemplateDto } from '../../sidebar/models/template.dto';
import { Template } from '../../sidebar/models/template-response';

export interface ProjectLang {
    label: string;
    id: string;
}

export class TemplateProjectsStore {
    private static readonly templatesCache: Record<string, TemplateDto[]> = {};
    static readonly defaultLanguage: string = 'ru';
    static readonly importKey: string = 'import';

    @observable templates: TemplateDto[] = [];
    @observable currentLang: string = '';

    langs: ProjectLang[] = [
        { label: 'English', id: 'en' },
        { label: 'Russian', id: 'ru' }
    ];

    constructor() {
        makeObservable(this);
        reaction(() => this.currentLang, () => {
            if (this.isImportSelected) {
                return;
            }
            this.fetchTemplates(this.currentLang);
        });
    }

    @computed
    get isImportSelected(): boolean {
        return this.currentLang === TemplateProjectsStore.importKey;
    }

    @action
    setCurrentLanguage(id: string): void {
        this.currentLang = id;
    }

    @action
    async fetchTemplates(language: string): Promise<void> {
        const { templatesCache } = TemplateProjectsStore;

        if (templatesCache[language]) {
            this.templates = templatesCache[language];
            return;
        }


        const templatesResponse = await API.get<Template[]>('/project-templates-groups', { language });
        templatesResponse.forEach(
            templateGroup => {
                templatesCache[language] = templateGroup.templates;
                this.templates = templateGroup.templates;
            }
        );
    }
}
