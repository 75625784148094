import React from 'react';
import { Input, Form, Select, Button, Spin } from 'antd';
import cn from './ProfileSettings.module.scss';
import { ActionsMenu } from '@/common/components/actions-menu/ActionsMenu';
import { inject, observer } from 'mobx-react';
import { UserStore } from '@/core/stores/user.store';
import { Page } from '@/common/components/page/Page';
import {
    SaveButton,
    SaveButtonState,
    SaveButtonWaitingToDefaultTimeout
} from '@/common/components/save-button/SaveButton';
import { makeObservable, observable } from 'mobx';
import { ProfileStore } from './profile.store';
import { Company } from './models/company';
import { AuthAPI } from '@/auth/auth.api';
import { WithTranslation, withTranslation } from 'react-i18next';


interface ProfileSettingsProps extends WithTranslation {
    user?: UserStore;
    profileStore?: ProfileStore;
}

interface ProfileUser {
    first_name: string;
    last_name: string;
    email: string;
    language: string;
}

@inject('user', 'profileStore')
@observer
export class ProfileSettingsComp extends React.Component<ProfileSettingsProps> {
    @observable saveState: SaveButtonState = SaveButtonState.default;
    @observable titlesByState: Record<SaveButtonState, string> = {
        [SaveButtonState.default]: 'actions.save',
        [SaveButtonState.process]: 'actions.saving',
        [SaveButtonState.saved]: 'actions.saved',
        [SaveButtonState.error]: 'actions.error'
    };
    @observable isReset = false;
    @observable editUser: ProfileUser = {first_name: '', last_name: '', email: '', language: ''};
    // @ts-ignore
    @observable company: Company = {};

    constructor(props: ProfileSettingsProps) {
        super(props);
        makeObservable(this);
        this.getCompany();
        this.editUser.email = this.props.user.userInfo!.email;
        this.editUser.first_name = this.props.user.userInfo!.first_name;
        this.editUser.last_name = this.props.user.userInfo!.last_name;
        this.editUser.language = this.props.user.userInfo!.language;
    }

    async getCompany() {
        this.company = await this.props.profileStore.getCompany();
    }

    selectIndustry = (value: string) => () => {
        this.company.industry = value;
    };

    selectWorkers = (value: string) => () => {
        this.company.workers_count = value;
    };

    selectLang = (value: string) => () => {
        this.editUser.language = value;
    };

    save = async () => {
        this.saveState = SaveButtonState.process;
        await this.props.profileStore.saveProfile(this.company);
        const user = await this.props.user.updateUser(this.editUser);
        this.props.user.lang = user.language;
        this.saveState = SaveButtonState.saved;
        setTimeout(() => this.saveState = SaveButtonState.default, SaveButtonWaitingToDefaultTimeout)
    };

    resetPassword = async () => {
        this.isReset = true;
        await AuthAPI.reset({email: this.editUser.email});
    };

    render() {
        return this.props.profileStore.isLoaded ? <Page actionMenu={<ActionsMenu
            right={
                <SaveButton onClick={this.save}
                            state={this.saveState}
                            titlesByState={this.titlesByState}/>
            }
        />}>
            <div className={cn.formGroupTitle}>{this.props.t('profile.personal_settings')}</div>
            <Form layout="vertical">
                <Form.Item label={this.props.t('profile.first_name')}>
                    <Input
                        value={this.editUser.first_name}
                        onChange={e => this.editUser.first_name = e.target.value}
                    />
                </Form.Item>
                <Form.Item label={this.props.t('profile.last_name')}>
                    <Input
                        type="text"
                        value={this.editUser.last_name}
                        onChange={e => this.editUser.last_name = e.target.value}
                    />
                </Form.Item>
                <Form.Item label={this.props.t('profile.email')}>
                    <Input
                        value={this.editUser.email}
                        disabled={true}
                        onChange={e => this.editUser.email = e.target.value}
                    />
                </Form.Item>

                <Form.Item
                    label={this.props.t('profile.lang')}>
                    <Select
                        placeholder={this.props.t('profile.lang')}
                        optionFilterProp="children"
                        fieldNames={{value: 'id', label: 'name'}}
                        onChange={id => this.company.language = id}
                        value={this.company.language}
                        options={[{
                            id: 'ru', name: 'RU'
                        }, {
                            id: 'en', name: 'EN'
                        }]}/>
                </Form.Item>
            </Form>


            <Button onClick={this.resetPassword} type="link">
                {this.props.t(!this.isReset ? 'profile.reset_your_password' : 'profile.see_you_email')}
            </Button>


            <div className={cn.formGroupTitle}>{this.props.t('profile.organisation')}</div>
            <Form layout={'vertical'}>
                <Form.Item label={this.props.t('profile.organisation_name')}>
                    <Input
                        value={this.company.name}
                        onChange={e => this.company.name = e.target.value}
                    />
                </Form.Item>
                <Form.Item label={this.props.t('profile.phone')}>
                    <Input
                        value={this.company.phone}
                        onChange={e => this.company.phone = e.target.value}
                    />
                </Form.Item>
                <Form.Item
                    label={this.props.t('profile.workers')}>
                    <Select
                        placeholder={this.props.t('profile.workers')}
                        optionFilterProp="children"
                        onChange={id => this.company.workers_count = id}
                        value={this.company.workers_count}
                        fieldNames={{value: 'id', label: 'id'}}
                        options={[{
                            id: '1-10'
                        }, {
                            id: '11-100'
                        }, {
                            id: '101-1000'
                        }]}/>
                </Form.Item>
                <Form.Item label={this.props.t('profile.website')}>
                    <Input
                        value={this.company.website}
                        onChange={e => this.company.website = e.target.value}
                    />
                </Form.Item>

                <Form.Item
                    label={this.props.t('profile.workers')}>
                    <Select
                        placeholder={this.props.t('profile.workers')}
                        optionFilterProp="children"
                        onChange={id => this.company.industry = id}
                        value={this.company.industry}
                        fieldNames={{value: 'id', label: 'id'}}
                        options={[{
                            id: 'Marketing', label: this.props.t('profile.software')
                        }, {
                            id: 'Software', label: this.props.t('profile.marketing')
                        }]}/>
                </Form.Item>
            </Form>
        </Page> : <Spin/>
    }
}

export const ProfileSettings = withTranslation()(ProfileSettingsComp);
