import { makeObservable, observable } from 'mobx';
import { QuickReplies } from '../QuickReply';
import { Reaction } from './Reaction';

export class LocationReaction extends Reaction {
    static className = 'LocationReaction';

    @observable lat?: number = 0;
    @observable lon?: number = 0;
    @observable locationName?: string;
    @observable quickReplies: QuickReplies = new QuickReplies();

    constructor(id: string, name: string) {
        super(id, name);
        makeObservable(this);
    }
}
