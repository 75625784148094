import React, { FC } from 'react'

type SendMessageProps = { className?: string; onClick: () => void; };

export const SendMessage: FC<SendMessageProps> = ({className, onClick}) => <svg onClick={onClick}
                                                                                className={className}
                                                                                width="21"
                                                                                height="18"
                                                                                viewBox="0 0 21 18"
                                                                                fill="none"
                                                                                xmlns="http://www.w3.org/2000/svg">
        <path d="M0.847656 17.25V10.8477L14.5977 9L0.847656 7.15234V0.75L20.0977 9L0.847656 17.25Z" fill="#206AFA"/>
    </svg>


;


