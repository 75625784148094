import { API, HTTP_METHODS } from '@/common/api';
import { Pagination, PaginationResponse } from '@/common/models/pagination';
import { QA } from './models/qa';
import { Project } from '@/common/models/project';
import { Group } from '@/common/models/group';
import { QaFilter } from './models/qa-filter';
import { WithNameAndType } from '@/common/components/chat/models';


export class QAAPI extends API {
    static getAll(project: Project, pagination?: Pagination, filter?: QaFilter): Promise<PaginationResponse<QA>> {
        return QAAPI.get<PaginationResponse<QA>>(`/projects/${project.id}/qa`, Object.assign(pagination, filter));
    }

    static getQAById(project: Project, id: number): Promise<QA> {
        return QAAPI.get<QA>(`/projects/${project.id}/qa/${id}`);
    }

    static getGroups(project: Project, pagination?: Pagination): Promise<Group[]> {
        return QAAPI.get<Group[]>(`/projects/${project.id}/qa-groups`, pagination);
    }

    static createQA(project: Project, qa: QA): Promise<QA> {
        return QAAPI.post<QA>(`/projects/${project.id}/qa`, qa);
    }

    static createGroup(project: Project, group: Group): Promise<Group> {
        return QAAPI.post<Group>(`/projects/${project.id}/qa-groups`, group);
    }

    static updateGroup(project: Project, group: Group): Promise<Group> {
        return QAAPI.put<Group>(`/projects/${project.id}/qa-groups/${group.id}`, group);
    }

    static removeGroup(project: Project, groupId: number): Promise<void> {
        return QAAPI.delete<Group>(`/projects/${project.id}/qa-groups/${groupId}`);
    }

    static updateQA(project: Project, qa: QA): Promise<QA> {
        return QAAPI.put<QA>(`/projects/${project.id}/qa/${qa.id}`, {...qa, id: undefined });
    }

    static patchQA(project: Project, qa: any): Promise<any> {
        return QAAPI.patch<any, any>(`/projects/${project.id}/qa/${qa.id}`, {...qa, id: undefined });
    }

    static async deleteQA(project: Project, qa: QA): Promise<void> {
        return QAAPI.delete(`/projects/${project.id}/qa/${qa.id}`);
    }

    static async uploadQA(project: Project, file: File, replace: boolean): Promise<void> {
        const data = {
            'qa_file': file,
            'replace': replace ? 'True' : 'False',
        };
        return QAAPI.formData<void>(HTTP_METHODS.PUT, `/projects/${project.id}/qa-upload`, data);
    }
    static download(project: Project): Promise<string> {
        return QAAPI.get<string>(`/projects/${project.id}/qa-download`);
    }

    static intentQaSearch(project: Project, term: string) {
        return QAAPI.get<WithNameAndType[]>(`/projects/${project.id}/intent-qa-search`, { search: term })
    }
}
