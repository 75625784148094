import React from 'react';
import { Modal } from 'reactstrap';
import cn from './ImportModal.module.scss';
import cns from 'classnames';

export const ImportModal = ({ isOpen, children, modalContent, toggle }: any) => {
    return <div className={cn.wrapper}>
        { children }
        <Modal isOpen={isOpen} toggle={toggle}
               contentClassName={cn.modalContent}
               backdropClassName={cn.backdrop}
            className={cns("modal-dialog--primary", cn.modal)}>
            { modalContent }
        </Modal>
    </div>;
};
