import React from 'react';
import { action, computed } from 'mobx';
import { inject, observer } from 'mobx-react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom/dist';
import { EntityTrigger } from '../models/triggers/EntityTrigger';
import { SmartNode } from './Node';
import { EntityStore } from '../../entities/entity.store';
import cn from './Links.module.scss';
import { ScriptPageStore } from '../stores/script-page.store';
import { Entity } from '../../entities/models/entity';
import { RouteComponentProps, withRouter } from '@/common/utils/withRouter';

interface IntentTriggerViewProps extends WithTranslation, RouteComponentProps<{ projectId: string }> {
    trigger: EntityTrigger;
    entityStore?: EntityStore;
    scriptPageStore?: ScriptPageStore;
}

@inject('entityStore', 'scriptPageStore')
@observer
export class EntityTriggerViewComp extends React.Component<IntentTriggerViewProps> {
    componentDidMount() {
        this.props.entityStore!.load();
    }

    @computed
    get trigger() {
        return this.props.trigger;
    }

    @computed
    get entity(): Entity | undefined {
        if (!this.props.trigger.entity) {
            return undefined;
        }
        return this.props.entityStore!.entities.find(e => this.props.trigger.entity!.id === e.id);
    }


    @computed
    get body(): React.ReactNode {
        return this.entity
            ? <Link
                to={`/app/${this.props.match.params.projectId}/entities/${this.entity.id}/${this.entity.extractor_type}`}
                className={cn.link}>{this.entity.name}</Link>
            : this.props.t('flows.select_entity');
    }

    @computed
    get title(): string {
        return this.props.t('flows.entity');
    }

    @action.bound
    onDelete = () => {
        this.trigger.removeSelf();
    }


    @action.bound
    onClickNode = () => {
        this.props.scriptPageStore!.select(this.trigger);
    }

    render() {
        return <SmartNode title={this.title} onDelete={this.onDelete} onClick={this.onClickNode} type={'trigger'}>
            {this.body}
        </SmartNode>;
    }
}

export const EntityTriggerView = withTranslation()(withRouter(EntityTriggerViewComp));
