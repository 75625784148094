import React, { MouseEventHandler } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';

import { makeObservable, observable } from 'mobx';
import { observer } from 'mobx-react';

import classnames from 'classnames';

import { TextField } from '@mui/material';
import EditIcon from 'mdi-react/EditIcon';
import CloseCircleIcon from 'mdi-react/CloseCircleIcon';

import cn from './SidebarGroup.module.scss';
import { Group } from '@/common/models/group';
import { Tracker } from '@/core/analytics/tracker';
import { asDropTarget, AsDropTargetProps } from '@/common/components/drag-n-drop/asDropTarget';
import { DragSidemenuItem, DragSidemenuType, DropTargetItem } from './models/drag-sidemenu-item';
import { asDragSource, AsDragSourceProps } from '@/common/components/drag-n-drop/asDragSource';
import { ConfirmDelete } from '@/common/components/ConfirmDelete/ConfirmDelete.component';

const inputStyle: React.CSSProperties = {
    fontSize: 14,
    lineHeight: '14px',
    fontWeight: 'bold',
    padding: 0,
};

interface SidebarGroupRawProps {
	group: Group;
    onStartEdit: (group: Group) => void;
    currentEditedGroupId: number;
    onSave: (group: Group) => void;
    onRemove: (group: Group) => void;
    canEdit?: boolean;
}

type SidebarGroupProps = SidebarGroupRawProps & WithTranslation & AsDropTargetProps & AsDragSourceProps;

@observer
export class SidebarGroupComponent extends React.Component<SidebarGroupProps> {
    @observable title: string = '';

    constructor(props: SidebarGroupProps) {
        super(props);
        makeObservable(this);
    }

    componentDidMount() {
        this.title = this.props.group.name;
    }

    onStartEdit: MouseEventHandler = (e) => {
        e.stopPropagation();
        this.props.onStartEdit(this.props.group);
    };

    onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.title = e.target.value;
    };

    save = () => {
        this.props.group.name = this.title;
        this.props.onSave(this.props.group);
    };

    onKeyDown = (e: React.KeyboardEvent) => {
        e.stopPropagation();
        if (e.key === 'Enter') {
            this.save();
        }
    };

    onRemove: MouseEventHandler = (e) => {
        e.stopPropagation();
        Tracker.trackEvent('Group', {Type: 'Remove'});
        this.props.onRemove(this.props.group);
    };

	renderTextField = () => (
		<TextField inputProps={{style: inputStyle}}
			autoFocus={true}
			onClick={e => e.stopPropagation()}
			value={this.title}
			onChange={this.onChange}
			onBlur={this.save}
			onKeyDown={this.onKeyDown}
		/>
	);

	renderGroup = () => (
		<>
			<span>{this.props.group.name}</span>
            {this.props.canEdit && (
                <>
                    <ConfirmDelete title={this.props.t('actions.delete_group')}
                                   question={this.props.t('actions.delete_element', {name: this.props.group.name})}
                                   onConfirm={this.onRemove}>
                        <CloseCircleIcon className={cn.removeGroup} onClick={e => e.stopPropagation()} />
                    </ConfirmDelete>

                    <EditIcon className={cn.editGroup} onClick={this.onStartEdit}/>
                </>
            )}
		</>
	);

    render() {
        const isActive = this.props.canDrop && this.props.isOver;
        return (
			<div
				ref={this.props.dragRef}
				className={classnames(cn.sidebarGroupWrapper, isActive ? cn.sidebarGroupDropActive : '')}
			>
				{this.props.currentEditedGroupId === this.props.group.id ? this.renderTextField() : this.renderGroup()}
			</div>
		);
    }
}

const DragSourceSidebarGroup = asDragSource<SidebarGroupRawProps, DragSidemenuItem, DropTargetItem>(withTranslation()(SidebarGroupComponent), {
	type: DragSidemenuType,
	item: ({ group }) => ({ groupId: group.id })
})

export const SidebarGroup = asDropTarget<SidebarGroupRawProps, DragSidemenuItem, DropTargetItem>(
	DragSourceSidebarGroup,
	{
		accept: DragSidemenuType,
		drop: (props) => ({ group: props.group })
	}
);
