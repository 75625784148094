import React, { FC } from 'react';

import {
    LineChart,
    Line,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend, ResponsiveContainer, ReferenceLine, Label
} from 'recharts';
import { SearchKey, Version } from '@/app/stats/Types';
import { VersionLabel } from '@/app/stats/VersionLabel/VersionLabel.component';
import { colors } from '../common/colors';
import { xFormatter } from '../common/formatters';

const renderLabel = (props: any) => {
    return <VersionLabel offset={props.offset} x={props.viewBox.x} y={props.viewBox.y} label={props.value}/>
}

export const BaseChart: FC<{
    data: any[],
    selected: Record<string, { selected: boolean }>,
    keys: string[],
    max: number,
    versions: Version[],
    searchKey: SearchKey,
    syncId: string,
    labels: Record<number, Record<string, Record<string, string>>>,
    formatter: (num: string | number) => string
}> = ({data, selected, keys, max, versions = [], searchKey, labels, formatter, syncId}) => {
    const ticksX = data.map(key => key.number);
    const maxX = Math.max(...ticksX);
    const minX = Math.min(...ticksX);

    return (
            <ResponsiveContainer width={'100%'} height={300}>
                <LineChart
                    syncId={syncId}
                    data={data}
                    margin={{
                        top: 20,
                        right: 30,
                        left: 20,
                        bottom: 20
                    }}
                >

                    {versions.map(version => <ReferenceLine ifOverflow={'visible'} strokeDasharray="3 3"
                                                            stroke="#1677ff"
                                                            key={version.timestamp} x={version.timestamp}>

                        <Label content={renderLabel} value={version.timestamp + '###' + version.project_id}></Label>

                    </ReferenceLine>)}
                    <CartesianGrid stroke={'gray'} strokeDasharray="3 3"/>
                    <XAxis ticks={ticksX} domain={[minX, maxX]}
                           tickFormatter={(tick: number) => xFormatter(tick, searchKey)} type="number"
                           dataKey="number"/>
                    <YAxis domain={[0, max]} tickFormatter={formatter}/>
                    <Tooltip formatter={(value: string, name: string, props: any) => {
                        return labels[props.payload.number][name][value] ?? value;
                    }} labelFormatter={label => xFormatter(label, searchKey)}/>
                    <Legend/>
                    {keys.map((key, i) => <Line
                        key={key}
                        strokeWidth={2}
                        type="linear"
                        dot={false}
                        dataKey={key}
                        stroke={colors[i]}
                        hide={!selected[key].selected}
                        activeDot={{r: 8}}
                    />)}
                </LineChart>
            </ResponsiveContainer>
    );
}
