import { QAStore } from '../../../qa/qa.store';

export class QaMapper {
    constructor(private qa: QAStore) {}

    async load(): Promise<boolean> {
        const groups = this.qa.groups;
        const qas = this.qa.qas;
        if (groups.length > 0 || qas.length > 0) return false;
        await this.qa.load();
        return true;
    }
}
