export class Tracker {
    private static readonly isProd = process.env.NODE_ENV === 'production';
    static currentProjectName = '';
    static currentProjectId = -1;

    static trackEvent(eventName: string, trackData?: { [index: string]: any }, callback?: () => void) {
        const data = trackData || {};
        data.project = Tracker.currentProjectName;
        data.projectId = Tracker.currentProjectId;
        console.warn('Tracker.trackEvent', eventName, data);
        if (this.isProd) window.mixpanel.track(eventName, data, callback);
        else console.log('Tracker.trackEvent', eventName, data);
    }

    static registerUser(user: any) {
        if (this.isProd) {
            window.mixpanel.people.set(user);
            window.mixpanel.identify(user.id);
        } else {
            console.log('Tracker.registerUser', user);
        }
    }

    static reset() {
        if (this.isProd) window.mixpanel.reset();
        else console.log('Tracker.reset');
    }
}
