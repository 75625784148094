import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AllowOutTrigger } from '../models/triggers/AllowOutTrigger';
import cn from './Params.module.scss';
import { Form, Select } from 'antd';
import { LabeledValue } from 'antd/es/select';

type AllowTriggerParamsEditorProps = {
    trigger: AllowOutTrigger;
}

export const AllowTriggerParamsEditor: FC<AllowTriggerParamsEditorProps> = ({
                                                                                trigger
                                                                            }) => {
    const {t} = useTranslation();
    const allow = trigger ? {value: trigger.allow, label: trigger.allow} : null;
    const [currentAllow, setCurrentAllow] = useState<LabeledValue>(allow);

    const values = [
        {value: 'out', label: 'out'},
        {value: 'qa', label: 'qa'},
        {value: 'flow', label: 'flow'},
    ]
    const stopPropagation = (e: React.MouseEvent) => {
        e.stopPropagation();
    };

    const onChange = (e: LabeledValue) => {
        setCurrentAllow(e);
        trigger.allow = e.value.toString() as 'out' | 'qa' | 'flow';
    };

    return <div className={cn.editor}>
        <div className={cn.group}>
            <h6 className={cn.groupTitle}>{t('flows.allow_out')}</h6>
            <Form.Item>
                <Select
                    onClick={stopPropagation}
                    showSearch
                    placeholder={t('flows.select_button')}
                    optionFilterProp="children"
                    notFoundContent={t('flows.not_found_button')}
                    onChange={onChange}
                    labelInValue
                    value={currentAllow}
                    filterOption={(input, option) => {
                        return (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                    }}
                    options={values}/>
            </Form.Item>
        </div>
    </div>

}
