import React from 'react';
import cn from './MessageButton.module.scss';
import classnames from 'classnames';

type MessageButtonProps = {
    className?: string;
    onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
    children: React.ReactNode;
}

export class MessageButton extends React.Component<MessageButtonProps> {
    render() {
        return <button onClick={this.props.onClick}
                       className={classnames(cn.messageButton, this.props.className)}>
            {this.props.children}
        </button>;
    }
}
