import snakeCase from 'lodash/snakeCase';

export const snakeCaseKeys = (obj: any): object => {
    const newObj: any = {};

    Object.keys(obj)
        .forEach(key => {
            if (Array.isArray(obj[key])) {
                // eslint-disable-next-line @typescript-eslint/no-use-before-define
                newObj[snakeCase(key)] = processArray(obj[key]);
            } else if (typeof obj[key] === 'object' && obj[key] !== null) {
                newObj[snakeCase(key)] = snakeCaseKeys(obj[key]);
            } else {
                newObj[snakeCase(key)] = obj[key];
            }
        });

    return newObj;
};

function processArray(arr: any[]) {
    return arr.map(item => {
        if (typeof item === 'object' && !Array.isArray(item) && item !== null) {
            return snakeCaseKeys(item);
        }
        return item;
    });
}
