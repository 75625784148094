import styled from 'styled-components';

export const VersionLabelStyled = styled.g`
`

export const VersionLabelStyledContent = styled.div`
    //width: 62px;
    //height: 30px;
    width: 100%;
    height: 100%;
    background: #1677ff;
    border-radius: 6px;
    color: #fff;
    display: flex;
    padding: 3px 4px 3px 4px;
    font-size: 8px;
    flex-direction: column;
`;

export const VersionLabelTitle = styled.span`
  font-size: 9px;
`;
export const VersionDate = styled.span`
    font-size: 11px;
`;
