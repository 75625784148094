import * as React from 'react';
import { AnalyticsTable } from '@/app/stats/AnalyticsTable/AnalyticsTable.component';
import { FC, useState } from 'react';
import { ChartWrapper, FilterWrapper, StatsWrapper, TableWrapper } from '@/app/stats/StatsPage.styled';
import { AnalyticsFilter, Filter } from '@/app/stats/AnalyticsFilter/AnalyticsFilter.component';
import { inject, observer } from 'mobx-react';
import { ProjectStore } from '@/core/stores/project.store';
import { Empty } from 'antd';
import { downloadFile } from '@/common/utils/download-file';
import { StackedBarChart } from '@/app/stats/BarChart/BarChart.component';
import { IntentAnalyticsStore } from '@/app/stats/IntentAnalytics/store/intentAnalyticsStore.store';
import { LocalFilter } from '@/app/stats/LocalFilter/LocalFilter.component';
import { LocalFilterWrapper } from '@/app/stats/IntentAnalytics/IntentAnalyticsPage.styled';
import { ViewValue } from '@/app/stats/IntentAnalytics/types';
import { SystemFilterComponent, SystemValue } from '@/app/stats/SystemFilter/SystemFilter.component';

export const IntentAnalyticsPage: FC<{
    projectStore?: ProjectStore
}> = inject('projectStore')(observer(({projectStore}) => {
    const [analyticsStore] = useState(() => new IntentAnalyticsStore(projectStore));


    const {
        tableData,
        columns,
        chartData,
        selectedRows,
        max,
        isLoading,
        isEmpty,
        keys,
        selectedRowKeys,
        versions,
        currentFilter,
        labels,
        setViewValue,
        setSystemValue
    } = analyticsStore;

    const onChange = (selectedRowKeys: string[]) => {
        analyticsStore.onChangeTable(selectedRowKeys);
    }

    const search = (filter: Filter) => {
        analyticsStore.setCurrentFilter(filter);
    }

    const onDownload = () => {
        analyticsStore.downloadCsv().then(result => {
            downloadFile(`export-csv-${analyticsStore.currentFilter.period}-${analyticsStore.currentFilter.periodKey}.csv`, result);
        })
    }

    const onChangeLocalFilter = (viewValue: ViewValue) => {
        setViewValue(viewValue);
    }

    const onChangeSystemFilter = (value: SystemValue) => {
        setSystemValue(value);
    }

    return <StatsWrapper>
        <FilterWrapper>
            <AnalyticsFilter isEmpty={isEmpty} isLoading={isLoading}
                             onDownload={onDownload}
                             search={search}
                             project={projectStore.choosenProject}/>
        </FilterWrapper>
        <ChartWrapper>
            {!isEmpty ?
                <StackedBarChart labels={labels} searchKey={currentFilter.periodKey} versions={versions} max={max}
                                 data={chartData} keys={keys} selected={selectedRows}/> :
                <Empty description={'Нет данных за выбранный период'} image={Empty.PRESENTED_IMAGE_SIMPLE}/>}
        </ChartWrapper>
        {!isEmpty &&
            <LocalFilterWrapper>
                <SystemFilterComponent onChange={onChangeSystemFilter} isLoading={isLoading}/>
                <LocalFilter onChange={onChangeLocalFilter} isLoading={isLoading}/>
            </LocalFilterWrapper>
        }
        {!isEmpty && <TableWrapper>
            <AnalyticsTable selectedRowKeys={selectedRowKeys}
                            onChange={onChange} columns={columns}
                            data={tableData}/>
        </TableWrapper>}
    </StatsWrapper>;
}));
