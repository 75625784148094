import { action, computed, IObservableArray, makeObservable, observable, runInAction } from 'mobx';
import { capitalize } from 'lodash';

import { ConnectAPI } from './connect.api';
import { ConnectType } from './models/connect-type';
import { RootStore } from '@/core/stores/root.store';
import { Channel } from './models/channel';
import { SaveButtonState } from '@/common/components/save-button/SaveButton';
import { ChannelEditStore } from '@/app/connects/components/ConnectPageContent';

export class ConnectStore {
    @observable connectTypes: IObservableArray<ConnectType> = observable([]);
    @observable isLoadTypes: boolean = false;
    @observable isLoadChannels: boolean = false;
    @observable channels: IObservableArray<Channel> = observable([]);

    @observable currentChannel: Channel = null;
    @observable saveState: SaveButtonState = SaveButtonState.default;
    @observable titlesByState: Record<SaveButtonState, string> = {
        [SaveButtonState.default]: 'channels.save',
        [SaveButtonState.process]: 'actions.saving',
        [SaveButtonState.saved]: 'actions.saved',
        [SaveButtonState.error]: 'actions.error',
    };

    channelEditStore = new ChannelEditStore();

    iconUrls: Record<string, string> = {};
    constructor(private root: RootStore) {
        makeObservable(this);
    }

    @computed get d() {
        return this.currentChannel;
    }

    @action
    setChannel(id: number) {
        this.currentChannel = this.channels.find(i => i.id === +id);
    }

    @computed get popoverText() {
        const channelType = this.currentChannel.channel_type;
        return [
            'edna',
            'infobip',
            'webim2',
            'fb',
            'jivochat',
            'usedesk',
            'livechat',
        ].includes(channelType) ? `connect.${channelType}.popover_body` : 'connect.textback.popover_body';
    }

    @computed get isDisabledPopoverVisible() {
        return (this.currentChannel.channel_type === 'textback' && !this.currentChannel.params.textback_token)
            || (this.currentChannel.channel_type === 'chat2desk' && !this.currentChannel.params.chat2desk_token)
            || (this.currentChannel.channel_type === 'edna' && (!this.currentChannel.params.edna_token || !this.currentChannel.params.subdomain))
            || (this.currentChannel.channel_type === 'infobip' && (!this.currentChannel.params.infobip_token || !this.currentChannel.params.subdomain || !this.currentChannel.params.agent_id))
            || (this.currentChannel.channel_type === 'webim2' && (!this.currentChannel.params.webim_token || !this.currentChannel.params.subdomain || !this.currentChannel.params.redirect_department))
            || (this.currentChannel.channel_type === 'livechat' && (!this.currentChannel.params.client_id || !this.currentChannel.params.client_secret))
            || (this.currentChannel.channel_type === 'fb' && (!this.currentChannel.params.fb_token || !this.currentChannel.params.fb_page_access_token || !this.currentChannel.params.secondary_app_id))
            || (this.currentChannel.channel_type === 'jivochat' && (!this.currentChannel.params.jivochat_provider_id))
            || (this.currentChannel.channel_type === 'usedesk' && (!this.currentChannel.params.usedesk_token || !this.currentChannel.params.usedesk_operator || !this.currentChannel.params.usedesk_group));
    }

    get currentProject() {
        return this.root.projectStore.choosenProject;
    }

    @action.bound
    async loadConnectTypes() {
        if (this.connectTypes.length) return;
        const connects = await ConnectAPI.getChannelTypes();
        this.connectTypes.replace(connects);
        this.iconUrls = connects.reduce<Record<string, string>>((curr, prev) => {
            curr[prev.key] = prev.logo_url;
            return curr;
        }, {});
        this.isLoadTypes = true;
    }

    @computed
    get firstChannel() {

        return this.channels[0];
    }

    @action.bound
    async loadChannels(reload = false) {
        if (!reload && this.channels.length) return this.channels;
        const channels = await ConnectAPI.getChannels(this.currentProject);

        runInAction(() => {
            this.channels.replace(channels);
            this.isLoadChannels = true;
        });

        return this.channels;
    }

    @action.bound
    async loadLiaOperators(token: string){
        const limit = 200;
        let offset = 0;
        let data: any[] = [];
        let result;
        const response = await fetch(`https://api.chat2desk.com/v1/operators?offset=${offset}&limit=${limit}`, {
            headers: {
                'Authorization': token
            }
        });
        if (!response.ok) {
            throw new Error(`${response.status}`);
        } else {
            result = await response.json();
            data = data.concat(result.data);
            offset = offset + limit;
            while (result.data.length >= limit) {
                const response = await fetch(`https://api.chat2desk.com/v1/operators?offset=${offset}&limit=${limit}`, {
                    headers: {
                        'Authorization': token
                    }
                });

                if (!response.ok) {
                    throw new Error(`${response.status}`);
                }
                result = await response.json();
                data = data.concat(result.data);
                offset = offset + limit;
            }
        }
        return data;
    }

    @action.bound
    async loadChat2DeskGroups(token: string) {
        const response = await fetch('https://api.chat2desk.com/v1/operators_groups/', {
            headers: {
                'Authorization': token
            }
        });

        if (!response.ok) {
            throw new Error(`${response.status}`);
        } else {
            return response.json().then(d => d.data);
        }
    }

    @action.bound
    async saveChannel(channel: Channel) {
        const updatedChannel = await ConnectAPI.saveChannel(this.currentProject, channel);
        const _channel = this.channels.find(channelItem => channelItem.id === channel.id);
        Object.assign(_channel, updatedChannel);
        return _channel;
    }

    @action.bound
    async createNewConnection(connect: ConnectType) {
        const name = connect.key === 'api' ? connect.key.toUpperCase() : capitalize(connect.key);
        const sameName = this.channels.filter(c => c.name.replace(/\s\(\d\)$/g, '') === name);
        const is_active: boolean = true;
        let params: object = {
            color: '#636EFF',
            button: 'button'
        }
        if (connect.key === 'textback') {
            params = {
                mark_unread_on_terminate: true,
                silence_time_after_terminate: 14400
            }
        }
        if (connect.key === 'intercom') {
            params = {
                lia_operator_id: '',
                redirect_department: '',
                max_message_len: '',
                long_text_intent: '',
                intercom_url: ''
            };
        }

        if (connect.key === 'helpdeskeddy') {
            params = {
                unassign_on_terminate: true
            }
        }
        if (connect.key === 'chat2desk' || connect.key === 'edna' || connect.key === 'infobip'
            || connect.key === 'webim2' || connect.key === 'fb' || connect.key === 'jivochat'
            || connect.key === 'usedesk' || connect.key === 'livechat') {
            params = {}
        }
        const channel: Channel = {
            name: `${name}${sameName.length ? ` (${sameName.length})` : ''}`,
            is_active,
            params,
            channel_type: connect.key
        };

        const newChannel = await ConnectAPI.createNewChannel(this.currentProject, channel);
        this.channels.push(newChannel);
    }

    @action.bound
    async delete(channel: Channel) {
        await ConnectAPI.deleteChannel(this.currentProject, channel);
        const deletedChannel = this.channels.find(_channel => _channel.id === channel.id);
        if (deletedChannel)
            this.channels.remove(deletedChannel);
    }

    clearStore() {
        this.channels.replace([]);
    }
}
