import React from 'react';
import { Navigate, Route, Routes } from 'react-router';
import { inject, observer } from 'mobx-react';
import { RootStore } from '@/core/stores/root.store';
import cn from './EntitiesPage.module.scss';
import { Loader } from '@/common/components/Loader';
import { ZeroScreen } from '@/common/components/zero-screen/ZeroScreen';
import { EntityStore } from '../entity.store';
import { Entity } from './Entity';
import { Page } from '@/common/components/page/Page';
import { TestChat } from '../../chat/components/TestChat';
import { makeObservable, observable } from 'mobx';
import { WithTranslation, withTranslation } from 'react-i18next';
import { SideMenuRight } from '@/common/side-menu-right/SideMenuRight';
import { EntityMenu } from './entity-menu';
import { UserStore } from '@/core/stores/user.store';
import { RouteComponentProps } from '@/common/utils/withRouter';

interface EntitiesPageProps extends RouteComponentProps, WithTranslation {
    root?: RootStore;
    entityStore?: EntityStore;
    user?: UserStore;
}

@inject('root', 'entityStore', 'user')
@observer
export class EntitiesPageComp extends React.Component<EntitiesPageProps> {
    @observable loaded = false;

    constructor(props: EntitiesPageProps) {
        super(props);
        makeObservable(this);
    }

    async componentDidMount() {
        await this.props.entityStore!.load();
        this.loaded = true;
    }

    render() {
        if (!this.props.user.permissions.isViewEntities) {
            return <Navigate to={'/'}/>;
        }

        return <>
            <SideMenuRight>
                <EntityMenu entityStore={this.props.entityStore}/>
            </SideMenuRight>
            <div className={cn.wrapper}>
                {
                    this.loaded
                        ? <Routes>
                            <Route path={'/'} element={
                                <Page rightBar={<TestChat/>}><ZeroScreen
                                    title={this.props.t('entities.create_new_entity_title')}
                                    subtitle={
                                        <>{this.props.t('zero_screens.subtitle')}
                                            <br/>
                                            {this.props.t('entities.create_new_entity_title')}
                                        </>} /></Page>
                            }>
                            </Route>
                            <Route path={':id/:type?'} element={<Entity/>}/>
                            <Route element={<Navigate to={'/'}/>}/>
                        </Routes>
                        : <div className={cn.loader}><Loader/></div>
                }
            </div>
        </>;
    }
}

export const EntitiesPage = withTranslation()(EntitiesPageComp);
