import React from 'react';
import cns from 'classnames';
import { useDrop } from 'react-dnd';
import { DropItem } from '../models/DropItem';
import cn from './Node.module.scss';

interface DropZoneProps {
    types?: string[];
    accept: string[];
    onDrop?: (item: DropItem) => void;
    classes?: {
        drop?: string,
        hide?: string,
        over?: string
    }
}

export const DropZone = ({ accept, onDrop, classes, types }: DropZoneProps) => {
    const _classes = Object.assign({ drop: cn.drop, hide: cn.hideDrop, over: cn.dropOver }, classes);
    const [collected, dropRef] = useDrop({
        accept: types || accept,
        drop: onDrop,
		canDrop: (item) => item && accept.includes(item.type),
        collect: monitor => ({
            item: monitor.getItem(),
            isOver: monitor.isOver({ shallow: true }),
            canDrop: monitor.canDrop(),
        })
    });

    return <div ref={dropRef} className={cns(_classes.drop, { [_classes.hide]: !collected.canDrop, [_classes.over]: collected.isOver })}/>;
};
