import { API } from "../common/api";
import { LoginDTO } from "./models/login.dto";
import { SignupDto } from "./models/signup.dto";
import { User } from "../common/models/user";
import { ResetDto } from './models/reset.dto';
import { UserPatch } from '../common/models/user-patch';
import { TokenValidationResponse } from './models/token-validation.response';
import { PasswordSetDto } from './models/password-set.dto';

export class AuthAPI extends API {
    static login(data: LoginDTO): Promise<User> {
        return AuthAPI.post<User>('/login', data);
    }

    static signUp(data: SignupDto): Promise<User> {
        return AuthAPI.post<User>('/signup', data);
    }

    static async reset(data: ResetDto) : Promise<void> {
        await AuthAPI.post('/password-reset', data);
    }

    static validateToken(token: string, action: string = 'reset_password'): Promise<TokenValidationResponse> {
        return AuthAPI.post<TokenValidationResponse>('/token-validation', { token, action })
    }

    static passwordSet(data: PasswordSetDto): Promise<void> {
        return AuthAPI.post<void>('/password-set', data);
    }

    static sendActivationMail(email?: string): Promise<void> {
        return AuthAPI.post<void>('/activation-request', {
            email
        });
    }

    static activateAccount(data: PasswordSetDto): Promise<User> {
        return AuthAPI.post<User>('/activation', data);
    }

    static async logout(): Promise<void> {
        await AuthAPI.get('/logout');
    }

    static getUser(): Promise<User> {
        return AuthAPI.get('/user');
    }

    static patchUser(user: UserPatch): Promise<User> {
        return AuthAPI.patch<UserPatch, User>('/user', user);
    }
}
