import React from 'react';
import { ConnectDragSource, DragSourceHookSpec, DragSourceMonitor, useDrag } from 'react-dnd';

type AsDragSourceParams<DragObject, DropResult, CollectedProps, Props> = {
	type: DragSourceHookSpec<DragObject, DropResult, CollectedProps>['type'],
	item?: (props: Props, monitor: DragSourceMonitor<DragObject>) => DragObject;
	canDrag?: (props: Props) => boolean;
	end?: (props: Props, draggedItem: DragObject, monitor: DragSourceMonitor<DragObject, DropResult>) => void;
};

type DragCollectedProps = {
	canDrag: boolean;
    isDragging: boolean;
}

export type AsDragSourceProps = DragCollectedProps & {
	dragRef: ConnectDragSource;
};

export const asDragSource = <
	ComponentProps,
	DragObject,
	DropResult,
	C extends React.ComponentType<ComponentProps & AsDragSourceProps> = any,
>(Component: C, { type, item, end, canDrag }: AsDragSourceParams<DragObject, DropResult, DragCollectedProps, ComponentProps>) => {
	return ((props) => {
		const [collectedDrag, dragRef] = useDrag<DragObject, DropResult, DragCollectedProps>({
			type,
			item: (monitor) => {
				if (typeof end === 'function') return item(props, monitor);
				return;
			},
			end: (draggedItem, monitor) => {
				if (typeof end === 'function') end(props, draggedItem, monitor);
			},
			canDrag: () => {
				if (typeof canDrag === 'undefined') return true;
				if (typeof canDrag === 'boolean') return canDrag;
				return canDrag(props);
			},
			collect: monitor => ({
				isDragging: monitor.isDragging(),
				canDrag: monitor.canDrag(),
			})
		});

		return (
			<Component
				dragRef={ dragRef }
				isDragging={ collectedDrag.isDragging }
				canDrag={ collectedDrag.canDrag }
				{...props}
			/>
		);
	}) as React.ComponentType<React.ComponentProps<any> & AsDragSourceProps>
};
