import React, { useEffect, useState } from 'react';
import { Navigate, useParams } from 'react-router';

import { inject, observer } from 'mobx-react';

import cn from './LogChat.module.scss';
import { LogMessage } from './LogMessage';
import { LogChatStore } from './log-chat.store';
import { UserStore } from '@/core/stores/user.store';
import { QAStore } from '@/app/qa/qa.store';
import { IntentStore } from '@/app/intents/intent.store';
import { Spin } from 'antd';
import { LogsPageStore } from '@/app/logs/logs-page.store';

type LogChatProps = {
    user?: UserStore;
    qa?: QAStore;
    intentStore?: IntentStore;
    logsPageStore?: LogsPageStore;
}

const LogChatComp: React.FC<LogChatProps> = (props) => {
    const params = useParams();
    const userId = params.id;
    const [logChatStore] = useState(() => new LogChatStore(props.user!.currentProject, userId));
    const [pts, setPts] = useState(0);

    const markEvent = (eventId: string) => {
        setPts(pts + 1);
        return logChatStore.markEvent(eventId);
    };

    useEffect(() => {
        props.intentStore._load();
        props.qa._load(true);
    }, []);

    useEffect(() => {
        props.logsPageStore.fetchingMessages = logChatStore.fetching;
    }, [logChatStore.fetching])

    useEffect(() => {
        logChatStore.changeUser(userId);
    }, [userId]);

    return (<>
            {logChatStore.fetching && <div className={cn.loader}><Spin size={'large'}/></div>}
            { !logChatStore.fetching && <div className={cn.logsContainer}>
                {logChatStore.eventsByDate.map(it => {
                    return <React.Fragment key={it.date}>
                        <div className={cn.date}>{it.date}</div>
                        {it.events.map(event => <LogMessage pts={pts} markEvent={markEvent} event={event}
                                                            key={event.event_id}/>)}
                    </React.Fragment>
                })}
            </div> }
            {logChatStore.hasError && <Navigate to={`/app/${params.projectId}/logs`}/>}
        </>
    );
}

export const LogChat =
    inject('user', 'intentStore', 'qa')(
        observer(LogChatComp)
    );

