import React, { MouseEvent, Ref } from 'react';
import { useDropzone } from 'react-dropzone';
import cn from './ImportDropzone.module.scss';
import { useTranslation } from 'react-i18next';

export const InputDropzone = React.forwardRef(
    ({ onDrop }: { onDrop: (file: File[]) => void }, ref: Ref<HTMLInputElement>) => {
        const { t } = useTranslation();
        const onDownloadSample = (e: MouseEvent) => {
            e.stopPropagation();
            const a = document.createElement('a');
            const fileName = 'sample.csv';
            const file = new File([], fileName);
            a.href = URL.createObjectURL(file);
            a.download = fileName;
            a.click();
        };

        const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop, accept: { 'text/csv': ['.csv'] } });
        /**
         * Workaround to pass ref to Parent
         */
        (ref as any).current = (getInputProps() as any).ref.current;
        return <div {...getRootProps()} className={cn.root}>
            <input {...getInputProps()} />
            {
                isDragActive ? <h1>{t('import_fields.drop_here')}</h1> : <h1>{t('import_fields.drop_csv')}</h1>
            }
            <a onClick={onDownloadSample} className={cn.sample}>{t('import_fields.download_sample')}</a>
        </div>
    }
);
