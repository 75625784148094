import { makeObservable, observable, runInAction } from 'mobx';
import { CompanyAPI } from './company.api';
import { Company } from './models/company';

export class ProfileStore {
    @observable company: Company = {
        industry: 'Software',
        workers_count: '1 - 10',
        website :'http://',
        language: ''
    };

    @observable isLoaded = false;

    constructor() {
        makeObservable(this);
    }

    async getCompany() {
        const company = await CompanyAPI.getCompany();

        runInAction(() => {
            this.company = company;
            this.isLoaded = true;
        });

        return company;
    }

    async saveProfile(company: Company) {
        this.company = await CompanyAPI.saveCompany(company);
    }
}
