import React from 'react';
import cns from 'classnames';
import cn from './Page.module.scss';


type PageProps = {
    actionMenu?: React.ReactNode;
    rightBar?: React.ReactNode;
    editorPadding?: boolean;
    hideShadow?: boolean;
    className?: string;
    children: React.ReactNode;
}

export class Page extends React.PureComponent<PageProps> {
    render() {
        const padding = this.props.editorPadding != null ? this.props.editorPadding : true;
        return <div className={cn.wrapper}>
            <div className={cn.editorWrapper}>
                <div className={cn.editor}>
                    {!this.props.hideShadow && <div className={cn.shadow}/>}
                    <div className={cns(this.props.className, cn.editorContent, { [cn.padding]: padding })}>
                        {this.props.children}
                    </div>
                </div>
                {!!this.props.actionMenu && <div className={cn.actionMenu}>{this.props.actionMenu}</div>}
            </div>
            {this.props.rightBar && <div className={cn.rightBar}>{this.props.rightBar}</div>}
        </div>;
    }
}
