import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const BlueNodeStyled = styled.div`
    width: 300px;
    min-height: 100px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    background-color: #116FFC;
`;

export const Body = styled.div`
    padding: 7px 15px;
    color: #fff;
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    height: 56px;
`;



export const WhiteBody = styled(Body)`
    color: #000;
`;

export const FullWhiteBody = styled.div`
    padding: 7px 15px;
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
    color: #000;
`;

export const BlackLink = styled(Link)`
    color: #000;

    &:hover {
        opacity: 0.9;
        color: #000;
        border-bottom: 1px dashed #000;
    }
`;

export const WhiteLink = styled(Link)`
    color: #fff;

    &:hover {
        opacity: 0.9;
        color: #fff;
        border-bottom: 1px dashed #fff;
    }
`;

export const ExternalLink = styled.a`
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
    color: #000;

    &:hover {
        opacity: 0.9;
        color: #000;
        border-bottom: 1px dashed #000;
    }
`;


export const SnippetNodeStyled = styled(BlueNodeStyled)`
    background: #FFFFE9;
`;

export const JumpToNodeStyled = styled(BlueNodeStyled)`
    background: #F5E9FF;
`;

export const WhiteNodeStyled = styled(BlueNodeStyled)<{isFull?: boolean}>`
    background: #fff;
`;

export const StateNodeStyled = styled(BlueNodeStyled)`
    background: #F2F7FF;
    padding: 0;
    width: 100px;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const ImageNodeStyled = styled(BlueNodeStyled)`
`;

export const BaseUrlNodeStyled = styled(BlueNodeStyled)`
`;


export const Title = styled.div`
    text-align: left;
    padding-left: 15px;
    height: 26px;
    display: flex;
    align-items: center;
    width: 100%;
    background-color: #1264DF;

    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #fff;
`;

export const SnippetTitle = styled(Title)`
    background-color: #F8F8C6;
    color: #000;
`

export const SelectSnippet = styled.span`
  color: #000;
`

export const JumpTitle = styled(Title)`
    background-color: #F7C6F8;
    color: #000;
`

export const WhiteTitle = styled(Title)`
    background-color: #F4F4F4;
    color: #000;
`

export const StateTitle = styled.div`
    background-color: #fff;
    color: #000;
    width: 80px;
    height: 80px;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
`

export const StateZeroOpacityWrapper = styled.div`
    display: flex;
    width: 300px;
    justify-content: center;
`;
