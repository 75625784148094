import React, { ChangeEvent } from 'react';
import { inject, observer } from 'mobx-react';
import { EntityStore } from '../../entities/entity.store';
import { Entity } from '../../entities/models/entity';
import cn from './EntityEditor.module.scss';
import cns from 'classnames';
import { action, computed, makeObservable } from 'mobx';
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';
import ChevronDownIcon from 'mdi-react/ChevronDownIcon';
import { EntityExtractor } from '../../entities/models/entity-extractor';
import cnForm from '@/common/scss/form.module.scss';
interface EntityEditorProps {
    entityStore?: EntityStore;
    entity: Entity;
}

@inject('entityStore')
@observer
export class EntityEditor extends React.Component<EntityEditorProps> {
    @computed get types() {
        return this.props.entityStore!.extractors;
    }

    constructor(props: EntityEditorProps) {
        super(props);
        makeObservable(this);
    }

    @action.bound
    onChangeType = (extract: EntityExtractor) => () => {
        this.props.entity.extractor_type = extract.key;
        this.props.entity.extractor_params = {};
    };

    @action.bound
    onChangeName(e: ChangeEvent<HTMLInputElement>) {
        this.props.entity.name = e.target.value;
    }

    render() {
        return <div className={cn.editor}>
            <input type="text" onChange={this.onChangeName} className={cns(cn.input, 'form-control')}
                   value={this.props.entity.name}/>
            <UncontrolledDropdown className={cns(cn.editorForm, cnForm.formGroupDropdown)}>
                <DropdownToggle
                    outline
                    color="primary">
                    <p>{this.props.entity.extractor_type}<ChevronDownIcon/></p>
                </DropdownToggle>
                <DropdownMenu right>
                    { this.types.map(extract => <DropdownItem key={extract.key}
                                                              onClick={this.onChangeType(extract)}>{extract.name}</DropdownItem>) }
                </DropdownMenu>
            </UncontrolledDropdown>
            {/*<select className={cns(cn.selector, 'form-control')} defaultValue={this.props.entity.extractor_type}*/}
                    {/*onChange={this.onChangeType}>*/}
                {/*{this.types.map(extract => <option value={extract.key} key={extract.key}>{extract.name}</option>)}*/}
            {/*</select>*/}
        </div>
    }
}
