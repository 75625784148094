import React, { FC, useEffect } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { useTranslation } from 'react-i18next';
import { Channel } from '../../models/channel';
import cn from './Connection.module.scss';
import { Button, Form, Space, Input } from 'antd';
import { CopyOutlined } from '@ant-design/icons';
import { getCurrentDomain } from '@/common/utils/get-current-domain';
import { ChannelEditStore } from '@/app/connects/components/ConnectPageContent';

type WebimProps = {
    channel: Channel;
    onFinishForm: (values: any) => void;
    channelEditStore: ChannelEditStore;
}

export const Webim: FC<WebimProps> = ({
                                                                      channel,
                                                                      channelEditStore,
                                                                      onFinishForm
                                                                  }) => {
    const {t} = useTranslation();
    const [form] = Form.useForm();
    channelEditStore.setCurrentEditForm(form);
    useEffect(() => form.resetFields(), [channel.id]);

    const liaWebHook = `${getCurrentDomain()}/channels/webim?key=${channel.api_key}`;
    return <Form form={form} style={{maxWidth: '600px'}} layout="vertical" onFinish={onFinishForm}>
        <Form.Item label={t('connect.webim.input_label')}>
            <Space.Compact
                style={{
                    width: '100%',
                }}
            >
                <Input value={liaWebHook} readOnly/>
                <CopyToClipboard text={liaWebHook} onCopy={() => alert(t('actions.copied'))}>
                    <Button type="default" icon={<CopyOutlined/>}/>
                </CopyToClipboard>
            </Space.Compact>
            <a
                className={cn.formFieldLink}
                target="_blank"
                rel="noopener noreferrer"
                href="https://webim.ru/kb/dev/12394-robot-external-api/"
            >
                {t('connect.webim.link')}
            </a>
        </Form.Item>
    </Form>
}
