import * as React from 'react';
import { action, makeObservable, observable } from 'mobx';
import cn from './Markup.module.scss';
import { inject, Observer, observer } from 'mobx-react';
import { Input } from 'reactstrap';
import { WithTranslation, withTranslation } from 'react-i18next';
import cns from 'classnames';
import { QAStore } from '@/app/qa/qa.store';
import { WithNameAndType } from '@/common/components/chat/models';
import { MarkupElement } from '@/common/components/chat/MarkupElement';
import { TooltipPopper, TTooltipPopperFnParams } from '../TooltipPopper';

interface ChatIntentProps extends WithTranslation {
    qa?: QAStore;
    onSelect: (selected: any) => void;
}

const tooltipProps = {
    className: cns('tooltip-container', cn.tooltipContainerMarkup)
    /* your props here */
};

@inject('qa')
@observer
export class ChatIntentComp extends React.Component<ChatIntentProps> {
    @observable isOpenIntents = false;
    @observable selected?: WithNameAndType;
    @observable elements: any[] = [];

    constructor(props: ChatIntentProps) {
        super(props);
        makeObservable(this);
        this.search('');
    }

    onVisibilityChange = (visibility: boolean) => {
        this.isOpenIntents = visibility;
    };

    onSave() {
        this.isOpenIntents = false;
    }

    @action
    selectMarkup = (element: WithNameAndType) => {
        this.selected = element;
    };

    async search(text: string) {
        const elements = await this.props.qa.intentQaSearch(text);
        this.elements = elements;
    }

    @action.bound
    async onChangeSearch(e: React.ChangeEvent<HTMLInputElement>) {
        const text = e.target.value;

        this.search(text)
    }

    renderElement = (element: WithNameAndType) => {
        const selected = this.selected
            ? element.id === this.selected.id && element.type === this.selected.type
            : false;

        return <div key={element.id}>
            <MarkupElement onSelectMarkupElement={this.selectMarkup} element={element} selected={selected}/>
        </div>
    };

    renderDropDown = ({
                          getTooltipProps,
                          setTooltipRef
                      }: TTooltipPopperFnParams) => {
        return <div
            ref={setTooltipRef}
            {...getTooltipProps(tooltipProps)}
        >
            <div className={cn.popper}>
                <div className={cn.searchWrapper}>
                    <Input autoFocus={true} bsSize="sm"
                           onChange={this.onChangeSearch}
                           placeholder={this.props.t('chat.search_intent')} className={cn.input}/>
                </div>
                <Observer>
                    {() => <div className={cn.list}>
                        {this.elements.map(this.renderElement)}
                    </div>}
                </Observer>
                <div className={cn.bottom}>
                    <button className={cns(cn.cancel, 'btn btn-outline')} onClick={() => this.isOpenIntents = false}>
                        {this.props.t('actions.cancel')}
                    </button>
                    <button disabled={!this.selected} onClick={() => {
                        this.props.onSelect(this.selected);
                        this.isOpenIntents = false;
                    }} className={cns(cn.ok, 'btn btn-success btn-sm')}>{this.props.t('actions.ok')}</button>
                </div>
            </div>
        </div>
    };

    render() {
        return <TooltipPopper
            placement="bottom-start"
            trigger="click"
            closeOnOutsideClick={true}
            onVisibilityChange={this.onVisibilityChange}
            tooltipShown={this.isOpenIntents}
            tooltip={this.renderDropDown}
            triggerContent={this.props.t('chat.trigger_action')}
            offset={[248, 6]}
        />;
    }
}

export const ChatIntent = withTranslation()(ChatIntentComp);
