import { API } from '../../common/api';
import { Project } from '../../common/models/project';
import { Pagination } from '../../common/models/pagination';
import { Group } from '../../common/models/group';
import { EntityExtractor } from './models/entity-extractor';
import { Entity } from './models/entity';

export class EntitiesAPI extends API {
    static getAll(project: Project, pagination?: Pagination): Promise<Entity[]> {
        return EntitiesAPI.get<Entity[]>(`/projects/${project.id}/entities`, pagination);
    }

    static getGroups(project: Project, pagination?: Pagination): Promise<Group[]> {
        return EntitiesAPI.get<Group[]>(`/projects/${project.id}/entity-groups`, pagination);
    }

    static createEntity(project: Project, entity: Entity): Promise<Entity> {
        return EntitiesAPI.post<Entity>(`/projects/${project.id}/entities`, entity);
    }

    static createGroup(project: Project, group: Group): Promise<Group> {
        return EntitiesAPI.post<Group>(`/projects/${project.id}/entity-groups`, group);
    }

    static updateGroup(project: Project, group: Group): Promise<Group> {
        return EntitiesAPI.put<Group>(`/projects/${project.id}/entity-groups/${group.id}`, group);
    }

    static updateEntity(project: Project, entity: Entity): Promise<Entity> {
        return EntitiesAPI.put<Entity>(`/projects/${project.id}/entities/${entity.id}`, {...entity, id: undefined });
    }

    static patchEntity(project: Project, entity: any): Promise<any> {
        return EntitiesAPI.patch<any, any>(`/projects/${project.id}/entities/${entity.id}`, {...entity, id: undefined });
    }

    static removeGroup(project: Project, groupId: number): Promise<void> {
        return EntitiesAPI.delete<Group>(`/projects/${project.id}/entity-groups/${groupId}`);
    }

    static async deleteEntity(project: Project, entity: Entity): Promise<void> {
        return EntitiesAPI.delete(`/projects/${project.id}/entities/${entity.id}`);
    }

    static getExtractors(language: string): Promise<EntityExtractor[]> {
        return EntitiesAPI.get<EntityExtractor[]>(`/entity-extractor-types`, { language });
    }
}
