import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { action, computed } from 'mobx';
import { observer } from 'mobx-react';
import cn from './Flow.module.scss';
import { FlowArrow } from './FlowArrow';
import { Flow as FlowModel } from '../models/Flow';
import { DropZone } from './DropZone';
import { reactionTypes } from '../models/reactions/ReactionTypes';
import { triggerTypes } from '../models/triggers/TriggerTypes';
import { DropItem } from '../models/DropItem';
import { DropFactory } from '../factories/DropFactory';
import { StateOrReaction } from './StateOrReaction';

interface FlowComponentProps extends WithTranslation {
    flow: FlowModel;
}

@observer
class FlowComponent extends React.Component<FlowComponentProps> {
    @computed get flow() {
        return this.props.flow;
    }

    @computed get showDropZone(): boolean {
        return !this.flow.next;
    }

    render() {
        return <div style={{minHeight: '100%', paddingBottom: '50%'}}>
            {FlowComponent.renderStartArrow()}
            {this.showDropZone && this.renderDropZone()}
            {this.flow.next && <div>
                <DropZone onDrop={this.onDrop} accept={[...reactionTypes, ...triggerTypes]}/>
            </div>}
            {this.flow.next && <StateOrReaction next={this.flow.state || this.flow.reaction!}/>}
        </div>;
    }

    @action.bound
    private onDrop = (drop: DropItem) => {
        const next = DropFactory.build(drop, this.flow);
        this.flow.insertNext(next);
    }

    private static renderStartArrow() {
        return <div className={cn.arrow}>
            <FlowArrow/>
        </div>
    }

    private renderDropZone() {
        return <div className={cn.dropWrapper}>
            {this.props.t('flows.flows_tree_initial')}
            <DropZone onDrop={this.onDrop} accept={[...reactionTypes, ...triggerTypes]}
                      classes={{drop: cn.drop, over: cn.over}}/>
        </div>;
    }
}

export const Flow = withTranslation()(FlowComponent);
