import React, { FC, useEffect } from 'react';
import { observer } from 'mobx-react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { useTranslation } from 'react-i18next';
import { Channel } from '../../models/channel';
import { ChannelEditStore } from '@/app/connects/components/ConnectPageContent';
import { Button, Form, Input, Space } from 'antd';
import { getCurrentDomain } from '@/common/utils/get-current-domain';
import { useMaxLength, useRequired } from '@/common/utils/form-validators';
import { CopyOutlined } from '@ant-design/icons';

type InfobipProps = {
    channel: Channel;
    channelEditStore: ChannelEditStore;
    isManageChannels: boolean;
    onFinish: (values: any) => void;
    isRequest: boolean;
}

export const Infobip: FC<InfobipProps> = observer(({
                                                                                   channel,
                                                                                   isManageChannels,
                                                                                   channelEditStore,
                                                                                   onFinish,
                                                                                   isRequest
                                                                               }) => {
    const {t} = useTranslation();
    const [form] = Form.useForm();
    channelEditStore.setCurrentEditForm(form);
    const liaWebHook = `${getCurrentDomain()}/channels/infobip?key=${channel.api_key}`;
    const onFinishForm = (values: any) => {
        onFinish(values);
    }

    useEffect(() => form.resetFields(), [channel.id]);

    const required = useRequired();
    const maxLength = useMaxLength(4096);

    const initialValues = {
        subdomain: channel.params.subdomain,
        infobip_token: channel.params.infobip_token,
        agent_id: channel.params.agent_id,
    };

    return <Form form={form} style={{maxWidth: '600px'}} layout="vertical" onFinish={onFinishForm}
                 initialValues={initialValues}>
        <Form.Item label={t('connect.infobip.input_label_link')}>
            <Space.Compact
                style={{
                    width: '100%',
                }}
            >
                <Input value={liaWebHook} readOnly/>
                <CopyToClipboard text={liaWebHook} onCopy={() => alert(t('actions.copied'))}>
                    <Button type="default" icon={<CopyOutlined/>}/>
                </CopyToClipboard>
            </Space.Compact>
        </Form.Item>

        <Form.Item rules={[required, maxLength]} name="subdomain"
                   label={t('connect.infobip.input_label_subdomain')}>
            <Input disabled={!isManageChannels || isRequest}/>
        </Form.Item>

        <Form.Item rules={[required, maxLength]} name="infobip_token" label={t('connect.infobip.input_label_token')}>
            <Input disabled={!isManageChannels || isRequest}/>
        </Form.Item>

        <Form.Item rules={[required, maxLength]} name="agent_id"
                   label={t('connect.infobip.input_label_agent_id')}>
            <Input disabled={!isManageChannels || isRequest}/>
        </Form.Item>
    </Form>
});
