import { action, makeObservable, observable } from 'mobx';
import { Script } from '../models/Script';
import { Label } from '../models/Label';
import { LoadableStore } from '@/common/store';
import { ScriptPageStore } from './script-page.store';
import { ScriptsAPI } from '../api/scripts.api';
import { ProjectStore } from '@/core/stores/project.store';
import { MapperFactory } from '../mappers/MapperFactory';

export class LabelsStore extends LoadableStore {
    @observable private _cache: Map<string, Label> = new Map();
    @observable scripts: Array<Script> = [];

    constructor(
        private scriptPageStore: ScriptPageStore,
        private projectStore: ProjectStore,
    ) {
        super();
        makeObservable(this);
    }

    clear() {
        this._cache.clear();
        this.markAsNotLoad();
    }

    async _load() {
        if (this.scriptPageStore.script && this.scriptPageStore.script.data) {
            this._saveLabels(this.scriptPageStore.script.data.labels, this.scriptPageStore.script)
        }

        if (this.scriptPageStore.script.id) {
            const labels = await ScriptsAPI.getScriptLabels(this.projectStore.choosenProject, this.scriptPageStore.script);
            this._saveLabels(labels);
        }

        await this._loadFLows();
    }

    private _saveLabels(labels: Array<Label>, script?: Script) {
        labels.forEach(l => {
            if (this._cache.has(l.id)) {
                this._cache.set(l.id, {script, ...this._cache.get(l.id), ...l});
            } else {
                this._cache.set(l.id, {script, ...l});
            }
        });
    }

    public get(next: string): Label | undefined {
        return this._cache.get(next);
    }

    public get all(): Array<Label> {
        return Array.from(this._cache.values());
    }

    @action.bound
    private _loadFLows = async (): Promise<Array<Script>> => {
        if (!this.scripts.length) {
            const response = await ScriptsAPI.getAll(this.projectStore.choosenProject, {page: 1, per_page: 1000});
            if (response.results && response.results.length > 0) {
                const mapper = MapperFactory.buildMapper(response.results[0]);

                this.scripts = response.results.map(dto => mapper.fromDTO(dto));
            }
        }

        return this.scripts;
    }

    @action.bound
    getLabelsForScript = async (script: Script): Promise<Array<Label>> => {
        const labels = await ScriptsAPI.getLabels(this.projectStore.choosenProject, script);
        this._saveLabels(labels, script);

        return labels;
    }
}
