import React from 'react';
import classnames from 'classnames';

import cn from './IntentItem.module.scss';

interface IntentItemProps {
    name: string;
    draggable?: boolean;
    additional?: any;
    intentRef?: (el: HTMLButtonElement | null) => void;
    className?: string;
    id?: string;
}

export class IntentItem extends React.PureComponent<IntentItemProps> {
    render() {
        return <button id={this.props.id} draggable={this.props.draggable}
                       ref={el => this.props.intentRef && this.props.intentRef(el)}
                       className={classnames("btn btn-outline-primary btn-sm", cn.intent, this.props.className)}
        >{this.props.name}</button>;
    }
}
