import { makeObservable, observable, reaction } from 'mobx';
import { Filter } from '@/app/stats/AnalyticsFilter/AnalyticsFilter.component';
import { IntentAnalyticsData, Version } from '@/app/stats/Types';
import { getChartData, getTableData } from '@/app/stats/IntentAnalytics/helpers';
import { notification } from 'antd';
import { ProjectStore } from '@/core/stores/project.store';
import { cloneDeep } from 'lodash-es';
import { IntentAnalyticsApi } from '@/app/stats/IntentAnalytics/api/intentAnalyticsApi';
import { uniqBy } from 'lodash';
import { ViewValue } from '@/app/stats/IntentAnalytics/types';
import { SystemValue } from '@/app/stats/SystemFilter/SystemFilter.component';

export class IntentAnalyticsStore {
    // @ts-ignore
    @observable tableData = null;
    // @ts-ignore
    @observable columns = null;
    // @ts-ignore
    @observable chartData = [];
    @observable selectedRows: Record<string, {
        selected: boolean,
    }> = {};
    @observable maxKeys: Record<string, number> = {};
    @observable max = 0;
    @observable currentFilter: Filter = {
        periodKey: 'day',
        period: 7
    };
    @observable isLoading: boolean = null;
    @observable isEmpty: boolean = true;
    @observable keys: { key: string; label: string; }[] = [];
    @observable selectedRowKeys: string[] = [];
    @observable versions: Version[] = [];
    @observable labels: Record<number, Record<string, Record<string, string>>> = {};
    @observable private cachedRequest: IntentAnalyticsData = null;
    @observable viewValue: ViewValue = 'absolute_volume';
    @observable value: SystemValue = 'all';

    constructor(private projectStore: ProjectStore) {
        makeObservable(this);

        reaction(() => this.currentFilter, () => this.updateAnalytics());
        reaction(() => this.projectStore.choosenProject.id, () => this.updateAnalytics());
        reaction(() => this.viewValue, () => this.updateLocalAnalytics());
        reaction(() => this.value, () => this.updateLocalAnalytics());
        this.updateAnalytics();
    }

    setCurrentFilter(filter: Filter) {
        this.currentFilter = filter;
    }

    downloadCsv() {
        return IntentAnalyticsApi.downloadCsv(this.projectStore.choosenProject, this.currentFilter);
    }

    onChangeTable = (selectedRowKeys: string[]) => {
        this.selectedRowKeys = selectedRowKeys;

        Object.keys(this.selectedRows).forEach(key => {
            this.selectedRows[key].selected = false;
        });

        selectedRowKeys.forEach(selectedKey => {
            this.selectedRows[selectedKey].selected = true;
            this.selectedRows = cloneDeep(this.selectedRows);
        });

        if (selectedRowKeys.length === 0) {
            Object.keys(this.selectedRows).forEach(key => {
                this.selectedRows[key].selected = false;
            });
            this.selectedRows = cloneDeep(this.selectedRows);
        }


        this.max = Math.max(...selectedRowKeys.map(key => this.maxKeys[key]));
    };

    setViewValue = (value: ViewValue) => {
        this.viewValue = value;
    }

    setSystemValue = (value: SystemValue) => {
        this.value = value;
    }

    updateLocalAnalytics = () => {
        this.updateViewDate(this.cachedRequest);
    }

    updateViewDate(response: IntentAnalyticsData) {
        const {
            tableColumns,
            tableData,
            defaultSelected
        } = getTableData(response, this.viewValue, this.value);
        const {
            chartData,
            labels,
            maxByKey,
            keys,
            selected,
            max
        } = getChartData(response, this.viewValue, this.value);

        this.columns = tableColumns;
        this.isEmpty = false;
        this.tableData = tableData;
        this.keys = keys;
        this.selectedRows = selected;
        this.chartData = chartData;
        this.isLoading = false;
        this.max = max;
        this.maxKeys = maxByKey;
        this.selectedRowKeys = defaultSelected;
        this.labels = labels;
    }

    updateAnalytics() {
        this.isLoading = true;
        IntentAnalyticsApi.updateAnalytics(this.projectStore.choosenProject, this.currentFilter).then((response: IntentAnalyticsData) => {
            // TODO: убрать это
            response.elements = uniqBy(response.elements, 'key');
            this.cachedRequest = response;
            if (!response || !response.points) {
                this.isEmpty = true;
                this.isLoading = false;
                return;
            }

            this.updateViewDate(response);
        }).catch((error) => {
            console.error(error);
            notification.error({
                message: 'Ошибка получения данных',
                duration: 3
            })
            this.isLoading = false;
        })
    }
}
