import React from 'react';
import { Input } from 'antd';
import { makeObservable, observable } from 'mobx';
import { observer } from 'mobx-react';
import cn from './EntityCommon.module.scss';
import { WithTranslation, withTranslation } from 'react-i18next';

interface EntityRegExpProps extends WithTranslation {
    onChange: (modelToSave: { regexp: string }) => void;
    modelToSave: { regexp: string };
    canEdit: boolean;
}

@observer
export class EntityRegExpComp extends React.Component<EntityRegExpProps> {
    @observable modelToSave = {regexp: ''};

    constructor(props: EntityRegExpProps) {
        super(props);
        makeObservable(this);
        this.onPropsUpdate();
    }

    onChange = (regexp: string) => {
        this.modelToSave.regexp = regexp;
        this.props.onChange({regexp});
    };

    UNSAFE_componentWillReceiveProps(props: EntityRegExpProps) {
        this.onPropsUpdate(props);
    }

    onPropsUpdate(props?: EntityRegExpProps) {
        if (!props) {
            props = this.props;
        }
        this.modelToSave = props.modelToSave || {regexp: ''};
    }

    render() {
        return <div className={cn.formField}>
            <label className={cn.formFieldLabel}
                   htmlFor="entity-regexp">{this.props.t('entities.regexp.regexp')}</label>
            <Input disabled={!this.props.canEdit} id="entity-regexp" value={this.modelToSave.regexp}
                   onChange={e => this.onChange(e.target.value)}/>
        </div>
    }
}

export const EntityRegExp = withTranslation()(EntityRegExpComp);
