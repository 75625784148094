import React from 'react';
import { inject, observer } from 'mobx-react';
import cn from './EntityPopper.module.scss';
import { IntentsPageStore } from '../intents-page.store';
import { Entity } from '../../entities/models/entity';
import { EntityExtractor } from '../../entities/models/entity-extractor';
import { IntentExamplePart } from '../models/Intent';
import { WithTranslation, withTranslation } from 'react-i18next';

interface EntityPopperProps extends WithTranslation {
    store?: IntentsPageStore;
    part: IntentExamplePart;
    onChangeEntity: (entity: Entity) => void;
    onCreateEntity: (extractor: EntityExtractor) => void;
}

@inject('store')
@observer
export class EntityPopperComp extends React.Component<EntityPopperProps> {
    render() {
        const usedEntities = this.props.store!.usedEntities
            .filter(entity => this.props.part.entity_id! !== entity.id);
        return <div className={cn.popper}>
            {
                usedEntities.length > 0 &&
                <>
                    <ul className={cn.blue}>
                        {usedEntities.map((entity, i) => (
                            <li key={i} className={cn.listItem} onClick={() => this.props.onChangeEntity(entity)}>
                                {entity.name}
                                <span className={cn.type}>{entity.extractor_type}</span>
                            </li>
                        ))}
                    </ul>
                    <hr className={cn.hr}/>
                </>
            }
            {
                this.props.store!.customEntities.length > 0 &&
                <>
                    <h2 className={cn.listHeader}>{ this.props.t('entities.custom_entities') }</h2>
                    <ul className={cn.secondary}>
                        {this.props.store!.customEntities.map((entity, i) => (
                            <li key={i} className={cn.listItem} onClick={() => this.props.onChangeEntity(entity)}>
                                {entity.name}
                                <span className={cn.type}>{entity.extractor_type}</span>
                            </li>
                        ))}
                    </ul>
                    <hr className={cn.hr}/>
                </>
            }
            {
                this.props.store!.types.length > 0 &&
                <>
                    <h2 className={cn.listHeader}>{ this.props.t('entities.create_from_list') }</h2>
                    <ul className={cn.primary}>
                        {this.props.store!.types.map((type, i) => (
                            <li key={i} className={cn.listItem}
                                onClick={() => this.props.onCreateEntity(type)}>{type.name}</li>
                        ))}
                    </ul>
                </>
            }
        </div>
    }
}
export const EntityPopper = withTranslation()(EntityPopperComp);
