import { API } from '@/common/api';
import { PublishVersion, PublishVersionRaw } from '@/app/publish/PublishPage.model';
import { camelCaseKeys } from '@/common/utils/camel-case-keys';
import { stringsToDatesInObj } from '@/common/utils/strings-to-dates-in-obj';

export class PublishPageApi extends API {
    private static processVersion(ver: PublishVersionRaw): PublishVersion {
        const version = camelCaseKeys(ver) as PublishVersion;
        return stringsToDatesInObj(version);
    }

    /**
     * Cписок версий
     */
    static getVersions(projectID: number) {
        return PublishPageApi.get<PublishVersionRaw[]>(`/projects/${projectID}/versions`)
            .then(versions => versions.map(this.processVersion));
    }

    /**
     * Информация по версии
     */
    static getVersion(projectID: number, versionID: number) {
        return PublishPageApi.get<PublishVersionRaw>(`/projects/${projectID}/versions/${versionID}`)
            .then(version => this.processVersion(version));
    }

    /**
     * Экспорт версии
     */
    static exportVersion(projectID: number, versionID: number) {
        return PublishPageApi.get(`/projects/${projectID}/versions/${versionID}/export`);
    }

    /**
     * Экспорт текущей версии проекта (нужен для скачивания Draft version)
     */
    static exportCurrentVersion(projectID: number) {
        return PublishPageApi.get(`/projects/${projectID}/export`);
    }

    /**
     * Восстановление из версии
     */
    static restoreVersion(projectID: number, versionID: number) {
        return PublishPageApi.post(`/projects/${projectID}/versions/${versionID}/restore`, {});
    }

    /**
     * Редактирование версии
     */
    static editVersion(projectID: number, versionID: number, params: Partial<PublishVersionRaw>) {
        return PublishPageApi.put(`/projects/${projectID}/versions/${versionID}`, params);
    }

    /**
     * Удаление версии
     */
    static removeVersion(projectID: number, versionID: number) {
        return PublishPageApi.delete(`/projects/${projectID}/versions/${versionID}`);
    }
}
