import React from 'react';

import { WithTranslation, withTranslation } from 'react-i18next';
import { Button } from 'reactstrap';
import cns from 'classnames';

import cn from './ActivityLogsFilter.module.scss';
import cnCommon from '@/common/scss/form.module.scss';

import { ActivityLogFilters, ActivityLogFiltersData } from '@/common/models/activity-logs';
import { Select, SelectOption } from '@/common/components/select/Select';
import { getUserName } from '@/common/utils/get-user-name';
import { DateRange, DateRangeChangeEvent } from '@/common/date-range/DateRange';
import { CircularProgress } from '@mui/material';

interface ActivityLogsFilterProps extends WithTranslation {
    filter: ActivityLogFilters;
    filterData: ActivityLogFiltersData;
    isApplying: boolean;
    isResetting: boolean;
    onFilterChange: (f: ActivityLogFilters) => void;
    onApply: () => void;
    onReset: () => void;
}

type SelectFiltersData = Record<keyof ActivityLogFiltersData, SelectOption[]>;

const processData = (fd: ActivityLogFiltersData): SelectFiltersData => {
    const simpleFields: (keyof ActivityLogFiltersData)[] = ['actions', 'components', 'items'];
    const result: SelectFiltersData = {
        actions: [],
        components: [],
        items: [],
        projectVersions: [],
        projects: [],
        users: []
    };

    simpleFields.forEach(key => {
        // @ts-ignore
        result[key] = fd[key].map(d => ({ value: d, name: d }));
    });

    result.projects = fd.projects.map(d => ({ value: d.id, name: d.name }));
    result.projectVersions = fd.projectVersions.map(d => ({ value: d.id, name: d.name }));
    result.users = fd.users.map(d => ({ value: d.id, name: getUserName(d) }));

    return result;
};

const ActivityLogsFilterComp: React.FunctionComponent<ActivityLogsFilterProps> = props => {
    const fd = props.filterData && processData(props.filterData);
    const f = props.filter;

    const changeFilter = (data: Partial<ActivityLogFilters>) =>
        props.onFilterChange(Object.assign({}, f, data));

    const onChange = (field: keyof ActivityLogFilters) => (val: any) =>
        changeFilter({ [field]: val });

    const onChangeDate = ({ startDate, endDate }: DateRangeChangeEvent) =>
        changeFilter({ dateFrom: startDate, dateTo: endDate });

    const submit = () => props.onApply();
    const reset = () => props.onReset();

    const renderSelect = (field: keyof SelectFiltersData) => (
        <div className={cn.input}>
            <Select
                disabledFirst={false}
                selectProps={{ className: cn.select }}
                value={f[field]}
                label={props.t(`activity_logs.filter.${field.slice(0, -1)}`)}
                onChange={onChange(field)}
                options={fd[field]}
            />
        </div>
    );

    return (
        <div className={cn.filters}>
            <div className={cn.filtersLine}>
                {renderSelect('users')}
                {renderSelect('actions')}
                {renderSelect('components')}
                {renderSelect('items')}
            </div>
            <div className={cn.filtersLine}>
                <div className={cn.input}>
                    <DateRange
                        className={cns(cnCommon.inputFilter, cnCommon.datePicker)}
                        startDate={f.dateFrom}
                        endDate={f.dateTo}
                        onChange={onChangeDate}
                    />
                </div>
                {renderSelect('projectVersions')}
                {renderSelect('projects')}
                <div className={cn.filtersControls}>
                    <Button
                        color="primary"
                        disabled={props.isApplying}
                        onClick={submit}
                        className={cn.submitBtn}
                    >
                        {props.t('actions.ok')}
                        {props.isApplying && <CircularProgress size={24} className={cn.btnLoader} />}
                    </Button>
                    <Button
                        outline
                        disabled={props.isResetting}
                        className={cn.resetBtn}
                        onClick={reset}
                    >
                        {props.t('actions.reset')}
                        {props.isResetting && <CircularProgress size={24} className={cn.btnLoader} />}
                    </Button>
                </div>
            </div>


        </div>
    );
};

export const ActivityLogsFilter = withTranslation()(ActivityLogsFilterComp);
