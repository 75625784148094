export function getMessageDelay(n: number) {
    // const A = 1;
    // const B = 3;
    // // const ASPS = 2.67;
    // const ASPS = 2.67 * 10;
    // const S = 1;
    // return Math.max(A, Math.min(B, n / ASPS + S * 2 * (Math.random() - 0.5)))
	console.warn('Зачем и почему?', n);
    return 0.5;
}
