import React from 'react';
import { DropTargetHookSpec, useDrop, DropTargetMonitor } from 'react-dnd';

type AsDropTargetParams<DragObject, DropResult, CollectedProps, Props> = {
	accept: DropTargetHookSpec<DragObject, DropResult, CollectedProps>['accept'],
	drop?: (props: Props, item: DragObject, monitor: DropTargetMonitor<DragObject, DropResult>) => DropResult | undefined;
};

type DragCollectedProps = {
	canDrop: boolean;
    isOver: boolean;
}

export type AsDropTargetProps = DragCollectedProps;

export const asDropTarget = <
	ComponentProps,
	DragObject,
	DropResult,
	C extends React.ComponentType<ComponentProps & AsDropTargetProps> = any
>(Component: C, { accept, drop }: AsDropTargetParams<DragObject, DropResult, DragCollectedProps, ComponentProps>) => {
	return ((props) => {
		const [collectedDrop, dropRef] = useDrop<DragObject, DropResult, DragCollectedProps>({
			accept,
			drop: (item, monitor) => {
				if (typeof drop === 'function') return drop(props, item, monitor);
			},
			collect: monitor => ({
				isOver: monitor.isOver({ shallow: true }),
				canDrop: monitor.canDrop(),
			})
		});

		return (
			<div ref={ dropRef }>
				<Component
					isOver={ collectedDrop.isOver }
					canDrop={ collectedDrop.canDrop }
					{...props}
				/>
			</div>
		);
	}) as React.ComponentType<React.ComponentProps<any> & AsDropTargetProps>
};
