import { QAStore } from '../qa/qa.store';
import { EntityStore } from '../entities/entity.store';
import { SnippetStore } from '../snippets/snippet.store';
import { IntentStore } from '../intents/intent.store';
import { ConnectStore } from '../connects/connect.store';
import { ChatStore } from '../chat/chat.store';
import { ScriptsStore } from '../scripts/stores/scripts.store';

export class AppStore {
    constructor(private qaStore: QAStore, private entityStore: EntityStore, private snippetStore: SnippetStore,
                private intentsStore: IntentStore, private scriptsStore: ScriptsStore,
                private connectStore: ConnectStore,
                private chatStore: ChatStore) {}

    clearApp() {
        this.qaStore.clearStore();
        this.entityStore.clearStore();
        this.snippetStore.clearStore();
        this.intentsStore.clearStore();
        this.scriptsStore.clearStore();
        this.connectStore.clearStore();
        this.chatStore.reset();
    }
}
