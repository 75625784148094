import { ScriptsStore } from '@/app/scripts/stores/scripts.store';

export class FlowsMapper {
    constructor(private scriptsStore: ScriptsStore) {}

    async load(): Promise<boolean> {
        const groups = this.scriptsStore.groups;
        const flows = this.scriptsStore.scripts;
        if (groups.length > 0 || flows.length > 0) return false;
        await this.scriptsStore.load();
        return true;
    }
}
