import { withTranslation, WithTranslation } from 'react-i18next';
import { inject, observer } from 'mobx-react';
import React from 'react';
import { SidebarListMenu } from '../../components/sidebar/models/sidebar-list-menu';
import { action, makeObservable, observable } from 'mobx';
import { Group } from '../../../common/models/group';
import CollapseComponent from '../../../common/components/Collapse';
import cn from '../../components/sidebar/sidebar-menu/sidebar-menu.module.scss';
import { SidebarAddButton, SidebarGroup, SidebarSubMenuLink } from '../../components/sidebar';
import { EntityStore } from '../entity.store';
import { Entity } from '../models/entity';
import { DragSidemenuItem } from '@/app/components/sidebar/models/drag-sidemenu-item';
import { Permission, UserStore } from '@/core/stores/user.store';
import { PermissionsView } from '@/app/permissions/Permissions';
import { RouteComponentProps, withRouter } from '@/common/utils/withRouter';


type EntityMenuProps = WithTranslation & RouteComponentProps & {
    entityStore: EntityStore;
    user?: UserStore;
}

@inject('user')
@observer
export class EntityMenuComponent extends React.Component<EntityMenuProps> implements SidebarListMenu<Entity> {
    @observable editGroupId = -1;

    constructor(props: EntityMenuProps) {
        super(props);
        makeObservable(this);
    }

    @action.bound
    onRemoveGroup(group: Group) {
        this.props.entityStore.removeGroup(group.id!);
    }

    @action.bound
    onStartNameGroupEdit(group: Group) {
        this.editGroupId = group.id!;
    }

    @action.bound
    onSaveGroup(group: Group) {
        this.editGroupId = -1;
        this.props.entityStore.saveGroup(group);
    }

    renderGroup = (group: Group, i: number) => {
        return <CollapseComponent key={group.id} className={cn.collapse}

                                  isOpen={!i}
                                  title={
                                      <SidebarGroup canDrop={true}
                                                    canEdit={this.props.user.permissions.isEditEntities}
                                                    onSave={this.onSaveGroup}
                                                    onStartEdit={this.onStartNameGroupEdit}
                                                    currentEditedGroupId={this.editGroupId}
                                                    group={group}
                                                    onRemove={this.onRemoveGroup}
                                      />
                                  }>
            {this.getItemsByGroup(group)}

        </CollapseComponent>
    };

    onDragEnd = (from: DragSidemenuItem, to: DragSidemenuItem) => {
        if (from.groupId === to.groupId) {
            const group = this.props.entityStore.groups.find((_group: Group) => _group.id === from.groupId);
            if (group && this.filterItemsByGroup(group).length === 2) {
                return;
            }
        }

        this.props.entityStore.replaceElementsInGroup(from, to);
    };

    // TODO: will using matchRoutes after refactoring to FC component
    isActiveRoute = (route: string) => this.props.location.pathname.endsWith(route);

    renderGroupItem = (entity: Entity) => {
        return <SidebarSubMenuLink key={entity.id}
                                   groupId={entity.entity_group_id}
                                   id={entity.id}
                                   isActive={true}
                                   onDragEnd={this.onDragEnd}
                                   canDrop={true}
                                   noDragging={!this.props.user.permissions.isEditEntities}
                                   route={`${entity.id!}/${entity.extractor_type}`}
                                   analyticName={'entities'}
                                   subtitle={entity.extractor_type}
                                   isSelected={this.isActiveRoute(`${entity.id!}/${entity.extractor_type}`)}
                                   title={entity.name!}/>
    };

    filterItemsByGroup(group: Group) {
        return this.props.entityStore.entities.filter(
            entity => entity.entity_group_id === group.id
        );
    }

    getItemsByGroup(group: Group) {
        const entities = this.filterItemsByGroup(group);

        return entities.map(this.renderGroupItem);
    }


    render() {
        return <>
            <PermissionsView permission={Permission.EDIT_ENTITIES}>
                <SidebarAddButton analyticName={'entities'}
                                  routeToNewItem={'new'}>
                </SidebarAddButton>
            </PermissionsView>
            <div
                className={cn.menuScroll}>{this.props.entityStore.isLoaded && this.props.entityStore.groups.map(this.renderGroup)}</div>

        </>
    }
}

export const EntityMenu = withTranslation()(withRouter(EntityMenuComponent));
