import React, { FC, useState } from 'react';
import { Radio, RadioChangeEvent, Space } from 'antd';
import { Sort, Value, ViewValue } from '@/app/stats/IntentAnalytics/types';

type LocalFilterProps = {
    isLoading: boolean;
    onChange: (viewValue: ViewValue) => void;
};
export const LocalFilter: FC<LocalFilterProps> = ({
                                                      onChange, isLoading
                                                  }) => {
    const values = [{value: 'absolute', label: '#'}, {value: 'relative', label: '%'}];
    const typeOfValues = [{value: 'volume', label: 'По объёму'}, {value: 'automated', label: 'По автоматизации'}];

    const [value, setValue] = useState<Value>('absolute');
    const [typeOfValue, setTypeOfValue] = useState<Sort>('volume');
    const onChangeValue = (e: RadioChangeEvent) => {
        setValue(e.target.value);
        const viewValue = `${e.target.value}_${typeOfValue}` as ViewValue;
        onChange(viewValue);
    }

    const onChangeTypeOfValues = (e: RadioChangeEvent) => {
        setTypeOfValue(e.target.value);
        const viewValue = `${value}_${e.target.value}` as ViewValue;
        onChange(viewValue);
    }

    return <Space direction="horizontal">
        <Radio.Group
            options={values}
            onChange={onChangeValue}
            value={value}
            disabled={isLoading}
            optionType="button"
            buttonStyle="solid"
        />

        <Radio.Group
            options={typeOfValues}
            onChange={onChangeTypeOfValues}
            value={typeOfValue}
            disabled={isLoading}
            optionType="button"
            buttonStyle="solid"
        />
    </Space>
}
