import React, { FC, useState } from 'react';
import { Popconfirm } from 'antd';
import { useTranslation,  } from 'react-i18next';

type ConfirmDeleteProps = {
    onConfirm: (e?: React.MouseEvent<HTMLElement>) => void;
    onCancel?: () => void;
    children: React.ReactNode;
    title: string;
    question: string;
};

export const ConfirmDelete: FC<ConfirmDeleteProps> = ({
    onConfirm,
     onCancel, children, title, question
                                                      }) => {

        const {t} = useTranslation();
        const [open, setOpen] = useState(false);

        const confirm = (e: React.MouseEvent<HTMLElement>) => {
            setOpen(false);
            onConfirm(e);
        };

        const cancel = () => {
            setOpen(false);
            onCancel?.();
        };

        const handleOpenChange = (newOpen: boolean) => {
            if (!newOpen) {
                setOpen(newOpen);
                return;
            }

            setOpen(newOpen);
        };

        return (
                <Popconfirm
                    title={title}
                    description={question}
                    open={open}
                    onOpenChange={handleOpenChange}
                    onConfirm={confirm}
                    onCancel={cancel}
                    okText={t('actions.delete')}
                    cancelText={t('actions.cancel')}
                >
                    { children }
                </Popconfirm>
        );

}
