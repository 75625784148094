import { Reaction } from './Reaction';
import { makeObservable, observable } from 'mobx';

export class RepeatReaction extends Reaction {
    static className = 'RepeatReaction';
    @observable text?: string;
    @observable tts?: string;

    constructor(id: string, name: string) {
        super(id, name);
        makeObservable(this);
    }
}
