import { action, makeObservable, observable, reaction } from 'mobx';
import { createViewModel } from 'mobx-utils';
import * as _ from 'lodash';
import { v4 } from 'uuid';
import { Script } from '../models/Script';
import { ScriptDTO } from '../dtos/script.dto';
import { MapperFactory } from '../mappers/MapperFactory';
import { Node } from '../models/Node';
import { State } from '../models/State';
import { Reaction } from '../models/reactions/Reaction';
import { ScriptsStore } from './scripts.store';
import { UserStore } from '@/core/stores/user.store';
import * as LocalForage from 'localforage';
import { format } from 'date-fns';

const emptyScript: ScriptDTO = {
    script_group_id: 0,
    name: 'New flow',
    type: 'flow0.1',
    data: { flow: null },
};

export class ScriptPageStore {
    private _oldScript?: Script;
    private intervalId: number | any;
    @observable selectedNode?: Node;
    @observable script!: Script;
    @observable autoSaveInfo: { isAutoSave: boolean; autoSaveDate: string; } = undefined;


    constructor(
        private scriptsStore: ScriptsStore,
        private userStore: UserStore,
    ) {
        makeObservable(this);

        reaction(() => this.script?.id, async () => {
           this.stopAutoSave();
           this.autoSaveInfo = await this.getAutoSaveInfo();
           const isAutoSave = await this.isAutoSave();

           if (isAutoSave) {
               this.startAutoSave();
           }
        });
    }

    getAutoSaveInfo = async () => {
        const autoSaveDict = await LocalForage.getItem<Record<number, { isAutoSave: boolean; autoSaveDate: string; }>>('flowAutoSave') ?? {};

        return autoSaveDict[this.script.id];
    }

    startAutoSave() {
        this.intervalId = setInterval(async () => {
            await this.save();
            await this.updateAutoSave();
            this.autoSaveInfo = await this.getAutoSaveInfo();
        }, 10000);
    }

    stopAutoSave() {
        clearInterval(this.intervalId);
    }

    isChanged(): boolean {
        return this._oldScript ? !_.isEqual(this.toDTO(), this.toDTO(this._oldScript)) : false;
    }

    isAutoSave = async () => {
        const autoSaveDict = await LocalForage.getItem<Record<number, { isAutoSave: boolean; autoSaveDate: string; }>>('flowAutoSave') ?? {};

        return autoSaveDict[this.script.id]?.isAutoSave;
    }

    updateAutoSave = async () => {
        const autoSaveDict = await LocalForage.getItem<Record<number, { isAutoSave: boolean; autoSaveDate: string; }>>('flowAutoSave') ?? {};
        autoSaveDict[this.script.id] = {
            isAutoSave: true,
            autoSaveDate: format(new Date(), 'HH:mm:ss dd.MM.yyyy')
        };

        return LocalForage.setItem('flowAutoSave', autoSaveDict);
    }

    autoSaveToggle = async (isAutoSave: boolean) => {
        const autoSaveDict = await LocalForage.getItem<Record<number, { isAutoSave: boolean; autoSaveDate: string; }>>('flowAutoSave') ?? {};
        autoSaveDict[this.script.id] = {
            isAutoSave,
            autoSaveDate: autoSaveDict[this.script.id]?.autoSaveDate
        };

        if (isAutoSave) {
            this.startAutoSave();
        } else {
            this.stopAutoSave();
            await this.getAutoSaveInfo();
        }

        LocalForage.setItem('flowAutoSave', autoSaveDict);

        this.autoSaveInfo = await this.getAutoSaveInfo();
    }

    @action.bound changeName = (name: string) => {
        this.script.name = name;
    }

    @action.bound
    fetchScript = async (id?: Pick<ScriptDTO, 'id'>) => {
        if (id) {
            const scriptDTO = await this.scriptsStore.fetchById(id);
            this.setScript(scriptDTO);
        } else {
            this.setScript(emptyScript);
        }
    }

    @action.bound setScript = (dto: ScriptDTO) => {
        const mapper = MapperFactory.buildMapper(dto);
        const script = mapper.fromDTO(dto);
        this.script = script;
        this._oldScript = _.cloneDeep(script);
    }

    @action.bound
    select(node?: Node) {
        if (this.userStore.permissions.isEditScripts) {
            this.selectedNode = node;
        }
    }

    @action.bound updateName = (name: string): Promise<ScriptDTO> => {
        this.script.name = name;

        return this.scriptsStore.patchScript({id: this.script.id, name: this.script.name});
    }

    @action.bound toggleArchive = (): Promise<ScriptDTO> => {
        this.script.isActive = !this.script.isActive;
        return this.scriptsStore.patchScript({
            id: this.script.id,
            is_active: this.script.isActive
        });
    }

    @action.bound
    delete = async (): Promise<boolean> => {
        if (this.script.id) {
            return this.scriptsStore.remove({id: this.script.id})
                .then(() => true)
                .catch(() => false);
        }

        return Promise.resolve(true);
    }

    @action.bound
    clone = async (): Promise<ScriptDTO> => {
        await this.save();
        const clonedScript = createViewModel(this.script);
        clonedScript.name += ' Clone';
        delete clonedScript.id;
        clonedScript.dateCreated = new Date();
        clonedScript.data.forEach(n => {

            if (State.is(n) || Reaction.is(n)) {
                (n as (State | Reaction)).id = v4();
            }
        });

        return this.scriptsStore.create(this.toDTO(clonedScript));
    }

    @action.bound
    save = async (): Promise<ScriptDTO> => {
        let scriptDTO;
        if (this.script.id) {
            scriptDTO = await this.scriptsStore.patchScript(this.toDTO());
        } else {
            scriptDTO = await this.scriptsStore.create(this.toDTO());
        }

        this.setScript(scriptDTO);

        return scriptDTO;
    }

    toDTO(script: Script = this.script): ScriptDTO {
        const mapper = MapperFactory.buildMapper(script);
        return mapper.toDTO(script);
    }

}
