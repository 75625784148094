import React, { FC, useEffect, useState } from 'react';
import { Radio, RadioChangeEvent, Space } from 'antd';

export type SystemValue = 'system' | 'all';

type SystemFilterComponentProps = {
    isLoading: boolean;
    onChange: (value: SystemValue) => void;
};
export const SystemFilterComponent: FC<SystemFilterComponentProps> = ({ isLoading, onChange }) => {
    const values = [{value: 'system', label: 'Скрыть системные'}, {value: 'all', label: 'Показать всё'}];

    const [value, setValue] = useState<SystemValue>('system');
    useEffect(() => {
        onChange('system');
    }, []);
    const onChangeValue = (e: RadioChangeEvent) => {
        setValue(e.target.value);
        onChange(e.target.value);
    }

    return <Space direction="horizontal">
        <Radio.Group
            options={values}
            onChange={onChangeValue}
            value={value}
            disabled={isLoading}
            optionType="button"
            buttonStyle="solid"
        />
    </Space>
}
