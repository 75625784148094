import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom/dist';

import cn from './NotFound.module.scss';
import { MinimalPage } from '@/common/minimal-page/MinimalPage';

export const NotFound = () => {
    const { t } = useTranslation();

    return (
        <MinimalPage withHeader={false}>
            <div className={cn.notFound}>
                <h1 className={cn.title}>404</h1>
                <div className={cn.text}>
                    {t('not_found.text')}
                    {' '}
                    <Link to={'/'}>Lia</Link>
                </div>
            </div>
        </MinimalPage>
    );
}
