import { Reaction } from './Reaction';
import { makeObservable, observable } from 'mobx';

export class JumpToReaction extends Reaction {
    static className = 'JumpToReaction';
    @observable jumpNext?: string = '';

    constructor(id: string, name: string) {
        super(id, name);
        makeObservable(this);
    }
}
