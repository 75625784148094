import React, { ChangeEvent, FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { inject, observer } from 'mobx-react';
import { Button, Input, Checkbox, Select } from 'antd';
import classnames from 'classnames';
import type { CheckboxChangeEvent } from 'antd/es/checkbox';
import cn from './LogFilter.module.scss';
import { LogFilterModel } from './models/log-filter-model';
import { SearchOutlined } from '@ant-design/icons';
import { LogFilterSearch } from '@/common/components/logs/LogFilter.styled';
import { DatePicker } from 'antd';
import locale from 'antd/es/date-picker/locale/ru_RU';
import dayjs, { Dayjs } from 'dayjs';
import { ConnectStore } from '@/app/connects/connect.store';
import { ProjectStore } from '@/core/stores/project.store';

const {RangePicker} = DatePicker;


type LogFilterProps = {
    filter: LogFilterModel;
    onChange: (v: LogFilterModel) => void;
    onResetFilter: () => void;
    connectStore?: ConnectStore;
    projectStore?: ProjectStore;
    isLoading: boolean;
}

export const LogFilter: FC<LogFilterProps> = inject('connectStore', 'projectStore')(observer(({
                                                                                                  filter,
                                                                                                  onResetFilter,
                                                                                                  onChange,
                                                                                                  isLoading,
                                                                                                  connectStore,
                                                                                                  projectStore
                                                                                              }) => {
    const defaultRange: [Dayjs, Dayjs] = [dayjs().add(-14, 'day').startOf('day'), dayjs().endOf('day')];
    const {t} = useTranslation();
    const [allVersions, setAllVersions] = useState([]);
    const [allChannels, setAllChannels] = useState([]);
    const [text, setText] = useState(filter.text);
    const [channels, setChannels] = useState(filter.channels);
    const [versions, setVersions] = useState(filter.versions);
    const [range, setRange] = useState(filter.range);
    const [isFails, setIsFails] = useState(filter.isFails);

    useEffect(() => {
        projectStore.getProjectVersion().then(versions => {
            setAllVersions(versions);
        });
        connectStore.loadChannels().then(channels => {
            setAllChannels(channels);
        });
    }, []);

    const onChangeRange = (range: [Dayjs, Dayjs]) => {
        if (!range) {
            setRange(defaultRange);
        } else {
            setRange(range);
        }
    }


    const onTextChange = (e: ChangeEvent<HTMLInputElement>) => {
        setText(e.target.value);
    }

    const onFailsChange = (e: CheckboxChangeEvent) => {
        setIsFails(e.target.checked);
    }

    const startSearch = () => {
        onChange({
            versions,
            channels,
            isFails,
            range,
            text
        });
    }

    const resetFilter = () => {
        setRange(defaultRange);
        setText('');
        setChannels([]);
        setVersions([]);
        setIsFails(false);
        onResetFilter();
    }

    const onChangeChannel = (value: number[]) => {
        setChannels(value);
    }

    const onChangeVersion = (value: number[]) => {
        setVersions(value);
    }

    return <div className={cn.filterWrapper}>

        <div className={cn.menuTitle}>{t('stats_logs_filters.user_activity')}</div>
        <div className={cn.formFieldLog}>
            <RangePicker
                locale={locale}
                defaultValue={range}
                onChange={onChangeRange}
                value={range}
                format={'DD/MM/YY'}
            />
        </div>

        <div className={cn.formFieldLog}>
            <Input
                placeholder={t('stats_logs_filters.id_or_text')}
                value={text}
                onChange={onTextChange}
            />
        </div>

        <div className={cn.formFieldLog}>
            <Select
                mode="multiple"
                maxTagCount={1}
                allowClear
                onChange={onChangeChannel}
                defaultValue={channels}
                value={channels}
                style={{width: '100%'}}
                options={allChannels}
                placeholder={'Каналы'}
                fieldNames={{value: 'id', label: 'name'}}
            />
        </div>

        <div className={cn.formFieldLog}>
            <Select
                mode="multiple"
                maxTagCount={1}
                allowClear
                onChange={onChangeVersion}
                defaultValue={versions}
                value={versions}
                style={{width: '100%'}}
                options={allVersions}
                optionLabelProp={'name'}
                placeholder={'Версии'}
                fieldNames={{value: 'id', label: 'name'}}
            />
        </div>

        <label className={classnames(cn.checkbox)}>

            <Checkbox checked={isFails}
                      onChange={onFailsChange}>
                {t('stats_logs_filters.fails')}
            </Checkbox>
        </label>

        <LogFilterSearch className={cn.search}>
            <Button icon={<SearchOutlined/>} disabled={isLoading} loading={isLoading} type="primary"
                    onClick={startSearch}>{t('stats_logs_filters.search')}</Button>
            <Button type="text" size="small" color="link"
                    disabled={isLoading}
                    onClick={resetFilter}>{t('stats_logs_filters.cancel')}</Button>
        </LogFilterSearch>
    </div>
}));
