import React, { FC } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom/dist';
import { Login } from './components/login';
import { Reset } from './components/reset';
import { ResetPassword } from './components/reset-password/ResetPassword';
import { EmailActivation } from './components/email-activation/EmailActivation';

export const Auth: FC = () => {
    return <Routes>
        <Route path={'/login'} element={<Login/>}/>
        <Route path={'reset'} element={<Reset/>}/>
        <Route path={'activate'} element={<EmailActivation />}/>
        <Route path={'reset-password'} element={<ResetPassword />}/>
        <Route path='/' element={<Navigate to={'/auth/login'}/>}/>
    </Routes>;
}
