import React, { RefObject } from 'react';
import { action, IObservableArray, makeObservable, observable } from 'mobx';
import { inject, observer } from 'mobx-react';

import cns from 'classnames';

import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';
import CollapseComponent from '../../../common/components/Collapse';

import MoreVertIcon from '@mui/icons-material/MoreVert';
import ChevronDownIcon from 'mdi-react/ChevronDownIcon';

import { Group } from '@/common/models/group';

import cn from '../../components/sidebar/sidebar-menu/sidebar-menu.module.scss';
import { WithTranslation, withTranslation } from 'react-i18next';
import { ErrorsStore } from '@/core/stores/errors.store';
import { SidebarListMenu } from '../../components/sidebar/models/sidebar-list-menu';
import { IntentStore } from '../intent.store';
import { WithNameAndType } from '@/common/components/chat/models';
import { Intent } from '../models/Intent';
import {
    DragSidemenuItem,
    ImportControlsFactory,
    ImportModal,
    InputDropzone,
    SidebarAddButton, SidebarGroup, SidebarSearch,
    SidebarSubMenuLink
} from '../../components/sidebar';
import { QAStore } from '../../qa/qa.store';
import { Permission, UserStore } from '@/core/stores/user.store';
import { PermissionsView } from '@/app/permissions/Permissions';
import { RouteComponentProps, withRouter } from '@/common/utils/withRouter';


type IntentMenuProps = WithTranslation & RouteComponentProps & {
    intentStore?: IntentStore;
    errorsStore?: ErrorsStore;
    qa?: QAStore;
    user?: UserStore;
}

interface IntentMenuState {
    importState: ImportState;
    importFiles: File[];
}

enum ImportState {
    INIT,
    READY
}

@inject('errorsStore', 'qa', 'user')
@observer
class IntentMenuComponent extends React.Component<IntentMenuProps, IntentMenuState> implements SidebarListMenu<Intent> {
    state: IntentMenuState = {
        importState: ImportState.INIT,
        importFiles: null
    };
    @observable editGroupId = -1;
    @observable searchResult: IObservableArray<WithNameAndType> = observable([]);
    @observable startTerm = false;
    @observable isOpenImportModal: boolean = false;
    @observable addOrReplace: string = '';
    importInputRef: RefObject<HTMLInputElement> = React.createRef();

    constructor(props: IntentMenuProps) {
        super(props);
        makeObservable(this);
    }

    @action.bound
    onRemoveGroup(group: Group) {
        this.props.intentStore.removeGroup(group.id!);
    }

    @action.bound
    onStartNameGroupEdit(group: Group) {
        this.editGroupId = group.id!;
    }

    @action.bound
    onSaveGroup(group: Group) {
        this.editGroupId = -1;
        this.props.intentStore.saveGroup(group);
    }

    @action.bound
    onToggleGroup(group: Group, state: boolean) {
        const intents = this.filterItemsByGroup(group);
        if (state && !intents.length) {
            this.props.intentStore.getMoreElements(group.id!);
        }
    }

    @action.bound
    getNextItems(group: Group) {
        this.props.intentStore.getMoreElements(group.id!);
    }

    renderGroup = (group: Group, i: number) => {
        return <CollapseComponent key={group.id} className={cn.collapse}
                                  onToggle={(state: boolean) => this.onToggleGroup(group, state)}
                                  isOpen={!i}
                                  title={
                                      <SidebarGroup canDrop={true}
                                                    onSave={this.onSaveGroup}
                                                    onStartEdit={this.onStartNameGroupEdit}
                                                    currentEditedGroupId={this.editGroupId}
                                                    group={group}
                                                    onRemove={this.onRemoveGroup}
                                                    canEdit={this.props.user.permissions.isEditIntents}
                                      />
                                  }>
            {this.getItemsByGroup(group)}

            {this.props.intentStore.isLoaded && !this.props.intentStore.isEndPagination(group) && <div onClick={() => {
                this.getNextItems(group)
            }} className={cn.loadMore}>
                <ChevronDownIcon/>
            </div>}
        </CollapseComponent>
    };

    // ???: тут типсы надо
    onDragEnd = (from: DragSidemenuItem, to: DragSidemenuItem) => {
        console.warn('Types', from, to);
        if (from.groupId === to.groupId) {
            const group = this.props.intentStore.groups.find((_group: Group) => _group.id === from.groupId);
            if (group && this.filterItemsByGroup(group).length === 2) {
                return;
            }
        }

        this.props.intentStore.replaceElementsInGroup(from, to);
    };

    // TODO: will using matchRoutes after refactoring to FC component
    isActiveRoute = (route: string) => this.props.location.pathname.endsWith(route);

    export = () => {
        this.props.intentStore.download();
    };

    renderImportExport = () => (
        <UncontrolledDropdown className={cn.importExportDropdown} onClick={e => e.preventDefault()}>
            <DropdownToggle className={cn.importExportDropdownToggle}>
                <MoreVertIcon className={cn.importExportDropdownToggleIcon}/>
            </DropdownToggle>
            <DropdownMenu className={cns(cn.importExportDropdownMenu, 'dropdown__menu')}>
                <DropdownItem className={cn.importExportDropdownToggleItem}
                              onClick={() => this.isOpenImportModal = true}>{this.props.t('actions.import')}</DropdownItem>
                <DropdownItem className={cn.importExportDropdownToggleItem}
                              onClick={this.export}>{this.props.t('actions.export')}</DropdownItem>
            </DropdownMenu>
        </UncontrolledDropdown>
    );

    renderGroupItem = (intent: Intent) => {
        return <SidebarSubMenuLink key={intent.id}
                                   groupId={intent.intent_group_id}
                                   id={intent.id}
                                   isActive={intent.is_active}
                                   onDragEnd={this.onDragEnd}
                                   canDrop={true}
                                   route={intent.id.toString()}
                                   analyticName={'intent'}
                                   noDragging={!this.props.user.permissions.isEditIntents}
            // subtitle={qa.questions.length.toString()}
                                   isSelected={this.isActiveRoute(intent.id.toString())}
                                   title={intent.name!}/>
    };

    filterItemsByGroup(group: Group) {
        return this.props.intentStore.intents.filter(
            intent => intent.intent_group_id === group.id
        );
    }

    getItemsByGroup(group: Group) {
        const intents = this.filterItemsByGroup(group);

        return intents.map(this.renderGroupItem);
    }

    onSearchItem = async (term: string) => {
        this.startTerm = !!term;
        if (!this.startTerm) {
            this.searchResult.replace([]);
            return;
        }

        const response = await this.props.qa.intentQaSearch(term);
        this.searchResult.replace(response.filter(
            (item) => item.type === 'intent'
        ));
    };

    onImportModalCancel = () => {
        this.isOpenImportModal = false;
        this.setState({importState: ImportState.INIT, importFiles: null});
    };

    onImportModalChange = (isAdd: boolean) => {
        this.addOrReplace = isAdd ? 'add' : 'replace';
    };

    onImportModalImport = () => {
        this.importInputRef.current!.click();
    };

    importFiles = async () => {
        const files = this.state.importFiles;
        try {
            await this.props.intentStore.upload(files, this.addOrReplace === 'replace');
            this.props.intentStore.isLoaded = false;
            await this.props.intentStore._load();
            this.isOpenImportModal = false;
            this.setState({importState: ImportState.INIT, importFiles: null});
        } catch (e: any) {
            this.isOpenImportModal = false;
            this.setState({importState: ImportState.INIT, importFiles: null});
            let errorMsg: string = '';
            errorMsg = e.status_code === 400 ? 'Something is wrong with the file' : 'File should be of .csv format';
            this.props.errorsStore!.setHeaderError(
                `Houston, we have a problem. ${errorMsg}`,
                5000
            );
        }
    }

    onDropImport = async (files: File[]) => {
        this.setState({importState: ImportState.READY, importFiles: files});
    };

    renderImportModalContent = () => {
        const {importState} = this.state;
        const Component = ImportControlsFactory('import_fields.to_existing_intents');
        switch (importState) {
            case ImportState.INIT:
                return <>
                    <InputDropzone ref={this.importInputRef} onDrop={(files) => {
                        this.onDropImport(files);
                    }}/>
                    {
                        Component
                            ? <Component
                                isAddingToMarkup={this.addOrReplace ? this.addOrReplace === 'add' : true}
                                onCancel={this.onImportModalCancel}
                                onChange={this.onImportModalChange}
                                onImport={this.onImportModalImport}
                            />
                            : null
                    }
                </>
            case ImportState.READY:
                return <>
                    <div className={cn.importReady}>
                        <h1>{this.props.t('intents.intents_import_ready')}</h1>
                    </div>
                    {
                        Component
                            ? <Component
                                isAddingToMarkup={this.addOrReplace ? this.addOrReplace === 'add' : true}
                                onCancel={this.onImportModalCancel}
                                onChange={this.onImportModalChange}
                                onImport={this.importFiles}
                            />
                            : null
                    }
                </>
        }
    };

    renderImportModal = () => <>
        <ImportModal isOpen={this.isOpenImportModal}
                     toggle={() => this.isOpenImportModal = !this.isOpenImportModal}
                     modalContent={this.renderImportModalContent()}>
        </ImportModal>
    </>;

    render() {
        return <>
            <PermissionsView permission={Permission.EDIT_INTENTS}>
                <SidebarAddButton analyticName={'intent'}
                                  routeToNewItem={'new'}>
                    <>{this.renderImportExport()}</>
                </SidebarAddButton>
            </PermissionsView>

            <SidebarSearch className={cn.sidebarSearch} onChange={this.onSearchItem}/>

            <div
                className={cn.menuScroll}>{this.props.intentStore.isLoaded && !this.startTerm && this.props.intentStore.groups.map(this.renderGroup)}</div>
            {this.renderImportModal()}
            <div
                className={cn.searchWrapper}>{this.props.intentStore.isLoaded && this.startTerm && this.searchResult.map(intent =>
                <SidebarSubMenuLink
                    key={intent.id}
                    id={intent.id}
                    isActive={true}
                    onDragEnd={this.onDragEnd}
                    canDrop={false}
                    isDragging={false}
                    route={intent.id.toString()}
                    analyticName={'intent'}
                    noDragging={true}
                    isSelected={this.isActiveRoute(intent.id.toString())}
                    title={intent.name!}/>)}</div>
        </>
    }
}

export const IntentMenu = withTranslation()(withRouter(IntentMenuComponent));
