import { EntityStore } from '../entities/entity.store';
import { action, computed, IObservableArray, makeObservable, observable, runInAction } from 'mobx';
import { Intent, IntentExample } from './models/Intent';
import { IntentStore } from './intent.store';
import { Entity } from '../entities/models/entity';
import { EntityExtractor } from '../entities/models/entity-extractor';
import { SaveButtonState, SaveButtonWaitingToDefaultTimeout } from '@/common/components/save-button/SaveButton';

const defaultIntent: Intent = {
    name: 'Intent Name',
    intent_group_id: 0,
    is_active: true,
    intent_examples: [],
    type: '',
};

export class IntentsPageStore {
    constructor(private entityStore: EntityStore, private intentStore: IntentStore) {
        makeObservable(this);
    }

    @observable intentId?: number;
    @observable exampleText = '';
    @observable examples: IObservableArray<string> = observable([]);
    @observable intent: Intent = defaultIntent;

    @observable isEntityView = false;
    @observable newEntities: IObservableArray<Entity> = observable([]);
    isTitleEdited: boolean = false;
    private currentId: number = -1;

    @observable saveState: SaveButtonState = SaveButtonState.default;

    @computed
    private get usedEntitiesIds(): number[] {
        const set: Set<number> = new Set();
        this.intent.intent_examples.forEach(example => {
            const entities_ids = example.parts.filter(p => p.entity_id).map(p => p.entity_id);
            entities_ids.forEach(id => set.add(id!))
        });

        return Array.from(set.values());
    }

    @computed get canSave() {
        return this.intent.intent_examples.length > 0;
        // &&
        // this.newEntities.every(e => !!e.name);
    }

    @action.bound
    async save() {
        this.saveState = SaveButtonState.process;
        await Promise.all([this.saveNewEntities(), this.saveCurrentEntities()]);
        const intent = await this.intentStore!.saveIntent(this.intent);
        runInAction(() => {
            this.saveState = SaveButtonState.saved;
        });

        setTimeout(() => {
            this.saveState = SaveButtonState.default;
        }, SaveButtonWaitingToDefaultTimeout);

        return intent;
    }

    async saveNewEntities() {
        const _mapNewToCreated: Record<number, number> = {};

        await Promise.all(this.newEntities.map(entity => {
            return this.entityStore.createEntity(entity).then(created => {
                _mapNewToCreated[entity.id!] = created.id!;
            });
        }));

        this.intent.intent_examples.forEach(example => {
            example.parts.forEach(part => {
                if (_mapNewToCreated[part.entity_id!]) {
                    part.entity_id = _mapNewToCreated[part.entity_id!];
                }
            })
        });

        this.newEntities.replace([]);
    }

    async saveCurrentEntities() {
        return await Promise.all(this.usedEntities.map(entity => this.entityStore.saveEntity(entity)));
    }

    @action.bound
    async setIntent(id?: number) {
        this.isTitleEdited = false;
        if (id) {
            this.intent = this.intentStore.intents.find(i => i.id === id)!;
            if (!this.intent) {
                this.intent = await this.intentStore.getIntentByIdAsync(id);
            }
        } else {
            this.intent = defaultIntent;
        }

    }

    @action.bound
    toggleView = () => {
        this.isEntityView = !this.isEntityView;
    }

    @computed
    get usedEntities() {
        return this.entityStore.entities.filter(entity => this.usedEntitiesIds.includes(entity.id!));
    }

    @computed
    get customEntities() {
        return this.entityStore.entities.filter(entity => !this.usedEntitiesIds.includes(entity.id!));
    }

    @computed
    get types() {
        return this.entityStore.extractors;
    }

    @action.bound
    onExampleMultiAdd(examples: string[]) {
        examples.forEach(example => {
            this.intent.intent_examples.push({parts: [{text: example}]});
        });
    }

    @action.bound
    onExampleAdd() {
        if (this.exampleText) {
            if (!this.intent.intent_examples.length && !this.isTitleEdited) {
                this.changeName(this.exampleText);
            }
            this.intent.intent_examples.push({parts: [{text: this.exampleText}]});
            runInAction(() => {
                this.exampleText = '';
            })
        }
    }

    @action.bound
    changeName(name: string) {
        if (name) {
            this.intent.name = name;
        }

        if (this.intent.id) {
            this.intentStore.patchIntent({name: this.intent.name, id: this.intent.id});
        }
    }

    @action.bound
    onRemoveExample(example: IntentExample) {
        const index = this.intent.intent_examples.findIndex(e => e === example);
        this.intent.intent_examples.splice(index, 1);
    }

    @action.bound
    addEntity(extractor: EntityExtractor): Entity {
        const entity = {name: '', extractor_type: extractor.key, extractor_params: {}, id: this.currentId--};
        this.newEntities.push(entity);

        return entity;
    }

    @action.bound
    removeEntity(entity_id: number): any {
        const index = this.newEntities.findIndex(e => e.id === entity_id);
        if (index >= 0) {
            this.newEntities.splice(index, 1);
        }
    }
}
