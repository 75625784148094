import React, { ReactNode } from 'react';
import { Trans, withTranslation, WithTranslation } from 'react-i18next';
import { Button } from 'reactstrap';
import { Modal, Button as AntdButton, Typography, Space } from 'antd';
import cns from 'classnames';
import { format } from 'date-fns';

import { IReactionDisposer, makeObservable, observable, reaction } from 'mobx';
import { inject, observer } from 'mobx-react';

import { Page } from '@/common/components/page/Page';
import { ProjectStore } from '@/core/stores/project.store';
import { PublishPageStore } from '@/app/publish/PublishPage.store';
import { Loader } from '@/common/components/Loader';
import { PublishRestoreState, PublishVersion } from '@/app/publish/PublishPage.model';

import { ReactComponent as ReloadIcon } from './icons/reload.svg';
import { ReactComponent as UploadIcon } from './icons/upload.svg';
import PublishCard from './publish-card/PublishCard';
import cn from './PublishPage.module.scss';
import { PermissionsView } from '@/app/permissions/Permissions';
import { Permission, UserStore } from '@/core/stores/user.store';
import { getUserName } from '@/common/utils/get-user-name';
import { RootStore } from '@/core/stores/root.store';
import { Tracker } from '@/core/analytics/tracker';
import { WarningOutlined } from '@ant-design/icons';

const {Text} = Typography;

interface PublishPageProps extends WithTranslation {
    root?: RootStore;
    projectStore?: ProjectStore;
    publishPageStore?: PublishPageStore;
    user?: UserStore;
}

interface PublishModalContent {
    modalClass: string;
    title: string;
    text: string;
    control: ReactNode;
}

interface RestoredVersion {
    versionName: string;
    user: string;
    date: string;
}

@inject('root', 'projectStore', 'publishPageStore', 'user')
@observer
class PublishPage extends React.Component<PublishPageProps> {
    private static draftBtnLabelByState = {
        [PublishRestoreState.INIT]: 'publish_page.card_draft_btn_text',
        [PublishRestoreState.PROGRESS]: 'publish_page.card_draft_btn_text_progress',
        [PublishRestoreState.DONE]: 'publish_page.card_draft_btn_text_done',
    };
    private static restoreBtnLabelByState = {
        [PublishRestoreState.INIT]: 'publish_page.card_version_btn_text',
        [PublishRestoreState.PROGRESS]: 'publish_page.card_version_btn_text',
        [PublishRestoreState.DONE]: 'publish_page.card_version_btn_text_done',
    };
    private projectChangeDisposer: IReactionDisposer;

    @observable publishState: PublishRestoreState = PublishRestoreState.INIT;
    @observable restoredVersion: RestoredVersion = null;
    @observable restoreHappened: boolean = false;
    @observable publishError: boolean = false;
    @observable isModalVisible: boolean = false;
    @observable restoreLoading: boolean = false;
    @observable modalContent: PublishModalContent = null;
    @observable selectedRestoreVersion: PublishVersion;


    constructor(props: PublishPageProps) {
        super(props);
        makeObservable(this);
    }

    private static getI18NValues(version: PublishVersion): {
        author: string;
        date: string;
    } {
        const {publisher} = version;
        return {
            author: getUserName(publisher),
            date: format(version.dateFrom, 'HH:mm:ss dd.MM.yyyy'),
        };
    }

    async componentDidMount(): Promise<void> {
        await this.props.root.update();
        this.props.publishPageStore.load();
        this.projectChangeDisposer = reaction(
            () => this.props.projectStore.choosenProject.id,
            () => this.props.publishPageStore.reload(),
        );
    }

    componentWillUnmount(): void {
        this.projectChangeDisposer?.();
    }

    publish = async () => {
        const project = this.props.projectStore.choosenProject;

        Tracker.trackEvent('Publish', {
            versionId: project.current_version?.id ?? 0
        });
        this.publishState = PublishRestoreState.PROGRESS;
        this.publishError = false;
        try {
            await this.props.publishPageStore.publish();
            this.publishState = PublishRestoreState.DONE;
        } catch (e) {
            this.publishError = true;
        } finally {
            if (this.publishState === PublishRestoreState.DONE) {
                setTimeout(() => {
                    this.publishState = PublishRestoreState.INIT;
                }, 2500);
            } else {
                this.publishState = PublishRestoreState.INIT;
            }
        }
    };

    closeModal = () => {
        this.isModalVisible = false;
    };
    restoreVersion = async (ver: PublishVersion) => {
        const user = this.props.publishPageStore.currentUser;
        this.restoreLoading = true;

        try {
            await this.props.publishPageStore.restore(ver);
            this.restoredVersion = {
                versionName: ver.name,
                user: `${user.first_name} ${user.last_name}`,
                date: format(new Date(), 'HH:mm:ss dd.MM.yyyy'),
            };
            this.restoreHappened = true;
            setTimeout(() => {
                this.restoreHappened = false;
            }, 2500);
        } catch (e) {
            ver.hasRestoreError = true;
            setTimeout(() => {
                ver.hasRestoreError = false;
            }, 5000);
        }
        this.restoreLoading = false;
        this.isModalVisible = false;
    };
    removeVersion = async (ver: PublishVersion) => {
        await this.props.publishPageStore.remove(ver);
        this.isModalVisible = false;
    };
    exportVersion = (ver: PublishVersion) => this.props.publishPageStore.export(ver);
    editVersionName = (ver: PublishVersion, name: string) =>
        this.props.publishPageStore.editName(ver, name);
    exportDraftVersion = () => this.props.publishPageStore.exportDraft();
    restore = (ver: PublishVersion) => () => {
        this.isModalVisible = true;
        this.selectedRestoreVersion = ver;
    };
    remove = (ver: PublishVersion) => {
        this.isModalVisible = true;
        this.modalContent = {
            modalClass: cn.modalContentRemove,
            title: this.props.t('publish_page.modal_delete_title'),
            text: this.props.t('publish_page.modal_delete_text'),
            control: (
                <Button
                    className={cns(cn.modalBtn, cn.modalBtnDanger)}
                    color={'danger'}
                    onClick={() => this.removeVersion(ver)}
                >
                    {this.props.t('publish_page.modal_delete_btn')}
                </Button>
            ),
        };
    };

    renderProdVersion(productionVersion?: PublishVersion) {
        const prodText = `publish_page.card_published_text${productionVersion ? '' : '_init'}`;
        const prodValues =
            productionVersion &&
            productionVersion.publisher &&
            PublishPage.getI18NValues(productionVersion);
        return (
            // @ts-ignore
            <PublishCard
                title={this.props.t('publish_page.card_published_title')}
                text={<Trans i18nKey={prodText} values={prodValues}/>}
                version={productionVersion}
                showUpdate={this.publishState === PublishRestoreState.DONE}
                onExport={productionVersion ? this.exportVersion : null}
            />
        );
    }

    renderDraftVersion() {
        const projectStore = this.props.root.projectStore;
        const draftLastActivity = projectStore.choosenProject.last_activity;

        const text = this.restoredVersion
            ? 'publish_page.card_draft_text_restored'
            : draftLastActivity
                ? 'publish_page.card_draft_text_saved'
                : 'publish_page.card_draft_text';
        const draftUser = draftLastActivity && draftLastActivity.user;
        const userName = draftUser && `${draftUser.first_name} ${draftUser.last_name}`.trim();
        const tProps =
            this.restoredVersion ||
            (draftLastActivity && {
                author: userName || draftLastActivity.user.email,
                date: format(new Date(draftLastActivity.date), 'HH:mm:ss dd.MM.yyyy'),
            });

        return (
            <PublishCard
                title={this.props.t('publish_page.card_draft_title')}
                text={this.props.t(text, tProps)}
                error={this.publishError && this.props.t('publish_page.error_publishing')}
                showUpdate={this.restoreHappened}
                onExport={this.exportDraftVersion}
            >
                <PermissionsView permission={Permission.PUBLISH_PROJECTS}>
                    <Button
                        color={'primary'}
                        className={cns(cn.cardBtn, cn.cardBtnPublish)}
                        disabled={
                            this.publishState === PublishRestoreState.PROGRESS ||
                            projectStore!.isUpdateNow ||
                            !projectStore!.updateStatus.success
                        }
                        onClick={this.publish}
                    >
                        <UploadIcon/>
                        {this.props.t(PublishPage.draftBtnLabelByState[this.publishState])}
                    </Button>
                </PermissionsView>
            </PublishCard>
        );
    }

    renderVersions(versions: PublishVersion[]) {
        const subTitle = this.props.t('publish_page.saved_versions_subtitle');
        return (
            <article className={cns(cn.container, cn.savedVersionsContainer)}>
                <h1 className={cn.title}>
                    {this.props.t('publish_page.saved_versions_title')}
                    <span className={cn.subTitle} dangerouslySetInnerHTML={{__html: subTitle}}/>
                </h1>

                <ul className={cn.cardList}>{versions.map(this.renderVersion)}</ul>
            </article>
        );
    }

    renderVersion = (version: PublishVersion) => {
        const versionValues = version.publisher && PublishPage.getI18NValues(version);
        const {restoreBtnLabelByState} = PublishPage;

        return (
            <li className={cn.cardListItem} key={version.id}>
                <PublishCard
                    title={version.id}
                    text={
                        <Trans i18nKey={'publish_page.card_version_text'} values={versionValues}/>
                    }
                    isEditable={this.props.user.permissions.isPublishProjects}
                    version={version}
                    error={version.hasRestoreError && this.props.t('publish_page.error_restoring')}
                    isProdVersion={version.isProdVersion}
                    onExport={this.exportVersion}
                    onEdit={this.editVersionName}
                    onRemove={this.remove}
                >
                    <PermissionsView permission={Permission.PUBLISH_PROJECTS}>
                        <Button
                            outline
                            className={cns(cn.cardBtn, cn.cardBtnRestore)}
                            onClick={this.restore(version)}
                        >
                            <ReloadIcon/>
                            <span>
                                {this.props.t(restoreBtnLabelByState[version.processState])}
                            </span>
                        </Button>
                    </PermissionsView>
                </PublishCard>
            </li>
        );
    };

    renderPage() {
        const {productionVersion, versions} = this.props.publishPageStore;

        return (
            <Page editorPadding={false}>
                <article className={cn.container}>
                    <div className={cn.header}>
                        <h1 className={cn.title}>{this.props.t('publish_page.title')}</h1>
                    </div>
                    <ul className={cn.cardList}>
                        <li className={cn.cardListItem}>
                            {this.renderProdVersion(productionVersion)}
                        </li>
                        <li className={cn.cardListItem}>{this.renderDraftVersion()}</li>
                    </ul>
                </article>

                {!!versions.length && this.renderVersions(versions)}
            </Page>
        );
    }

    closeRestore = () => {
        this.isModalVisible = false;
        this.selectedRestoreVersion = undefined;
    }

    renderModal = () => {
        return (
                <Modal
                    width="658px"
                    open={this.isModalVisible}
                    onCancel={this.closeRestore}
                    footer={[
                        <AntdButton loading={this.restoreLoading} danger key="back" onClick={() => this.restoreVersion(this.selectedRestoreVersion)}>
                            Восстановить
                        </AntdButton>,
                        <AntdButton key="cancel" onClick={this.closeRestore}>
                            Отменить
                        </AntdButton>
                    ]}
                >
                    <Space size={[20, 20]}>
                        <WarningOutlined style={{ fontSize: '22px', color: '#FAAD14' }} />
                        <Text style={{ fontSize: '16px' }} strong>
                            Уверены что хотите восстановить версию
                            от {format(this.selectedRestoreVersion?.dateFrom ?? new Date(), 'HH:mm:ss dd.MM.yyyy')}?</Text>
                    </Space>
                    <div style={{marginBlock: '20px', width: '85%'}}>
                        <Text style={{fontSize: '14px'}}>
                            При восстановлении версии сбросятся настройки маппинга тематик и приоритизации намерений. Продолжайте только если вы в этом уверены.
                        </Text>
                    </div>
                </Modal>
        );
    }

    render() {
        const {isLoading} = this.props.publishPageStore;
        return (
            <>
                {isLoading ? (
                    <div className={cn.loaderWrapper}>
                        <Loader/>
                    </div>
                ) : (
                    this.renderPage()
                )}
                {this.renderModal()}
            </>
        );
    }
}

export default withTranslation()(PublishPage);
