import { action, observable } from 'mobx';

export class Node {
    @observable parent: Node | null = null;
    @observable next: Node | null = null;
    static className: string;

    static get type() {
        return this.className;
    }

    get type(): string {
        return Object.getPrototypeOf(this).constructor.type;
    }

    removeSelf(): void {
        if (this.parent) {
            this.parent.remove(this);
        }
    }

    remove(child: Node): void {
        if (child !== this.next) {
            return;
        }

        const nextChild = this.next.next;
        if (nextChild) {
            nextChild.parent = this;
            this.next = nextChild;
        } else {
            this.next = null;
        }
    }

    @action.bound insertNext = (newNext: Node) => {
        if (!this.next) {
            this.next = newNext;
        } else {
            newNext.parent = this.next.parent;
            this.next.parent = newNext;
            newNext.next = this.next;
            this.next = newNext;
        }
    }

    static is(v: Node) {
        return v instanceof this;
    }
}
