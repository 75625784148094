import { Element, SearchKey, TableColumn, TableData, Version } from '@/app/stats/Types';
import { addDays, format } from 'date-fns';
import { ru } from 'date-fns/locale'

export function capitalizeFirstLetter(str: string) {
    return str.charAt(0).toUpperCase() + str.slice(1);
}

export const getTableData = (points: number[], elements: Element[], searchKey: SearchKey) => {
    const tableData: TableData[] = elements.map(element => {
        const values = element.values.reduce<Record<string, string>>((curr, prev, i) => {
            curr[points[i]] = prev.label;
            return curr;
        }, {});

        return {
            key: element.label,
            index: 1,
            name: element.label,
            // selectable: element.selectable,
            ...values
        }
    });

    const defaultSelected = elements.map(element => element.label);

    const dateFormat = searchKey === 'day' ? 'EEEEEE dd.MM' : searchKey === 'month' ? 'LLLL' : 'dd MMM';

    const tableColumns: TableColumn[] = [
        {
            title: 'Название',
            width: 160,
            dataIndex: 'name',
            key: 'name',
            fixed: 'left'
        },
        ...points.map(point => {
            const currentDate = format(new Date(point * 1000), dateFormat, {locale: ru});
            const nextWeek = format(addDays(new Date(point * 1000), 7), dateFormat, {locale: ru});
            const resultDate = searchKey === 'week' ? `${capitalizeFirstLetter(currentDate)} - ${capitalizeFirstLetter(nextWeek)}` : capitalizeFirstLetter(currentDate);
            return {
                width: 100,
                dataIndex: point,
                key: point,
                title: resultDate
            }
        })
    ];
    console.log(tableData, tableColumns);
    return {tableData, tableColumns, defaultSelected}
}

export const getChartData = (points: number[], elements: Element[], searchKey: SearchKey) => {
    const chartData: Record<string, string | number>[] = [];
    const keys = elements.map(({label}) => label);
    const selected = elements.reduce<Record<string, {
        selected: boolean,
    }>>((curr, prev) => {
        curr[prev.label] = {selected: true};
        return curr;
    }, {});

    let max = 0;
    const maxByKey: Record<string, number> = {};
    const dateFormat = searchKey === 'day' ? 'EEEEEE dd.MM' : searchKey === 'month' ? 'LLLL' : 'dd MMM';

    const labels: Record<number, Record<string, Record<string, string>>> = {};

    points.forEach((date, i) => {
        const currentDate = format(new Date(date * 1000), dateFormat, {locale: ru});
        const nextWeek = format(addDays(new Date(date * 1000), 7), dateFormat, {locale: ru});
        const resultDate = searchKey === 'week' ? `${capitalizeFirstLetter(currentDate)} - ${capitalizeFirstLetter(nextWeek)}` : capitalizeFirstLetter(currentDate);
        const number = date * 1000;
        const point: Record<string, number | string> = {
            date: resultDate,
            number
        };
        labels[number] = {};

        elements.forEach(element => {
            labels[number][element.label] = element.values.reduce<Record<string, string>>((curr, prev) => {
                curr[prev.value] = prev.label;
                return curr;
            }, {});

            maxByKey[element.label] = Math.max(+element.values[i].value, maxByKey[element.label] ?? 0)
            max = Math.max(max, +element.values[i].value);
            point[element.label] = element.values[i].value;
        });
        chartData.push(point);
    });

    return {chartData, keys, selected, max, maxByKey, labels};
}

export function getVersions(versions: Version[]) {
    return versions.map(version => {
        return {...version, timestamp: version.timestamp * 1000};
    })
}
