import styled from 'styled-components';

export const FlowDiagramPageRoot = styled.div`
    width: 100vw;
    height: 100vh;
    overflow: auto;
`;

export const FlowDiagramWrapper = styled.div`
  height: calc(100vh - 80px);
`;

export const LoaderWrapper = styled(FlowDiagramPageRoot)`
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const FlowHeader = styled.div`
    display: flex;
    padding: 24px;
    justify-content: center;
    position: relative;
    background: #fff;
    width: 100%;
`;

export const FlowLogo = styled.div`
    position: absolute;
    top: 24px;
    left: 24px;
`;

export const ProjectTitle = styled.div`
    font-family: 'Arial';
    font-size: 14px;
    line-height: 16px;
    text-align: center;
`

export const Title = styled.div`
  text-align: center;
`;

export const ScriptTitle = styled.div`
    font-family: 'Arial';
    font-weight: 700;
    font-size: 18px;
    line-height: 21px;
    text-align: center;
`;
