import React from 'react';
import cn from './ChatMessageMedia.module.scss';

interface ChatMessageAudioProps {
    url: string;
    caption: string;
}
export class ChatMessageAudio extends React.Component<ChatMessageAudioProps> {
    render() {
        return <div>
            <audio src={this.props.url} controls/>
            <div className={cn.caption}>{this.props.caption}</div>
        </div>
    }
}
