import { Node } from './Node';
import { Flow } from './Flow';

export class FlowIterator {
    static getHead(node: Node): Node {

        if (node.parent == null && Flow.is(node)) {
            return node;
        }

        let parent = node.parent;
        // run to the top node; in our case it's always Flow
        while (parent && parent.parent) {
            parent = parent.parent;
        }

        return parent;
    }
}
