import { API } from '@/common/api';
import { TeamUser, TeamUserRole, UpdateCreateTeamUser } from '@/common/models/team-user';
import { Project } from '@/common/models/project';

export class TeamPageApi extends API {

    static getTeam(): Promise<TeamUser[]> {
        return this.get('/company/users');
    }

    static getRoles(): Promise<TeamUserRole[]> {
        return this.get('/roles');
    }

    static getProjects(): Promise<Project[]> {
        return this.get('/company/projects');
    }

    static updateUser(id: number, data: UpdateCreateTeamUser): Promise<TeamUser> {
        return this.put(`/company/users/${id}`, data);
    }

    static inviteUser(data: UpdateCreateTeamUser): Promise<TeamUser> {
        return this.post('/company/users', data);
    }
}
