import React from 'react';
import { useTranslation } from 'react-i18next';
import cn from './sidebar-menu.module.scss';
import { Checkbox, FormControlLabel } from '@mui/material';
import cns from 'classnames';

interface ImportControlsConfig {
    isAddingToMarkup: boolean;
    onCancel: () => void;
    onImport: () => void;
    onChange: (val: boolean) => void;
}

export const ImportControlsFactory = (label: string) => (
    {isAddingToMarkup = true, onCancel, onImport, onChange}: ImportControlsConfig
): JSX.Element => {
    const onCheckboxChange = (_: React.ChangeEvent<HTMLInputElement>, checked: boolean) => onChange(checked);
    const {t} = useTranslation();
    return <div className={cn.importControls}>
        <div className={cn.importControlsCheckbox}>
            <FormControlLabel
                control={<Checkbox
                    className={cns(isAddingToMarkup && cn.importControlsCheckboxChecked)}
                    onChange={onCheckboxChange}
                    checked={isAddingToMarkup}/>}
                label={t(label)}
            />
        </div>

        <div className={cn.importControlsBtns}>
            <button
                type="button"
                className="btn btn-default account__btn account__btn--small"
                onClick={onCancel}
            >
                {t('actions.cancel')}
            </button>
            <button
                className={cns(cn.importControlsImportBtn, 'btn btn-primary account__btn account__btn--small')}
                type="submit"
                onClick={onImport}
            >
                {t('actions.import')}
            </button>
        </div>
    </div>;
}
