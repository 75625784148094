import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'antd';

export enum SaveButtonState {
    default = 'DEFAULT',
    process = 'PROCESS',
    saved = 'SAVED',
    error = 'ERROR',
}

export const SaveButtonWaitingToDefaultTimeout = 2000;

interface SaveButtonProps {
    onClick: () => void;
    state: SaveButtonState;
    titlesByState: Partial<Record<SaveButtonState, string>>;
    disabled?: boolean;
    className?: string;
}


export const SaveButton = (props: SaveButtonProps) => {
    const { t } = useTranslation();
    return <Button type={'primary'} disabled={props.disabled || props.state === SaveButtonState.process}
                   danger={props.state === SaveButtonState.error}
                   loading={props.state === SaveButtonState.process}
                   onClick={props.onClick}>{t(props.titlesByState[props.state])}
    </Button>;
}
