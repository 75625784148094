import React, { FC, useEffect } from 'react';
import { Channel } from '../../models/channel';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { ChannelEditStore } from '@/app/connects/components/ConnectPageContent';
import { Form, InputNumber, Input, Space, Button } from 'antd';
import { useMaxLength, useRegExp, useRequired } from '@/common/utils/form-validators';
import CopyToClipboard from 'react-copy-to-clipboard';
import { CopyOutlined } from '@ant-design/icons';
import { getCurrentDomain } from '@/common/utils/get-current-domain';

type UsedeskProps = {
    channel: Channel;
    channelEditStore: ChannelEditStore;
    isManageChannels: boolean;
    onFinish: (values: any) => void;
    isRequest: boolean;
}



export const Sherlock: FC<UsedeskProps> = observer(({
                                                        channel,
                                                        isManageChannels,
                                                        channelEditStore,
                                                        onFinish,
                                                        isRequest
                                                    }) => {
    const {t} = useTranslation();
    const [form] = Form.useForm();
    channelEditStore.setCurrentEditForm(form);
    const onFinishForm = (values: any) => {
        onFinish(values);
    }

    useEffect(() => form.resetFields(), [channel.id]);

    const required = useRequired();
    const maxLength = useMaxLength(4096);
    const maxLength128 = useMaxLength(128);
    const startsWithQaIntentValidator = useRegExp(/^qa-.+|intent-.+/, 'qa- / intent-');

    const initialValues = {
        domain: channel.params.domain,
        sherlock_user_name: channel.params.sherlock_user_name,
        sherlock_password: channel.params.sherlock_password,
        max_message_len: channel.params.max_message_len,
        long_text_intent: channel.params.long_text_intent,
        queue_id: channel.params.queue_id,
        request_priority_id: channel.params.request_priority_id,
    };

    const liaWebHook = `${getCurrentDomain()}/channels/sherlock?key=${channel.api_key}`;



    return <Form form={form} style={{maxWidth: '600px'}} layout="vertical" onFinish={onFinishForm}
                 initialValues={initialValues}>

        <Form.Item label={t('connect.sherlock.lia_webhook')}>
            <Space.Compact
                style={{
                    width: '100%',
                }}
            >
                <Input value={liaWebHook} readOnly/>
                <CopyToClipboard text={liaWebHook} onCopy={() => alert(t('actions.copied'))}>
                    <Button type="default" icon={<CopyOutlined/>}/>
                </CopyToClipboard>
            </Space.Compact>
        </Form.Item>

        <Form.Item rules={[required, maxLength]} name="domain"
                   label={t('connect.sherlock.domain')}>
            <Input disabled={!isManageChannels || isRequest}/>
        </Form.Item>

        <Form.Item rules={[required, maxLength128]} name="sherlock_user_name" label={t('connect.sherlock.sherlock_user_name')}>
            <Input disabled={!isManageChannels || isRequest}/>
        </Form.Item>

        <Form.Item rules={[required, maxLength128]} name="sherlock_password"
                   label={t('connect.sherlock.sherlock_password')}>
            <Input disabled={!isManageChannels || isRequest}/>
        </Form.Item>

        <Form.Item rules={[required]} name="max_message_len"
                   label={t('connect.sherlock.max_message_len')}>
            <InputNumber min={2} disabled={!isManageChannels || isRequest}/>
        </Form.Item>

        <Form.Item rules={[required, maxLength128, startsWithQaIntentValidator]} name="long_text_intent"
                   label={t('connect.sherlock.long_text_intent')}>
            <Input disabled={!isManageChannels || isRequest}/>
        </Form.Item>

        <Form.Item rules={[required, maxLength128]} name="queue_id"
                   label={t('connect.sherlock.queue_id')}>
            <Input disabled={!isManageChannels || isRequest}/>
        </Form.Item>

        <Form.Item rules={[required, maxLength128]} name="request_priority_id"
                   label={t('connect.sherlock.request_priority_id')}>
            <Input disabled={!isManageChannels || isRequest}/>
        </Form.Item>
    </Form>
});


