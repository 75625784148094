import React from 'react';
import { inject, Observer, observer } from 'mobx-react';
import cn from './Params.module.scss';
import { action, computed, makeObservable, observable } from 'mobx';
import { uniqBy } from 'lodash';
import cns from 'classnames';
import { WithTranslation, withTranslation } from 'react-i18next';
import { EntitySettingsParams, IntentTrigger } from '../models/triggers/IntentTrigger';
import { IntentStore } from '../../intents/intent.store';
import { EntityStore } from '../../entities/entity.store';
import { getEntitiesFromIntent, Intent } from '../../intents/models/Intent';
import { Entity } from '../../entities/models/entity';
import { Loader } from '@/common/components/Loader';
import { EntityEdit } from './EntityEdit';
import { Button, Form, Select, Space } from 'antd';
import { LabeledValue } from 'antd/es/select';
import { DeleteOutlined } from '@ant-design/icons';

interface IntentTriggerParamsEditorProps extends WithTranslation {
    trigger: IntentTrigger;
    intentStore?: IntentStore;
    entityStore?: EntityStore;
}

@inject('intentStore', 'entityStore')
@observer
export class IntentTriggerParamsEditorComp extends React.Component<IntentTriggerParamsEditorProps> {
    @observable selectedValue: LabeledValue = null;
    @observable loaded = false;

    constructor(props: IntentTriggerParamsEditorProps) {
        super(props);
        makeObservable(this);
    }

    async componentDidMount() {
        await this.props.intentStore!.load();
        await this.props.entityStore!.load();
        this.loaded = true;

        this.updateEntitySettings(this.props);
    }

    @action.bound
    onAddNewIntent = (e: LabeledValue) => {
        const id = +e.value;
        this.selectedValue = e;

        const intent = this.props.intentStore!.getIntentById(id);
        const entities = getEntitiesFromIntent(intent!, this.props.entityStore!.entities);

        this.props.trigger.addIntent(intent!, entities);

        this.selectedValue = null;
    }

    @computed
    get unusedIntents(): Array<Intent> {
        return this.props.intentStore!.intents.filter(intent => {
            const intentIds = this.props.trigger.intents.map(i => i.id);
            return !intentIds.includes(intent.id!);
        });
    }

    @computed
    get intents(): Array<Intent> {
        return this.props.intentStore!.intents.filter(intent =>
            this.props.trigger.intents.map(i => i.id).includes(intent.id!)
        );
    }

    @computed
    get entities(): Array<Entity> {
        return uniqBy(
            this.intents.flatMap(intent => getEntitiesFromIntent(intent, this.props.entityStore!.entities)),
            e => e.id
        );
    }

    private updateEntitySettings(props: IntentTriggerParamsEditorProps) {
        const settings = props.trigger.entitySettings;
        if (settings) {
            this.entities.forEach(entity => {
                const exist = settings.some(es => es.entity.id === entity.id);
                if (!exist) {
                    props.trigger.changeEntitySettings({
                        entity: {id: entity.id, name: entity.name},
                        settings: EntitySettingsParams.OPTIONAL,
                    });
                }
            });

            settings.forEach((es) => {
                const exist = this.entities.some(e => e.id === es.entity.id);
                if (!exist) {
                    props.trigger.removeEntitySettings(es);
                }
            })
        }
    }

    private createOnChangeEvent(old?: number) {
        return (_new: LabeledValue) => {
            const id = _new.value;
            return this.onChangeIntent(id && {id: +id}, old && {id: old});
        }
    }

    @action.bound
    private onChangeIntent = async (n?: Pick<Intent, 'id'>, old?: Pick<Intent, 'id'>) => {
        if (old) {
            const intent = await this.props.intentStore!.getIntentByIdAsync(old.id);
            this.props.trigger.removeIntent(intent, getEntitiesFromIntent(intent, this.props.entityStore!.entities));
        }

        if (n) {
            const newIntent = await this.props.intentStore!.getIntentById(n.id);
            this.props.trigger.addIntent(newIntent, getEntitiesFromIntent(newIntent, this.props.entityStore!.entities));
        }

    }

    @action.bound
    private deleteIntent = async (e: React.MouseEvent, id: number) => {
        e.stopPropagation();
        const intent = await this.props.intentStore!.getIntentByIdAsync(id);
        this.props.trigger.removeIntent(intent, getEntitiesFromIntent(intent, this.props.entityStore!.entities));
    }

    // Чтобы не закрывалась правая менюшка
    stopPropagation(e: React.MouseEvent) {
        e.stopPropagation();
    }


    renderGroups() {
        const existingIntents = (this.props.trigger.intents.filter(i => this.props.intentStore._intents.find(_intent => _intent.id === i.id)) || []);

        return <>
            <div className={cn.group}>
                <h6 className={cn.groupTitle}>
                    {this.props.t('flows.linked_intents')}
                </h6>
                {
                    existingIntents.map((item, i) => {
                        const intents = [this.props.intentStore!.getIntentById(item.id), ...this.unusedIntents];

                        const label = intents.find(i => i.id === item.id).name;

                        return <div key={item.id}>
                            {
                                <Form.Item>
                                    <Space.Compact
                                        style={{
                                            width: '100%',
                                        }}
                                    >
                                            <Select
                                                defaultValue={{value: item.id, label: label}}
                                                onClick={this.stopPropagation}
                                                showSearch
                                                placeholder={this.props.t('flows.select_intents')}
                                                optionFilterProp="children"
                                                notFoundContent={this.props.t('flows.intent_not_found')}
                                                fieldNames={{value: 'id', label: 'name'}}
                                                onChange={this.createOnChangeEvent(item.id)}
                                                labelInValue
                                                filterOption={(input, option) => {
                                                    return (option?.name ?? '').toLowerCase().includes(input.toLowerCase())
                                                }}
                                                options={this.unusedIntents}/>
                                        <Button type="default" onClick={e => this.deleteIntent(e, item.id)} icon={<DeleteOutlined/>}/>
                                    </Space.Compact>
                                </Form.Item>
                            }
                            {

                                <div className={cns(cn.or, {[cn.orActive]: i < existingIntents.length - 1})}>{this.props.t('flows.or')}</div>
                            }
                        </div>;
                    })
                }
                <Form.Item>
                    <Select
                        onClick={this.stopPropagation}
                        showSearch
                        placeholder={this.props.t('flows.select_intents')}
                        optionFilterProp="children"
                        notFoundContent={this.props.t('flows.intent_not_found')}
                        fieldNames={{value: 'id', label: 'name'}}
                        onChange={this.onAddNewIntent}
                        labelInValue
                        value={this.selectedValue}
                        filterOption={(input, option) => {
                            return (option?.name ?? '').toLowerCase().includes(input.toLowerCase())
                        }}
                        options={this.unusedIntents}/>
                </Form.Item>
            </div>
            {
                this.entities.length > 0 &&
                <div className={cn.group}>
                    <h6 className={cn.groupTitle}>
                        {this.props.t('flows.entities')}
                    </h6>
                    <EntityEdit entities={this.props.entityStore.entities}
                                trigger={this.props.trigger}/>
                </div>
            }

        </>
    }


    render() {
        if (!this.loaded) {
            return <Loader/>
        }

        return <div className={cn.editor}>
            <Observer>
                {
                    () => this.renderGroups()
                }
            </Observer>
        </div>
    }
}

export const IntentTriggerParamsEditor = withTranslation()(IntentTriggerParamsEditorComp);

