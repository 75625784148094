import { action, observable } from 'mobx';

export class ErrorsStore {
    @observable headerFixedError: string = '';

    @action
    setHeaderError(error: string, delay?: number) {
        this.headerFixedError = error;
        if (delay) {
            setTimeout(() => this.removeHeaderError(), delay);
        }
    }

    @action
    removeHeaderError() {
        this.headerFixedError = '';
    }
}
