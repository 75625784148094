import styled, { keyframes } from 'styled-components';
import { Typography } from 'antd';
const { Text } = Typography;
export const ButtonActions = styled.div`
  display: grid;
  grid-gap: 10px;
`;


const fade = (y: number) => keyframes`
    0% {
        opacity: 1;
    }
    100% {
        opacity: ${y};
    }
`;

export const FadeAnimation = styled(Text)<{fader: number}>`
    animation : ${props => fade(props.fader)} 0.6s linear
`;
