import React, {PureComponent} from 'react';
import {Collapse} from 'reactstrap';
import PropTypes from 'prop-types';
import ChevronDownIcon from 'mdi-react/ChevronDownIcon';
import ChevronUpIcon from 'mdi-react/ChevronUpIcon';

export default class CollapseComponent extends PureComponent {
    static propTypes = {
        title: PropTypes.element,
        className: PropTypes.string,
        children: PropTypes.element.isRequired,
        onToggle: PropTypes.func,
        isOpen: PropTypes.bool,
        isForceClose: PropTypes.bool,
    };

    static defaultProps = {
        title: '',
        className: '',
    };
    onEntering = () => {
        this.setState({status: 'opening', icon: <ChevronUpIcon className={'collapse__title-chevron'}/>});
    };
    onEntered = () => {
        this.setState({status: 'opened', icon: <ChevronUpIcon className={'collapse__title-chevron'}/>});
    };
    onExiting = () => {
        this.setState({status: 'closing', icon: <ChevronDownIcon className={'collapse__title-chevron'}/>});
    };
    onExited = () => {
        this.setState({status: 'closed', icon: <ChevronDownIcon className={'collapse__title-chevron'}/>});
    };
    toggle = () => {
        this.setState(prevState => {
          if (this.props.onToggle) this.props.onToggle(!prevState.collapse);
          return {collapse: !prevState.collapse}
        });
    };

    constructor() {
        super();
        this.state = {
            collapse: false,
            status: 'closed',
            icon: <ChevronDownIcon className={'collapse__title-chevron'}/>,
        };
    }

    componentDidMount() {
        this.setState({
          collapse: this.props.isOpen || false,
        })
    }

    componentDidUpdate(prevProps) {
        if (this.props.isOpen !== prevProps.isOpen) {
            this.setState({
                collapse: this.props.isOpen || false,
            })
        }
      }

    UNSAFE_componentWillReceiveProps(props) {
        if (props.isForceClose) {
            this.setState({
                collapse: false
            });
        }
    }

    render() {
        const {className, title, children} = this.props;
        const {icon, collapse, status} = this.state;

        return (
            <div className={`collapse__wrapper ${status} ${className}`}>
                <button onClick={this.toggle} className="collapse__title" type="button">
                    <div className="collapse__title-span">{title}</div>
                    {icon}
                </button>
                <Collapse
                    isOpen={collapse}
                    className="collapse__content"
                    onEntering={this.onEntering}
                    onEntered={this.onEntered}
                    onExiting={this.onExiting}
                    onExited={this.onExited}
                >
                    <div>
                        {children}
                    </div>
                </Collapse>
            </div>
        );
    }
}
