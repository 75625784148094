import React, { FC, useState } from 'react';
import * as Styled from './CustomNodes.styled';
import { Handle, Position } from 'reactflow';
import { useTranslation } from 'react-i18next';
import { NodeProps } from '@reactflow/core/dist/esm/types/nodes';

type BlueNodeData = {
    renderData: { title: string; };
    label: string;
};

type BlueLinkNodeDataProps = {
    renderData: {
        list: { id: number; name: string; link: string; }[];
        title: string;
        emptyTitle: string;
    };
    label: string;
};

type SnippetNodeDataProps = {
    renderData: {
        projectId: string;
        title: string;
        id: string;
        isEmpty: boolean;
    };
    label: string;
};

type JumpToNodeDataProps = {
    renderData: {
        title: string;
        projectId: string;
        id: string;
    };
    label: string;
};

type ImageNodeDataProps = {
    renderData: {
        url: string;
        caption: string;
        title: string;
    };

    label: string;
};

type WhiteNodeDataProps = {
    renderData: {
        title: string;
    }

    label: string;
};

type BaseUrlNodeDataProps = {
    label: string;
    renderData: {
        caption: string;
        url: string;
    }
};

type StateNodeDataProps = {
    renderData: {
        title: string;
    }
};

export const BlueNode: FC<NodeProps<BlueNodeData>> = ({data, isConnectable}) => {
    return <>
        <Handle
            type="target"
            position={Position.Top}
            isConnectable={isConnectable}
        />
        <Styled.BlueNodeStyled>
            <Styled.Title>{data.renderData.title}</Styled.Title>
            <Styled.Body>
                {data.label}
            </Styled.Body>
        </Styled.BlueNodeStyled>

        <Handle
            type="source"
            position={Position.Bottom}
            isConnectable={isConnectable}
        />
    </>
}

export const BlueLinkNode: FC<NodeProps<BlueLinkNodeDataProps>> = ({data, isConnectable}) => {
    const {t} = useTranslation();
    return <>
        <Handle
            type="target"
            position={Position.Top}
            isConnectable={isConnectable}
        />
        <Styled.BlueNodeStyled>
            <Styled.Title>{data.renderData.title}</Styled.Title>
            <Styled.Body>
                {data.renderData.list.length ? data.renderData.list.map((item, i: number) => {
                    return <><Styled.WhiteLink key={item.id}
                                               to={item.link}>
                        {item.name}
                    </Styled.WhiteLink>
                        {
                            i !== data.renderData.list.length - 1 ? ` ${t('flows.or')} ` : ''
                        }
                    </>
                }) : data.renderData.emptyTitle}
            </Styled.Body>
        </Styled.BlueNodeStyled>

        <Handle
            type="source"
            position={Position.Bottom}
            isConnectable={isConnectable}
        />
    </>
}

export const SnippetNode: FC<NodeProps<SnippetNodeDataProps>> = ({data, isConnectable}) => {
    const {t} = useTranslation();
    return <>
        <Handle
            type="target"
            position={Position.Top}
            onConnect={(params) => console.log('handle onConnect', params)}
            isConnectable={isConnectable}
        />
        <Styled.SnippetNodeStyled>
            <Styled.SnippetTitle>{data.renderData.title}</Styled.SnippetTitle>
            <Styled.Body>
                {data.renderData.isEmpty ? <Styled.SelectSnippet>⚠️ {t('flows.select_snippet')}</Styled.SelectSnippet> : <Styled.BlackLink
                    to={`/app/${data.renderData.projectId}/snippets/${data.renderData.id}`}>{data.label}
                </Styled.BlackLink>}

            </Styled.Body>
        </Styled.SnippetNodeStyled>
        <Handle
            type="source"
            position={Position.Bottom}
            isConnectable={isConnectable}
        />
    </>
}

export const JumpToNode: FC<NodeProps<JumpToNodeDataProps>> = ({data, isConnectable}) => {
    return <>
        <Handle
            type="target"
            position={Position.Top}
            isConnectable={isConnectable}
        />
        <Styled.JumpToNodeStyled>
            <Styled.JumpTitle>{data.renderData.title}</Styled.JumpTitle>
            <Styled.Body>
                <Styled.BlackLink
                    to={`/app/${data.renderData.projectId}/flows/${data.renderData.id}`}>{data.label}</Styled.BlackLink>
            </Styled.Body>
        </Styled.JumpToNodeStyled>
        <Handle
            type="source"
            position={Position.Bottom}
            isConnectable={isConnectable}
        />
    </>
}

export const ImageNode: FC<NodeProps<ImageNodeDataProps>> = ({data, isConnectable}) => {
    return <>
        <Handle
            type="target"
            position={Position.Top}
            isConnectable={isConnectable}
        />
        <Styled.WhiteNodeStyled>
            <Styled.WhiteTitle>{data.renderData.title}</Styled.WhiteTitle>
            <Styled.Body>
                <Styled.ExternalLink href={data.renderData.url} rel={'noreferrer'} target={'_blank'}>
                    <img width={'30px'} height={'30px'} src={data.renderData.url} alt={data.renderData.caption}></img>
                </Styled.ExternalLink>
            </Styled.Body>
        </Styled.WhiteNodeStyled>
        <Handle
            type="source"
            position={Position.Bottom}
            isConnectable={isConnectable}
        />
    </>
}


export const BaseUrlNode: FC<NodeProps<BaseUrlNodeDataProps>> = ({data, isConnectable}) => {
    return <>
        <Handle
            type="target"
            position={Position.Top}
            isConnectable={isConnectable}
        />
        <Styled.WhiteNodeStyled>
            <Styled.WhiteTitle>{data.label}</Styled.WhiteTitle>
            <Styled.Body>
                <Styled.ExternalLink href={data.renderData.url} rel={'noreferrer'} target={'_blank'}>
                    {data.renderData.caption}
                </Styled.ExternalLink>
            </Styled.Body>
        </Styled.WhiteNodeStyled>
        <Handle
            type="source"
            position={Position.Bottom}
            isConnectable={isConnectable}
        />
    </>
}


export const WhiteNode: FC<NodeProps<WhiteNodeDataProps>> = ({data, isConnectable}) => {
    const [isFull, setIsFull] = useState(false);
    const toggleFull = () => {
        setIsFull(!isFull);
    }

    return <>
        <Handle
            type="target"
            position={Position.Top}
            isConnectable={isConnectable}
        />
        <Styled.WhiteNodeStyled isFull={isFull} onClick={toggleFull}>
            <Styled.WhiteTitle>{data.renderData.title}</Styled.WhiteTitle>
            {isFull ?
                <Styled.FullWhiteBody>
                    {data.label}
                </Styled.FullWhiteBody> :
                <Styled.WhiteBody>
                    {data.label}
                </Styled.WhiteBody>
            }
        </Styled.WhiteNodeStyled>
        <Handle
            type="source"
            position={Position.Bottom}
            isConnectable={isConnectable}
        />
    </>
}

export const StateNode: FC<NodeProps<StateNodeDataProps>> = ({data, isConnectable}) => {
    return <>
        <Handle
            type="target"
            position={Position.Top}
            isConnectable={isConnectable}
        />

        <Styled.StateZeroOpacityWrapper>
            <Styled.StateNodeStyled title={data.renderData.title}>
                <Styled.StateTitle>{data.renderData.title}</Styled.StateTitle>
            </Styled.StateNodeStyled>
        </Styled.StateZeroOpacityWrapper>
        <Handle
            type="source"
            position={Position.Bottom}
            isConnectable={isConnectable}
        />
    </>
}
