import { IntentAnalyticsData } from '@/app/stats/Types';
import { Filter } from '@/app/stats/AnalyticsFilter/AnalyticsFilter.component';
import { Project } from '@/common/models/project';
import { API } from '@/common/api';

export class IntentAnalyticsApi extends API {
    static async updateAnalytics(project: Project, filter?: Filter): Promise<IntentAnalyticsData> {
        return this.post<IntentAnalyticsData>(`/projects/${project.id}/analytics/stack-intents`, filter);
    }

    static async downloadCsv(project: Project, filter?: Filter): Promise<string> {
        return this.post<string>(`/projects/${project.id}/analytics/analytics-intents-csv-export`, filter);
    }
}
