type LoadablePromise = {
    resolve: (value: any) => void;
    reject: (reason?: any) => void;
    promise: Promise<any>;
};

export function createLoadablePromise(): LoadablePromise {
    let resolve = null;
    let reject = null;

    const promise = new Promise((res, rej) => {
        resolve = res;
        reject = rej;
    });

    return { resolve, reject, promise };
}
