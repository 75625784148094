import React from 'react';
import DatePicker from 'react-datepicker';
import { Input } from 'reactstrap';
import cn from './DateRange.module.scss';
import { makeObservable, observable } from 'mobx';
import { observer } from 'mobx-react';
import classnames from 'classnames';
import { DateRangeIcon } from './DateRangeIcon';

interface DateRangeProps {
    startDate: Date;
    endDate: Date;
    onChange: (v: DateRangeChangeEvent) => void;
    className?: string;
}

export interface DateRangeChangeEvent {
    startDate: Date;
    endDate: Date;
    open: boolean;
}


@observer
export class DateRange extends React.Component<DateRangeProps> {
    @observable openStart: boolean = false;
    @observable openEnd: boolean = false;

    constructor(props: DateRangeProps) {
        super(props);
        makeObservable(this);
    }

    handleChangeStart = (date: Date) => {
        this.openStart = !this.openStart;
        date.setHours(0);
        date.setMinutes(1);
        this.props.onChange({
            startDate: date,
            endDate: this.props.endDate,
            open: this.openEnd || this.openStart
        });
    };

    handleChangeEnd = (date: Date) => {
        date.setHours(23);
        date.setMinutes(59);
        this.openEnd = !this.openEnd;
        this.props.onChange({
            startDate: this.props.startDate,
            endDate: date,
            open: this.openEnd || this.openStart
        });
    };

    render() {
        return <div className={classnames(cn.dateRangeWrapper, this.props.className)}>
            <DatePicker
                className={classnames(cn.dateRangeInputLeft, 'datepicker-input')}
                customInput={<Input className={cn.dateRangeInput}/>}
                selected={this.props.startDate}
                selectsStart
                startDate={this.props.startDate}
                endDate={this.props.endDate}
                onChange={this.handleChangeStart}
                dateFormat="dd.MM.yy"
            />
            <div className={cn.divider}>—</div>
            <DatePicker
                open={false}
                className={classnames(cn.dateRangeInputRight, 'datepicker-input')}
                customInput={<Input className={cn.dateRangeInput}/>}
                selected={this.props.endDate}
                selectsEnd
                startDate={this.props.startDate}
                endDate={this.props.endDate}
                onChange={this.handleChangeEnd}
                dateFormat="dd.MM.yy"
            />
            <DateRangeIcon className={cn.dateRangeIcon}/>
        </div>
    }
}
