import React from 'react';
import { Trigger } from '../models/triggers/Trigger';
import { FallbackTrigger } from '../models/triggers/FallbackTrigger';
import { FallbackTriggerView } from './FallbackTriggerView';
import { IntentTrigger } from '../models/triggers/IntentTrigger';
import { IntentTriggerView } from './IntentTriggerView';
import { EntityTriggerView } from './EntityTriggerView';
import { EntityTrigger } from '../models/triggers/EntityTrigger';
import { AllowOutTrigger } from '../models/triggers/AllowOutTrigger';
import { AllowTriggerView } from './AllowTriggerView';
import { StartTrigger } from '../models/triggers/StartTrigger';
import { StartTriggerView } from './StartTriggerView';
import { LocationTrigger } from '../models/triggers/LocationTrigger';
import { StickerTrigger } from '../models/triggers/StickerTrigger';
import { FileTrigger } from '../models/triggers/FileTrigger';
import { ImageTrigger } from '../models/triggers/ImageTrigger';
import { AudioTrigger } from '../models/triggers/AudioTrigger';
import { VideoTrigger } from '../models/triggers/VideoTrigger';
import { FileLikeTriggerView } from './FileLikeTriggerView';
import { UserSendTrigger } from '../models/triggers/UserSendTrigger';

export class TriggerViewFactory {
    static build(trigger: Trigger) {
        switch (trigger.type) {
            case FallbackTrigger.type:
                return <FallbackTriggerView trigger={trigger as FallbackTrigger}/>;
            case IntentTrigger.type:
                return <IntentTriggerView trigger={trigger as IntentTrigger}/>;
            case EntityTrigger.type:
                return <EntityTriggerView trigger={trigger as EntityTrigger}/>;
            case AllowOutTrigger.type:
                return <AllowTriggerView trigger={trigger as AllowOutTrigger}/>;
            case StartTrigger.type:
                return <StartTriggerView trigger={trigger as StartTrigger}/>;
            case LocationTrigger.type:
            case StickerTrigger.type:
            case FileTrigger.type:
            case ImageTrigger.type:
            case AudioTrigger.type:
            case VideoTrigger.type: {
                return <FileLikeTriggerView trigger={trigger as UserSendTrigger}/>;
            }
            default:
                throw new Error('Unknown type of trigger, implement it in TriggerViewFactory')
        }
    }
}
