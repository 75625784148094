import React, { FC } from 'react';
import { Table } from 'antd';
import { RowSelectMethod } from 'antd/es/table/interface';


export const AnalyticsTable: FC<{
    columns: any,
    data: any;
    onChange?: (selectedRowKeys: any[], selectedRows: any[], info: {
        type: RowSelectMethod;
    }) => void,
    selectedRowKeys: React.Key[]
}> = ({columns, data, onChange, selectedRowKeys}) => {
    if (!columns || !data) {
        return null;
    }

    return <Table
        size={'small'}
        rowSelection={{
            onChange,
            selectedRowKeys,
            // getCheckboxProps: (record) => {
            //     return {
            //         disabled: !record.selectable,
            //         name: record.name,
            //     }
            // }
        }} pagination={false} scroll={{x: '100vw'}} columns={columns} dataSource={data}/>
}
