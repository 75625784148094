export interface PublishVersionRaw {
    id: number;
    name: string;
    publisher: {
        id: number;
        is_active: boolean;
        is_activated: boolean;
        is_superuser: boolean;
        date_joined: Date;
        email: string;
        first_name: string;
        last_name: string;
        avatar_url: string;
    };
    date_from: Date;
    date_to: Date;
}

export enum PublishRestoreState {
    INIT,
    PROGRESS,
    DONE
}

export interface PublishVersion {
    id: number;
    name: string;
    publisher: {
        id: number;
        isActive: boolean;
        isActivated: boolean;
        isSuperuser: boolean;
        dateJoined: Date;
        email: string;
        firstName: string;
        lastName: string;
        avatarUrl: string;
    };
    dateFrom: Date;
    dateTo: Date;
    hasRestoreError: boolean;
    isProdVersion: boolean;
    processState?: PublishRestoreState;
}
