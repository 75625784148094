import { DropItem } from '../models/DropItem';
import { Reaction } from '../models/reactions/Reaction';
import { State } from '../models/State';
import { Node } from '../models/Node';
import { ReactionsFactory } from './ReactionsFactory';
import { TriggersFactory } from './TriggersFactory';
import { v4 } from 'uuid';
import { Flow } from '../models/Flow';
import { FlowIterator } from '../models/FlowIterator';

export class DropFactory {
    static build(drop: DropItem, parent: Node, isNext = true): State | Reaction {
        const flow = FlowIterator.getHead(parent) as Flow;
        if (!flow) {
            throw new Error('can\'t find flow in head');
        }

        if (drop.type.includes('Reaction')) {
            return ReactionsFactory.build({
                id: v4(),
                name: `${drop.type.replace('Reaction', '')} ${flow.nextReactionNum}`,
                parent: parent,
                type: drop.type,
                branches: [],
                params: {},
            });
        }


        if (drop.type.includes('Trigger')) {
            const stateName = `State ${flow.nextStateNum}`;

            const state = new State(v4(), stateName, []);
            state.parent = parent;
            const trigger = TriggersFactory.build({
                parent: state,
                params: {allow: 'out'},
                type: drop.type,
                branches: [],
            });

            // @ts-ignore
            if (parent.next && isNext) {
                trigger.next = parent.next as (State | Reaction);
            }

            // @ts-ignore
            if (!isNext && parent.nextFail) {
                // @ts-ignore
                trigger.next = parent.nextFail as (State | Reaction);
            }

            state.addTrigger(trigger);
            return state
        }

        throw new Error('Bad type of Node for DropFactory');
    }
}
