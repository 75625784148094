import React, { FC } from 'react';
import cn from './Params.module.scss';
import { CopyId } from '@/app/scripts/components/CopyId/CopyId.component';
import { Reaction } from '../models/reactions/Reaction';

type DefaultReactionParamsEditorProps = {
    reaction: Reaction;
}

export const DefaultReactionParamsEditor: FC<DefaultReactionParamsEditorProps> = ({
                                                                                      reaction,
                                                                                  }) => {

    return <div className={cn.editor}>
            <CopyId id={reaction.id}/>
    </div>
}
