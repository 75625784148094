import { computed, makeObservable, observable } from 'mobx';

import {Flow} from './Flow';

export class Script {
    @observable id?: number;
    @observable groupId: number;
    @observable name: string;
    @observable type: string;
    @observable data: Flow;

    @observable isActive: boolean;
    @observable dateCreated: Date;

    constructor(
        groupID: number,
        name: string, type: string,
        data: Flow,
        isActive: boolean,
        dateCreated: Date
    ) {
        makeObservable(this);
        this.groupId = groupID;
        this.name = name;
        this.type = type;
        this.data = data;
        this.isActive = isActive;
        this.dateCreated = dateCreated;
    }

    @computed get labels() {
        return this.data.labels;
    }
}
