import { Reaction } from './Reaction';
import { makeObservable, observable } from 'mobx';
import { QuickReplies } from '../QuickReply';

export class ButtonsReaction extends Reaction {
    static className = 'ButtonsReaction';
    @observable text: string = '';
    @observable buttons = new QuickReplies();

    constructor(id: string, name: string) {
        super(id, name);
        makeObservable(this);
    }
}
