import React from 'react';
import { ReactionNode, ReactionNodeProps } from './ReactionNode';
import { ButtonsReaction } from '../models/reactions/ButtonsReaction';
import { inject, observer } from 'mobx-react';
import { ScriptPageStore } from '../stores/script-page.store';
import { WithTranslation, withTranslation } from 'react-i18next';

type ButtonsReactionViewProps = {
    scriptPageStore?: ScriptPageStore;
} & ReactionNodeProps<ButtonsReaction> & WithTranslation;

@inject('scriptPageStore')
@observer
class ButtonsReactionViewComp extends ReactionNode<ButtonsReaction, ButtonsReactionViewProps> {
    onClick(): void {
        this.props.scriptPageStore!.select(this.props.reaction);
    }

    onDelete(): void {
        this.props.reaction.removeSelf();

        this.props.scriptPageStore!.select();
    }

    renderBody(): React.ReactNode {
        return this.props.reaction.text || this.props.t('flows.reactions_body.config_buttons');
    }
}

export const ButtonsReactionView = withTranslation()(ButtonsReactionViewComp);
