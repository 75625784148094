import { IntentAnalyticsData } from '@/app/stats/Types';
import { addDays, format } from 'date-fns';
import { ru } from 'date-fns/locale'
import { ViewValue } from '@/app/stats/IntentAnalytics/types';
import { SystemValue } from '@/app/stats/SystemFilter/SystemFilter.component';

export function capitalizeFirstLetter(str: string) {
    return str.charAt(0).toUpperCase() + str.slice(1);
}

export const getTableData = (response: IntentAnalyticsData, viewValue: ViewValue, value: SystemValue) => {
    const elements = response.elements.filter(element => {
        return value === 'system' ? !element.isSystem : true;
    });

    const tableData = elements.map(element => {
        const values = element.values.reduce<Record<string, string>>((curr, prev, i) => {
            curr[response.points[i]] = viewValue.includes('relative') ? `${prev[viewValue]} %` : `${prev[viewValue]}`;
            return curr;
        }, {});

        return {
            key: element.key,
            index: 1,
            name: element.label,
            // selectable: element.selectable,
            ...values
        }
    });

    const defaultSelected = elements
        .filter(element => element.selectable)
        .map(element => element.key);

    const dateFormat = response.searchKey === 'day' ? 'EEEEEE dd.MM' : response.searchKey === 'month' ? 'LLLL' : 'dd MMM';

    const tableColumns = [
        {
            title: 'Название',
            width: 160,
            dataIndex: 'name',
            key: 'name',
            fixed: 'left'
        },
        ...response.points.map(point => {
            const currentDate = format(point * 1000, dateFormat, {locale: ru});
            const nextWeek = format(addDays(point * 1000, 7), dateFormat, {locale: ru});
            const resultDate = response.searchKey === 'week' ? `${capitalizeFirstLetter(currentDate)} - ${capitalizeFirstLetter(nextWeek)}` : capitalizeFirstLetter(currentDate);
            return {
                width: 100,
                dataIndex: point,
                key: point,
                title: resultDate
            }
        })
    ];

    return {tableData, tableColumns, defaultSelected}
}

export const getChartData = (response: IntentAnalyticsData, viewValue: ViewValue, value: SystemValue) => {
    const chartData: Record<string, string | number>[] = [];
    const elements = response.elements.filter(element => {
        return value === 'system' ? !element.isSystem : true;
    });
    const keys = elements.map(({label, key}) => ({ label, key }));
    const selected = elements
        .reduce<Record<string, {
        selected: boolean,
    }>>((curr, prev) => {
        curr[prev.key] = {selected: prev.selectable};
        return curr;
    }, {});

    let max = 0;
    const maxByKey: Record<string, number> = {};
    const versions =  [].map((date, i) => {
        return {
            date: addDays(date * 1000, 5).getTime(),
            label: `1.0.${i}`
        };
    });

    const labels: Record<number, Record<string, Record<string, string>>> = {};

    response.points.forEach((date, i) => {
        const number = date * 1000;
        const point: Record<string, number | string> = {
            number
        };

        labels[number] = {};

        elements.forEach(element => {
            labels[number][element.label] = element.values.reduce<Record<string, string>>((curr, prev) => {
                // TODO: подумать как с этим говном разобраться
                curr[prev[viewValue]] = viewValue.includes('relative') ? `${prev[viewValue]} %` : `${prev[viewValue]}`;
                return curr;
            }, {});

            maxByKey[element.key] = Math.max(+element.values[i][viewValue], maxByKey[element.label] ?? 0)
            max = Math.max(max, +element.values[i][viewValue]);
            point[element.key] = element.values[i][viewValue];
        });
        chartData.push(point);
    });

    return {chartData, keys, selected, max, maxByKey, versions, labels};
}
