import React from 'react';
import { Link } from 'react-router-dom/dist';

import cns from 'classnames';

import ChevronRightIcon from '@mui/icons-material/ChevronRight';

import cn from './SidebarSubMenuLink.module.scss';

import { DragSidemenuItem, DragSidemenuType, DropTargetItem } from './models/drag-sidemenu-item';
import { Tracker } from '@/core/analytics/tracker';
import { AsDropTargetProps, asDropTarget } from '@/common/components/drag-n-drop/asDropTarget';
import { AsDragSourceProps, asDragSource } from '@/common/components/drag-n-drop/asDragSource';

type SidebarSubMenuLinkRawProps = {
    count?: number | null | undefined;
    subtitle?: string;
    route?: string;
    title: string;
    id?: number;
    groupId?: number,
    onDragEnd?: (from: DragSidemenuItem, to: DragSidemenuItem) => void;
    isActive: boolean;
    isSelected: boolean;
    analyticName: string;
    noDragging?: boolean;
    alertCount?: boolean;
}

type SidebarSubMenuLinkProps = SidebarSubMenuLinkRawProps & AsDragSourceProps & AsDropTargetProps;

export class SidebarSubMenuLinkComponent extends React.PureComponent<SidebarSubMenuLinkProps> {
    render() {
        const isActiveDD = this.props.canDrop && this.props.isOver && !this.props.isDragging;

        const classList = [cn.sidebarSubMenuLinkWrapper];
        if (isActiveDD) {
            classList.push(cn.sidebarDropActive);
        }

        if (!this.props.isActive) {
            classList.push(cn.sidebarNotActive);
        }

        if (this.props.isSelected) {
            classList.push(cn.sidebarRightLinkSelected);
        }

        return <Link
            onClick={() => Tracker.trackEvent('Menu', {Name: this.props.analyticName, Subname: this.props.title})}
            title={this.props.title} key={this.props.id} to={this.props.route}
		>
            <div ref={this.props.dragRef} className={cns(...classList)}>
                <div className={cn.sidebarRightLink}>
                    <p className={cns('sidebar__link-title', cn.sidebarRightLinkTitle)}>{this.props.title}</p>
                    {this.props.subtitle ? <span className={cn.sidebarRightLinkSubtitle}> {this.props.subtitle} </span> : ''}
                </div>
                {this.props.count ? <span className={cns(this.props.alertCount && cn.alertCount, cn.sidebarRightLinkCount)}>{this.props.count}</span> : ''}
                <ChevronRightIcon className={cn.sidebarRightLinkArrow} />
            </div>
        </Link>
    }
}

const DropTargetMenuLink = asDropTarget<SidebarSubMenuLinkRawProps, DragSidemenuItem, DropTargetItem>(SidebarSubMenuLinkComponent, {
	accept: DragSidemenuType,
	drop: ({ groupId, id, title }) => ({ groupId, id, title })
});

const SidebarSubMenuLink = asDragSource<SidebarSubMenuLinkRawProps, DragSidemenuItem, DropTargetItem>(
	DropTargetMenuLink,
	{
		type: DragSidemenuType,
		canDrag: (props) => !props.noDragging,
		item: (props) => ({
			groupId: props.groupId,
			id: props.id,
			title: props.title
		}),
		end: (props, item, monitor) => {
			const dropResult = monitor.getDropResult();

			if (dropResult) {
				const to = dropResult.group ?
					{ groupId: dropResult.group.id } :
					{ groupId: dropResult.groupId, id: dropResult.id, title: dropResult.title };
				if (!dropResult.group || item.groupId !== to.groupId) {
					props.onDragEnd(item, to)
				}
			}
		}
	}
);

export { SidebarSubMenuLink };

