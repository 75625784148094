import React from 'react'

export const SelectedIcon: React.FunctionComponent<{ className?: string }> = (props) => <svg className={props.className}
                                                                                             width="12" height="9"
                                                                                             viewBox="0 0 12 9"
                                                                                             fill="none"
                                                                                             xmlns="http://www.w3.org/2000/svg">
        <path d="M1 5.11765L3.91667 8L11 1" stroke="#116FFC" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
;

