export enum ActivityLogComponents {
    ALL = '',
    SCRIPT_GROUP = 'script_group',
    SCRIPT = 'script',
    INTENT_GROUP = 'intent_group',
    INTENT = 'intent',
    ENTITY_GROUP = 'entity_group',
    ENTITY = 'entity',
    QA_GROUP = 'qa_group',
    QA = 'qa',
    FACT_GROUP = 'fact_group',
    FACT = 'fact',
    PROJECT = 'project',
    PROJECT_VERSION = 'project_version',
    USER = 'user',
    COMPANY = 'company',
    CHANNEL = 'channel'
}

export enum ActivityLogActions {
    ALL = '',
    CREATE = 'create',
    UPDATE = 'update',
    DELETE = 'delete',
    PUBLISH = 'publish',
    ACTIVATION = 'activation',
    UPLOAD = 'upload'
}

export interface ActivityLogsResponse {
    currentPage: number;
    pageCount: number;
    perPage: number;
    results: ActivityLog[];
}

export interface ActivityLogProject {
    id: number;
    name: string;
}

export interface ActivityLogProjectVersion {
    id: number;
    name: string;
    dateFrom: Date;
    dateTo?: Date;
}

export interface ActivityLog {
    user: ActivityLogUser;
    project?: ActivityLogProject;
    projectVersion?: ActivityLogProjectVersion;
    component: string;
    item: string;
    action: string;
    date: Date;
}

export interface ActivityLogUser {
    id: number;
    isActive: boolean;
    isActivated: boolean;
    isSuperuser: boolean;
    dateJoined: Date;
    email: string;
    firstName: string;
    lastName: string;
    avatar_url?: string;
}

export interface ActivityLogFilters {
    users: number;
    projects: number;
    projectVersions: number;
    components: ActivityLogComponents;
    items: string;
    actions: ActivityLogActions;
    dateFrom: Date;
    dateTo: Date;
}

export interface ActivityLogFiltersData {
    users: ActivityLogUser[];
    projects: ActivityLogProject[];
    projectVersions: ActivityLogProjectVersion[];
    components: ActivityLogComponents[];
    items: string[];
    actions: ActivityLogActions[];
}
