import { inject, observer } from 'mobx-react';
import { WithTranslation, withTranslation } from 'react-i18next';

import { AllowOutTrigger } from '../models/triggers/AllowOutTrigger';
import { BaseStaticTriggerView, BaseStaticTriggerViewProps } from './BaseStaticTriggerView';
import {ScriptPageStore} from "../stores/script-page.store";

interface AllowTriggerViewProps extends BaseStaticTriggerViewProps<AllowOutTrigger>, WithTranslation {
    scriptPageStore?: ScriptPageStore;
}

@inject('scriptPageStore', 'user')
@observer
export class AllowTriggerViewComp extends BaseStaticTriggerView<AllowOutTrigger, AllowTriggerViewProps> {
    body: string = this.props.t('flows.triggers.allow_out');
}

export const AllowTriggerView = withTranslation()(AllowTriggerViewComp);
