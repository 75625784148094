import React, { FC, useEffect } from 'react';
import { Channel } from '../../models/channel';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { ChannelEditStore } from '@/app/connects/components/ConnectPageContent';
import { Form, InputNumber, Input, Space, Button } from 'antd';
import { useMaxLength, useRegExp, useRequired } from '@/common/utils/form-validators';
import CopyToClipboard from 'react-copy-to-clipboard';
import { CopyOutlined } from '@ant-design/icons';
import { getCurrentDomain } from '@/common/utils/get-current-domain';

type UsedeskProps = {
    channel: Channel;
    channelEditStore: ChannelEditStore;
    isManageChannels: boolean;
    onFinish: (values: any) => void;
    isRequest: boolean;
}



export const Intercom: FC<UsedeskProps> = observer(({
                                                       channel,
                                                       isManageChannels,
                                                       channelEditStore,
                                                       onFinish,
                                                       isRequest
                                                   }) => {
    const {t} = useTranslation();
    const [form] = Form.useForm();
    channelEditStore.setCurrentEditForm(form);
    const onFinishForm = (values: any) => {
        onFinish(values);
    }

    useEffect(() => form.resetFields(), [channel.id]);

    const required = useRequired();
    const maxLength = useMaxLength(4096);
    const maxLength128 = useMaxLength(128);
    const startsWithQaIntentValidator = useRegExp(/^qa-.+|intent-.+/, 'qa- / intent-');

    const initialValues = {
        intercom_url: channel.params.intercom_url,
        lia_operator_id: channel.params.lia_operator_id,
        redirect_department: channel.params.redirect_department,
        max_message_len: channel.params.max_message_len,
        long_text_intent: channel.params.long_text_intent,
    };

    const liaWebHook = `${getCurrentDomain()}/channels/intercom?key=${channel.api_key}`;



    return <Form form={form} style={{maxWidth: '600px'}} layout="vertical" onFinish={onFinishForm}
                 initialValues={initialValues}>

        <Form.Item label={t('connect.intercom.lia_webhook')}>
            <Space.Compact
                style={{
                    width: '100%',
                }}
            >
                <Input value={liaWebHook} readOnly/>
                <CopyToClipboard text={liaWebHook} onCopy={() => alert(t('actions.copied'))}>
                    <Button type="default" icon={<CopyOutlined/>}/>
                </CopyToClipboard>
            </Space.Compact>
        </Form.Item>

        <Form.Item rules={[required, maxLength]} name="intercom_url"
                   label={t('connect.intercom.intercom_url')}>
            <Input disabled={!isManageChannels || isRequest}/>
        </Form.Item>

        <Form.Item rules={[required, maxLength128]} name="lia_operator_id" label={t('connect.intercom.lia_operator_id')}>
            <Input disabled={!isManageChannels || isRequest}/>
        </Form.Item>

        <Form.Item rules={[required, maxLength128]} name="redirect_department"
                   label={t('connect.intercom.redirect_department')}>
            <Input disabled={!isManageChannels || isRequest}/>
        </Form.Item>

        <Form.Item rules={[required]} name="max_message_len"
                   label={t('connect.intercom.max_message_len')}>
            <InputNumber min={2} disabled={!isManageChannels || isRequest}/>
        </Form.Item>

        <Form.Item rules={[required, maxLength128, startsWithQaIntentValidator]} name="long_text_intent"
                   label={t('connect.intercom.long_text_intent')}>
            <Input disabled={!isManageChannels || isRequest}/>
        </Form.Item>
    </Form>
});


