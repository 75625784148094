import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Navigate, Route, Routes } from 'react-router';
import { inject, observer } from 'mobx-react';
import { makeObservable, observable, runInAction } from 'mobx';
import { Page } from '@/common/components/page/Page';
import { TestChat } from '@/app/chat/components/TestChat';
import { ZeroScreen } from '@/common/components/zero-screen/ZeroScreen';
import { Loader } from '@/common/components/Loader';
import { ScriptComponent } from './Script';
import { IntentStore } from '../../intents/intent.store';
import { EntityStore } from '../../entities/entity.store';
import { ScriptsStore } from '../stores/scripts.store';
import { SideMenuRight } from '@/common/side-menu-right/SideMenuRight';
import { ScriptsMenu } from '../scripts-menu';
import cn from '@/app/scripts/components/Script.module.scss';
import { UserStore } from '@/core/stores/user.store';
import { RouteComponentProps, withRouter } from '@/common/utils/withRouter';

type ScriptPateComponentProps = RouteComponentProps & WithTranslation & {
    intentStore?: IntentStore;
    entityStore?: EntityStore;
    scriptsStore?: ScriptsStore;
    user?: UserStore;
}

@inject('intentStore', 'entityStore', 'scriptsStore', 'user')
@observer
export class ScriptPageComponent extends React.Component<ScriptPateComponentProps> {
    @observable isLoaded = false;

    constructor(props: ScriptPateComponentProps) {
        super(props);
        makeObservable(this);
    }

    async componentDidMount() {
        runInAction(() => {
            this.isLoaded = false;
        });

        await Promise.all([this.props.intentStore!.load(),
            this.props.entityStore!.load(),
            this.props.scriptsStore!.load()]);

        runInAction(() => {
            this.isLoaded = true;
        })
    }

    render() {
        if (!this.props.user.permissions.isViewScripts) {
            return <Navigate to={`/app/${this.props.user!.currentChoosedProjectId}/project-settings`}/>;
        }

        return (
            <>
                <SideMenuRight>
                    <ScriptsMenu scriptsStore={this.props.scriptsStore} intentStore={this.props.intentStore}/>
                </SideMenuRight>
                {this.isLoaded ? (
                    <Routes>
                        <Route path={'/'} element={
                            <Page rightBar={<TestChat/>}>
                                <ZeroScreen
                                    title={this.props.t('flows.create_new_flow_title')}
                                    subtitle={
                                        <>
                                            {this.props.t('zero_screens.subtitle')} <br/>
                                            {this.props.t('flows.create_new_flow_subtitle_suffix')}
                                        </>
                                    }
                                />
                            </Page>
                        }>
                        </Route>
                        <Route path={':id'} element={<ScriptComponent/>}/>
                        {/*<Route path={':id'} element={<ScriptComponent/>} />*/}
                        {/*<Route path={':id'} element={<ScriptComponent/>} />*/}
                        {/*<Route element={<Navigate to={`${match.path}/`} />} />*/}
                    </Routes>
                ) : (
                    <div className={cn.loader}>
                        <Loader/>
                    </div>
                )}
            </>
        );
    }
}

export const ScriptPage = withTranslation()(withRouter(ScriptPageComponent));
