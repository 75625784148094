import {StateDTO} from "./state.dto";
import {ReactionsDTO} from './reaction.dto';
import {Entity} from "../../entities/models/entity";
import {Condition} from "@/app/scripts/models/Condition";


export interface BranchDTO {
    name: string;
    condition: Condition | null;
    next?: StateDTO | ReactionsDTO;
}

export function makeTrueBranch(name: string, next?: StateDTO | ReactionsDTO): BranchDTO {
    return {
        name: name,
        condition: null,
        next,
    };
}

export function makeFailureBranch(name: string, next?: StateDTO | ReactionsDTO): BranchDTO {
    return {
        name: name,
        condition: {type: 'failure', params: {}},
        next,
    };
}

export function makeSuccessBranch(name: string, next?: StateDTO | ReactionsDTO): BranchDTO {
    return {
        name: name,
        condition: {type: 'success', params: {}},
        next,
    };
}

export function makeAllEntitiesBranch(name: string, next?: StateDTO | ReactionsDTO): BranchDTO {
    return {
        name: name,
        condition: {type: 'all-entities', params: {}},
        next,
    };
}

export function makeNoEntityBranch(name: string, entity: Entity, next?: StateDTO | ReactionsDTO): BranchDTO {
    return {
        name: name,
        condition: {type: 'no-entity', params: {entity_id: entity.id!}},
        next,
    };
}
