import React from 'react';
import cn from './ChatMessageMedia.module.scss';

interface ChatMessageVideoProps {
    url: string;
    caption: string;
}
export class ChatMessageVideo extends React.Component<ChatMessageVideoProps> {
    render() {
        return <div>
            <video width={300} src={this.props.url} controls/>
            <div className={cn.caption}>{this.props.caption}</div>
        </div>;
    }
}
