import React, { FC, useMemo } from 'react';
import { EntityTrigger } from '../models/triggers/EntityTrigger';
import { inject, observer } from 'mobx-react';
import { EntityStore } from '../../entities/entity.store';
import cn from './Params.module.scss';
import { useTranslation } from 'react-i18next';
import { Form, Select } from 'antd';
import { LabeledValue } from 'antd/es/select';

type EntityTriggerParamsEditorProps = {
    trigger: EntityTrigger;
    entityStore?: EntityStore;
}

export const EntityTriggerParamsEditor: FC<EntityTriggerParamsEditorProps> = inject('entityStore')(observer(({
                                                                                                                 entityStore,
                                                                                                                 trigger
                                                                                                             }) => {
    const {t} = useTranslation();
    const stopPropagation = (e: React.MouseEvent) => {
        e.stopPropagation();
    };

    const onChange = (e: LabeledValue) => {
        if (e) {
            const id = +e.value;
            trigger.entity = entityStore!.getEntityById(id);
        } else {
            trigger.entity = undefined;
        }
    }

    const entity = useMemo<LabeledValue>(() => {
        if (!trigger.entity) {
            return;
        }
        const e = entityStore.getEntityById(trigger.entity.id);
        if (!e) {
            return null;
        }
        return {value: e.id, label: e.name};
    }, [trigger.entity, entityStore]);

    return <div className={cn.editor}>
        <div className={cn.group}>
            <h6 className={cn.groupTitle}>
                {t('entities.linked_entities')}
            </h6>
            <Form.Item>
                <Select
                    onClick={stopPropagation}
                    showSearch
                    placeholder={t('entities.select_entity')}
                    optionFilterProp="children"
                    notFoundContent={t('entities.entity_not_found')}
                    fieldNames={{value: 'id', label: 'name'}}
                    onChange={onChange}
                    labelInValue
                    value={entity}
                    filterOption={(input, option) => {
                        return (option?.name ?? '').toLowerCase().includes(input.toLowerCase())
                    }}
                    options={entityStore.entities}/>
            </Form.Item>
        </div>
    </div>
}));

