import React from 'react';
import cn from './Branch.module.scss';
import cns from 'classnames';
import { Reaction } from '../models/reactions/Reaction';
import { State } from '../models/State';
import { action, computed, IReactionDisposer, makeObservable, observable, reaction } from 'mobx';
import { StateOrReaction } from './StateOrReaction';
import { DropZone } from './DropZone';
import { triggerTypes } from '../models/triggers/TriggerTypes';
import { reactionTypes } from '../models/reactions/ReactionTypes';
import { DropItem } from '../models/DropItem';
import { DropFactory } from '../factories/DropFactory';
import { inject, observer } from 'mobx-react';
import { EntityTrigger } from '../models/triggers/EntityTrigger';
import { EntityStore } from '../../entities/entity.store';
import { Entity } from '../../entities/models/entity';
import { withTranslation, WithTranslation } from 'react-i18next';

interface BranchesProps extends WithTranslation {
    trigger: EntityTrigger;
    entityStore?: EntityStore;
}

enum Next {
    next, noEntityNext
}

@inject('entityStore')
@observer
class EntityTriggerBranchesComp extends React.Component<BranchesProps> {
    @observable activeBranch: Next = Next.next;

    @observable lastActive?: Next;

    branchesReaction?: IReactionDisposer;

    constructor(props: BranchesProps) {
        super(props);
        makeObservable(this);
    }

    componentDidMount(): void {
        this.branchesReaction = reaction(
            () => this.props.trigger.noEntityNext, () => {
                if (!this.props.trigger.noEntityNext) {
                    this.activeBranch = Next.next;
                }
            });
    }

    @computed get next(): State | Reaction | null {
        if (this.activeBranch === Next.next) {
            return this.props.trigger.next;
        } else {
            return this.props.trigger.noEntityNext;
        }
    }

    @computed get entity(): Entity {
        return this.props.entityStore!.entities.find(e => e.id === this.props.trigger.entity?.id)!;
    }

    @computed get entityName(): string {
        if (this.entity) {
            return this.entity.name;
        }

        if (this.props.trigger.entity && this.props.trigger.entity.name) {
            return this.props.trigger.entity.name;
        }

        return '';
    }


    @action.bound
    private onNextActiveBranch = (drop: DropItem) => {
        const next = DropFactory.build(drop, this.props.trigger);

        if (this.activeBranch === Next.next) {
            this.props.trigger.insertNext(next);
        } else {
            this.props.trigger.insertNotEntityNext(next);
        }
    }

    buildOnClick = (next: Next) => () => {
        if (this.activeBranch !== next) {
            this.lastActive = this.activeBranch;
            this.activeBranch = next;
        }
    };


    render() {
        return <>
            <div className={cn.branches}>
                <button
                    className={cns(cn.branch, {
                        [cn.active]: this.activeBranch === Next.next,
                        [cn.deactivate]: this.lastActive === Next.next
                    })}
                    onClick={this.buildOnClick(Next.next)}
                    key={'All'}>
                    {this.entityName ? this.props.t('flows.all') : this.props.t('flows.select_entity_you')} {this.entityName}
                </button>
                {
                    this.props.trigger.entity &&
                    <button
                        className={cns(cn.branch, {
                            [cn.active]: this.activeBranch === Next.noEntityNext,
                            [cn.deactivate]: this.lastActive === Next.noEntityNext
                        })}
                        onClick={this.buildOnClick(Next.noEntityNext)}
                        key={this.entityName}>
                        {this.props.t('flows.no')} {this.entityName}
                    </button>
                }
            </div>
            <DropZone accept={[...triggerTypes, ...reactionTypes]} onDrop={this.onNextActiveBranch}/>
            {
                (this.next) && <StateOrReaction next={this.next} key={this.next.name}/>
            }
        </>;
    }
}

export const EntityTriggerBranches = withTranslation()(EntityTriggerBranchesComp);
