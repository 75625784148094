import React from 'react'

export const ChatIcon: React.FunctionComponent<{ className?: string }> = (props) => <svg className={props.className}
                                                                                         width="40" height="40"
                                                                                         viewBox="0 0 40 40" fill="none"
                                                                                         xmlns="http://www.w3.org/2000/svg">
        <path
            d="M31.75 12.306V8.3587H8.25V12.306H31.75ZM23.9473 24.056V20.1087H8.25V24.056H23.9473ZM8.25 14.2337V18.181H31.75V14.2337H8.25ZM35.6973 0.555969C36.7376 0.555969 37.625 0.953756 38.3594 1.74933C39.1549 2.4837 39.5527 3.37107 39.5527 4.41144V27.9114C39.5527 28.9518 39.1549 29.8698 38.3594 30.6653C37.625 31.4609 36.7376 31.8587 35.6973 31.8587H8.25L0.447266 39.6614V4.41144C0.447266 3.37107 0.814453 2.4837 1.54883 1.74933C2.3444 0.953756 3.26237 0.555969 4.30273 0.555969H35.6973Z"
            fill="white"/>
    </svg>
;
