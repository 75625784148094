import React from 'react';
import { observer } from 'mobx-react';
import { action, makeObservable, observable, runInAction } from 'mobx';
import { TextField } from '@mui/material';
import EditIcon from 'mdi-react/EditIcon';

import cn from './EditableText.module.scss';

interface EditableTextProps {
    text: string;
    editable?: boolean;
    onEdit: (text: string) => void;
    className?: string;
}

const editStyle: React.CSSProperties = {
    cursor: 'pointer',
};

@observer
export class EditableText extends React.Component<EditableTextProps> {
    @observable isEdit = false;
    @observable currentText: string;
    @observable error = false;
    textElementRef = React.createRef<HTMLDivElement>();
    inputStyle: Partial<React.CSSProperties> = null;

    constructor(props: EditableTextProps) {
        super(props);
        makeObservable(this);
        this.currentText = props.text;
    }

    componentDidMount(): void {
        const styles = getComputedStyle(this.textElementRef.current!);
        this.inputStyle = {
            fontSize: styles.fontSize,
            lineHeight: styles.lineHeight,
            // @ts-ignore
            fontWeight: styles.fontWeight,
            padding: 0
        };
    }

    UNSAFE_componentWillReceiveProps(props: EditableTextProps) {
        runInAction(() => {
            this.currentText = props.text;
        });
    }

    @action.bound
    toggleEdit() {
        if (this.props.editable) {
            this.isEdit = !this.isEdit;
        }
    }

    @action.bound
    onChange(e: React.ChangeEvent<HTMLInputElement>) {
        const {value} = e.target;
        this.currentText = value;
        this.error = !this.currentText;
    }

    @action.bound
    save() {
        if (this.currentText) {
            this.isEdit = false;
            this.props.onEdit(this.currentText);
        }
    }

    @action.bound
    onKeyDown(e: React.KeyboardEvent) {
        if (e.key === 'Enter') {
            this.save();
        }
    }

    render() {
        return <React.Fragment>{
            this.isEdit
                ? <TextField inputProps={{style: this.inputStyle}}
                             autoFocus={true}
                             value={this.currentText}
                             onChange={this.onChange}
                             error={this.error}
                             onBlur={this.save}
                             onKeyDown={this.onKeyDown}
                             className={this.props.className!}/>
                : <div
                    className={this.props.className}
                    style={this.props.editable ? editStyle : {}}
                    onClick={this.toggleEdit}
                    ref={this.textElementRef}
                >
                    {this.currentText}
                    {this.props.editable && <EditIcon className={cn.editableIcon} size={14}/>}
                </div>
        }</React.Fragment>
    }
}
