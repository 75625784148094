import React from 'react';
import { LocationReaction } from '../models/reactions/LocationReaction';
import { observer } from 'mobx-react';
import cn from './Params.module.scss';
import { ButtonEditor } from './ButtonEditor';
import { action } from 'mobx';
import LocationIcon from 'mdi-react/LocationIcon';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Tracker } from '@/core/analytics/tracker';
import { Form, Input, InputNumber, Space, Button } from 'antd';
import { CopyId } from '@/app/scripts/components/CopyId/CopyId.component';

interface LocationReactionParamsEditorProps extends WithTranslation {
    reaction: LocationReaction;
}

@observer
export class LocationReactionParamsEditorComp extends React.Component<LocationReactionParamsEditorProps> {
    @action.bound
    onAddButton = () => {
        this.props.reaction.quickReplies.addButton({
            name: '',
            type: 'text',
            title: '',
            params: {
                text: ''
            },
        });
    }

    @action.bound
    onFetchCurrentLocation = () => {
        if (navigator && navigator.geolocation) {
            navigator.geolocation.getCurrentPosition((position) => {
                Tracker.trackEvent('Dialog', {Type: 'ShareLocation'});
                this.onSuccessFetchCurrentLocation(position);
            }, () => {
                Tracker.trackEvent('Dialog', {Type: 'Error', ErrorEvent: 'shareLocation'});
            }, {enableHighAccuracy: true})
        }
    }

    @action.bound
    // ??? тип Position
    private onSuccessFetchCurrentLocation = (position: {
        coords: {
            latitude: number;
            longitude: number;
        }
    }) => {
        const {coords} = position;
        this.props.reaction.lat = coords.latitude;
        this.props.reaction.lon = coords.longitude;
    }


    render() {
        const quickReplies = this.props.reaction.quickReplies;

        return <div className={cn.editor}>
            <div className={cn.group}>
                <h6 className={cn.groupTitle}>{this.props.t('flows.coordinates')}</h6>
                <Form className={cn.inputRow}>
                    <Space.Compact block style={{
                        width: '100%',
                    }}>
                        <InputNumber style={{ width: '47%' }} value={this.props.reaction.lat}
                               onChange={value => this.props.reaction.lat = value}
                               placeholder={'latitude'}/>
                        <InputNumber style={{ width: '47%' }} value={this.props.reaction.lon}
                               onChange={value => this.props.reaction.lon = value}
                               placeholder={'longtitude'}/>

                        <Button type="default" icon={<LocationIcon/>} onClick={this.onFetchCurrentLocation} />
                    </Space.Compact>
                </Form>
                <Input value={this.props.reaction.name}
                       onChange={e => this.props.reaction.name = e.target.value}
                       placeholder={this.props.t('flows.name')}/>
            </div>
            <div className={cn.group}>
                <div className={cn.buttons}>
                    {quickReplies.buttons.map((button, i) => <ButtonEditor button={button} key={i}
                                                                           onRemove={() => quickReplies.deleteButton(button)}/>)}
                </div>
            </div>
            {
                quickReplies.buttons.length <= 5 && <button className={cn.addButton} onClick={this.onAddButton}>
                    {this.props.t('flows.add_button')}
                </button>
            }

                <CopyId id={this.props.reaction.id}/>
        </div>
    }
}

export const LocationReactionParamsEditor = withTranslation()(LocationReactionParamsEditorComp);
