import styled from 'styled-components';

export const CopyIdText = styled.div`
    font-size: 12px;
    color: #999999;
    cursor: pointer;
    margin: 6px 0;
    opacity: 0.5;
    &:hover {
        opacity: 0.3;
    }
`

export const Wrapper = styled.div<{align: boolean}>`
    display: flex;
    flex-grow: 1;
    align-items: flex-end;
    padding-left: ${p => p.align ? 20 : 0}px;
`
