import React from 'react';
import { ReactionNode, ReactionNodeProps } from './ReactionNode';
import { FileReaction } from '../models/reactions/FileReaction';
import { inject, observer } from 'mobx-react';
import { ScriptPageStore } from '../stores/script-page.store';

interface FileReactionViewProps extends ReactionNodeProps<FileReaction> {
    scriptPageStore?: ScriptPageStore;
}

@inject('scriptPageStore')
@observer
export class FileReactionView extends ReactionNode<FileReaction, FileReactionViewProps> {
    onClick(): void {
        this.props.scriptPageStore!.select(this.props.reaction);
    }

    onDelete(): void {
        this.props.reaction.removeSelf();

        this.props.scriptPageStore!.select();
    }

    renderBody(): React.ReactNode {
        return <a
            href={this.props.reaction.url}>{this.props.reaction.caption || this.props.reaction.url}</a>
    }

}
