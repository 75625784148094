import React, { ReactNode } from 'react';
import { Button } from 'reactstrap';
import { withTranslation, WithTranslation } from 'react-i18next';
import cns from 'classnames';

import cn from './PublishCard.module.scss';
import { ReactComponent as CloudDownloadIcon } from '../icons/cloud-download.svg';
import { ReactComponent as RemoveIcon } from '../icons/remove.svg';
import { PublishVersion } from '@/app/publish/PublishPage.model';
import { EditableText } from '@/common/components/EditableText';
import { PermissionsView } from '@/app/permissions/Permissions';
import { Permission } from '@/core/stores/user.store';

interface PublishCardProps extends WithTranslation {
    title: ReactNode;
    text: ReactNode;
    error?: string;
    isEditable?: boolean;
    version?: PublishVersion;
    isProdVersion?: boolean;
    showUpdate?: boolean;
    onExport: (ver?: PublishVersion) => void;
    onEdit?: (ver: PublishVersion, name: string) => void;
    onRemove?: (ver: PublishVersion) => void;
    children: React.ReactNode;
}

class PublishCard extends React.Component<PublishCardProps> {
    export = () => this.props.onExport(this.props.version);
    remove = () => this.props.onRemove(this.props.version);
    editName = (name: string) => this.props.onEdit(this.props.version, name);

    renderError(errorMsg: string) {
        return <div className={cn.cardError} dangerouslySetInnerHTML={{ __html: errorMsg }} />;
    }

    renderRemove() {
        return <Button outline className={cn.cardRemoveBtn} onClick={this.remove}><RemoveIcon /></Button>;
    }

    renderExport() {
        return <Button outline className={cn.cardExport} onClick={this.export}><CloudDownloadIcon /></Button>;
    }

    renderEditableText() {
        const { title } = this.props;
        return <div className={cn.cardEditableTitle}><EditableText text={title as string} onEdit={this.editName} editable /></div>;
    }

    render() {
        const { isEditable, title, error, children, isProdVersion, showUpdate } = this.props;

        return (
            <div className={cn.card}>
                <div className={cn.cardContent}>
                    <h3 className={cn.cardTitle}>
                        {title}
                        {isProdVersion && <span className={cn.cardTitleHint}>{this.props.t('publish_page.card_current_prod')}</span>}
                    </h3>
                    <div className={cns(cn.cardText, showUpdate && cn.updateHappened)}>{this.props.text}</div>
                </div>
                <div className={cn.cardControls}>
                    {error && this.renderError(error)}
                    <PermissionsView permission={Permission.PUBLISH_PROJECTS}>{this.props.onExport && this.renderExport()}</PermissionsView>
                    <div className={cn.cardControl}>{children}</div>
                </div>
                {isEditable && !isProdVersion && this.renderRemove()}
            </div>
        );
    }
}

export default withTranslation()(PublishCard);
