import React from 'react';
import { inject, observer } from 'mobx-react';
import * as _ from 'lodash';
import { IntentTrigger } from '../models/triggers/IntentTrigger';
import { action, computed } from 'mobx';
import { Intent } from '../../intents/models/Intent';
import { SmartNode } from './Node';
import { Link } from 'react-router-dom/dist';
import cn from './Links.module.scss';
import { WithTranslation, withTranslation } from 'react-i18next';
import { ScriptPageStore } from '../stores/script-page.store';
import { IntentStore } from '../../intents/intent.store';
import { RouteComponentProps, withRouter } from '@/common/utils/withRouter';

interface IntentTriggerViewProps extends WithTranslation, RouteComponentProps<{ projectId: string }> {
    trigger: IntentTrigger;
    scriptPageStore?: ScriptPageStore;
    intentStore?: IntentStore;
}

@inject('scriptPageStore', 'intentStore')
@observer
export class IntentTriggerViewComp extends React.Component<IntentTriggerViewProps> {
    @computed
    get trigger(): IntentTrigger {
        return this.props.trigger as IntentTrigger;
    }

    @computed
    get intents(): Array<Intent> {
        return this.props.intentStore!.intents.filter(i => {
            return this.trigger.intents.some(ti => ti.id === i.id);
        });
    }

    private buildBody() {
        return <>
            {
                this.intents.map((intent, i) => <React.Fragment key={intent.id}>
                        <Link to={`/app/${this.props.match.params.projectId}/intent/${intent.id}`}
                              className={cn.link}>{intent.name}</Link>
                        {i < this.intents.length - 1 ? ` ${this.props.t('flows.or')} ` : ''}
                    </React.Fragment>
                )
            }
        </>
    }

    @computed
    get body(): React.ReactNode {
        return this.intents.length
            ? this.buildBody()
            : this.props.t('flows.select_intents');
    }

    @computed
    get title(): string {
        if (!this.intents.length) {
            return this.props.t('flows.Intent');
        }

        const entitiesCount =
            _.uniq(
                this.intents
                    .flatMap(intent =>
                        intent.intent_examples.flatMap(example =>
                            example.parts.flatMap(part => part.entity_id)
                        )
                    )
                    .filter(e => e != null)
            ).length;

        const intentsCount = this.intents.length;

        if (entitiesCount === 0) {
            return `${intentsCount} ${this.props.t(intentsCount > 1 ? 'flows.intents' : 'flows.intent')}`;
        }

        return `${intentsCount} ${this.props.t(intentsCount > 1 ? 'flows.intents' : 'flows.intent')} ${this.props.t('flows.with')} ${entitiesCount} ${this.props.t(entitiesCount > 1 ? 'flows.entities' : 'flows.entity')}`;
    }

    @action.bound
    onDelete = () => {
        this.trigger.parent!.removeTrigger(this.trigger);
    }


    @action.bound
    onClickNode = () => {
        this.props.scriptPageStore!.select(this.trigger);
    }

    render() {
        return <SmartNode title={this.title} onDelete={this.onDelete} onClick={this.onClickNode} type={'trigger'}>
            {this.body}
        </SmartNode>;
    }
}

export const IntentTriggerView = withTranslation()(withRouter(IntentTriggerViewComp));
