import React from 'react';
import { TextReaction } from '../models/reactions/TextReactions';
import VoiceIcon from 'mdi-react/VoiceChatIcon';
import cn from './TextReactionNode.module.scss';
import {action} from 'mobx';
import { inject, observer } from 'mobx-react';
import { ReactionNode, ReactionNodeProps } from './ReactionNode';
import {ScriptPageStore} from "../stores/script-page.store";

interface TextReactionNodeProps extends ReactionNodeProps<TextReaction> {
    scriptPageStore?: ScriptPageStore;
}

@inject('scriptPageStore')
@observer
export class TextReactionNode extends ReactionNode<TextReaction, TextReactionNodeProps> {
    renderVoice() {
        return <div className={cn.voice}>
            <VoiceIcon size={20} color={'#999'} /><span className={cn.voiceBody}>{this.reaction.tts}</span>
        </div>
    }

    @action.bound
    onDelete() {
        super.onDelete();

        this.props.scriptPageStore!.select(undefined);
    }

    @action.bound
    onClick = () => {
        this.props.scriptPageStore!.select(this.reaction);
    }

    title = this.reaction.name;

    renderBody(): React.ReactNode {
        return <>
            {this.reaction.texts.length ? this.reaction.texts[0] : ''}
            {this.reaction.tts && this.renderVoice()}
        </>;
    }
}
