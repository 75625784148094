// import { day100 } from './prod-137-day-100';
import { AnalyticsData, Version } from '@/app/stats/Types';
import { Filter, VersionsFilter } from '@/app/stats/AnalyticsFilter/AnalyticsFilter.component';
import { Project } from '@/common/models/project';
import { API } from '@/common/api';

export class BaseAnalyticsApi extends API {
    static async updateAnalytics(project: Project, filter?: Filter): Promise<AnalyticsData> {
        return this.post<AnalyticsData>(`/projects/${project.id}/analytics/base-analytics`, filter);
    }

    static async downloadCsv(project: Project, filter?: Filter): Promise<string> {
        return this.post<string>(`/projects/${project.id}/analytics/analytics-csv-export`, filter);
    }

    static async getVersions(project: Project, filter?: VersionsFilter): Promise<Version[]> {
        return this.post<Version[]>(`/projects/${project.id}/analytics/project-versions`, filter);
    }
}
