import React from 'react';
import { inject, observer } from 'mobx-react';
import { IntentStore } from '../intent.store';
import { Loader } from '@/common/components/Loader';
import { Intents } from './Intents';
import { EntityStore } from '../../entities/entity.store';
import { makeObservable, observable } from 'mobx';
import { Page } from '@/common/components/page/Page';
import { TestChat } from '../../chat/components/TestChat';
import { ZeroScreen } from '@/common/components/zero-screen/ZeroScreen';
import { Navigate, Route, Routes } from 'react-router';
import cn from './IntentPage.module.scss';
import { WithTranslation, withTranslation } from 'react-i18next';
import { SideMenuRight } from '@/common/side-menu-right/SideMenuRight';
import { IntentMenu } from './intent-menu';
import { UserStore } from '@/core/stores/user.store';
import { RouteComponentProps, withRouter } from '@/common/utils/withRouter';

interface IntentsPageProps extends RouteComponentProps, WithTranslation {
    intentStore?: IntentStore;
    entityStore?: EntityStore;
    user?: UserStore;
}

@inject('intentStore', 'entityStore', 'user')
@observer
export class IntentsPageComp extends React.Component<IntentsPageProps> {
    @observable loaded: boolean = false;

    constructor(props: IntentsPageProps) {
        super(props);
        makeObservable(this);
    }

    async componentDidMount() {
        await this.props.entityStore!.load();
        await this.props.intentStore!.load();
        this.loaded = true;
    }

    render() {
        if (!this.props.user.permissions.isViewIntents) {
            return <Navigate to={'/'}/>;
        }
        return <>
            <SideMenuRight>
                <IntentMenu intentStore={this.props.intentStore}></IntentMenu>
            </SideMenuRight>
            {this.loaded ? <Routes>
            <Route path={'/'} element={
                <Page rightBar={<TestChat/>}>
                    <ZeroScreen title={this.props.t('intents.create_new_intent_title')} subtitle={<>
                        {this.props.t('zero_screens.subtitle')} <br/>
                        {this.props.t('intents.create_new_intent_subtitle_suffix')}</>
                    }/>
                </Page>
            }>
            </Route>
            <Route path={':id'} element={<Intents />}/>
            <Route element={<Navigate to={'/'}/>}/>
        </Routes> : <div className={cn.loader}><Loader/></div>}</>;
    }
}

export const IntentsPage = withTranslation()(withRouter(IntentsPageComp));
