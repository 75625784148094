import { withTranslation, WithTranslation } from 'react-i18next';
import { inject, observer } from 'mobx-react';
import React from 'react';
import { SidebarListMenu } from '../../components/sidebar/models/sidebar-list-menu';
import { action, makeObservable, observable } from 'mobx';
import { Group } from '@/common/models/group';
import CollapseComponent from '../../../common/components/Collapse';
import cn from '../../components/sidebar/sidebar-menu/sidebar-menu.module.scss';
import { DragSidemenuItem, SidebarAddButton, SidebarGroup, SidebarSubMenuLink } from '../../components/sidebar';
import { SnippetStore } from '../snippet.store';
import { SnippetDto } from '../models/snippet.dto';
import { Permission, UserStore } from '@/core/stores/user.store';
import { PermissionsView } from '@/app/permissions/Permissions';
import { RouteComponentProps, withRouter } from '@/common/utils/withRouter';

interface QaMenuProps extends WithTranslation, RouteComponentProps {
    snippetStore: SnippetStore;
    user?: UserStore;
}

@inject('user')
@observer
export class SnippetMenuComponent extends React.Component<QaMenuProps> implements SidebarListMenu<SnippetDto> {
    @observable editGroupId = -1;

    constructor(props: QaMenuProps) {
        super(props);
        makeObservable(this);
    }

    private getSnippetIdFromPath(): number {
        const lastItem = this.props.location.pathname.split('/').pop();
        return isNaN(+lastItem) ? null : +lastItem;
    }

    @action.bound
    onRemoveGroup(group: Group) {
        const snippetsIds = this.filterItemsByGroup(group).map(sn => sn.id);
        const snippetId = this.getSnippetIdFromPath();
        this.props.snippetStore.removeGroup(group.id!);
        if (snippetsIds.includes(snippetId)) {
            this.props.history.push(this.props.match.parentPath);
        }
    }

    @action.bound
    onStartNameGroupEdit(group: Group) {
        this.editGroupId = group.id!;
    }

    @action.bound
    onSaveGroup(group: Group) {
        this.editGroupId = -1;
        this.props.snippetStore.saveGroup(group);
    }

    renderGroup = (group: Group, i: number) => {
        return <CollapseComponent key={group.id} className={cn.collapse}

                                  isOpen={!i}
                                  title={
                                      <SidebarGroup canDrop={true}
                                                    canEdit={this.props.user.permissions.isEditFacts}
                                                    onSave={this.onSaveGroup}
                                                    onStartEdit={this.onStartNameGroupEdit}
                                                    currentEditedGroupId={this.editGroupId}
                                                    group={group}
                                                    onRemove={this.onRemoveGroup}
                                      />
                                  }>
            {this.getItemsByGroup(group)}

        </CollapseComponent>
    };

    onDragEnd = (from: DragSidemenuItem, to: DragSidemenuItem) => {
        if (from.groupId === to.groupId) {
            const group = this.props.snippetStore.groups.find((_group: Group) => _group.id === from.groupId);
            if (group && this.filterItemsByGroup(group).length === 2) {
                return;
            }
        }

        this.props.snippetStore.replaceElementsInGroup(from, to);
    };

    // TODO: will using matchRoutes after refactoring to FC component
    isActiveRoute = (route: string) => this.props.location.pathname.endsWith(route);

    renderGroupItem = (snippet: SnippetDto) => {
        return <SidebarSubMenuLink key={snippet.id}
                                   groupId={snippet.fact_group_id}
                                   id={snippet.id}
                                   isActive={snippet.is_active!}
                                   onDragEnd={this.onDragEnd}
                                   canDrop={true}
                                   route={snippet.id.toString()}
                                   analyticName={'snippet'}
                                   noDragging={!this.props.user.permissions.isEditFacts}
            // subtitle={qa.questions.length.toString()}
                                   isSelected={this.isActiveRoute(snippet.id.toString())}
                                   title={snippet.name!}/>
    };

    filterItemsByGroup(group: Group) {
        return this.props.snippetStore.snippets.filter(
            snippet => snippet.fact_group_id === group.id
        );
    }

    getItemsByGroup(group: Group) {
        const qas = this.filterItemsByGroup(group);

        return qas.map(this.renderGroupItem);
    }


    render() {
        return <>
            <PermissionsView permission={Permission.EDIT_FACTS}>
                <SidebarAddButton analyticName={'qa'}
                                  routeToNewItem={'new'}>
                </SidebarAddButton>
            </PermissionsView>
            <div className={cn.menuScroll}>{this.props.snippetStore.isLoaded && this.props.snippetStore.groups.map(this.renderGroup)}</div>
        </>
    }
}

export const SnippetMenu = withTranslation()(withRouter(SnippetMenuComponent));
