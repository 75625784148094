import { AllowOutTrigger } from './AllowOutTrigger';
import { AudioTrigger } from './AudioTrigger';
import { EntityTrigger } from './EntityTrigger';
import { FallbackTrigger } from './FallbackTrigger';
import { FileTrigger } from './FileTrigger';
import { ImageTrigger } from './ImageTrigger';
import { IntentTrigger } from './IntentTrigger';
import { LocationTrigger } from './LocationTrigger';
import { StartTrigger } from './StartTrigger';
import { StickerTrigger } from './StickerTrigger';
import { VideoTrigger } from './VideoTrigger';

export const triggerTypes = [
    AllowOutTrigger.type,
    AudioTrigger.type,
    EntityTrigger.type,
    FallbackTrigger.type,
    FileTrigger.type,
    ImageTrigger.type,
    IntentTrigger.type,
    LocationTrigger.type,
    StartTrigger.type,
    StickerTrigger.type,
    StartTrigger.type,
    VideoTrigger.type,
];

export const isTriggerType = (type: string) => {
    return triggerTypes.includes(type);
};
