import { SnippetStore } from '../../../snippets/snippet.store';

export class SnippetMapper {
    constructor(private snippetStore: SnippetStore) {}

    async load(): Promise<boolean> {
        const groups = this.snippetStore.groups;
        const qas = this.snippetStore.snippets;
        if (groups.length > 0 || qas.length > 0) return false;
        await this.snippetStore.load();
        return true;
    }
}
