import React from 'react';

export const LogsIcon: React.FunctionComponent = () => <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M13.5 19.25C8.675 19.25 4.75 15.325 4.75 10.5V9.5C4.75 4.675 8.675 0.75 13.5 0.75H17.5C22.325 0.75 26.25 4.675 26.25 9.5V19.25H13.5Z" fill="currentColor"/>
<path d="M17.5 1.5C21.918 1.5 25.5 5.082 25.5 9.5V18.5H13.5C9.082 18.5 5.5 14.918 5.5 10.5V9.5C5.5 5.082 9.082 1.5 13.5 1.5H17.5ZM17.5 0H13.5C8.262 0 4 4.262 4 9.5V10.5C4 15.738 8.262 20 13.5 20H25.5H27V18.5V9.5C27 4.262 22.738 0 17.5 0Z" fill="white"/>
<path d="M0.75 26.25V17.5C0.75 15.43 1.684 13.503 3.312 12.212L4.236 11.48L4.507 12.627C5.5 16.821 9.197 19.75 13.5 19.75H20.297L20.15 20.624C19.601 23.884 16.805 26.25 13.501 26.25H0.75Z" fill="currentColor"/>
<path d="M3.778 12.8C4.821 17.207 8.779 20.5 13.5 20.5H19.41C18.933 23.337 16.472 25.5 13.5 25.5H1.5V17.5C1.5 15.594 2.392 13.899 3.778 12.8ZM4.694 10.159L2.846 11.625C1.037 13.059 0 15.2 0 17.5V25.5V27H1.5H13.5C17.173 27 20.28 24.371 20.889 20.749L21.183 19H19.41H13.5C9.547 19 6.149 16.308 5.237 12.454L4.694 10.159Z" fill="white"/>
<path d="M11.5 8.5H19.5" stroke="white" strokeWidth="2" strokeMiterlimit="10"/>
<path d="M11.5 12.5H17.5" stroke="white" strokeWidth="2" strokeMiterlimit="10"/>
</svg>
