import { Navigate, Route, Routes } from 'react-router';
import { ZeroScreen } from '@/common/components/zero-screen/ZeroScreen';
import * as React from 'react';
import { ConnectPageContent } from './ConnectPageContent';
import { SideMenuRight } from '@/common/side-menu-right/SideMenuRight';
import { ConnectMenu } from './ConnectMenu';
import cn from './ConnectPage.module.scss';
import { inject, observer } from 'mobx-react';
import { ConnectStore } from '../connect.store';
import { Loader } from '@/common/components/Loader';
import { WithTranslation, withTranslation } from 'react-i18next';

interface ConnectPageProps extends WithTranslation {
    connectStore?: ConnectStore;
}

@inject('connectStore')
@observer
export class ConnectPageComp extends React.Component<ConnectPageProps> {

    render() {
        this.props.connectStore.loadConnectTypes();
        this.props.connectStore.loadChannels();
        return this.props.connectStore.isLoadTypes ? <div className={cn.wrapper}>
            <SideMenuRight>
                <ConnectMenu connectStore={this.props.connectStore}/>
            </SideMenuRight>
            {this.props.connectStore.isLoadChannels ? <Routes>
                <Route path={'/'} element={<ZeroScreen
                    subtitle={<React.Fragment>{this.props.t('zero_screens.subtitle')} <br/>
                        {this.props.t('channels.create_new_channel_subtitle_suffix')}</React.Fragment>}
                    title={this.props.t('channels.create_new_channel_title')}/>}>


                </Route>
                <Route path={'channels/:id/*'} element={<ConnectPageContent/>}/>
                <Route element={<Navigate to={'/'}/>}/>
            </Routes> : <div className={cn.loader}><Loader/></div>}
        </div> : <div className={cn.loader}><Loader/></div>
    }
}

export const ConnectPage = withTranslation()(ConnectPageComp);
