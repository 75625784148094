import { IntentExamplePart } from '../models/Intent';
import React, { useState } from 'react';
import { EntityPopper } from './EnityPopper';
import { inject, observer } from 'mobx-react';
import { action, computed, makeObservable, observable } from 'mobx';
import { Tag } from '@/common/components/tag';
import CloseIcon from 'mdi-react/CloseIcon';
import cn from './ExampleEntity.module.scss';
import { EntityStore } from '../../entities/entity.store';
import { Entity } from '../../entities/models/entity';
import { EntityExtractor } from '../../entities/models/entity-extractor';
import { IntentsPageStore } from '../intents-page.store';
import { TooltipPopper, TTooltipPopperFnParams } from '@/common/components/TooltipPopper';

interface ExampleEntityProps {
    part: IntentExamplePart;
    onRemove: () => void;
    entityStore?: EntityStore;
    store?: IntentsPageStore;
}

interface ExampleTagProps {
    text: string;
    onRemove: () => void;
    part: IntentExamplePart;
    entityStore?: EntityStore;
}

const ExampleTag = ({ onRemove, text, part }: ExampleTagProps) => {
    const [isHover, onChangeHover] = useState(part.entity_id! == 0);
    return <Tag
        className={cn.tag}
        onMouseEnter={() => onChangeHover(true)} onMouseLeave={() => onChangeHover(false)}>
        <span>{text}</span>
        {isHover && <div className={cn.pointer} onClick={onRemove}><CloseIcon size={14}/></div>}
    </Tag>
};

@inject('entityStore', 'store')
@observer
export class ExampleEntity extends React.Component<ExampleEntityProps> {
    @observable isTooltipVisible: boolean = false;

    @computed get entity() {
        return this.props.part.entity_id! < 0
            ? this.props.store!.newEntities.find(e => e.id === this.props.part.entity_id)
            : this.props.entityStore!.entities.find(e => e.id === this.props.part.entity_id);
    }

    @computed get entityViewText(): string {
        return this.entity!.name || this.entity!.extractor_type!;
    }

    @computed get text() {
        return (this.props.store!.isEntityView && this.entity)
            ? this.entityViewText
            : this.props.part.text;
    }

    constructor(props: ExampleEntityProps) {
        super(props);
        makeObservable(this);
        this.onChangeProps();
    }

    onChangeProps(props?: ExampleEntityProps) {
        if (!props) {
            props = this.props;
        }

        if (props.part.entity_id == 0) {
            this.isTooltipVisible = true;
        }
    }

    UNSAFE_componentWillReceiveProps(props: ExampleEntityProps) {
        this.onChangeProps(props);
    }

    renderTrigger() {
		return <ExampleTag text={this.text} onRemove={this.onRemove} part={this.props.part}/>;
    }

    @action.bound
    onChangeEntity(entity: Entity) {
        if (this.props.part.entity_id! < 0) {
            this.props.store!.removeEntity(this.props.part.entity_id!)
        }
        this.props.part.entity_id = entity.id;
        this.isTooltipVisible = false;
    }

    @action.bound
    async onCreateEntityFromExtractor(extractor: EntityExtractor) {
        const entity = this.props.store!.addEntity(extractor);

        this.onChangeEntity(entity);
    }

    renderTooltip = ({
		getArrowProps,
		getTooltipProps,
		setTooltipRef
	}: TTooltipPopperFnParams) => {
        return <div
			ref={ setTooltipRef }
            {...getTooltipProps({ className: 'tooltip-container' })}
        >
            <EntityPopper part={this.props.part} onChangeEntity={this.onChangeEntity} onCreateEntity={this.onCreateEntityFromExtractor}/>
            <div {...getArrowProps({ className: 'tooltip-arrow' })} />
        </div>
    }

    @action.bound
    onRemove() {
        this.props.store!.removeEntity(this.props.part.entity_id!);
        this.props.onRemove();
    }

    onTooltipVisibilityChange = (visibility: boolean): void => {
        this.isTooltipVisible = visibility;
        if (!this.props.part.entity_id! && !visibility) {
            this.onRemove();
        }
    }

    render() {
		return <TooltipPopper
			placement='bottom'
			trigger='click'
			closeOnOutsideClick={true}
			onVisibilityChange={this.onTooltipVisibilityChange}
			tooltipShown={this.isTooltipVisible}
			tooltip={this.renderTooltip}
			triggerContent={this.renderTrigger()}
            offset={[-30, 10]}
			onTriggerClick={() => this.isTooltipVisible = true}
		/>;
    }
}
