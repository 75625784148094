import React from 'react';
import { Input } from 'reactstrap';
import { observer } from 'mobx-react';
import { makeObservable, observable } from 'mobx';

import cn from './Markup.module.scss';
import cns from 'classnames';
import { MarkupElement } from './MarkupElement';
import { WithNameAndType } from './models';
import { WithTranslation, withTranslation } from 'react-i18next';
import { TooltipPopper, TTooltipPopperFnParams } from '../TooltipPopper';

interface MarkupProps extends WithTranslation {
    elements: WithNameAndType[];
    onChangeSearch?: (s: string) => void;
    onSelect: (element: WithNameAndType) => void;
    onSave: (text: string) => void;
    savedMarkupProcess: boolean;
    text: string;
    isOpen: boolean;
    onCancel: () => void;
    onOpen: () => void;
    showTrigger: boolean;
}

const tooltipProps = {
    className: cns('tooltip-container', cn.tooltipContainerMarkup),
    modifiers: {
        offset: {
            top: '-10px',
        }
    }
};

@observer
export class MarkupComp extends React.Component<MarkupProps> {
    @observable selected?: WithNameAndType;

	constructor(props: MarkupProps) {
		super(props);
		makeObservable(this);
	}

    selectMarkup = (element: WithNameAndType) => {
        this.props.onSelect(element);
        this.selected = element;
    };

    renderTooltip = ({
		getTooltipProps,
		setTooltipRef
	}: TTooltipPopperFnParams) => {
        return (
            <div ref={ setTooltipRef } {...getTooltipProps(tooltipProps)}>
                <MarkupTooltip
                    text={this.props.text}
                    elements={this.props.elements}
                    onChangeSearch={this.props.onChangeSearch}
                    onSelect={this.selectMarkup}
                    onSave={(text: string) => this.props.onSave(text)}
                    savedMarkupProcess={this.props.savedMarkupProcess}
                    onCancel={() => this.props.onCancel()}
                />
            </div>
        );
    };

    renderTrigger = () => {
        return this.props.showTrigger && <button className={cns("btn btn-link", cn.btnLink)}>{this.props.t('chat.add_to')}</button>;
    };

    render() {
		return <TooltipPopper
			placement='bottom-start'
			trigger='click'
			closeOnOutsideClick={true}
			defaultTooltipShown={false}
			tooltipShown={this.props.isOpen}
			tooltip={this.renderTooltip}
			triggerContent={this.renderTrigger()}
			onTriggerClick={this.props.onOpen}
		/>;
    }
}

export const Markup = withTranslation()(MarkupComp);

interface MarkupTooltipProps extends WithTranslation {
    elements: Array<WithNameAndType>;
    onChangeSearch?: (s: string) => void;
    onSelect: (element: WithNameAndType) => void;
    onSave: (value: string) => any;
    onCancel: () => void;
    savedMarkupProcess: boolean;
    text: string;
}

@observer
class MarkupTooltipComp extends React.Component<MarkupTooltipProps> {
    @observable selected?: WithNameAndType;
    prevSavedMarkupProcess: boolean;
    @observable text = '';

    constructor(props: MarkupTooltipProps) {
        super(props);
        makeObservable(this);
    }

    selectMarkup = (element: WithNameAndType) => {
        this.props.onSelect(element);
        this.selected = element;
    };

    UNSAFE_componentWillReceiveProps(props: MarkupTooltipProps) {
        if (props.savedMarkupProcess === false && this.prevSavedMarkupProcess === true) {
            this.props.onCancel();
        }

        this.prevSavedMarkupProcess = props.savedMarkupProcess;
        if (!this.text) {
            this.text = this.props.text;
        }
    }

    renderElement = (element: WithNameAndType) => {
        const selected = this.selected
            ? element.id === this.selected.id && element.type === this.selected.type
            : false;

        return (
            <div key={element.id} onClick={() => this.props.onSelect(element)}>
                <MarkupElement onSelectMarkupElement={this.selectMarkup} element={element} selected={selected}/>
            </div>
        );
    };

    onChangeText = (text: string) => {
        this.text = text;
    }

    render() {
        return <div className={cn.popper}>
            <div className={cn.searchWrapper}>
                <Input autoFocus={true} onChange={e => this.props.onChangeSearch && this.props.onChangeSearch(e.target.value)} bsSize='sm'
                       placeholder={this.props.t('chat.search_intent_or_qa')} className={cn.input}/>
            </div>
            <div className={cn.list}>
                {this.props.elements.map(this.renderElement)}
            </div>
            <div className={cn.textareaBorder}/>
            <div className={cn.textareaWrapper}>
                <textarea
                    rows={3} className={cn.textarea}
                    onChange={e => this.onChangeText(e.target.value)}
                    value={this.text}
                    />
            </div>

            <div className={cn.bottom}>
                <button disabled={this.props.savedMarkupProcess} className={cns(cn.cancel, "btn btn-outline")} onClick={() => this.props.onCancel()}>
                    {this.props.t('actions.cancel')}
                </button>
                <button disabled={this.props.savedMarkupProcess || !this.selected || !this.text} onClick={() => this.props.onSave(this.text)} className={cns(cn.ok, "btn btn-success btn-sm")}>{ this.props.savedMarkupProcess ? this.props.t('actions.saving') : this.props.t('actions.ok') }</button>
            </div>
          </div>
    }
}

export const MarkupTooltip = withTranslation()(MarkupTooltipComp);
