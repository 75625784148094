import { inject } from 'mobx-react';
import { WithTranslation, withTranslation } from 'react-i18next';

import { FallbackTrigger } from '../models/triggers/FallbackTrigger';
import { BaseStaticTriggerView, BaseStaticTriggerViewProps } from './BaseStaticTriggerView';

interface FallbackTriggerViewProps extends BaseStaticTriggerViewProps<FallbackTrigger>, WithTranslation {
}

@inject('scriptPageStore', 'user')
export class FallbackTriggerViewComp extends BaseStaticTriggerView<FallbackTrigger, FallbackTriggerViewProps> {
    body: string = this.props.t('flows.triggers.fallback');

    onClick = () => {
        this.props.scriptPageStore!.select();
    }
}

export const FallbackTriggerView = withTranslation()(FallbackTriggerViewComp);
