import React, { ChangeEvent } from 'react';
import { observer } from 'mobx-react';
import { Input, Switch } from 'antd';
import cn from './EntityCommon.module.scss';
import cnCommon from '@/common/scss/form.module.scss';
import { IObservableArray, makeObservable, observable } from 'mobx';
import classnames from 'classnames';
import CloseIcon from 'mdi-react/CloseIcon';
import { WithTranslation, withTranslation } from 'react-i18next';
import { PermissionsView } from '@/app/permissions/Permissions';
import { Permission } from '@/core/stores/user.store';

interface Keyword {
    key: string;
    words: any,
    invalid?: boolean;
}

type KeywordsArray = IObservableArray<Keyword>;

interface EntityKeywordsModelToSave {
    keywords: KeywordsArray;
    lowercase: boolean;
    stemm: boolean;
}

interface EntityKeywordsProps extends WithTranslation {
    onChange: (modelToSave: EntityKeywordsModelToSave) => void;
    modelToSave: EntityKeywordsModelToSave;
    canEdit: boolean;
}

@observer
export class EntityKeywordsComp extends React.PureComponent<EntityKeywordsProps> {
    @observable output: EntityKeywordsModelToSave = {
        keywords: (observable([{key: '', words: ''}]) as KeywordsArray),
        lowercase: false,
        stemm: true
    };

    constructor(props: EntityKeywordsProps) {
        super(props);
        makeObservable(this);
        this.onChangeProps();
    }

    UNSAFE_componentWillReceiveProps(props: EntityKeywordsProps) {
        this.onChangeProps(props);
    }

    onChangeProps(props?: EntityKeywordsProps) {
        if (!props) {
            props = this.props;
        }
        if (props.modelToSave) {
            const {lowercase, stemm, keywords} = props.modelToSave;
            this.output.lowercase = typeof lowercase === 'boolean' ? !lowercase : false;
            this.output.stemm = typeof stemm === 'boolean' ? stemm : true;
            this.output.keywords.replace([{key: '', words: []}]);

            if (keywords && keywords.length) {
                // @ts-ignore
                this.output.keywords.replace(
                    keywords.map(keyword => ({
                        key: keyword.key,
                        words: typeof keyword.words !== 'string' ? keyword.words.join('\n') : '',
                        invalid: keyword.invalid
                    }))
                );
            }
            this.onChange();
        }
    }


    onChange() {
        const saveModel: EntityKeywordsModelToSave = {
            lowercase: !this.output.lowercase,
            stemm: this.output.stemm,
            keywords: this.output.keywords.map(keyword => {
                const words = typeof keyword.words === 'string' ? keyword.words.split('\n') : [];
                return {
                    key: keyword.key,
                    words: words.filter(word => !!word)
                };
            }) as KeywordsArray
        };
        this.props.onChange(saveModel);
    }

    pushCleanInput = () => {
        this.output.keywords.push({
            key: '',
            words: ''
        });
    };

    onKeyChange = (item: Keyword) => (e: ChangeEvent<HTMLInputElement>) => {
        item.key = e.target.value;
        this.onChange();
    };

    onKeyWordsChange = (item: Keyword) => (e: ChangeEvent<HTMLTextAreaElement>) => {
        item.words = e.target.value;
        item.invalid = false;
        this.onChange();
    };

    renderKeywords = (item: Keyword, i: number) => {
        return <div key={i} className={classnames(cnCommon.formField, cnCommon.formFieldStart)}>
            <label className={cnCommon.formFieldLabel}
                   htmlFor="entity-radius">{this.props.t('entities.keywords_entity.keywords')}</label>
            <Input className={cn.formFieldSmallLeftInput}
                   placeholder="keyword"
                   disabled={!this.props.canEdit}
                   id="entity-radius"
                   value={item.key}
                   onChange={this.onKeyChange(item)}/>

            <Input.TextArea disabled={!this.props.canEdit}
                            autoSize={{ minRows: 1, maxRows: 8 }} placeholder="Add one phrase per line"
                   id="entity-radius" value={item.words}
                   status={item.invalid && 'error'}
                   onChange={this.onKeyWordsChange(item)}/>
            <PermissionsView permission={Permission.EDIT_ENTITIES}>{this.output.keywords.length > 1 &&
                <span onClick={this.removeInput(item)}><CloseIcon
                    className={cn.formCloseIcon}/></span>}</PermissionsView>
        </div>
    };

    removeInput = (item: { key: string, words: string }) => () => {
        const i = this.output.keywords.find(keywordItem => item.key === keywordItem.key);
        if (i)
            this.output.keywords.remove(i);
        this.onChange();
    };

    render() {
        return <div>
            {this.output.keywords.map(this.renderKeywords)}
            <PermissionsView permission={Permission.EDIT_ENTITIES}><span className={cn.addInput}
                                                                         onClick={this.pushCleanInput}>+ {this.props.t('actions.add')}</span></PermissionsView>
            <div className={cn.checkboxItem}>
                <div className={cnCommon.formField}>
                    <label className={cnCommon.formFieldLabel}
                           htmlFor="entity-circle-radius">{this.props.t('entities.keywords_entity.case_sensitive')}</label>
                    <Switch disabled={!this.props.canEdit} checked={this.output.lowercase}
                            onChange={e => {
                                this.output.lowercase = e;
                                this.onChange();
                            }}/>
                </div>
                <div className={cn.checkBoxDesc}>{this.props.t('entities.keywords_entity.case_sensitive_desc')}
                </div>
            </div>
            <div className={cn.checkboxItem}>
                <div className={cnCommon.formField}>
                    <label className={cnCommon.formFieldLabel}
                           htmlFor="entity-circle-radius">{this.props.t('entities.keywords_entity.stemm')}</label>
                    <Switch disabled={!this.props.canEdit} checked={this.output.stemm}
                            onChange={e => {
                                this.output.stemm = e;
                                this.onChange();
                            }}/>
                </div>
                <div className={cn.checkBoxDesc}>{this.props.t('entities.keywords_entity.stemm_desc')}
                    stem.
                </div>
            </div>
        </div>
    }
}

export const EntityKeywords = withTranslation()(EntityKeywordsComp);
