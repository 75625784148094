import cns from 'classnames';
import { Modal } from 'reactstrap';
import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import cn from './CustomAlert.module.scss';
import { AlertIcon } from './AlertIcon';
import CrossIcon from 'mdi-react/CloseIcon';

interface CustomAlertProps extends WithTranslation {
    isOpen: boolean;
    title: string;
    subtitle: string;
    buttonText: string;
    onButtonClick: () => void;
    toggle: () => void;
    isLoadingActivated: boolean;
}

export class CustomAlertComp extends React.Component<CustomAlertProps> {
    render() {
        return <Modal isOpen={this.props.isOpen} toggle={this.props.toggle}
                      contentClassName={cn.modalContent}
                      backdropClassName={cn.backdrop}
                      centered={true}
                      >
            <CrossIcon onClick={this.props.toggle} className={cn.cross} size={14}/>
            <div className={cn.wrapper}>
                <AlertIcon/>
                {/*className={cns("modal-dialog--primary", cn.modal)}*/}
                <div className={cn.title}>{ this.props.t(this.props.title) }</div>
                <div className={cn.subtitle}>{ this.props.t(this.props.subtitle) }</div>
                <button
                    type="button"
                    className={cns("btn btn-primary", this.props.isLoadingActivated && cn.waiting)}
                    onClick={this.props.onButtonClick}
                >
                    { this.props.t(this.props.isLoadingActivated ? 'auth.while_process' : this.props.buttonText) }
                </button>
            </div>
        </Modal>
    }
}

export const CustomAlert = withTranslation()(CustomAlertComp);
