import React, { ReactNode } from 'react';
import cn from './ActionsMenu.module.scss';

interface ActionsMenuProps {
    left?: ReactNode;
    right?: ReactNode;
}

export const ActionsMenu = ({ left, right }: ActionsMenuProps) => {
    return <div className={cn.wrapper}>
        {left ? left : <div/>}
        {right ? right : <div/>}
    </div>
};
