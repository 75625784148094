import React, { useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd';

interface StateNodeItemWrapperProps {
    id: string;
    index: number;
	key: string;
    move: (from: number, to: number) => void;
    className: string;
    onClick: (e: React.MouseEvent) => void;
    draggable?: boolean;
    children?: React.ReactNode;
}

interface DragItem {
    index: number
    id: string
    type: string
}

enum ItemTypes {
    CARD = 'card',
}

export const StateNodeItemWrapper: React.FC<StateNodeItemWrapperProps> =
    ({id, index, move, children, className, onClick, draggable = true}) => {
        const ref = useRef<HTMLDivElement>(null);
        const [, drop] = useDrop({
            accept: ItemTypes.CARD,
            hover: (item: DragItem) => {
                if (!ref.current) {
                    return;
                }
                const dragIndex = item.index;
                const hoverIndex = index;

                if (dragIndex === hoverIndex) {
                    return;
                }

                move(dragIndex, hoverIndex);
                item.index = hoverIndex
            },
        });

        const [, drag] = useDrag<unknown, unknown, { isDragging: boolean }>(() => ({
            type: ItemTypes.CARD,
            item: {type: ItemTypes.CARD, id, index},
            canDrag: draggable
        }))

        drag(drop(ref));

        return (
			<div ref={ref} className={className} onClick={onClick}>{children}</div>
		);
    };
