import { action } from 'mobx';
import { MenuTypes } from '../models/menu-types.enum';
import { DefaultLeftMenuItem, defaultLeftMenuItems } from '../models/default-groups';
import { QAStore } from '@/app/qa/qa.store';
import { QaMapper } from './qa.mapper';
import { SnippetStore } from '@/app/snippets/snippet.store';
import { SnippetMapper } from './snippet.mapper';
import { EntityStore } from '@/app/entities/entity.store';
import { EntityMapper } from './entity.mapper';
import { IntentStore } from '@/app/intents/intent.store';
import { IntentMapper } from './intent.mapper';
import { ScriptsStore } from '@/app/scripts/stores/scripts.store';
import { FlowsMapper } from './flow.mapper';

export class LeftMenuStore {
    private stores = {
        [MenuTypes.FLOWS]: new FlowsMapper(this.scriptsStore),
        [MenuTypes.INTENTS]: new IntentMapper(this.intentStore),
        [MenuTypes.ENTITIES]: new EntityMapper(this.entitiesStore),
        [MenuTypes.QA]: new QaMapper(this.qaStore),
        [MenuTypes.SNIPPETS]: new SnippetMapper(this.snippetStore),
    };

    leftMenuItems: DefaultLeftMenuItem[] = defaultLeftMenuItems;

    constructor(public qaStore: QAStore,
                public snippetStore: SnippetStore,
                public entitiesStore: EntityStore,
                private intentStore: IntentStore,
                private scriptsStore: ScriptsStore) {
    }

    @action.bound
    async loadStore(type: MenuTypes) {
        this.stores[type].load();
    }
}
