import React, { FC, useEffect, useState } from 'react';
import cn from './ProjectSettings.module.scss';
import { Permission, UserStore } from '@/core/stores/user.store';
import { Project } from '@/common/models/project';
import { API } from '@/common/api';
import { Page } from '@/common/components/page/Page';
import { ActionsMenu } from '@/common/components/actions-menu/ActionsMenu';
import {
    SaveButton,
    SaveButtonState,
    SaveButtonWaitingToDefaultTimeout
} from '@/common/components/save-button/SaveButton';
import { makeObservable, observable } from 'mobx';
import { inject, observer } from 'mobx-react';
import { ProjectStore } from '@/core/stores/project.store';
import { AppStore } from '../stores/app.store';
import { useTranslation, WithTranslation, withTranslation } from 'react-i18next';
import { PermissionsView } from '@/app/permissions/Permissions';
import { withRouter, RouteComponentProps } from '@/common/utils/withRouter';
import { ConfirmDelete } from '@/common/components/ConfirmDelete/ConfirmDelete.component';
import { Slider, Input, Space, Form, Button } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { useRequired } from '@/common/utils/form-validators';

interface ProjectSettingsProps extends RouteComponentProps<{ projectId: string }>, WithTranslation {
    user?: UserStore;
    projectStore?: ProjectStore;
    appStore?: AppStore;
}

type ProjectSettingsProps1 = {
    user?: UserStore;
    projectStore?: ProjectStore;
    appStore?: AppStore;
}

@inject('user', 'projectStore', 'appStore')
@observer
export class ProjectSettingsComponent extends React.Component<ProjectSettingsProps> {
    @observable saveState: SaveButtonState = SaveButtonState.default;
    @observable titlesByState: Partial<Record<SaveButtonState, string>> = {
        [SaveButtonState.default]: 'settings.save',
        [SaveButtonState.process]: 'actions.saving',
        [SaveButtonState.saved]: 'actions.saved'
    };

    @observable project = new Project();

    constructor(props: ProjectSettingsProps) {
        super(props);
        makeObservable(this);
    }

    async componentDidMount() {
        this.project = await API.get<Project>(`/projects/${this.props.user.currentChoosedProjectId}`);
    }

    save = async () => {
        this.saveState = SaveButtonState.process;
        await this.props.projectStore.updateProject(this.project);
        this.saveState = SaveButtonState.saved;
        setTimeout(() => this.saveState, SaveButtonWaitingToDefaultTimeout);
    };

    onChangeProject = (value: string) => {
        this.project.name = value;
    };

    delete = async () => {
        const switchedProject = await this.props.projectStore!.removeProject(this.project);
        await this.props.user!.chooseProject(switchedProject.id);
        this.props.appStore!.clearApp();
        this.props.history.replace(`/app/${switchedProject.id}/flows`);
    };

    renderActions() {
        return (this.props.user.permissions.isEditProjectSettings || this.props.user.permissions.isManageProjects) &&
            <ActionsMenu right={<div className="btn-group-sm">
                <PermissionsView permission={Permission.MANAGE_PROJECTS}>
                    <ConfirmDelete title={this.props.t('actions.delete_project')}
                                   question={this.props.t('actions.delete_element', {name: this.project.name})}
                                   onConfirm={this.delete}>
                        <Button title="Удалить" icon={<DeleteOutlined/>}/>

                    </ConfirmDelete>
                </PermissionsView>
                <PermissionsView permission={Permission.EDIT_PROJECT_SETTINGS}>
                    <SaveButton onClick={this.save}
                                state={this.saveState}
                                titlesByState={this.titlesByState}/>
                </PermissionsView>
            </div>}/>;
    }

    render() {
        return <Page actionMenu={this.renderActions()}>
            <div className={cn.header}>
                <h1 className={cn.title}>{this.props.t('settings.personal_settings')}</h1>
            </div>
            <Form layout="vertical" style={{maxWidth: '600px'}}>
                <Form.Item label={this.props.t('settings.project_name')}>
                    <Input
                        disabled={!this.props.user.permissions.isEditProjectSettings}
                        name="defaultInput"
                        type="text"
                        value={this.project.name}
                        onChange={e => this.onChangeProject(e.target.value)}
                    />
                </Form.Item>
                <Form.Item label={this.props.t('settings.intent_classification_threshold')}>
                    {/*<div className={cnCommon.formGroupLabel}>{this.props.t('settings.intent_classification_threshold')} %</div>*/}
                    {/*<Slider defaultValue={30} disabled={disabled} />*/}
                    <Slider value={this.project.intent_classification_threshold * 100}
                            disabled={!this.props.user.permissions.isEditProjectSettings}
                            onChange={(value: number) => this.project.intent_classification_threshold = (value / 100)}
                            min={0.1} step={0.1} max={100}/>
                </Form.Item>
            </Form>
        </Page>
    }
}

export const ProjectSettings1 = withTranslation()(withRouter(ProjectSettingsComponent));

const titlesByState: Partial<Record<SaveButtonState, string>> = {
    [SaveButtonState.default]: 'settings.save',
    [SaveButtonState.process]: 'actions.saving',
    [SaveButtonState.saved]: 'actions.saved'
};
export const ProjectSettings: FC<ProjectSettingsProps1> = inject('user', 'projectStore', 'appStore')(observer(({
    user, projectStore, appStore

                                                                                                               }) => {

    const {t} = useTranslation();
    const navigate = useNavigate();
    const [project, setProject] = useState(new Project());
    const [saveState, setSaveState] = useState(SaveButtonState.default);
    const required = useRequired();

    useEffect(() => {
        API.get<Project>(`/projects/${user.currentChoosedProjectId}`).then(responseProject => {
            setProject(responseProject);
            console.log(responseProject);
            form.setFieldValue('name', responseProject.name);
            form.setFieldValue('intent_classification_threshold', responseProject.intent_classification_threshold * 100);
        });
    }, []);

    const [form] = Form.useForm();

    const save = async () => {
        setSaveState(SaveButtonState.process);
        await projectStore.updateProject(project);
        setSaveState(SaveButtonState.saved);
        setTimeout(() => setSaveState(SaveButtonState.default), SaveButtonWaitingToDefaultTimeout);
    };

    const onChangeProject = (value: string) => {
        project.name = value;
    };

    const deleteProject = async () => {
        const switchedProject = await projectStore.removeProject(project);
        await user!.chooseProject(switchedProject.id);
        appStore.clearApp();
        navigate(`/app/${switchedProject.id}/flows`, { replace: true });
    };

    return <Page actionMenu={
        <>
        {
            (user.permissions.isEditProjectSettings || user.permissions.isManageProjects) &&
            <ActionsMenu right={<Space size={[12, 0]} wrap>
                <PermissionsView permission={Permission.MANAGE_PROJECTS}>
                    <ConfirmDelete title={t('actions.delete_project')}
                                   question={t('actions.delete_element', {name: project.name})}
                                   onConfirm={deleteProject}>
                        <Button title="Удалить" icon={<DeleteOutlined/>}/>

                    </ConfirmDelete>
                </PermissionsView>
                <PermissionsView permission={Permission.EDIT_PROJECT_SETTINGS}>
                    <SaveButton onClick={save}
                                state={saveState}
                                titlesByState={titlesByState}/>
                </PermissionsView>
            </Space>}/>
        }
        </>
    }>
        <div className={cn.header}>
            <h1 className={cn.title}>{t('settings.personal_settings')}</h1>
        </div>
        <Form form={form} layout="vertical" style={{maxWidth: '600px'}}>
            <Form.Item name="name" rules={[required]} label={t('settings.project_name')}>
                <Input
                    required
                    disabled={!user.permissions.isEditProjectSettings}
                    type="text"
                    value={project.name}
                    onChange={e => onChangeProject(e.target.value)}
                />
            </Form.Item>
            <Form.Item name="intent_classification_threshold" label={t('settings.intent_classification_threshold')}>
                <Slider value={project.intent_classification_threshold * 100}

                        disabled={!user.permissions.isEditProjectSettings}
                        onChange={(value: number) => project.intent_classification_threshold = (value / 100)}
                        min={0.1} step={0.1} max={100}/>
            </Form.Item>
        </Form>
    </Page>
}))
