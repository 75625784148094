import { action, computed, IObservableArray, makeObservable, observable, runInAction } from 'mobx';
import { LoadableStore } from '@/common/store';
import { Group } from '@/common/models/group';
import { Pagination } from '@/common/models/pagination';
import { RootStore } from '@/core/stores/root.store';
import { ScriptsAPI } from '../api/scripts.api';
import { ScriptDTO } from '../dtos/script.dto';
import { Tracker } from '@/core/analytics/tracker';
import { DragSidemenuItem } from '@/app/components/sidebar/models/drag-sidemenu-item';
import { createLoadablePromise } from '@/common/utils/loadable-promise';


interface PaginationScripts {
    end?: boolean;
}

export class ScriptsStore extends LoadableStore {
    scriptsPerPage = 1000;
    @observable isLoaded = false;
    @observable scripts: IObservableArray<ScriptDTO> = observable([]);
    @observable groups: IObservableArray<Group> = observable([]);
    @observable _paginationGroups: Record<number, Pagination & PaginationScripts> = {};
    private loadablePromise = createLoadablePromise();

    get isLoadedPromise() {
        return this.loadablePromise.promise;
    }

    constructor(private rootStore: RootStore) {
        super();
        makeObservable(this);
    }

    @computed get paginationGroups() {
        return this._paginationGroups;
    }

    @action.bound
    clearStore = () => {
        this.scripts.replace([]);
        this.groups.replace([]);
        this._paginationGroups = {};
        this._load();
    }

    get currentProject() {
        return this.rootStore.projectStore.choosenProject;
    }

    @computed
    get defaultGroup() {
        return this.groups.length > 0 ? this.groups[0] : null;
    }

    getById(id: number) {
        return this.scripts.find(s => s.id === id);
    }

    async fetchById({id}: Pick<ScriptDTO, 'id'>): Promise<ScriptDTO> {
        const existScript = this.getById(id);
        if (!existScript) {
            const script = await ScriptsAPI.getScript(this.currentProject, {id});
            this.scripts.push(script);
            return script;
        }

        return existScript;
    }

    @action.bound
    _load = async (): Promise<void> => {
        const groups = await ScriptsAPI.getGroups(this.currentProject);
        this.groups.replace(groups);
        this._paginationGroups = {};
        if (groups.length) {
            const flows = await ScriptsAPI.getAll(this.currentProject, {
                page: 1,
                per_page: this.scriptsPerPage,
            });

            this.scripts.replace(flows.results);

            this._paginationGroups[groups[0].id!] = {
                page: 2, per_page: this.scriptsPerPage,
                end: flows.current_page === flows.page_count
            }
        }

        this.loadablePromise.resolve('loaded');

        runInAction(() => {
            this.isLoaded = true;
        });
    }

    @action.bound
    save = async (script: ScriptDTO): Promise<ScriptDTO> => {
        if (script.id) {
            return this.update(script);
        } else {
            return this.create(script);
        }
    }

    @action.bound
    create = async (script: ScriptDTO): Promise<ScriptDTO> => {
        if (!this.groups.length) {
            const group = await ScriptsAPI.createGroup(this.currentProject, {name: 'Default Group'});
            this._paginationGroups[group.id!] = {page: 1, per_page: this.scriptsPerPage};
            this.groups.push(group);
        }

        const scriptWithGroup = Object.assign({}, script, {
            name: script.name,
            script_group_id: this.defaultGroup!.id!,
            type: 'flow0.1'
        });

        const _script = await ScriptsAPI.createScript(this.currentProject, scriptWithGroup);
        this.scripts.push(_script);
        return _script;
    }

    @action.bound
    update = async (script: ScriptDTO): Promise<ScriptDTO> => {
        const _script = await ScriptsAPI.updateScript(this.currentProject, script);
        const storeScript = this.scripts.find(s => s.id === _script.id)!;
        Object.assign(storeScript, {..._script});
        return _script;
    }


    @action.bound
    patchScript = async (script: Partial<ScriptDTO>): Promise<ScriptDTO> => {
        const _script = await ScriptsAPI.patchScript(this.currentProject, script);
        const storedScript = this.scripts.find(s => s.id === _script.id)!;
        Object.assign(storedScript, {..._script});
        return _script;
    }

    @action.bound
    remove = async (script: Pick<ScriptDTO, 'id'>): Promise<ScriptDTO> => {
        await ScriptsAPI.deleteScript(this.currentProject, script);
        const i = this.scripts.findIndex(s => s.id === script.id);
        return this.scripts.splice(i, 1)[0];
    }

    @action.bound
    createGroupWithScripts = async (name = 'New group', scripts: number[]) => {
        const group = await ScriptsAPI.createGroup(this.currentProject, {name});
        this.groups.push(group);
        this._paginationGroups[group.id!] = {per_page: 0, page: 1};
        await Promise.all(
            scripts.map(id => {
                const script = this.scripts.find(s => s.id === id);
                if (script) {
                    script.script_group_id = group.id!;
                    return this.update(script);
                } else {
                    return Promise.resolve(script);
                }
            })
        );

        return group;
    }

    @action.bound
    saveGroup = async (group: Group): Promise<Group> => {
        const updatedGroup = await ScriptsAPI.updateGroup(this.currentProject, group);
        const _group = this.groups.find(g => g.id === updatedGroup.id);
        if (_group) {
            _group.name = updatedGroup.name;
        }

        return _group || updatedGroup;
    }

    @action.bound
    removeGroup = async (group: Group) => {
        await ScriptsAPI.removeGroup(this.currentProject, group);
        const i = this.groups.findIndex(g => g.id === group.id);
        this.groups.splice(i, 1);
    }

    @action.bound
    async getMoreElements(group: Group) {
        if (!this._paginationGroups[group.id!]) {
            this._paginationGroups[group.id!] = {page: 1, per_page: this.scriptsPerPage};
        }
        const scripts = await ScriptsAPI.getAll(
            this.currentProject,
            this._paginationGroups[group.id!],
            {script_group_id: group.id!}
        );

        scripts.results.forEach(script => {
            if (!this.getById(script.id)) {
                this.scripts.push(script);
            }
        })

        if (scripts.current_page === scripts.page_count) {
            this.paginationGroups[group.id!].end = true;
        } else {
            this.paginationGroups[group.id!].page++;
        }
    }

    @action
    upload() {
        throw new Error('Script upload doesn\'t exist');
    }

    @action
    download() {
        throw new Error('Script download doesn\'t exist');
    }

    async replaceElementsInGroup(from: DragSidemenuItem, to: DragSidemenuItem) {
        if (from.id === to.id) return;
        if (from.groupId === to.groupId) {
            Tracker.trackEvent('Group', {Type: 'Create'});
            await this.createGroupWithScripts(`New group ${this.groups.length + 1}`, [from.id, to.id]);
        } else {
            const script = this.getById(from.id);
            if (script) {
                script.script_group_id = to.groupId;
                await this.update(script);
            }
        }
    }

    isEndPagination(group: Group) {
        if (!this._paginationGroups[group.id!]) {
            return true;
        }
        return this._paginationGroups[group.id!].end;
    }
}
