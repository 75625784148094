import { Trigger } from './Trigger';
import { makeObservable } from 'mobx';

export class UserSendTrigger extends Trigger {
    static className = 'UserSendTrigger';
    constructor() {
        super();
        makeObservable(this);
    }
}
