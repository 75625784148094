import { Reaction } from './Reaction';
import { action, makeObservable, observable } from 'mobx';
import { State } from '../State';
import { Node } from '../Node';

export class SnippetReaction extends Reaction {
    static className = 'SnippetReaction';
    static EMPTY_SNIPPET_ID = -1;
    @observable snippetId: number = SnippetReaction.EMPTY_SNIPPET_ID;

    @observable fail: boolean = false;
    @observable nextFail: State | Reaction | null = null;

    constructor(id: string, name: string) {
        super(id, name);
        makeObservable(this);
    }

    insertFailNext(newNext: State | Reaction) {
        if (!this.nextFail) {
            this.nextFail = newNext;
        } else {
            newNext.parent = this.nextFail.parent;
            this.nextFail.parent = newNext;
            newNext.next = this.nextFail;
            this.nextFail = newNext;
        }
    }

    @action.bound remove (child: Node) {
        if (child === this.next) {
            super.remove(child);
        } else if (child === this.nextFail) {
            const nextChild = this.nextFail.next;
            if (nextChild) {
                nextChild.parent = this;
                this.nextFail = nextChild as (Reaction | State);
            } else {
                this.nextFail = null;
            }
        } else {
            return;
        }
    }
}
