import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import { RootStore, rootStore } from './stores/root.store';

export class BootstrapService {
    constructor(private rootStore: RootStore) {
        makeObservable(this);
    }

    @observable private _isLoaded = false;

    @computed get isLoaded(): boolean {
        return this._isLoaded;
    }

    @action
    async bootstrap() {
        await this.rootStore.userStore.load();
        runInAction(() => {
            this._isLoaded = true;
        });
    }
}

export const bootstrapService = new BootstrapService(rootStore);
