import React from 'react';
import cn from './ChatMessageCard.module.scss';
import { ChatEvent } from '@/app/chat/models/chat-event';

type ChatMessageCardProps = {
    message: ChatEvent;
}

export class ChatMessageCard extends React.Component<ChatMessageCardProps> {

    renderSelected = (selected: any, i: number) => {
        return <button key={i} className={cn.chatMessageButton}>{ selected.title }</button>
    };

    render() {
        return <div className={cn.chatMessageCardWrapper}>
            <div className={cn.chatMessageCardImage}>
                <img src={this.props.message.params!.image} alt='Message' width='100%' height='175px'/>
            </div>
            <div className={cn.chatMessageCardText}>
                <div className={cn.chatMessageCardTitle}>{ this.props.message.params!.title }</div>
                <div className={cn.chatMessageCardSubtitle}>{ this.props.message.params!.subtitle }</div>
            </div>
            <div className={cn.chatMessageGroup}>
                { this.props.message.params!.buttons.map(this.renderSelected) }
            </div>
        </div>
    }
}
