import { computed, IObservableArray, makeObservable, observable } from 'mobx';
import { Reaction } from './Reaction';
import { QuickReplies } from '../QuickReply';

export class TextReaction extends Reaction {
    static className = 'TextReaction';
    private _texts: IObservableArray<string> = observable([]);
    @observable quickReplies: QuickReplies = new QuickReplies();

    @computed get texts(): Array<string> {
        return this._texts;
    }

    set texts(value: Array<string>) {
        this._texts.replace(value);
    }

    @observable tts?: string;

    constructor(id: string, name: string) {
        super(id, name);
        makeObservable(this);
    }
}
