import React from 'react';
import { Navigate, Route, Routes } from 'react-router';
import { inject, observer } from 'mobx-react';

import cn from './SnippetPage.module.scss';
import { RootStore } from '@/core/stores/root.store';
import { Loader } from '@/common/components/Loader';
import { SnippetStore } from '../snippet.store';
import { Snippet } from './snippet-edit/Snippet';
import { SnippetZero } from './snippet-zero/SnippetZero';
import { SideMenuRight } from '@/common/side-menu-right/SideMenuRight';
import { SnippetMenu } from './snippets-menu';
import { UserStore } from '@/core/stores/user.store';


type SnippetPageProps = {
    root?: RootStore;
    snippetStore?: SnippetStore;
    user?: UserStore;
}

@inject('root', 'snippetStore', 'user')
@observer
export class SnippetPage extends React.Component<SnippetPageProps> {
    render() {
        if (!this.props.user.permissions.isViewFacts) {
            return <Navigate to={'/'}/>;
        }

        return (
            <>
                <SideMenuRight>
                    <SnippetMenu snippetStore={this.props.snippetStore}/>
                </SideMenuRight>
                <div className={cn.wrapper}>
                    {
                        this.props.snippetStore.isLoaded
                            ? <Routes>
                                <Route path={'/'} element={<SnippetZero />}>
                                </Route>
                                <Route path={':id'} element={<Snippet />}/>
                                {/*<Route element={<Navigate to={'/'}/>}/>*/}
                            </Routes>
                            : <div className={cn.loader}><Loader/></div>
                    }
                </div>
            </>
        );
    }
}
