import { ModelsFactory } from './ModelsFactory';
import { Node } from '../models/Node';
import { Reaction } from '../models/reactions/Reaction';
import { Branch } from '../models/Branch';
import { TextReaction } from '../models/reactions/TextReactions';
import { QuickReplies } from '../models/QuickReply';
import { VideoReaction } from '../models/reactions/VideoReaction';
import { FileReaction } from '../models/reactions/FileReaction';
import { ImageReaction } from '../models/reactions/ImageReaction';
import { AudioReaction } from '../models/reactions/AudioReaction';
import { FileLikeReaction } from '../models/reactions/FileLikeReaction';
import { ButtonsReaction } from '../models/reactions/ButtonsReaction';
import { State } from '../models/State';
import { LocationReaction } from '../models/reactions/LocationReaction';
import { JumpToReaction } from '../models/reactions/JumpToReaction';
import { RepeatReaction } from '../models/reactions/RepeatReaction';
import { ResetReaction } from '../models/reactions/ResetReaction';
import { TerminateReaction } from '../models/reactions/TerminateReaction';
import { SnippetReaction } from '../models/reactions/SnippetReaction';
import { Button } from '../models/Button';

type TextReactionParams = {
    texts: string[];
    tts?: string;
    quickReplies?: QuickReplies;
}

type FileLikeReactionParams = {
    url: string;
    caption?: string;
    quickReplies?: QuickReplies;
}

type ButtonsReactionParams = {
    text: string;
    buttons?: Button[];
}

type LocationReactionParams = {
    lat?: number;
    lon?: number;
    name?: string;
    quickReplies?: QuickReplies;
}

type JumpToReactionParams = {
    next?: string;
}

type SnippetReactionParams = {
    snippet_id?: number;
    fail?: boolean;
}


type RepeatReactionParams = {
    text?: string;
    tts?: string;
}

export type ReactionsFactoryBuildParam = object
    | TextReactionParams
    | FileLikeReactionParams
    | ButtonsReactionParams
    | SnippetReactionParams
    | RepeatReactionParams
    | LocationReactionParams
    | JumpToReactionParams;

export interface ReactionsFactoryBuildParams {
    parent: Node | null;
    id: string;
    name: string;
    type: string;
    branches: Branch[];
    params: ReactionsFactoryBuildParam;
}

const dtoTypeToClass: Record<string, string> = {
    'text': TextReaction.type,
    'audio': AudioReaction.type,
    'video': VideoReaction.type,
    'file': FileReaction.type,
    'image': ImageReaction.type,
    'buttons': ButtonsReaction.type,
    'terminate': TerminateReaction.type,
    'reset': ResetReaction.type,
    'repeat': RepeatReaction.type,
    'jump': JumpToReaction.type,
    'snippet': SnippetReaction.type,
    'location': LocationReaction.type,
};

export class ReactionsFactory extends ModelsFactory {
    static build(params: ReactionsFactoryBuildParams): Reaction {
        const type = dtoTypeToClass[params.type] || params.type;
        switch (type) {
            case TextReaction.type: {
                return this.buildTextReaction(params);
            }
            case AudioReaction.type: {
                return this.buildFileLikeReaction(params, AudioReaction);
            }
            case VideoReaction.type: {
                return this.buildFileLikeReaction(params, VideoReaction);
            }
            case ImageReaction.type: {
                return this.buildFileLikeReaction(params, ImageReaction);
            }
            case FileReaction.type: {
                return this.buildFileLikeReaction(params, FileReaction);
            }
            case ButtonsReaction.type: {
                return this.buildButtonsReaction(params);
            }
            case LocationReaction.type: {
                return this.buildLocationReactionType(params);
            }
            case JumpToReaction.type: {
                return this.buildJumpToReactionType(params);
            }
            case RepeatReaction.type: {
                return this.buildRepeatReaction(params);
            }
            case ResetReaction.type: {
                return this.buildResetReaction(params);
            }
            case TerminateReaction.type: {
                return this.buildTerminateReaction(params);
            }
            case SnippetReaction.type: {
                return this.buildSnippetReaction(params);
            }
            default:
                throw new Error('Bad reaction type');
        }
    }

    private static buildNext(branches: Branch[], parent: Reaction | null): State | Reaction | null {
        if (branches.length) {
            const [branch] = branches;
            const next = branch.next;
            if (branch.next) {
                branch.next.parent = parent;
            }
            return next;
        } else {
            return null;
        }
    }

    private static buildButtonsReaction(params: ReactionsFactoryBuildParams): ButtonsReaction {
        const reaction = new ButtonsReaction(params.id, params.name);
        const buttonsReactionParams = params.params as ButtonsReactionParams;

        reaction.buttons = new QuickReplies(buttonsReactionParams.buttons || []);
        reaction.text = buttonsReactionParams.text;
        reaction.next = this.buildNext(params.branches, reaction);

        reaction.parent = params.parent;
        return reaction;
    }

    private static buildTextReaction(params: ReactionsFactoryBuildParams): TextReaction {
        const reaction = new TextReaction(params.id, params.name);
        const textReactionParams = params.params as TextReactionParams;
        reaction.texts = textReactionParams.texts;
        reaction.tts = textReactionParams.tts;
        reaction.quickReplies = textReactionParams.quickReplies || new QuickReplies();
        reaction.next = this.buildNext(params.branches, reaction);

        reaction.parent = params.parent;
        return reaction;
    }

    private static buildFileLikeReaction<T extends FileLikeReaction>(
        params: ReactionsFactoryBuildParams,
        Constructor: new (id: string, name: string) => T
    ): T {
        const reaction = new Constructor(params.id, params.name);
        const fileLikeReaction = params.params as FileLikeReactionParams;
        reaction.url = fileLikeReaction.url;
        reaction.caption = fileLikeReaction.caption;
        reaction.quickReplies = fileLikeReaction.quickReplies || new QuickReplies();
        reaction.next = this.buildNext(params.branches, reaction);

        reaction.parent = params.parent;
        return reaction;
    }

    private static buildLocationReactionType(params: ReactionsFactoryBuildParams) {
        const reaction = new LocationReaction(params.id, params.name);
        const locationParams = params.params as LocationReactionParams;
        reaction.lat = locationParams.lat;
        reaction.lon = locationParams.lon;
        reaction.locationName = locationParams.name;
        reaction.quickReplies = locationParams.quickReplies || new QuickReplies();
        reaction.next = this.buildNext(params.branches, reaction);
        reaction.parent = params.parent;

        return reaction;
    }

    private static buildJumpToReactionType(params: ReactionsFactoryBuildParams) {
        const reaction = new JumpToReaction(params.id, params.name);
        const jumpToParams = params.params as JumpToReactionParams;
        reaction.jumpNext = jumpToParams.next;
        reaction.next = this.buildNext(params.branches, reaction);
        reaction.parent = params.parent;

        return reaction;
    }

    private static buildRepeatReaction(params: ReactionsFactoryBuildParams) {
        const reaction = new RepeatReaction(params.id, params.name);
        const repeatParams = params.params as RepeatReactionParams;
        reaction.text = repeatParams.text;
        reaction.tts = repeatParams.tts;

        reaction.parent = params.parent;
        reaction.next = this.buildNext(params.branches, reaction);

        return reaction;
    }

    private static buildResetReaction(params: ReactionsFactoryBuildParams) {
        const reaction = new ResetReaction(params.id, params.name);

        reaction.next = this.buildNext(params.branches, reaction);
        reaction.parent = params.parent;

        return reaction;
    }

    private static buildTerminateReaction(params: ReactionsFactoryBuildParams) {
        const reaction = new TerminateReaction(params.id, params.name);
        reaction.next = this.buildNext(params.branches, reaction);
        reaction.parent = params.parent;

        return reaction;
    }

    private static buildSnippetReaction(params: ReactionsFactoryBuildParams) {
        const reaction = new SnippetReaction(params.id, params.name);
        const snippetParams = params.params as SnippetReactionParams;
        reaction.snippetId = snippetParams.snippet_id || -1;

        if (snippetParams.fail) {
            const failureBranch = params.branches.find((b: Branch) => {
                return Boolean(b.condition && b.condition.type === 'failure');
            });
            if (failureBranch) {
                reaction.nextFail = failureBranch.next;
                if (reaction.nextFail) {
                    reaction.nextFail.parent = reaction;
                }
            }

            const successBranch = params.branches.find(b => {
                return !b.condition || b.condition.type === 'success';
            });
            if (successBranch) {
                reaction.next = successBranch.next;
                if (reaction.next) {
                    reaction.next.parent = reaction;
                }
            }
        } else {
            const branch = params.branches.find(b => b.condition === null);
            reaction.next = branch ? branch.next : null;
            if (reaction.next) {
                reaction.next.parent = reaction;
            }
        }

        reaction.fail = snippetParams.fail;


        reaction.parent = params.parent;
        return reaction;
    }
}
