import { UserSendTrigger } from './UserSendTrigger';
import { makeObservable } from 'mobx';

export class VideoTrigger extends UserSendTrigger {
    static className = 'VideoTrigger';
    constructor() {
        super();
        makeObservable(this);
    }
}
