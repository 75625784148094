import { AudioReaction } from './AudioReaction';
import { ButtonsReaction } from './ButtonsReaction';
import { FileReaction } from './FileReaction';
import { ImageReaction } from './ImageReaction';
import { JumpToReaction } from './JumpToReaction';
import { LocationReaction } from './LocationReaction';
import { RepeatReaction } from './RepeatReaction';
import { ResetReaction } from './ResetReaction';
import { SnippetReaction } from './SnippetReaction';
import { TerminateReaction } from './TerminateReaction';
import { TextReaction } from './TextReactions';
import { VideoReaction } from './VideoReaction';

export const reactionTypes: Array<string> = [
    AudioReaction.type,
    ButtonsReaction.type,
    FileReaction.type,
    ImageReaction.type,
    JumpToReaction.type,
    LocationReaction.type,
    RepeatReaction.type,
    ResetReaction.type,
    SnippetReaction.type,
    TerminateReaction.type,
    TextReaction.type,
    VideoReaction.type
];

export const isReactionType = (type: string) => {
    return reactionTypes.includes(type);
};
