import React from 'react';
import cn from './Branch.module.scss';
import cns from 'classnames';
import { Reaction } from '../models/reactions/Reaction';
import { State } from '../models/State';
import { action, computed, IReactionDisposer, makeObservable, observable, reaction } from 'mobx';
import { StateOrReaction } from './StateOrReaction';
import { DropZone } from './DropZone';
import { triggerTypes } from '../models/triggers/TriggerTypes';
import { reactionTypes } from '../models/reactions/ReactionTypes';
import { IntentTrigger } from '../models/triggers/IntentTrigger';
import { DropItem } from '../models/DropItem';
import { DropFactory } from '../factories/DropFactory';
import { observer } from 'mobx-react';

interface BranchesProps {
    trigger: IntentTrigger;
}

@observer
export class IntentTriggerBranches extends React.Component<BranchesProps> {
    @observable activeBranchIndex: number = -1; // -1 all, >0 noEntityBranches

    branchesReaction?: IReactionDisposer;
    private lastActive?: number;

    constructor(props: BranchesProps) {
        super(props);
        makeObservable(this);
    }

    componentDidMount(): void {
        this.branchesReaction = reaction(
            () => this.props.trigger.noEntityBranches.length, () => {
                this.activeBranchIndex = -1;
            });
    }

    @computed get next(): State | Reaction | null {
        if (this.activeBranchIndex === -1) {
            return this.props.trigger.next;
        } else {
            return this.props.trigger.noEntityBranches[this.activeBranchIndex].next;
        }
    }

    @action.bound
    private onNextActiveBranch = (drop: DropItem) => {
        const next = DropFactory.build(drop, this.props.trigger);

        if (this.activeBranchIndex === -1) {
            this.props.trigger.insertNext(next);
        } else {
            const branch = this.props.trigger.noEntityBranches[this.activeBranchIndex];
            this.props.trigger.insertNotEntityNext(branch.name, next);
        }
    }

    buildOnClick = (index: number) => () => {
        if (this.activeBranchIndex !== index) {
            this.lastActive = this.activeBranchIndex;
            this.activeBranchIndex = index;
        }
    };


    render() {
        return <>
            <div className={cn.branches}>
                {
                    this.props.trigger.noEntityBranches.length > 0 &&
                    <button
                        className={cns(cn.branch, {
                            [cn.active]: this.activeBranchIndex === -1,
                            [cn.deactivate]: this.lastActive === -1,
                        })}
                        onClick={this.buildOnClick(-1)}
                        key={'All'}>
                        All
                    </button>
                }
                {this.props.trigger.noEntityBranches.map((branch, i) => {
                    return <button
                        className={cns(cn.branch, {
                            [cn.active]: this.activeBranchIndex === i,
                            [cn.deactivate]: this.lastActive === i,
                        })}
                        onClick={this.buildOnClick(i)}
                        key={branch.name}>
                        {branch.name}
                    </button>
                })}
            </div>
            <DropZone accept={[...triggerTypes, ...reactionTypes]} onDrop={this.onNextActiveBranch}/>
            {
                (this.next) && <StateOrReaction next={this.next} key={this.next.name}/>
            }
        </>;
    }
}
