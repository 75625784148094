export const colors = [
    '#1E61F0',
    '#93BD31',
    '#7E21AD',
    '#67D1D5',
    '#FD765E',
    '#A071FF',
    '#F8A0EF',
    '#11669B',
    '#EA5545',
    '#EA5545',
    '#F46A9B',
    '#EF9B20',
    '#C89600',
    '#EDE15B',
    '#BED700',
    '#7ACB15',
    '#27AEEF',
    '#B33DC6',
    '#0038FF',
    '#1C3965',
    '#2E8D71',
    '#7C1FD8'
];


export function getColors(i: number) {
    return colors[i % colors.length];
}

