import { Trigger } from './Trigger';
import { action, makeObservable } from 'mobx';

export class FallbackTrigger extends Trigger {
    static className = 'FallbackTrigger';
    constructor() {
        super();
        makeObservable(this);
    }

    @action.bound removeSelf = () => {
        if (this.parent) {
            this.parent.fallback = null;
            this.parent = null;
        }
    }
}
