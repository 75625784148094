import React, { useState } from 'react';
import cns from 'classnames';

import ChevronDownIcon from 'mdi-react/ChevronDownIcon';

import cn from './RightMenu.module.scss';

interface RightMenuProps {
    content?: React.ReactNode;
    contentMaxHeight?: string;
    chat?: React.ReactNode;
}

export function RightMenu({ content, chat, contentMaxHeight }: RightMenuProps) {
    const [isContentCollapsed, setState] = useState(false);
    const renderContent = () => (
        <>
            { content && <div className={cn.rightMenuContent} style={{ maxHeight: contentMaxHeight }}>
                {content}
            </div>
            }
            <button
                className={cn.rightMenuHideBtn}
                onClick={() => setState(!isContentCollapsed)}
            >
                <span className={cn.rightMenuHideBtnArrow}><ChevronDownIcon/></span>
            </button>
        </>
    );

    return (
        <aside className={cns(cn.rightMenu, isContentCollapsed && cn.rightMenuHideContent)}>
            {content && renderContent()}
            <div className={cn.rightMenuChat}>{chat}</div>
        </aside>
    );
}
