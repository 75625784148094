import React, { useState } from 'react';

import qs from 'query-string';
import cns from 'classnames';

import { Loader } from '@/common/components/Loader';
import { AuthAPI } from '../../auth.api';

import cn from './ResetPassword.module.scss';
import cnLogin from '../login/Login.module.scss';
import { inject, observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom/dist';
import { Button, Input } from 'antd';

type ResetPasswordFormInputs = 'password' | 'repeatPassword';


interface ResetPasswordState {
    errors: string[];
    password: string;
    repeatPassword: string;
    formErrors: Record<ResetPasswordFormInputs, string>;
    isTokenValid: boolean | null;
    isSubmitted: boolean;
}

const initialState: ResetPasswordState = {
    errors: [],
    password: '',
    repeatPassword: '',
    formErrors: {
        password: '',
        repeatPassword: ''
    },
    isTokenValid: null,
    isSubmitted: false
};

const inputErrors = {
    password: {
        required: 'auth.enter_new_password',
        useStrongPassword: 'auth.use_strong_password'
    },
    repeatPassword: {
        required: 'auth.repeat_new_password',
        notEqual: 'auth.password_do_not_match'
    }
};

const validateForm = (password: string, repeatPassword: string): Record<ResetPasswordFormInputs, string> => {
    const res = {
        password: '',
        repeatPassword: ''
    };
    const strongPassword = new RegExp('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,250})');
    if(!strongPassword.test(password)) res.password = inputErrors.password.useStrongPassword;
    if (!password) res.password = inputErrors.password.required;
    if (!repeatPassword) res.repeatPassword = inputErrors.repeatPassword.required;
    if (password && repeatPassword && password !== repeatPassword) {
        res.repeatPassword = inputErrors.repeatPassword.notEqual;
    }

    return res;
}

const _ResetPassword = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const { email, token } = qs.parse(location.search);
    const [state, setState] = useState<ResetPasswordState>(initialState);
    const isInitPhase = state.isTokenValid === null;
    const { t } = useTranslation();
    const resetPassword = async () => {
        const formErrors = validateForm(state.password, state.repeatPassword);

        if (formErrors.password || formErrors.repeatPassword) {
            return setState(Object.assign({}, state, { formErrors }));
        }

        try {
            await AuthAPI.passwordSet({ password: state.password, token: token as string });
            // await props.user.login({ email: email as string, password: state.password as string });
            navigate('/auth/login');
        } catch (e) {
            console.log(e);
        }
    };

    const onInputChange = (name: ResetPasswordFormInputs, value: string) => {
        setState(Object.assign({}, state, { [name]: value }));
    };

    const renderInput = (name: ResetPasswordFormInputs, placeholder: string) => (
        <div className={cns("form__form-group-field", cn.formInputWrapper)}>
            <div className="form__form-group-input-wrap">
                <Input
                    size={'large'}
                    name={name}
                    value={state[name]}
                    onChange={e => onInputChange(name, e.target.value)}
                    type="password"
                    placeholder={t(placeholder)}
                />
                <div className={cn.inputErrors}>
                    { t(state.formErrors[name]) || ''}
                </div>
            </div>
        </div>
    );
    const renderNewPasswordForm = () => (
        <form className="form">
            {renderInput('password', 'auth.new_password')}
            {renderInput('repeatPassword', 'auth.confirm_new_password')}
        </form>
    );
    const renderResetPasswordContent = () => (
        // ???: Проверить строчку navigate('/auth/reset', { state: { email } })}
        // было history.push('/auth/reset', { email })
        <article className={cns("account__card card-body", cn.cardBody)}>
            <div className={cns('card__title', cn.title)}>
                <h4>{ t(state.isTokenValid ? 'auth.reset_your_password' : 'auth.error_resetting')}</h4>
            </div>
            <div className={cn.content}>
                {state.isTokenValid ? renderNewPasswordForm() : state.errors.map(err => t(err)).join(', ')}
            </div>

            <div className={cns("account__btns", cn.controls)}>
                {
                    state.isTokenValid ? (
                        <>
                        <Button
                            size={'large'}
                            style={{ flex: 1 }}
                            onClick={() => navigate('/')}
                        >{t('actions.cancel')}</Button>
                        <Button
                            size={'large'}
                            style={{ flex: 1 }}
                            type={'primary'}
                            onClick={() => resetPassword()}
                        >{t('auth.reset_password')}</Button>
                        </>
                    ) : (
                        <Button
                            size={'large'}
                            onClick={() => navigate('/auth/reset', { state: { email } })}
                        >{t('auth.reset_password_again')}</Button>
                    )
                }
            </div>
        </article>
    );

    if (isInitPhase) {
        AuthAPI.validateToken(token as string, 'reset_password')
            .then(res => {
                setTimeout(() =>
                    setState(Object.assign({}, state, { isTokenValid: res.is_valid })),
                    500
                );
            })
            .catch(() => {
                setTimeout(() => {
                    setState(Object.assign(
                        {},
                        state,
                        { errors: ['auth.tokent_non_active'], isTokenValid: false }
                    ));
                }, 500);
            });
    }

    return <div className="account">
        <div className={cnLogin.leftLiaBaloon} />
        <div className={cnLogin.rightLiaBaloon} />
        <div className={cnLogin.logo} />
        <div className="account__wrapper">
            {isInitPhase ? <Loader /> : renderResetPasswordContent()}
        </div>
    </div>
};

export const ResetPassword = inject('user')(observer(_ResetPassword));
