import React, { FC, useEffect, useState } from 'react';

import { observer, inject } from 'mobx-react';
import { LabelsStore } from '@/app/scripts/stores/labels.store';
import { IntentStore } from '@/app/intents/intent.store';
import { ProjectStore } from '@/core/stores/project.store';
import { SnippetStore } from '@/app/snippets/snippet.store';
import { EntityStore } from '@/app/entities/entity.store';
import { useParams } from 'react-router-dom/dist';
import { ScriptPageStore } from '@/app/scripts/stores/script-page.store';
import { UserStore } from '@/core/stores/user.store';
import { ScriptsStore } from '@/app/scripts/stores/scripts.store';
import { rootStore } from '@/core/stores/root.store';
import * as Styled from '@/app/scripts/components/FlowDiagram/FloaDiagramPage.styled';
import { Spin } from 'antd';
import { FlowDiagramComponent } from '@/app/scripts/components/FlowDiagram/FlowDiagram.component';
import { FiaLogo } from '@/common/components/lia-logo/LiaLogo';
import { useTranslation } from 'react-i18next';

type FlowDiagramProps = {
    labelsStore?: LabelsStore;
    projectStore?: ProjectStore;
    user?: UserStore;
};

export const scriptsStore: ScriptsStore = new ScriptsStore(rootStore);
export const intentStore: IntentStore = new IntentStore(rootStore);
export const snippetStore = new SnippetStore(rootStore);
export const entityStore: EntityStore = new EntityStore(rootStore);

export const FlowDiagramPageComponent: FC<FlowDiagramProps> = inject(
    'projectStore', 'user'
)(observer((
    {user, projectStore}
) => {
    const [scriptStorePage] = useState(() => new ScriptPageStore(scriptsStore, user));
    const [labelsStore] = useState(() => new LabelsStore(scriptStorePage, projectStore));
    const params = useParams();
    const {t} = useTranslation();

    const [isLoaded, setIsLoaded] = useState(false);
    const [isError, setIsError] = useState(false);
    useEffect(() => {
        scriptStorePage.fetchScript({id: +params.id}).then(() => {
            return Promise.all([scriptsStore.load(), labelsStore.load(), intentStore.load(), snippetStore.load(), entityStore.load()])
        }).then(() => {
            setIsLoaded(true);
        }).catch(() => {
            setIsError(true);
        })
    }, []);


    if (isError) {
        return <Styled.FlowDiagramPageRoot>
            <Styled.LoaderWrapper>{ t('actions.request_error') }</Styled.LoaderWrapper>
        </Styled.FlowDiagramPageRoot>
    }

    return <Styled.FlowDiagramPageRoot>
        {!isLoaded && <Styled.LoaderWrapper><Spin size={'large'}></Spin></Styled.LoaderWrapper>}
        {isLoaded &&
            <>
                <Styled.FlowHeader>
                    <Styled.FlowLogo>
                        <FiaLogo/>
                    </Styled.FlowLogo>

                    <Styled.Title>
                        <Styled.ProjectTitle>{ projectStore.choosenProject.name }</Styled.ProjectTitle>
                        <Styled.ScriptTitle>{ scriptStorePage.script.name }</Styled.ScriptTitle>
                    </Styled.Title>
                </Styled.FlowHeader>
                <Styled.FlowDiagramWrapper>
                    <FlowDiagramComponent
                        intentStore={intentStore}
                        projectStore={projectStore}
                        snippetStore={snippetStore}
                        entityStore={entityStore}
                        labelsStore={labelsStore}
                        script={scriptStorePage.script}/>
                </Styled.FlowDiagramWrapper>
            </>

        }
    </Styled.FlowDiagramPageRoot>
}));
