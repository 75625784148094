import React, { FC } from 'react'
import { CopyIdText, Wrapper } from '@/app/scripts/components/CopyId/CopyId.styled';
import CopyToClipboard from 'react-copy-to-clipboard';
import { useTranslation } from 'react-i18next';

export const CopyId: FC<{ id: string; align?: boolean }> = ({id, align = true}) => {
    const {t} = useTranslation();

    return <Wrapper align={align}>
        <CopyToClipboard text={id} onCopy={() => alert(t('actions.copied_id'))}>
            <CopyIdText>{id}</CopyIdText>
        </CopyToClipboard>
    </Wrapper>
}
