import { Trigger } from './Trigger';
import { makeObservable } from 'mobx';

export class StartTrigger extends Trigger {
    static className = 'StartTrigger';
    constructor() {
        super();
        makeObservable(this);
    }
}
