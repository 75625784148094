import React from 'react';
import { observer } from 'mobx-react';
import { TextReaction } from '../models/reactions/TextReactions';
import cn from './Params.module.scss';
import cns from 'classnames';
import { action } from 'mobx';
import { WithTranslation, withTranslation } from 'react-i18next';
import { ButtonEditor } from './ButtonEditor';
import Textarea from './Textarea'
import { Button, Space } from 'antd';
import { CopyId } from '@/app/scripts/components/CopyId/CopyId.component';

interface ChoiceEditorProps {
    choices: Array<string>;
    index: number;
    onRemoveFocus: () => void;
    onRemoveChoice: () => void;
    indexToFocus?: number;
}

@observer
class ChoiceEditor extends React.Component<ChoiceEditorProps> {
    @action.bound
    onChangeText = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        if (e.target.value) {
            this.props.choices[this.props.index] = e.target.value;
        } else {
            this.props.choices.splice(this.props.index, 1);
            this.props.onRemoveChoice();
        }
    }

    render() {
        const choice = this.props.choices[this.props.index];
        return <Textarea
            id={`choice-txt-${this.props.index}`}
            rows={3} className={cn.input} onChange={this.onChangeText}
            value={choice}
            isIndexToFocus={this.props.indexToFocus === this.props.index}
            onRemoveFocus={this.props.onRemoveFocus}
        />;
    }
}


interface TextReactionParamsEditorProps extends WithTranslation {
    reaction: TextReaction;
}

@observer
export class TextReactionParamsEditorComp extends React.Component<TextReactionParamsEditorProps> {
    private ref = React.createRef<HTMLTextAreaElement>();

    private _refToFocus?: number = -1; // -1 - focus on new choice textarea, >=0 on exist choice textarea

    get refToFocus(): number | undefined {
        return this._refToFocus;
    }

    set refToFocus(value: number | undefined) {
        if (value === -1 && this.ref.current) {
            setTimeout(() => this.ref.current!.focus());
            delete this._refToFocus;
        } else {
            this._refToFocus = value;
        }
    }

    renderOr(active?: boolean) {
        return <div className={cns(cn.or, {[cn.orActive]: active})}>{this.props.t('flows.or')}</div>
    }

    @action.bound
    onAddButton = () => {
        this.props.reaction.quickReplies.addButton({
            name: '',
            type: 'text',
            title: '',
            params: {
                text: ''
            },
        });
    }

    @action.bound
    addNewChoice = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        this.props.reaction.texts.push(e.target.value);
        this.refToFocus = this.props.reaction.texts.length - 1;
    }

    @action.bound
    onRemoveFocus = () => {
        if (this.refToFocus != null) {
            delete this._refToFocus;
        }
    }

    @action.bound
    onRemoveChoice = () => {
        this.refToFocus = -1;
    }

    renderChoiceEditorWithOrDelimiter(index: number) {
        const {texts} = this.props.reaction;
        return <React.Fragment key={index}>
            <ChoiceEditor choices={this.props.reaction.texts} index={index} onRemoveFocus={this.onRemoveFocus}
                          onRemoveChoice={this.onRemoveChoice}
                          indexToFocus={this.refToFocus}/>
            {
                this.renderOr(index < texts.length - 1)
            }
        </React.Fragment>;
    }

    render() {
        const {texts, quickReplies} = this.props.reaction;

        return <div className={cn.editor}>
            <div className={cn.group}>
                <h6 className={cn.groupTitle}>
                    {this.props.t('flows.answer')}
                </h6>
                {
                    this.props.reaction.texts.map((_, i) => {
                        return this.renderChoiceEditorWithOrDelimiter(i)
                    })
                }

                <textarea rows={3} key={texts.length} value={''} className={cn.input}
                          ref={this.ref}
                          onChange={this.addNewChoice}
                />
            </div>
            <div className={cn.group}>
                <div className={cn.buttons}>
                    {quickReplies.buttons.map((button, i) => <ButtonEditor button={button} key={i}
                                                                           onRemove={() => quickReplies.deleteButton(button)}/>)}
                </div>
            </div>

            {quickReplies.buttons.length <= 5 &&
                <Space align="center" direction="vertical"><Button type="primary" block={true}
                                                                   onClick={this.onAddButton}>
                    {this.props.t('flows.add_button')}
                </Button></Space>}

            <CopyId id={this.props.reaction.id}/>
        </div>
    }
}

export const TextReactionParamsEditor = withTranslation()(TextReactionParamsEditorComp);
