import { computed, observable, IObservableArray, action, makeObservable } from 'mobx';
import {Node, Trigger, FallbackTrigger, Flow} from './internal';


export class State extends Node {
    static className = 'State';
    private _triggers: IObservableArray<Trigger> = observable([]);
    @computed get triggers(): Trigger[] {
        return this._triggers;
    }

    set triggers(value: Array<Trigger>) {
        this._triggers.replace(value);
    }

    @observable id: string;
    @observable name: string;

    @observable fallback: FallbackTrigger | null = null;

    constructor(id: string, name: string, triggers: Trigger[]) {
        super();
        makeObservable(this);
        this.id = id;
        this.name = name;
        this._triggers.replace(triggers);
    }

    @action.bound addTrigger = (trigger: Trigger) => {
        if (FallbackTrigger.is(trigger) && this.fallback) {
            return;
        }

        if (FallbackTrigger.is(trigger)) {
            this.fallback = trigger as FallbackTrigger;
        } else {
            this._triggers.unshift(trigger);
        }

        trigger.parent = this;
    }

    @action.bound removeTrigger = (trigger: Trigger) => {
        const idx = this._triggers.indexOf(trigger);
        if (idx >= 0) {
            this._triggers.splice(idx, 1);
        }
    }

    @action.bound moveTrigger = (from: number, to: number) => {
        const fromTrigger = this.triggers[from];

        this._triggers.splice(from, 1);
        this._triggers.splice(to, 0, fromTrigger);
    }

    @action.bound removeSelf() {
        if (!this.parent) {
            return;
        }

        if (this.parent.type === Flow.type) {
            (this.parent as Flow).next = null; // remove self from parent flow
            this.parent = null; // clear parent link
        } else {
            super.removeSelf();
        }
    }

    @action.bound removeSelfWithNextTrigger(activeTrigger?: Trigger) {
        if (!this.parent) {
            return;
        }

        if (this.parent.type === Flow.type) {
            if (activeTrigger && activeTrigger.next) {
                activeTrigger.next.parent = this.parent;
                this.parent.next = activeTrigger.next;
            } else {
                this.parent.next = null; // remove self from parent flow
                this.parent = null; // clear parent link
            }
        } else {
            super.removeSelf();
        }
    }

}
