export interface WithNameAndType {
    id: number;
    name: string;
    type: MarkupType;
    selected?: boolean;
}


export enum MarkupType {
    QA = 'qa', Intent = 'intent'
}
