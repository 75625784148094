import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';

import { inject, observer } from 'mobx-react';
import { format } from 'date-fns';
import cns from 'classnames';

import cn from './ActivityLogsPage.module.scss';
import { Page } from '@/common/components/page/Page';
import { ActivityLogsPageStore } from '@/app/activity-logs/ActivityLogsPage.store';
import { Loader } from '@/common/components/Loader';
import { ActivityLogsFilter } from '@/app/activity-logs/ActivityLogsFilter';
import { getUserName } from '@/common/utils/get-user-name';
import { animatedScrollTo } from '@/common/utils/animated-scroll-to';

interface ActivityLogsPageProps extends WithTranslation {
    activityLogsPageStore?: ActivityLogsPageStore;
}

@inject('activityLogsPageStore')
@observer
class ActivityLogsPageComp extends React.Component<ActivityLogsPageProps> {
    private static preloadMargin = 100;
    private isFetchingMore: boolean;
    private scrollContainerRef = React.createRef<HTMLDivElement>();

    componentDidMount(): void {
        this.props.activityLogsPageStore.reload();
    }

    onScroll = async (e: any) => {
        const sc = e.target as HTMLDivElement;
        const currentPos = sc.scrollTop + sc.clientHeight;
        const targetLine = sc.scrollHeight - ActivityLogsPageComp.preloadMargin;

        if (currentPos >= targetLine && !this.isFetchingMore) {
            this.isFetchingMore = true;
            await this.props.activityLogsPageStore.loadMore();
            this.isFetchingMore = false;
        }
    }

    onResize = async () => {
        if (!this.isFetchingMore) {
            this.isFetchingMore = true;
            await this.props.activityLogsPageStore.loadMore();
            this.isFetchingMore = false;
        }
    }

    applyFilter = async () => {
        animatedScrollTo(0, 300, this.scrollContainerRef.current);
        await this.props.activityLogsPageStore.applyFilter();
    }

    renderList() {
        return this.props.activityLogsPageStore.logs.map(log => (
            <tr className={cn.activityLogsItem} key={+log.date}>
                <td className={cns(cn.column, cn.columnUser)}>{getUserName(log.user)}</td>
                <td className={cns(cn.column, cn.columnAction)}>{log.action}</td>
                <td className={cns(cn.column, cn.columnComponent)}>{log.component}</td>
                <td className={cns(cn.column, cn.columnItem)}>{log.item}</td>
                <td className={cns(cn.column, cn.columnDate)}>{format(new Date(log.date), 'HH:mm:ss dd.MM.yyyy')}</td>
                <td className={cns(cn.column, cn.columnVersion)}>{log.projectVersion ? log.projectVersion.name : '-'}</td>
                <td className={cns(cn.column, cn.columnProject)}>{log.project ? log.project.name : '-'}</td>
            </tr>
        ));
    }

    renderPage() {
        window.onresize = this.onResize;
        return (
            <article className={cn.container}>
                <h1 className={cn.title}>
                    {this.props.t('activity_logs.title')}
                </h1>
                <div className={cn.activityLogsFilter}>
                    <ActivityLogsFilter
                        filter={this.props.activityLogsPageStore.draftFilter}
                        filterData={this.props.activityLogsPageStore.filtersData}
                        isApplying={this.props.activityLogsPageStore.isApplying}
                        isResetting={this.props.activityLogsPageStore.isResetting}
                        onFilterChange={this.props.activityLogsPageStore.changeFilter}
                        onApply={this.applyFilter}
                        onReset={this.props.activityLogsPageStore.resetFilter}
                    />
                </div>
                <div ref={this.scrollContainerRef} className={cn.activityLogsItems} onScroll={this.onScroll}>
                    <table  className={cn.itemsTable}>
                        <thead>
                            <tr>
                                <th className={cn.column}>{this.props.t('activity_logs.labels.user')}</th>
                                <th className={cn.column}>{this.props.t('activity_logs.labels.action')}</th>
                                <th className={cn.column}>{this.props.t('activity_logs.labels.component')}</th>
                                <th className={cn.column}>{this.props.t('activity_logs.labels.item')}</th>
                                <th className={cn.column}>{this.props.t('activity_logs.labels.date')}</th>
                                <th className={cn.column}>{this.props.t('activity_logs.labels.projectVersion')}</th>
                                <th className={cn.column}>{this.props.t('activity_logs.labels.project')}</th>
                            </tr>
                        </thead>
                        <tbody>{this.renderList()}</tbody>
                    </table>
                </div>
            </article>
        );
    }

    render() {
        const { isLoading } = this.props.activityLogsPageStore!;

        return (
            <Page editorPadding={false} hideShadow={true} className={cn.wrapper}>
                {isLoading ? <div className={cn.loaderWrapper}><Loader/></div> : this.renderPage()}
            </Page>
        );
    }
}

export const ActivityLogsPage = withTranslation()(ActivityLogsPageComp);
