import React from 'react'

export const DateRangeIcon: React.FunctionComponent<{ className?: string }> = (props) => <svg className={props.className}
                                                                                              width="14" height="16"
                                                                                              viewBox="0 0 14 16"
                                                                                              fill="none"
                                                                                              xmlns="http://www.w3.org/2000/svg">
        <path
            d="M12.1434 14.0117V5.75H1.66679V14.0117H12.1434ZM12.1434 1.98828C12.5418 1.98828 12.8934 2.14062 13.198 2.44531C13.5027 2.75 13.6551 3.10156 13.6551 3.5V14.0117C13.6551 14.4102 13.5027 14.7617 13.198 15.0664C12.8934 15.3477 12.5418 15.4883 12.1434 15.4883H1.66679C1.24491 15.4883 0.881631 15.3477 0.576944 15.0664C0.295694 14.7852 0.155069 14.4336 0.155069 14.0117V3.5C0.155069 3.10156 0.295694 2.75 0.576944 2.44531C0.881631 2.14062 1.24491 1.98828 1.66679 1.98828H2.40507V0.511719H3.91679V1.98828H9.89335V0.511719H11.4051V1.98828H12.1434ZM10.6668 7.26172V8.73828H9.15507V7.26172H10.6668ZM7.64335 7.26172V8.73828H6.16679V7.26172H7.64335ZM4.65507 7.26172V8.73828H3.14335V7.26172H4.65507Z"
            fill="#999999"/>
    </svg>

;

