import React from 'react';
import {ReactionNode, ReactionNodeProps} from './ReactionNode';
import {VideoReaction} from '../models/reactions/VideoReaction';
import {inject, observer} from 'mobx-react';
import cn from './Reactions.module.scss';
import {ScriptPageStore} from "../stores/script-page.store";


interface VideoReactionViewProps extends ReactionNodeProps<VideoReaction> {
    scriptPageStore?: ScriptPageStore;
}

@inject('scriptPageStore')
@observer
export class VideoReactionView extends ReactionNode<VideoReaction, VideoReactionViewProps> {
    onClick(): void {
        this.props.scriptPageStore!.select(this.props.reaction);
    }

    onDelete(): void {
        this.props.reaction.removeSelf();

        this.props.scriptPageStore!.select();
    }

    renderBody(): React.ReactNode {
        return <div className={cn.media}>
            <video controls preload={'meta'}>
                <source src={this.props.reaction.url}/>
            </video>
        </div>
    }

}
