import {inject, observer} from 'mobx-react';
import {BaseStaticTriggerView, BaseStaticTriggerViewProps} from './BaseStaticTriggerView';
import {WithTranslation, withTranslation} from 'react-i18next';
import {ScriptPageStore} from "../stores/script-page.store";
import {StartTrigger} from "../models/triggers/StartTrigger";

interface StartTriggerViewProps extends BaseStaticTriggerViewProps<StartTrigger>, WithTranslation {
    scriptPageStore?: ScriptPageStore;
}

@inject('scriptPageStore', 'user')
@observer
export class StartTriggerViewComp extends BaseStaticTriggerView<StartTrigger, StartTriggerViewProps> {
    body: string = this.props.t('flows.triggers.start');
}

export const StartTriggerView = withTranslation()(StartTriggerViewComp);
