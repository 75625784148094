type UserLike = {
    email: string;
    firstName: string;
    lastName: string;
}

export const getUserName = (user: UserLike) => {
    const authorName = `${user.firstName || ''} ${user.lastName || ''}`.trim();
    return authorName || user.email;
};
