import React from 'react';
import cn from './ChatMessageMedia.module.scss';

interface ChatMessageImageProps {
    url: string;
    caption: string;
}
export class ChatMessageImage extends React.Component<ChatMessageImageProps> {
    render() {
        return <div>
            <img width={300} src={this.props.url}/>
            <div className={cn.caption}>{this.props.caption}</div>
        </div>;
    }
}
