import React from 'react';
import { useTranslation,  } from 'react-i18next';

import cn from './NeedAccess.module.scss';
import { MinimalPage } from '@/common/minimal-page/MinimalPage';

export const NeedAccess = () => {
    const { t } = useTranslation();
    return (
        <MinimalPage withHeader={true}>
            <div className={cn.needAccess}>
                <h1 className={cn.title}>
                    {t('need_access.title')}
                </h1>
                <div className={cn.text}>

                </div>
            </div>
        </MinimalPage>
    );
};
