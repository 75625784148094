import React from 'react';
import { Input, Switch } from 'antd';
import cnCommon from '@/common/scss/form.module.scss';
import { makeObservable, observable } from 'mobx';
import { observer } from 'mobx-react';
import { float, int } from '@/common/validators/numbers';
import { WithTranslation, withTranslation } from 'react-i18next';

interface EntityLocationProps extends WithTranslation {
    onChange: (modelToSave: EntityLocationSavedModel) => void;
    modelToSave: EntityLocationSavedModel;
    canEdit: boolean;
}

interface EntityLocationSavedModel {
    stopwords: string[];
    center: { lat: number; lng: number; };
    radius: number;
    must_be_in_circle: boolean;
}

@observer
export class EntityLocationComp extends React.PureComponent<EntityLocationProps> {
    @observable output: EntityLocationSavedModel = {
        stopwords: [],
        center: {
            lat: null,
            lng: null
        },
        radius: null,
        must_be_in_circle: false
    };

    constructor(props: EntityLocationProps) {
        super(props);
        makeObservable(this);
        this.onChangeProps();
    }

    UNSAFE_componentWillReceiveProps(props: EntityLocationProps) {
        this.onChangeProps(props);
    }

    onChangeProps(props?: EntityLocationProps) {
        if (!props) {
            props = this.props;
        }

        if (props.modelToSave) {
            this.output = Object.assign(this.output, props.modelToSave);
        }
    }

    onChange = () => {
        this.props.onChange(this.output);
    };

    render() {
        return <div>
            <div className={cnCommon.formField}>
                <label className={cnCommon.formFieldLabel}
                       htmlFor="entity-stopwords">{this.props.t('entities.location.stopwords')}</label>
                <Input disabled={!this.props.canEdit} id="entity-stopwords" value={this.output.stopwords}
                       onChange={e => {
                           this.output.stopwords = e.target.value.split(',');
                           this.onChange();
                       }}/>
            </div>

            <div className={cnCommon.formField}>
                <label className={cnCommon.formFieldLabel}>{this.props.t('entities.location.center')}</label>
                <Input disabled={!this.props.canEdit} className={cnCommon.formFieldLeftEquals}
                       value={this.output.center.lat} onChange={e => {
                    if (!float(e.target.value)) return;
                    this.output.center.lat = +e.target.value;
                    this.onChange();
                }}/>
                <Input disabled={!this.props.canEdit} value={this.output.center.lng} onChange={e => {
                    if (!float(e.target.value)) return;
                    this.output.center.lng = +e.target.value;
                    this.onChange();
                }}/>
            </div>

            <div className={cnCommon.formField}>
                <label className={cnCommon.formFieldLabel}
                       htmlFor="entity-radius">{this.props.t('entities.location.radius')}</label>
                <Input disabled={!this.props.canEdit} id="entity-radius" value={this.output.radius} onChange={e => {
                    if (!int(e.target.value)) return;
                    this.output.radius = +e.target.value;
                    this.onChange();
                }}/>
            </div>

            <div className={cnCommon.formField}>
                <label className={cnCommon.formFieldLabel}
                       htmlFor="entity-circle-radius">{this.props.t('entities.location.must_be_in_circle')}</label>
                <Switch disabled={!this.props.canEdit} checked={this.output.must_be_in_circle}
                        onChange={e => {
                            this.output.must_be_in_circle = e;
                            this.onChange();
                        }}/>
            </div>
        </div>
    }
}

export const EntityLocation = withTranslation()(EntityLocationComp);
