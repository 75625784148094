import React from 'react';
import { ReactionNode, ReactionNodeProps } from './ReactionNode';
import { RepeatReaction } from '../models/reactions/RepeatReaction';
import { inject, observer } from 'mobx-react';
import { action } from 'mobx';
import { ScriptPageStore } from '../stores/script-page.store';
import { withTranslation, WithTranslation } from 'react-i18next';

type RepeatReactionViewProps = ReactionNodeProps<RepeatReaction> & WithTranslation & {
    scriptPageStore?: ScriptPageStore;
}

@inject('scriptPageStore')
@observer
class RepeatReactionViewComp extends ReactionNode<RepeatReaction, RepeatReactionViewProps> {
    @action.bound
    onClick = () => {
        this.props.scriptPageStore!.select(this.props.reaction);
    }

    @action.bound
    onDelete = () => {
        this.props.reaction.removeSelf();
    }

    renderBody(): React.ReactNode {
        return this.props.t('flows.reactions_body.repeat');
    }
}

export const RepeatReactionView = withTranslation()(RepeatReactionViewComp);


