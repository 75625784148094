import React, { useCallback } from 'react';
import cns from 'classnames';
import CloseIcon from 'mdi-react/CloseIcon';
import cn from './Node.module.scss';
import { DropItem } from '../models/DropItem';
import { useTranslation } from 'react-i18next';
import { triggerTypes } from '../models/triggers/TriggerTypes';
import { reactionTypes } from '../models/reactions/ReactionTypes';
import { DropZone } from './DropZone';
import { inject } from 'mobx-react';
import { UserStore } from '@/core/stores/user.store';
import { groups } from '@/app/scripts/components/IntentsSelector';

type NodeType = 'state' | 'trigger' | 'reaction';

interface NodeProps {
    title: string;
    children: React.ReactNode;
    type: NodeType;
    deletable?: boolean;
    dragable?: boolean;
    onDelete?: () => void;
    onDrop?: (item: DropItem) => void;
    accept?: string[];
    onDropRow?: (item: DropItem) => void;
    onClick?: () => void;
}

const iconColor: Record<NodeType, string> = {
    ['reaction']: '#999',
    ['trigger']: 'white',
    ['state']: 'white',
};

const defaultAcceptForTypes: Record<NodeType, string[]> = {
    'state': [],
    'trigger': [...triggerTypes, ...reactionTypes],
    'reaction': [...triggerTypes, ...reactionTypes],
};

interface SmartNodeProps extends NodeProps {
    user?: UserStore;
}

@inject('user')
export class SmartNode extends React.PureComponent<SmartNodeProps> {
    render() {
        return <Node deletable={this.props.user!.permissions.isEditScripts} {...this.props} />
    }
}

export function translateTitle(title: string, type: string) {
    const [name, value] = title.split(' ');
    if (type === 'trigger') {
        const t = groups.triggers.find(t => t.type.replace('Trigger', '') === name)?.name ?? name;
        return {title: t, value};
    } else if (type === 'state') {
        return {title};
    } else if (type === 'reaction') {
        const t = groups.reactions.find(t => t.type.replace('Reaction', '') === name)?.name ?? name;
        return {title: t, value};
    }
}

export const Node = ({deletable = true, title, onDelete, children, type, onClick, onDrop, accept}: NodeProps) => {
    const {t} = useTranslation();
    const _onDelete = useCallback((e: React.MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();

        onDelete && onDelete();
    }, [onDelete]);

    const nodeTitle = translateTitle(title, type);

    return <>
        <div className={cns(cn.node, {
            [cn.trigger]: type === 'trigger' || type === 'state',
            [cn.reaction]: type === 'reaction',
        })} onClick={onClick}>
            <div className={cn.header}>
                <div className={cn.title}>{t(nodeTitle?.title)} {nodeTitle.value ?? ''}</div>
                {
                    deletable &&
                    <div className={cn.delete} onClick={_onDelete}>
                        <CloseIcon size={18} color={iconColor[type]}/>
                    </div>
                }
            </div>
            <div className={cn.content}>
                {children}
            </div>
        </div>
        {onDrop && <DropZone onDrop={onDrop} accept={accept || defaultAcceptForTypes[type]}/>}
    </>
};

