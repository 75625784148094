import React from 'react';
import { ReactionNode, ReactionNodeProps } from './ReactionNode';
import { LocationReaction } from '../models/reactions/LocationReaction';
import { inject, observer } from 'mobx-react';
import cn from './Reactions.module.scss';
import { GoogleMap, withGoogleMap, withScriptjs } from "react-google-maps"
import {ScriptPageStore} from "../stores/script-page.store";

interface LocationReactionViewProps extends ReactionNodeProps<LocationReaction> {
    scriptPageStore?: ScriptPageStore;
}

const MapComponent = withScriptjs(withGoogleMap((props: { lat: number, long: number}) =>
    // @ts-ignore
    <GoogleMap
        defaultZoom={14}
        defaultCenter={{ lat: 55.7496162, lng: 37.6110457 }}
        center={{ lat: props.lat || 55.7496162, lng: props.long || 37.6110457}}
    >
    </GoogleMap>
));

@inject('scriptPageStore')
@observer
export class LocationReactionView extends ReactionNode<LocationReaction, LocationReactionViewProps> {
    onClick(): void {
        this.props.scriptPageStore!.select(this.props.reaction);
    }

    onDelete(): void {
        this.props.reaction.removeSelf();

        this.props.scriptPageStore!.select();
    }

    renderBody(): React.ReactNode {
        return <div className={cn.map}>
            {/*
// @ts-ignore */}
            <MapComponent lat={this.props.reaction.lat!} long={this.props.reaction.lon!}
                          googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyDsBf7YA2vW-BCNpvZO6s_j__ntPoPrzZE&v=3"
                          loadingElement={<div style={{ height: `100%` }} />}
                          containerElement={<div style={{ height: `400px`, width: '75%' }} />}
                          mapElement={<div style={{ height: `100%` }} />}/>
        </div>
    }

}
