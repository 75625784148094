import React from 'react';
import { ReactionNode, ReactionNodeProps } from './ReactionNode';
import { ResetReaction } from '../models/reactions/ResetReaction';
import { inject, observer } from 'mobx-react';
import { action } from 'mobx';
import { ScriptPageStore } from '../stores/script-page.store';
import { WithTranslation, withTranslation } from 'react-i18next';

type ResetReactionViewProps = ReactionNodeProps<ResetReaction> & WithTranslation & {
    scriptPageStore?: ScriptPageStore;
}

@inject('scriptPageStore')
@observer
export class ResetReactionViewComp extends ReactionNode<ResetReaction, ResetReactionViewProps> {
    @action.bound
    onClick = () => {
        this.props.scriptPageStore!.select(this.props.reaction);
    }

    @action.bound
    onDelete = () => {
        this.props.reaction.removeSelf();
    }

    renderBody(): React.ReactNode {
        return this.props.t('flows.reactions_body.reset');
    }
}

export const ResetReactionView = withTranslation()(ResetReactionViewComp);
