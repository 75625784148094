import { makeObservable, observable, reaction } from 'mobx';
import { Filter } from '@/app/stats/AnalyticsFilter/AnalyticsFilter.component';
import { BaseAnalyticsApi } from '@/app/stats/BaseAnalytics/api/baseAnalyticsApi';
import { AnalyticsData, TableColumn, TableData, Version } from '@/app/stats/Types';
import { getChartData, getTableData, getVersions } from '@/app/stats/BaseAnalytics/helpers';
import { notification } from 'antd';
import { ProjectStore } from '@/core/stores/project.store';
import { cloneDeep } from 'lodash-es';

export type ChartData = {
    chartData: Record<string, string | number>[];
    keys: string[];
    selected: Record<string, { selected: boolean }>;
    max: number;
    maxByKey: Record<string, number>;
    labels: Record<number, Record<string, Record<string, string>>>;
};

export class BaseAnalyticsStore {
    @observable relativeChart: ChartData;
    @observable absoluteChart: ChartData;
    @observable tableData: TableData[] = [];
    @observable columns: TableColumn[] = [];
    @observable currentFilter: Filter = {
        periodKey: 'day',
        period: 7
    };
    @observable isLoading: boolean = null;
    @observable isEmpty: boolean = true;
    @observable keys: string[] = [];
    @observable selectedRowKeys: string[] = [];
    @observable versions: Version[] = [];

    constructor(private projectStore: ProjectStore) {
        makeObservable(this);

        reaction(() => this.currentFilter, () => this.updateAnalytics());
        reaction(() => this.projectStore.choosenProject.id, () => this.updateAnalytics());

        this.updateAnalytics();
    }

    setCurrentFilter(filter: Filter) {
        this.currentFilter = filter;
    }

    downloadCsv() {
        return BaseAnalyticsApi.downloadCsv(this.projectStore.choosenProject, this.currentFilter);
    }

    onChangeTable = (selectedRowKeys: string[]) => {
        this.selectedRowKeys = selectedRowKeys;
        const selectedRelative = this.relativeChart.selected;
        const selectedAbsolute = this.absoluteChart.selected;

        [selectedRelative, selectedAbsolute].forEach(selected => {
            Object.keys(selected).forEach(key => {
                selected[key].selected = false;
            })
        });

        selectedRowKeys.forEach(selectedKey => {
            if (selectedRelative[selectedKey]) selectedRelative[selectedKey].selected = true;
            if (selectedAbsolute[selectedKey]) selectedAbsolute[selectedKey].selected = true;
            this.relativeChart.selected = cloneDeep(selectedRelative);
            this.absoluteChart.selected = cloneDeep(selectedAbsolute);
        });

        if (selectedRowKeys.length === 0) {
            [selectedRelative, selectedAbsolute].forEach(selected => {
                Object.keys(selected).forEach(key => {
                    selected[key].selected = false;
                })
            });
            this.relativeChart.selected = cloneDeep(selectedRelative);
            this.absoluteChart.selected = cloneDeep(selectedAbsolute);
        }

        this.relativeChart.max = Math.max(...selectedRowKeys.map(key => this.relativeChart.maxByKey[key]));
        this.absoluteChart.max = Math.max(...selectedRowKeys.map(key => this.absoluteChart.maxByKey[key]));
    };

    updateAnalytics() {
        this.isLoading = true;
        BaseAnalyticsApi.getVersions(this.projectStore.choosenProject, {
            period: this.currentFilter.period,
            periodKey: this.currentFilter.periodKey
        }).then(response => {
            this.versions = getVersions(response)
        });

        BaseAnalyticsApi.updateAnalytics(this.projectStore.choosenProject, this.currentFilter).then((response: AnalyticsData) => {
            if (!response || !response.points) {
                this.isEmpty = true;
                this.isLoading = false;
                return;
            }

            const automated = response.elements.filter(element => element.relative);
            const absolute = response.elements.filter(element => !element.relative);

            const {
                tableColumns,
                tableData,
                defaultSelected
            } = getTableData(response.points, response.elements, response.searchKey);


            this.absoluteChart = getChartData(response.points, absolute, response.searchKey);

            this.relativeChart = getChartData(response.points, automated, response.searchKey);

            this.columns = tableColumns;
            this.isEmpty = false;
            this.isLoading = false;
            this.selectedRowKeys = defaultSelected;
            this.tableData = tableData;
        }).catch((reason) => {
            console.error(reason);
            notification.error({
                message: 'Ошибка получения данных',
                duration: 3
            })
            this.isLoading = false;
        })
    }
}
