import cn from './SidebarRight.module.scss';
import { PlusIcon } from '@/common/svg-icons/PlusIcon';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Tracker } from '@/core/analytics/tracker';
import { Link } from 'react-router-dom/dist';

type SidebarAddButtonComponentProps = {
    children?: React.ReactNode
    analyticName: string;
    routeToNewItem: string;
}

export const SidebarAddButton: FC<SidebarAddButtonComponentProps> = ({children, analyticName, routeToNewItem}) => {
    const { t } = useTranslation();
    return <Link onClick={() => Tracker.trackEvent('Add', {Object: analyticName})} to={routeToNewItem}>
        <div className={cn.addGroup}>
            <PlusIcon className={cn.addGroupPlus}/>
            {t('actions.add')}
            {children}
            {/*{this.currentGroup && this.currentGroup.exportable && this.renderImportExport()}*/}
        </div>
    </Link>
}

// export const SidebarAddButton = withTranslation()(SidebarAddButtonComponent);
