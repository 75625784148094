import React from 'react';
import CloseIcon from 'mdi-react/CloseIcon';
import { Trigger } from '../models/triggers/Trigger';
import { action } from 'mobx';
import cn from './BaseTriggerView.module.scss';
import {UserStore} from "@/core/stores/user.store";

interface Selectable<T extends Trigger> {
    select(node?: T): void;
}

export interface BaseStaticTriggerViewProps<T extends Trigger> {
    trigger: T;
    scriptPageStore?: Selectable<T>;
    user?: UserStore;
}


export abstract class BaseStaticTriggerView<T extends Trigger, P extends BaseStaticTriggerViewProps<T>> extends React.Component<P> {
    abstract body: string;

    render() {
        return <div className={cn.node} onClick={this.onClick}
                    style={{ cursor: 'pointer' }}>
            {
                this.props.user!.permissions.isEditScripts &&
                <div className={cn.delete} onClick={this.onRemove}>
                    <CloseIcon size={18} color={'white'}/>
                </div>
            }
            {this.body}
        </div>;
    }

    @action.bound
    onClick = () => {
        if (!this.props.scriptPageStore) {
            throw Error('Please inject FlowStore (@inject(\'flow\')) on children class, before use BaseStaticTriggerViewComp')
        }
        this.props.scriptPageStore!.select(this.props.trigger);
    }

    @action.bound
    onRemove = (e: React.MouseEvent) => {
        e.stopPropagation();

        this.props.trigger.removeSelf();
    }
}
