export const ButtonTypes = ["text", "url", "intent", "send_location", "send_email", "send_phone"];

export type TextButtonParams = {
    text: string;
};

export type UrlButtonParas = {
    url: string;
}

export type IntentButtonParams = {
    text: string;
    intentId: number;
    intent_id: number;
}

type ButtonParams = TextButtonParams | UrlButtonParas | IntentButtonParams;

export abstract class Button {
    protected constructor(public name: string, public type: string, public title: string, public params: ButtonParams) {
    }
}

export class TextButton extends Button {
    constructor(name: string, title: string, text: string) {
        super(name, 'text', title, {text});
    }
}

export class UrlButton extends Button {
    constructor(name: string, title: string, url: string) {
        super(name, 'url', title, {url});
    }
}

export class IntentButton extends Button {
    constructor(name: string, title: string, intent_id: number, text: string) {
        super(name, 'intent', title, {intent_id, text});
    }
}
