import { EntityStore } from '../../../entities/entity.store';

export class EntityMapper {
    constructor(private entityStore: EntityStore) {}

    async load(): Promise<boolean> {
        const groups = this.entityStore.groups;
        const entities = this.entityStore.entities;
        if (groups.length > 0 || entities.length > 0) return false;
        await this.entityStore.load();
        return true;
    }
}
