import qs from 'query-string';

import { API } from '@/common/api';
import { ActivityLogFilters, ActivityLogFiltersData, ActivityLogsResponse } from '@/common/models/activity-logs';
import { camelCaseKeys } from '@/common/utils/camel-case-keys';
import { stringsToDatesInObj } from '@/common/utils/strings-to-dates-in-obj';
import { snakeCaseKeys } from '@/common/utils/snake-case-keys';

export class ActivityLogsPageApi extends API {
    private static cleanFilter(flt: ActivityLogFilters): Partial<ActivityLogFilters> {
        const result: Partial<ActivityLogFilters> = {};

        // @ts-ignore
        Object.keys(flt).forEach((key: keyof ActivityLogFilters) => {
            if (flt[key]) {
                // ???: Типы
                // @ts-ignore
                result[key] = flt[key];
            }
        });

        return result;
    }

    private static processResponse = (resp: any): any => {
        return stringsToDatesInObj(camelCaseKeys(resp));
    }

    private static processRequestData(data: any): any {
        if (data.dateFrom) {
            data.dateFrom = data.dateFrom.toISOString();
        }

        if (data.dateTo) {
            data.dateTo = data.dateTo.toISOString();
        }

        if(data.projects) {
            data['project_ids'] = data['projects'];
            delete data['projects'];
        }

        if(data.projectVersions){
            data['project_version_ids'] = data['projectVersions'];
            delete data['projectVersions'];
        }

        return snakeCaseKeys(this.cleanFilter(data));
    }

    static getLogs(page: number, flt: ActivityLogFilters, itemsPerPage?: number): Promise<ActivityLogsResponse> {
        const data = Object.assign({}, flt, { page, per_page: itemsPerPage });
        return this.get<ActivityLogsResponse>(`/activity?${qs.stringify(this.processRequestData(data))}`)
            .then(this.processResponse);
    }

    static getLogsFilters(flt: ActivityLogFilters): Promise<ActivityLogFiltersData> {
        const data = Object.assign({}, flt);

        return this.get<ActivityLogFiltersData>(`/activity-filters?${qs.stringify(this.processRequestData(data))}`)
            .then(this.processResponse) ;
    }
}
