import React from 'react';

type TextareaProps = {
    id: string;
    isIndexToFocus: boolean;
    onRemoveFocus: () => void;
    value?: string;
    rows?: number;
    className?: string;
    placeholder?: string;
    onChange?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
}

class Textarea extends React.Component<TextareaProps> {
    state: { textarea: HTMLTextAreaElement } = {
        textarea: null
    }

    updateHeight (textarea: HTMLElement) {
        textarea.style.height = "auto";
        textarea.style.height = textarea.scrollHeight + "px";
    }

    componentDidMount() {
        const textarea = document.getElementById(this.props.id);
        if (textarea) {
            this.updateHeight(textarea);
            textarea.addEventListener("input", () => this.updateHeight(textarea));
        }
    }

    render() {
        const { id, isIndexToFocus, onRemoveFocus, ...rest} = this.props
        return (
            <textarea
                id={id} {...rest}
                ref={r => {
                if (isIndexToFocus && r) {
                    r.focus();
                    r.setSelectionRange(this.props.value.length, this.props.value.length);
                    onRemoveFocus();
                }
            }}
            />
        );
    }
}

export default Textarea
