import React from 'react';
import cn from './Params.module.scss';
import { action } from 'mobx';
import { observer } from 'mobx-react';
import { ButtonEditor } from './ButtonEditor';
import { WithTranslation, withTranslation } from 'react-i18next';
import { FileLikeReaction } from '../models/reactions/FileLikeReaction';
import { Input } from 'antd';
import { CopyId } from '@/app/scripts/components/CopyId/CopyId.component';

interface FileReactionParamsEditorProps extends WithTranslation {
    reaction: FileLikeReaction;
}

@observer
export class FileReactionParamsEditorComp extends React.Component<FileReactionParamsEditorProps> {
    @action.bound
    onCaptionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.props.reaction.caption = e.target.value.trim();
    }

    @action.bound
    onUrlChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.props.reaction.url = e.target.value.trim();
    }

    @action.bound
    onAddButton = () => {
        this.props.reaction.quickReplies.addButton({
            name: '',
            type: 'text',
            title: '',
            params: {
                text: ''
            },
        });
    }

    render() {
        const quickReplies = this.props.reaction.quickReplies;

        return <div className={cn.editor}>
            <div className={cn.group}>
                <h6 className={cn.groupTitle}>
                    {this.props.t('flows.reactions.file')}
                </h6>
                <Input onChange={this.onUrlChange} value={this.props.reaction.url}
                       placeholder={'URL'}
                       style={{marginBottom: 8}}/>
                <Input onChange={this.onCaptionChange}
                       value={this.props.reaction.caption}
                       placeholder={this.props.t('flows.caption')}/>
            </div>
            {quickReplies.buttons.length <= 5 && <button className={cn.addButton} onClick={this.onAddButton}>
                {this.props.t('flows.add_button')}
            </button>}
            <div className={cn.group}>
                <div className={cn.buttons}>
                    {quickReplies.buttons.map((button, i) => <ButtonEditor button={button} key={i}
                                                                           onRemove={() => quickReplies.deleteButton(button)}/>)}
                </div>
            </div>
                <CopyId id={this.props.reaction.id}/>
        </div>
    }
}

export const FileReactionParamsEditor = withTranslation()(FileReactionParamsEditorComp);
