import React, { ChangeEvent } from 'react';
import { action } from 'mobx';
import * as _ from 'lodash';
import cns from 'classnames';

import cn from './Select.module.scss';
import ChevronDownIcon from 'mdi-react/ChevronDownIcon';

interface SelectProps {
    label?: string;
    disabledFirst?: boolean;
    options: SelectOption[];
    value: any;
    invalid?: boolean;

    onChange(str: any): void;

    selectProps?: Record<string, any>;
}

export interface SelectOption {
    value: any;
    name?: string;
}

export class Select extends React.PureComponent<SelectProps> {
    @action.bound
    private onChange = (e: ChangeEvent<HTMLSelectElement>) => {
        e.preventDefault();

        this.props.onChange(e.target.value);
    }

    get options() {
        // return this.props.options;
        return _.uniqBy(this.props.options, o => o.value);
    }

    render() {
        const { className = '', ...selectProps } = this.props.selectProps || {};

        return <label className={cn.label}>
            <select onChange={this.onChange} value={this.props.value}
                    className={cns(cn.input, className, this.props.invalid && cn.inputInvalid)} {...(selectProps || {})}>
                {
                    this.props.label
                    && <option value=""
                               disabled={this.props.disabledFirst == null ? true : this.props.disabledFirst}>
                        {this.props.label}</option>}
                {
                    this.options.map(option => <option value={option.value}
                                                       key={option.value}>{option.name || option.value}</option>)
                }
            </select>
            <ChevronDownIcon className={cn.chevron} />
        </label>;
    }
}
