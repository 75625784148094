import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';

import classnames from 'classnames';

import { makeObservable, observable } from 'mobx';
import { observer } from 'mobx-react';

import cn from '@/common/components/chat/chat.module.scss';
import { TooltipPopper, TTooltipPopperFnParams } from '../TooltipPopper';


type FactsModalProps = {
    onChangeFact: (text: string) => void;
    hasError: boolean;
    onSave: () => void;
    onClose: () => void;
    value: any;
} & WithTranslation;

const tooltipProps = { className: cn.tooltip };

@observer
export class FactsModalComp extends React.Component<FactsModalProps> {
    @observable isOpenFacts = false;
    @observable factsJson: string;
    @observable isJsonError = false;

    constructor(props: FactsModalProps) {
        super(props);
        makeObservable(this);
    }

    onVisibilityChange = (visibility: boolean) => {
        this.isOpenFacts = visibility;
        if (!visibility) {
            this.props.onClose();
        }
    };

    onChangeFact = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        this.props.onChangeFact(e.target.value);
    };

    onSave() {
        this.isOpenFacts = false;
        this.props.onSave();
    }

    renderFacts() {
        return <div className={cn.facts}>
            <div className={cn.factsTitle}>{ this.props.t('chat.global_facts') }</div>
            <div className={cn.factsText}>
                <textarea value={this.props.value} onChange={this.onChangeFact} className={classnames(cn.factsTextArea, this.props.hasError && cn.factsTextAreaHasError)}/>
            </div>
            <div className={cn.factsActions}>
                {this.props.hasError && <div className={cn.errorMsg}>{this.props.t('chat.invalid_json')}</div>}
                {/*{ this.isJsonError }*/}
                <button disabled={this.props.hasError} className={classnames('btn btn-primary', cn.factsSave)}
                        onClick={() => this.onSave()}> { this.props.t('actions.save') }
                </button>
            </div>
        </div>;
    }

    renderDropDown = ({
                          getTooltipProps,
                          setTooltipRef
                      }: TTooltipPopperFnParams) => {
        return <div
            ref={setTooltipRef}
            {...getTooltipProps(tooltipProps)}
        >
            {this.renderFacts()}
        </div>
    };

    render() {
        return <TooltipPopper
            placement="bottom-start"
            trigger="click"
            closeOnOutsideClick={true}
            onVisibilityChange={this.onVisibilityChange}
            tooltipShown={this.isOpenFacts}
            tooltip={this.renderDropDown}
            triggerContent={this.props.t('chat.add_global_facts')}
			offset={[150, 8]}
        />;
    }
}

export const FactsModal = withTranslation()(FactsModalComp);

