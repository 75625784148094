import { BaseStaticTriggerView, BaseStaticTriggerViewProps } from './BaseStaticTriggerView';
import { inject } from 'mobx-react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { UserSendTrigger } from '../models/triggers/UserSendTrigger';
import { computed, makeObservable } from 'mobx';

interface FileLikeTriggerViewComponentProps extends BaseStaticTriggerViewProps<UserSendTrigger>, WithTranslation {
}


@inject('scriptPageStore', 'user')
class FileLikeTriggerViewComponent extends BaseStaticTriggerView<UserSendTrigger, FileLikeTriggerViewComponentProps> {
    @computed get type() {
        //TODO: fix it
        return this.props.trigger.type.replace('Trigger', '').toLowerCase();
    }

    constructor(props: FileLikeTriggerViewComponentProps) {
        super(props);
        makeObservable(this);
    }

    body: string = `${this.props.t('flows.user_send')} ${this.props.t('flows.triggers.' + this.type)}`;
}

export const FileLikeTriggerView = withTranslation()(FileLikeTriggerViewComponent);
