import { API } from '@/common/api';
import { Company } from './models/company';

export class CompanyAPI extends API {
    static getCompany(): Promise<Company> {
        return CompanyAPI.get('/company');
    }

    static saveCompany(company: Company): Promise<Company> {
        return CompanyAPI.patch(`/company`, company);
    }
}
