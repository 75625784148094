import { Node } from '../Node';
import { State } from '../State';
import { Reaction } from '../reactions/Reaction';
import { v4 } from 'uuid';

export class Trigger extends Node {
    parent: State | null = null;
    next: State | Reaction | null = null;
    id = v4();
    static className = 'Trigger';

    removeSelf(): void {
        if (this.parent) {
            this.parent.removeTrigger(this);
            this.parent = null;
        }
    }
}
