import React, { KeyboardEvent } from 'react';
import { inject, observer } from 'mobx-react';
import { action, makeObservable, observable, reaction, runInAction } from 'mobx';
import cn from './chat.module.scss';

import SmileIcon from 'mdi-react/SmileyHappyOutlineIcon';
import TextareaAutosize from 'react-autosize-textarea';
import { ChatSmile } from './ChatSmile';
import { SendMessage } from './SendMessageIcon';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Tracker } from '@/core/analytics/tracker';
import { FactsModal } from '@/common/components/chat/FactsModal';
import * as LocalForage from 'localforage';
import { SnippetStore } from '@/app/snippets/snippet.store';
import { ChatIntent } from '@/common/components/chat/ChatIntent';

interface ChatInputProps extends WithTranslation {
    text: string;
    placeholder?: string;
    onSend: (text: string) => void;
    reset: () => void;
    start: () => void;
    facts: (text: string) => void;
    typing: boolean;
    lastMessages: string[];
    replaceMessage: (index: number) => void;
    pushLastMessage: (text: string) => void;
    snippetStore?: SnippetStore;
    sendFirstIntent: (selected: any, facts: any) => void;
}

@inject('snippetStore')
@observer
export class ChatInputComp extends React.Component<ChatInputProps> {
    @observable text: string = '';
    @observable factsJsonText: string = '';
    @observable factsInvalidJson = false;
    lastMessage: string = '';
    lastMessageIndex: number = -10;
    currentCursor = 0;
    currentDirection: 'UP' | 'DOWN' | null = null;

    constructor(props: ChatInputProps) {
        super(props);
        makeObservable(this);
        this.text = props.text || '';
        this.currentCursor = this.props.lastMessages.length - 1;
        reaction(() => this.props.lastMessages.length, () => {
            this.currentCursor = this.props.lastMessages.length - 1;
        });
    }

    componentDidMount(): void {
        this.fetchFacts();
    }

    fetchFacts(): void {
        this.props.snippetStore.getFacts().then(result => {
            runInAction(() => {
                this.factsJsonText = result || '';
                this.factsInvalidJson = false;
            })
        })
    }

    @action.bound
    onSend() {
        if (!this.isDisabledSendMessage) {
            this.props.onSend(this.text);
            this.text = '';
        }
    }

    @action.bound
    onKeyDown(event: KeyboardEvent<HTMLTextAreaElement>) {
        if (event.key === 'ArrowUp') {
            event.preventDefault();
            if (this.currentDirection === 'DOWN') {
                this.currentCursor--;
            }
            const lastMessage = this.props.lastMessages[this.currentCursor];
            this.lastMessageIndex = this.currentCursor;
            if (this.currentCursor > 0) {
                this.currentCursor--;
            }
            this.text = lastMessage;
            this.lastMessage = lastMessage;
            this.currentDirection = 'UP';
            return;
        }
        if (event.key === 'ArrowDown') {
            event.preventDefault();
            if (this.currentDirection === 'UP' && this.currentCursor !== 0) {
                this.currentCursor++;
            }
            this.currentDirection = 'DOWN';
            if (this.currentCursor < this.props.lastMessages.length - 1) {
                this.currentCursor++;
            }
            const lastMessage = this.props.lastMessages[this.currentCursor];
            this.text = lastMessage;
            this.lastMessage = lastMessage;
            this.lastMessageIndex = this.currentCursor;
            return;
        }

        if (event.key === 'Enter' && !event.ctrlKey && !event.shiftKey) {
            if (this.text === this.lastMessage) {
                this.props.replaceMessage(this.lastMessageIndex);
                this.currentCursor = this.props.lastMessages.length - 1;
            } else {
                this.props.pushLastMessage(this.text);
                this.currentCursor = this.currentCursor + 1;
            }
            this.currentDirection = null;
            event.preventDefault();
            this.onSend();
        }
    }

    @action.bound
    onSelectSmile(data: any) {
        if (data.unified.length <= 0) {
            this.text += String.fromCodePoint(+`0x${data.unified}`);
        } else {
            const sum = data.unified.split('-');
            const codesArray: number[] = [];
            sum.forEach((el: string) => codesArray.push(+`0x${el}`));
            this.text += String.fromCodePoint(...codesArray);
        }
    }

    @action.bound
    reset() {
        Tracker.trackEvent('Dialog', {Type: 'Reset'});
        this.props.reset();
    }

    @action.bound
    start() {
        Tracker.trackEvent('Dialog', {Type: 'Start'});
        this.props.start();
    }

    @action.bound
    facts() {
        Tracker.trackEvent('Dialog', {Type: 'Facts'});
    }

    onChangeFact = (text: string) => {
        try {
            const facts = JSON.parse(text);
            this.factsJsonText = JSON.stringify(facts, null, '  ');
            this.factsInvalidJson = false;
        } catch (e) {
            this.factsInvalidJson = true;
            this.factsJsonText = text;
        }
    };

    @action.bound
    onSaveFact() {
        LocalForage.setItem('facts', this.factsJsonText);
    }

    @action.bound
    onCloseFacts() {
        this.fetchFacts();
    }

    @action.bound
    onSelect(selected: any) {
        this.props.sendFirstIntent(selected, this.factsJsonText);
    }

    get isDisabledSendMessage() {
        return !this.text || this.text.length <= 0;
    }

    render() {
        return <div className={cn.chatInput}>
            <div className={cn.actions}>
                <div title={this.props.t('chat.trigger_action')} className={cn.actionsDescription}>
                    <ChatIntent onSelect={this.onSelect}/>
                </div>
                <div title={this.props.t('chat.add_global_facts')} className={cn.actionsDescription}>
                    <FactsModal
                        value={this.factsJsonText}
                        onSave={this.onSaveFact}
                        onClose={this.onCloseFacts}
                        hasError={this.factsInvalidJson}
                        onChangeFact={this.onChangeFact}
                    />
                </div>
                <div title={this.props.t('chat.start')} onClick={this.start}
                     className={cn.actionsDescription}> {this.props.t('chat.start')} </div>
                <div title={this.props.t('chat.reset_dialog')} onClick={this.reset}
                     className={cn.actionsDescription}> {this.props.t('chat.reset_dialog')} </div>
            </div>
            <div className={cn.chatInputs}>
                <div className={cn.inputWrapper}>
                    <TextareaAutosize async={true}
                                      value={this.text}
                                      className={cn.input}
                                      onKeyDown={this.onKeyDown} onChange={(e: any) => {
                        this.text = e.target.value;
                    }} autoFocus
                                      placeholder={this.props.t('chat.write_a_reply')} maxRows={8}/>
                    {/*
// @ts-ignore */}
                    <ChatSmile onSelectSmile={this.onSelectSmile}>
                        <SmileIcon className={cn.emojiPicker}/>
                    </ChatSmile>
                </div>
                <SendMessage onClick={this.onSend} className={this.isDisabledSendMessage ? cn.disabledSendMessage : ''}/>
            </div>
        </div>
    }
}

export const ChatInput = withTranslation()(ChatInputComp);

{/*<MicrophoneIcon className={cn.voice}/>*/
}
