import { action, IObservableArray, makeObservable, observable } from 'mobx';
import * as LocalForage from 'localforage';
import isEqual from 'lodash-es/isEqual'

import { ChatUser } from '@/common/models/ChatUser';
import { LogsApi } from './logs.api';
import { Project } from '@/common/models/project';
import { LogFilterModel } from '@/common/components/logs/models/log-filter-model';
import _ from 'lodash';
import dayjs, { Dayjs } from 'dayjs';
import { notification } from 'antd';

export class LogsPageStore {
    @observable fetchingMessages = false;
    private static readonly FILTER_KEY: string = 'LogsPageStore::filter';
    private static readonly defaultFilter: LogFilterModel = {
        isFails: false,
        text: '',
        channels: [],
        versions: [],
        range: [dayjs().startOf('day').add(-14, 'day'), dayjs().endOf('day')]
    };

    paginationEndDate = LogsPageStore.defaultFilter.range[1];

    private static saveFilter(filter: LogFilterModel) {
        LocalForage.setItem<LogFilterModel>(LogsPageStore.FILTER_KEY, filter);
    }

    private static async getSavedFilter(): Promise<LogFilterModel> {
        const localFilter = await LocalForage.getItem<LogFilterModel>(LogsPageStore.FILTER_KEY);
        if (localFilter && localFilter.range ) {
            localFilter.range = localFilter.range.map(d => dayjs(d)) as [Dayjs, Dayjs];
            return localFilter;
        } else {
            return null;
        }
    }

    static resetSavedFilter() {
        LocalForage.removeItem(LogsPageStore.FILTER_KEY);
    }

    @observable users: IObservableArray<ChatUser>;
    @observable filter: LogFilterModel = null;
    @observable isLoadingUsers = false;

    private fetchingFilters: Promise<void>;
    constructor(private project: Project) {
        this.users = observable([]);
        this.fetchingFilters = LogsPageStore.getSavedFilter()
            .then(saved => {
                if (!saved) {
                    this.filter = LogsPageStore.defaultFilter;
                    return;
                }
                saved.range = [dayjs(saved.range[0]), dayjs(saved.range[1])]
                this.filter = saved;
                this.paginationEndDate = this.filter.range[1];
            });

        makeObservable(this);
    }

    @action
    updateFilter(filter: Partial<LogFilterModel>) {
        this.filter = Object.assign({}, this.filter, filter);

        // this.filter.startDate = this.filter.startDate.startOf('day');
        // this.filter.endDate = this.filter.endDate.startOf('day');

        if (isEqual(this.filter, LogsPageStore.defaultFilter)) {
            LogsPageStore.resetSavedFilter();
        } else {
            LogsPageStore.saveFilter(this.filter);
        }
    }

    @action
    updatePagination(endDate: Dayjs) {
        this.paginationEndDate = endDate;
    }

    @action
    resetFilter() {
        this.updateFilter(LogsPageStore.defaultFilter);
        this.fetchUsers();
    }

    @action
    async fetchUsers(filter?: LogFilterModel) {
        try {
            this.isLoadingUsers = true;
            await this.fetchingFilters;
            const users = await LogsApi.getUsers(this.project, filter || this.filter);
            this.isLoadingUsers = false;
            this.replaceUsers(users);
        } catch (e) {
            this.isLoadingUsers = false;
            notification.error({
                message: 'Ошибка загрузки данных.\n' +
                    'Если повторяется, обратись в поддержку.',
                duration: 3
            });
        }
    }

    async paginationUsers(filter: LogFilterModel = this.filter) {
        const currentFilter = _.cloneDeep(filter);
        currentFilter.range[1] = this.paginationEndDate;
        const users = await LogsApi.getUsers(this.project, currentFilter);
        this.users.push(...users);
    }

    @action
    private replaceUsers(users: ChatUser[]) {
        this.users.replace(users);
    }
}
