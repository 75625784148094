import React, { useState } from 'react';
import cns from 'classnames';
import cn from './Reset.module.scss';
import cnLogin from '../login/Login.module.scss';
import cnLoginForm from '../login/LoginForm.module.scss';
import { AuthAPI } from '../../auth.api';
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation } from 'react-router-dom/dist';
import { Input, Button } from 'antd';
export const Reset = () => {
    const location = useLocation();
    const [email, onChangeEmail] = useState(
        location.state ? location.state.email : ''
    );
    const [errors, onChangeErrors] = useState<Record<string, string[]>>({});
    const { t } = useTranslation();
    const navigate = useNavigate();

    const onReset = async () => {
        try {
            await AuthAPI.reset({ email });
            onChangeErrors({});
            navigate('/auth/login');
        } catch (e) {
            onChangeErrors(Object.assign({}, e, { status_code: undefined }));
        }
    };

    return <div className="account">
        <div className={cnLogin.leftLiaBaloon}/>
        <div className={cnLogin.rightLiaBaloon}/>
        <div className={cnLogin.logo}/>
        <div className="account__wrapper">
            <div className="account__card card-body">
                <div className={cns("card__title", cn.title)}>
                    <h4>{t('auth.reset_your_password')}</h4>
                    <div className={cns(cn.subtitle)}>
                        { t('auth.enter_the_email') }
                    </div>
                </div>
                <form className="form" onSubmit={e => e.preventDefault()}>
                    <div className="form__form-group">
                        <div className="form__form-group-field">
                            <div className="form__form-group-input-wrap">
                                <Input
                                    size={'large'}
                                    name="name"
                                    value={email}
                                    onChange={e => onChangeEmail(e.target.value)}
                                    type="text"
                                    placeholder={ t('auth.email') }
                                />
                                {
                                    errors['email'] && errors['email'].map((error, key) =>
                                        <span className="form__form-group-error" key={key}>{t('auth.email_invalid')}</span>)
                                }
                                {
                                    errors['conflict'] && errors['conflict'].map((error, key) =>
                                        <span className="form__form-group-error" key={key}>{error}</span>)
                                }
                                {
                                    errors['user_not_found'] && errors['user_not_found'].map((error, key) =>
                                        <span className="form__form-group-error" key={key}>{t('auth.user_not_found')}</span>)
                                }
                            </div>
                        </div>
                    </div>

                    <div className={cns("account__btns", cnLoginForm.footer)}>
                        <Button size={'large'}
                                style={{ flex: 1 }}
                                onClick={onReset}>{ t('auth.send_recovery') }
                        </Button>
                        <Button
                            size={'large'}
                            style={{ flex: 1 }}
                            type={'primary'}
                            onClick={() => navigate(-1)}>
                            {t('actions.cancel')}
                        </Button>
                    </div>
                </form>
            </div>
        </div>
    </div>
};
