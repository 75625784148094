import React, { ChangeEvent, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { Checkbox, CircularProgress, FormControlLabel } from '@mui/material';
import { withStyles } from '@mui/styles';
import { Button, Modal } from 'reactstrap';
import cns from 'classnames';
import { Form, Input, Select, Space, Switch } from 'antd';
import cn from './TeamPageModal.module.scss';
import { TeamUser, TeamUserRole, UpdateCreateTeamUser } from '@/common/models/team-user';
import { Project } from '@/common/models/project';
import { animatedScrollTo } from '@/common/utils/animated-scroll-to';
import { LabeledValue } from 'antd/es/select';

type TeamPageModalProps = {
    user: TeamUser;
    roles: TeamUserRole[];
    projects: Project[];
    isLoading: boolean;
    errorMsg: string;
    lang: string;
    closeModal: () => void;
    onSubmit: (user: UpdateCreateTeamUser) => void;
    isAdmin: boolean;
}

const TeamPageSwitch = withStyles({
    root: {
        padding: 8,
        color: '#116FFC'
    }
})(Checkbox);

const createUpdateUserModel = (user: TeamUser, lang: string): UpdateCreateTeamUser => {
    return user ? {
        id: user.id,
        email: user.email,
        first_name: user.first_name,
        is_active: user.is_active,
        language: user.language,
        last_name: user.last_name,
        project_ids: user.project_ids.slice(),
        role: user.role
    } : {
        is_active: true,
        role: '',
        project_ids: [],
        email: '',
        first_name: '',
        last_name: '',
        language: lang
    };
};

export const TeamPageModal = (props: TeamPageModalProps) => {
    const { t } = useTranslation();
    const [isSubmitted, setIsSubmitted] = useState(false);
    const isActivated = props.user && props.user.is_activated;
    const [user, setUser] = useState(createUpdateUserModel(props.user, props.lang));
    const role = props.user?.role ? { value: props.user.role, label: t(`roles.${props.user.role}`) } : null;
    const [currentRole, setCurrentRole] = useState<LabeledValue>(role);
    const title = t(`team_page.modal.title_${props.user ? 'edit' : 'create'}`);
    const submitBtnLabel = t(props.user ? 'actions.update' : 'team_page.modal.submit');
    const selectRoles = props.roles
        .map(role => {
            const roleTrans = t(`roles.${role.key}`);
            return {
                value: role.key,
                label: roleTrans === `roles.${role.key}` ? role.name : roleTrans
            };
        });
    const isAllSelected = user.project_ids.length === props.projects.length;
    const rolesDescriptions: Record<string, string> = {
        admin: t('roles.descriptions.admin'),
        editor: t('roles.descriptions.editor'),
        developer: t('roles.descriptions.developer'),
        accessor: t('roles.descriptions.accessor'),
        billing: t('roles.descriptions.billing'),
        viewer: t('roles.descriptions.viewer')
    };

    const editUser = (data: Partial<UpdateCreateTeamUser>) => {
        setUser(Object.assign({}, user, data));
    };

    const changeRole = (e: LabeledValue) => {
        const role = e.value.toString();
        setCurrentRole(e);
        editUser({ role });
    };

    const toggleActive = () => editUser({ is_active: !user.is_active });
    const textChangeFactory = (field: keyof UpdateCreateTeamUser) => (e: ChangeEvent<HTMLInputElement>) => {
        editUser({ [field]: e.target.value });
    };
    const handleProjectSelect = (id: number) => (_: any, isChecked: boolean) => {
        const projectIds = isChecked ? [...user.project_ids, id] : user.project_ids.filter(pId => pId !== id);
        editUser({ project_ids: projectIds });
    };
    const closeModal = () => props.closeModal();
    const toggleAllSelected = (e: React.MouseEvent) => {
        e.preventDefault();
        const projectIds = isAllSelected ? [] : props.projects.map(p => p.id);
        editUser({ project_ids: projectIds });
    };
    const submit = () => {
        setIsSubmitted(true);
        if (!user.first_name || !user.last_name || !user.email || !user.role) {
            animatedScrollTo(0, 200, document.querySelector('.modal'));
            return;
        }
        props.onSubmit(user);
    }
    const renderIsActive = () => (
        <Space direction="horizontal">
            <Switch checked={user.is_active} onChange={toggleActive} />
            {t('team_page.modal.toggle_label')}
        </Space>
    );
    const renderRoleDesc = () => (
        <div className={cn.rolesInfo}>
            {rolesDescriptions[user.role]}
            <br />
            <a href="#">{t('team_page.modal.roles_info_link')}</a>
        </div>
    );
    const renderProject = (project: Project) => (
        <FormControlLabel
            className={cn.project}
            key={project.id}
            labelPlacement="start"
            control={<TeamPageSwitch
                color={'default'}
                checked={user.project_ids.includes(project.id)}
                onChange={handleProjectSelect(project.id)}
            />}
            label={project.name}
        />
    );

    const stopPropagation = (e: React.MouseEvent) => {
        e.stopPropagation();
    };


    return (
        <Modal isOpen={true} toggle={closeModal}
               contentClassName={cn.modalContent}
               backdropClassName={cn.modalBackdrop}
               className={cns('modal-dialog--primary', cn.modal)}
        >
            <div className={cn.generalInfo}>
                <h3 className={cn.blockTitle}>
                    {title}
                    {props.user && renderIsActive()}
                </h3>
                <div className={cns(cn.blockLine, cn.blockLineSpace)}>
                    <Input
                        className={cn.blockInput}
                        placeholder={t('team_page.modal.placeholder_first_name')}
                        value={user.first_name}
                        onChange={textChangeFactory('first_name')}
                        status={isSubmitted && !user.first_name && 'error'}
                    />
                    <Input
                        className={cn.blockInput}
                        placeholder={t('team_page.modal.placeholder_last_name')}
                        value={user.last_name}
                        onChange={textChangeFactory('last_name')}
                        status={isSubmitted && !user.last_name && 'error'}
                    />
                    <Input
                        className={cns(cn.blockInput, cn.blockInputEmail)}
                        placeholder={t('team_page.modal.placeholder_email')}
                        value={user.email}
                        onChange={textChangeFactory('email')}
                        status={isSubmitted && !user.email && 'error'}
                        disabled={isActivated}
                    />
                </div>
                { props.isAdmin &&
                    <div className={cn.blockLine}>
                        <div className={cn.blockInput}>
                            <Form.Item>
                                <Select
                                    onClick={stopPropagation}
                                    showSearch
                                    placeholder={t('team_page.modal.placeholder_roles')}
                                    optionFilterProp="children"
                                    notFoundContent={t('flows.not_found_button')}
                                    onChange={changeRole}
                                    labelInValue
                                    value={currentRole}
                                    filterOption={(input, option) => {
                                        return (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                    }}
                                    status={isSubmitted && !user.role && 'error'}
                                    options={selectRoles}/>
                            </Form.Item>
                        </div>
                        {user.role && renderRoleDesc()}
                    </div>
                }
            </div>
            <div className={cn.projectPermissions}>
                <h3 className={cn.blockTitle}>
                    {t('team_page.modal.permissions_title')}
                    <div className={cn.blockSubTitle}>{t('team_page.modal.permissions_subtitle')}</div>
                </h3>
                <div className={cn.controlsWrapper}>
                    <a href="#" onClick={toggleAllSelected}>
                        {t(`team_page.modal.${isAllSelected ? 'deselect' : 'select'}_all`)}
                    </a>
                </div>
                <div className={cn.projects}>
                    {props.projects.map(renderProject)}
                </div>
            </div>
            <div className={cn.controls}>
                <div className={cn.error}>{props.errorMsg}</div>
                <Button
                    outline
                    disabled={props.isLoading}
                    className={cn.cancelBtn}
                    onClick={closeModal}
                >{t('actions.cancel')}</Button>
                <Button
                    color="primary"
                    disabled={props.isLoading}
                    onClick={submit}
                    className={cn.submitBtn}
                >
                    {submitBtnLabel}
                    {props.isLoading && <CircularProgress size={24} className={cn.submitBtnLoader} />}
                </Button>
            </div>
        </Modal>
    );
};
