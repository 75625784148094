import React, { FC } from 'react';

import {
    BarChart,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend, ResponsiveContainer, ReferenceLine, Label, Bar
} from 'recharts';
import { SearchKey, Version } from '@/app/stats/Types';
import { VersionLabel } from '@/app/stats/VersionLabel/VersionLabel.component';
import { getColors } from '../common/colors';
import { kFormatter, xFormatter } from '../common/formatters';
import { sub } from 'date-fns';

const renderLabel = (props: any) => {
    return <VersionLabel offset={props.offset} x={props.viewBox.x} y={props.viewBox.y}
                         label={props.value}/>
}

export const StackedBarChart: FC<{
    data: any[],
    selected: Record<string, { selected: boolean }>,
    keys: { key: string; label: string; }[],
    max: number,
    versions: Version[],
    searchKey: SearchKey,
    labels: Record<number, Record<string, Record<string, string>>>
}> = ({data, keys, max, versions = [], searchKey, labels, selected}) => {
    const ticksX = data.map(key => key.number);
    const maxX = Math.max(...ticksX);
    const minX = Math.min(...ticksX);
    const fakeMinX = searchKey === 'day' ? sub(minX, {days: 1}).getTime() : searchKey === 'week' ? sub(minX, {weeks: 1}).getTime() : sub(minX, {months: 1}).getTime()
    return (
        <ResponsiveContainer width={'100%'} height={500}>
            <BarChart
                data={data}
                margin={{
                    top: 20,
                    right: 30,
                    left: 20,
                    bottom: 20
                }}
            >
                {versions.map(version => <ReferenceLine isFront strokeDasharray="3 3" stroke="#1677ff"
                                                        key={version.timestamp} x={version.timestamp}>

                    <Label width={50} content={renderLabel} value={version.project_id + ' ' +version.version_id}></Label>

                </ReferenceLine>)}
                <CartesianGrid stroke={'gray'} strokeDasharray="3 3"/>
                <XAxis ticks={ticksX} domain={[fakeMinX, maxX]}
                       tickFormatter={(tick: number) => xFormatter(tick, searchKey)} type="number" dataKey="number"/>
                <YAxis domain={[0, max]} tickFormatter={(tick: string) => kFormatter(tick)}/>
                <Tooltip formatter={(value: string, name: string, props: any) => {
                    return labels[props.payload.number][name][value] ?? value;
                }} labelFormatter={label => xFormatter(label, searchKey)}/>
                <Legend/>
                {keys
                    .filter(({key}) => selected[key].selected)
                    .map(({key, label}, i) => <Bar
                    key={key}
                    stackId={'a'}
                    dataKey={key}
                    fill={getColors(i)}
                    name={label}
                    hide={!selected[key].selected}
                />)}
            </BarChart>
        </ResponsiveContainer>
    );
}
