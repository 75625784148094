import { IntentStore } from '../../../intents/intent.store';

export class IntentMapper {
    constructor(private intentStore: IntentStore) {}

    async load(): Promise<boolean> {
        const groups = this.intentStore.groups;
        const intents = this.intentStore.intents;
        if (groups.length > 0 || intents.length > 0) return false;
        await this.intentStore.load();
        return true;
    }
}
