import { Trigger } from './Trigger';
import { observable, makeObservable } from 'mobx';

export class AllowOutTrigger extends Trigger {
    static className = 'AllowOutTrigger';
    @observable allow?: 'out' | 'qa' | 'flow';

    constructor() {
        super();
        makeObservable(this);
    }
}
