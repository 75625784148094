import React, { useCallback, ReactNode, MouseEventHandler } from 'react';
import ReactDOM from 'react-dom';

import cns from 'classnames';

import { usePopperTooltip } from 'react-popper-tooltip';
import type { Config } from 'react-popper-tooltip';

type TUsePopperTooltipValue = ReturnType<typeof usePopperTooltip>;

export type TTooltipPopperFnParams = {
    getArrowProps: TUsePopperTooltipValue['getArrowProps'];
    getTooltipProps: TUsePopperTooltipValue['getTooltipProps'];
    setTooltipRef: TUsePopperTooltipValue['setTooltipRef'];
};

type TTooltipPopperProps = {
    placement: Config['placement'];
    trigger: Config['trigger'];
    closeOnOutsideClick: Config['closeOnOutsideClick'];
    triggerContent: ReactNode;
    tooltip: (params: TTooltipPopperFnParams) => ReactNode;
    tooltipShown?: Config['visible'];
    onVisibilityChange?: Config['onVisibleChange'];
    defaultTooltipShown?: Config['defaultVisible'];
    triggerClassName?: string;
    onTriggerClick?: MouseEventHandler<HTMLSpanElement>;
    offset?: Config['offset'];
    interactive?: Config['interactive']
}

export const TooltipPopper: React.FC<TTooltipPopperProps> = ({
    placement,
    trigger,
    closeOnOutsideClick,
    onVisibilityChange,
    tooltipShown,
    defaultTooltipShown,
    triggerContent,
    triggerClassName,
    onTriggerClick,
    tooltip,
    offset = [0, 0],
    interactive = true
}) => {
    const {
        getArrowProps,
        getTooltipProps,
        setTooltipRef,
        setTriggerRef,
        visible,
    } = usePopperTooltip({
        placement,
        trigger,
        closeOnOutsideClick,
        onVisibleChange: onVisibilityChange,
        visible: tooltipShown,
        defaultVisible: defaultTooltipShown,
        offset,
        interactive
    });
    const renderTrigger = useCallback(() => (
        <span
            className={cns('trigger', 'tooltip-popper__trigger', triggerClassName)}
            ref={setTriggerRef}
            onClick={onTriggerClick}
        >{triggerContent}</span>
    ), [setTriggerRef, triggerContent, triggerClassName, onTriggerClick]);

    return (
        <>
            {renderTrigger()}
            {visible &&
                ReactDOM.createPortal(tooltip({
                    getArrowProps,
                    getTooltipProps,
                    setTooltipRef
                }), document.querySelector('#popper-tooltip-portal'))
            }
        </>
    );
};
