import React from 'react';
import cns from 'classnames';

import cn from './LogMessage.module.scss';
import { Event } from '@/common/models/Event';
import { IIntent, IntentTable } from '@/common/components/chat/chat';
import moment from 'moment';
import { Markup } from '@/common/components/chat/Markup';
import { WithNameAndType } from '@/common/components/chat/models';
import { inject, observer } from 'mobx-react';
import { QAStore } from '@/app/qa/qa.store';
import { IntentStore } from '@/app/intents/intent.store';
import { computed, observable, action, IObservableArray, makeObservable } from 'mobx';
import { MarkupType } from '@/common/components/chat/models';
import { WithTranslation, withTranslation } from 'react-i18next';
import { SelectedIcon } from '@/common/svg-icons/SelectedIcon';
import { FileImageFilled } from '@ant-design/icons';

interface LogMessageProps extends WithTranslation {
    event: Event;
    qa?: QAStore;
    intentStore?: IntentStore;
    markEvent: (eventId: string) => Promise<void>;
    pts: number;
}

@inject('qa', 'intentStore')
@observer
export class LogMessageComp extends React.Component<LogMessageProps> {
    @observable filter: string = '';
    @observable elements: IObservableArray<WithNameAndType> = observable([]);
    @observable savedMarkupProcess: boolean = false;
    @observable resultText = '';
    @observable isOpen = false;
    @observable addedToName = '';
    textEl = React.createRef<HTMLSpanElement>();
    lastPts = 0;

    constructor(props: LogMessageProps) {
        super(props);
        console.log(props.event.type);
        makeObservable(this);
    }

    static getSelectionText() {
        let text = '';
        if (window.getSelection) {
            text = window.getSelection().toString();
        } else if ((document as any).selection && (document as any).selection.type != 'Control') {
            text = (document as any).selection.createRange().text;
        }
        return text;
    }

    componentDidMount() {
        this.onChangeSearch('');
        this.resultText = this.props.event.params!.text;
        this.textEl.current.addEventListener('mouseup', () => {
            const text = LogMessageComp.getSelectionText();
            if (text) {
                this.resultText = text;
                this.isOpen = true;
            }
        });
    }

    UNSAFE_componentWillReceiveProps(props: LogMessageProps) {
        if (props.pts > this.lastPts) {
            this.lastPts = props.pts;
            this.onChangeSearch('');
        }
    }

    onChangeSearch = async (term: string) => {
        this.elements.replace(await this.props.qa!.intentQaSearch(term));
    };

    @action.bound
    onChangeFilter(value: string) {
        this.filter = value;
    }

    onSaveMarkup = async (text: string) => {
        const element = this.elements.find(_element => !!_element.selected);
        if (!element) return;
        let result;
        this.savedMarkupProcess = true;

        if (element.type === MarkupType.QA) {
            const qa = await this.props.qa!.getQAWithoutPush(element.id);
            qa.questions.push({text});
            result = await this.props.qa!.updateQA(qa);
        } else if (element.type === MarkupType.Intent) {
                const intent = await this.props.intentStore!.getIntentWithoutPush(element.id);
                intent.intent_examples.push({ parts: [{ text }] });
        } else {
            console.log('Not exist markup type');
        }

        await this.props.markEvent(this.props.event.event_id);
        this.savedMarkupProcess = false;
        this.props.event.meta.marked = true;
        await this.onChangeSearch('');
        return result;
    };

    onSelectMarkup = (element: WithNameAndType) => {
        this.elements.forEach(_element => (_element.selected = false));
        element.selected = true;
    };

    @computed get filteredElements(): Array<WithNameAndType> {
        return this.elements.filter(e => e.name.toLowerCase().includes(this.filter.toLowerCase()));
    }

    onCancel = () => {
        this.isOpen = false;
        this.resultText = this.props.event.params!.text;
    };

    renderIncomingMessage() {
        const { event } = this.props;
        const { meta } = event;
        if (meta.marked) {
            return this.renderMessage(
                !this.addedToName && (
                    <div className={cn.marked}>
                        <SelectedIcon className={cn.addedTo} />
                        {this.props.t('chat.success_added')}
                    </div>
                ),
                cn.outgoing,
                true,
            );
        }

        return this.renderMessage(
            <>
                {meta.intents.length || meta.entities.length ? (
                    <IntentTable intents={meta.intents as IIntent[]} entities={meta.entities} unfilteredIntents={meta.unfiltered_intents} intentStore={this.props.intentStore} qa={this.props.qa} />
                ) : (
                    ''
                )}
                <Markup
                    showTrigger={this.isUnanswered}
                    text={this.resultText}
                    onCancel={this.onCancel}
                    isOpen={this.isOpen}
                    onOpen={() => (this.isOpen = true)}
                    onSelect={this.onSelectMarkup}
                    savedMarkupProcess={this.savedMarkupProcess}
                    elements={this.elements}
                    onChangeSearch={this.onChangeSearch}
                    onSave={async text => {
                        const addedTo = await this.onSaveMarkup(text);
                        this.addedToName = addedTo.name;
                        return addedTo;
                    }}
                />
            </>,
            cn.outgoing,
        );
    }

    get isUnanswered() {
        if (this.props.event.incoming) {
            return !(
                (this.props.event.meta.intents && this.props.event.meta.intents.length) ||
                (this.props.event.meta.entities && this.props.event.meta.entities.length) ||
                this.props.event.meta.marked
            );
            // return (!this.props.event.meta.intents || !this.props.event.meta.intents.length && !this.props.event.meta.entities || !this.props.event.meta.entities.length) && !this.props.event.meta.marked;
        } else {
            return false;
        }
    }

    renderMessage(children?: React.ReactNode, className?: string, marked?: boolean) {
        const date = new Date(this.props.event.timestamp);
        const time = moment(date).format('HH:mm:ss DD.MM.yyyy');
        let text = this.props.event.params!.text;
        if (this.props.event.type === 'terminate') {
            text = 'terminate';
        }
        return (
            <div className={cns(cn.message, className)}>
                <span className={cn.time}>{time}</span>
                { this.props.event.type === 'image' && <div>
                    <FileImageFilled style={{fontSize: '60px', marginBottom: '10px', color: '#D1F1FD', marginLeft: '-8px'}} />
                </div>}
                <span className={cn.text} ref={this.textEl}>
                    {text}
                </span>
                {marked && this.addedToName && (
                    <div className={cn.marked}>
                        <SelectedIcon className={cn.addedTo} /> {this.props.t('chat.success_added_to')} {this.addedToName}
                    </div>
                )}

                {this.isUnanswered ? <span className={cn.unanswered}>({this.props.t('chat.unanswered')})</span> : ''}
                {children}
            </div>
        );
    }

    render() {
        return this.props.event.incoming ? this.renderIncomingMessage() : this.renderMessage();
    }
}

export const LogMessage = withTranslation()(LogMessageComp);
