import { action, makeObservable, observable } from 'mobx';

import { LoadableStore } from '@/common/store';
import { ActivityLogsPageApi } from './ActivityLogsPage.api';
import {
    ActivityLog,
    ActivityLogActions,
    ActivityLogComponents,
    ActivityLogFilters,
    ActivityLogFiltersData
} from '@/common/models/activity-logs';

const headerHeight = 230;
const itemHeight = 41;
const itemsOffset = 3;
const calcItemsPerPage = () => {
    const wHeight = window.innerHeight;
    const availableHeight = wHeight - headerHeight;
    const items = Math.ceil(availableHeight / itemHeight);
    return items + itemsOffset;
}

export class ActivityLogsPageStore extends LoadableStore {
    private static readonly itemsPerPage: number = calcItemsPerPage();
    private static readonly loadTimeout: number = 500;
    private static readonly defaultFilter: ActivityLogFilters = {
        dateFrom: undefined,
        dateTo: undefined,
        actions: ActivityLogActions.ALL,
        components: ActivityLogComponents.ALL,
        items: '',
        projectVersions: 0,
        projects: 0,
        users: 0
    };

    private filter: ActivityLogFilters = ActivityLogsPageStore.defaultFilter;

    @observable isApplying: boolean = false;
    @observable isResetting: boolean = false;
    @observable isLoading: boolean = true;
    @observable page: number;
    @observable availablePages: number;
    @observable logs: ActivityLog[] = [];
    @observable draftFilter: ActivityLogFilters = ActivityLogsPageStore.defaultFilter;
    @observable filtersData: ActivityLogFiltersData;

    private static validateFilters(fd: ActivityLogFiltersData, f: ActivityLogFilters): [boolean, ActivityLogFilters] {
        const flt = Object.assign({}, f);
        let changed: boolean = false;

        if (flt.components && !fd.components.includes(flt.components)) {
            flt.components = ActivityLogComponents.ALL;
            changed = true;
        }

        if (flt.actions && !fd.actions.includes(flt.actions)) {
            flt.actions = ActivityLogActions.ALL;
            changed = true;
        }

        if (flt.items && !fd.items.includes(flt.items)) {
            flt.items = '';
            changed = true;
        }

        if (flt.projectVersions && !fd.projectVersions.find(v => v.id === +flt.projectVersions)) {
            flt.projectVersions = 0;
            changed = true;
        }


        if (flt.projects && !fd.projects.find(p => p.id === +flt.projects)) {
            flt.projects = 0;
            changed = true;
        }

        if (flt.users && !fd.users.find(u => u.id === +flt.users)) {
            flt.users = 0;
            changed = true;
        }

        return [changed, changed ? flt : f];
    }

    constructor() {
        super();
        makeObservable(this);
    }

    async _load(): Promise<void> {
        this.isLoading = true;
        this.logs = await this.fetchLogs();
        this.filtersData = await ActivityLogsPageApi.getLogsFilters(this.filter);
        this.isLoading = false;
    }

    async reload() {
        this.page = 1;
        this.filter = Object.assign({}, ActivityLogsPageStore.defaultFilter);
        await super.reload();
    }

    @action.bound
    async changeFilter(filter: ActivityLogFilters) {
        const data: ActivityLogFiltersData = await ActivityLogsPageApi.getLogsFilters(filter);
        const [isChanged, validatedFilter]= ActivityLogsPageStore.validateFilters(data, filter);
        if (isChanged) {
            this.filtersData = await ActivityLogsPageApi.getLogsFilters(validatedFilter);
        }
        if (validatedFilter.dateFrom) {
            validatedFilter.dateFrom = new Date(validatedFilter.dateFrom);
        }

        if (validatedFilter.dateTo) {
            validatedFilter.dateTo = new Date(validatedFilter.dateTo);
        }
        this.draftFilter = validatedFilter;
    }

    @action.bound
    async resetFilter() {
        this.isResetting = true;
        await this.changeFilter(ActivityLogsPageStore.defaultFilter);
        await this._applyFilter();
        setTimeout(() => { this.isResetting = false; }, ActivityLogsPageStore.loadTimeout);
    }

    @action.bound
     async applyFilter() {
        this.isApplying = true;
        await this._applyFilter();
        setTimeout(() => { this.isApplying = false; }, ActivityLogsPageStore.loadTimeout);
    }

    async loadMore() {
        if (this.page >= this.availablePages) return;
        this.page += 1;
        this.logs = this.logs.concat(await this.fetchLogs());
    }

    @action.bound
    private async _applyFilter() {
        this.filter = Object.assign({}, this.draftFilter);
        this.page = 1;
        this.logs = await this.fetchLogs();
        this.filtersData = await ActivityLogsPageApi.getLogsFilters(this.filter);
    }

    private async fetchLogs() {
        const { itemsPerPage } = ActivityLogsPageStore;
        const resp = await ActivityLogsPageApi.getLogs(this.page, this.filter, itemsPerPage);
        this.page = resp.currentPage;
        this.availablePages = resp.pageCount;
        return resp.results.map(r => {
            r.date = new Date(r.date);
            return r;
        });
    }
}
