import React from 'react'

export const PlusIcon: React.FunctionComponent<{ className?: string }> = (props) => <svg className={props.className}
                                                                                        width="11" height="11"
                                                                                        viewBox="0 0 11 11" fill="none"
                                                                                        xmlns="http://www.w3.org/2000/svg">
    <path
        d="M10.7383 6.12231H6.23828V10.6223H4.76172V6.12231H0.261719V4.64575H4.76172V0.145752H6.23828V4.64575H10.7383V6.12231Z"
        fill="#116FFC"/>
</svg>;
