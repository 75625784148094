import { API, HTTP_METHODS } from '@/common/api';
import { Project } from '@/common/models/project';
import { UpdateStatus } from './models/update-status';
import { ProjectVersion } from './models/project-version';

export class ProjectAPI extends API {
    static getProjectStatus(project: Project, abortSignal: AbortSignal): Promise<UpdateStatus> {
        return ProjectAPI.get(`/projects/${project.id}/test/update/status`, null, null, abortSignal);
    }

    static getProject(projectId: number): Promise<Project> {
        return ProjectAPI.get(`/projects/${projectId}`)
    }

    static publish(project: Project): Promise<UpdateStatus> {
        return ProjectAPI.post(`/projects/${project.id}/publish`);
    }

    static getProjectProdStatus(project: Project, abortSignal: AbortSignal): Promise<UpdateStatus> {
        return ProjectAPI.get(`/projects/${project.id}/publish/status`, null, null, abortSignal);
    }

    static getProjectVersions(project: Project): Promise<ProjectVersion[]> {
        return ProjectAPI.get(`/projects/${project.id}/versions`);
    }

    static updateProject(project: Project) {
        return ProjectAPI.put<Project>(`/projects/${project.id}`, project);
    }

    static createProject(name: string, templateProjectId?: number) {
        return API.post<Project>('/projects', {
            name,
            from_template: templateProjectId
        });
    }

    static importProject(file: File) {
        return ProjectAPI.formData<Project>(HTTP_METHODS.POST, '/projects/import', { dump: file });
    }

    static removeProject(project: Project) {
        return ProjectAPI.delete(`/projects/${project.id}`);
    }
}
