import React, { FC, useEffect } from 'react';
import { observer } from 'mobx-react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { useTranslation } from 'react-i18next';
import { Channel } from '../../models/channel';
import { ChannelEditStore } from '@/app/connects/components/ConnectPageContent';
import { Button, Form, Space, Input, InputNumber } from 'antd';
import { getCurrentDomain } from '@/common/utils/get-current-domain';
import { useMaxLength, useRegExp, useRequired } from '@/common/utils/form-validators';
import { CopyOutlined } from '@ant-design/icons';

type Webim2Props = {
    channel: Channel;
    channelEditStore: ChannelEditStore;
    isManageChannels: boolean;
    onFinish: (values: any) => void;
    isRequest: boolean;
}

export const Webim2: FC<Webim2Props> = observer(({
                                                                                 channel,
                                                                                 isManageChannels,
                                                                                 channelEditStore,
                                                                                 onFinish,
                                                                                 isRequest
                                                                             }) => {
    const {t} = useTranslation();
    const [form] = Form.useForm();
    channelEditStore.setCurrentEditForm(form);
    const liaWebHook = `${getCurrentDomain()}/channels/webim2?key=${channel.api_key}`;
    const onFinishForm = (values: any) => {
        onFinish(values);
    }

    console.log('channel id', channel.id, channel);

    useEffect(() => form.resetFields(), [channel.id]);

    const required = useRequired();
    const maxLength = useMaxLength(4096);
    const maxLength128 = useMaxLength(128);
    const startsWithQaIntentValidator = useRegExp(/^qa-.+|intent-.+/, 'qa- / intent-');

    const initialValues = {
        subdomain: channel.params.subdomain,
        webim_token: channel.params.webim_token,
        redirect_department: channel.params.redirect_department,
        max_message_len: channel.params.max_message_len,
        long_text_intent: channel.params.long_text_intent,
    };

    return <Form form={form} style={{maxWidth: '600px'}} layout="vertical" onFinish={onFinishForm}
                 initialValues={initialValues}>
        <Form.Item label={t('connect.webim2.input_label_link')}>
            <Space.Compact
                style={{
                    width: '100%',
                }}
            >
                <Input value={liaWebHook} readOnly />
                <CopyToClipboard text={liaWebHook} onCopy={() => alert(t('actions.copied'))}>
                    <Button type="default" icon={<CopyOutlined/>}/>
                </CopyToClipboard>
            </Space.Compact>
        </Form.Item>

        <Form.Item rules={[required, maxLength]} name="subdomain"
                   label={t('connect.webim2.input_label_subdomain')}>
            <Input disabled={!isManageChannels || isRequest}/>
        </Form.Item>

        <Form.Item rules={[required, maxLength]} name="webim_token" label={t('connect.webim2.input_label_token')}>
            <Input disabled={!isManageChannels || isRequest}/>
        </Form.Item>

        <Form.Item rules={[required, maxLength]} name="redirect_department"
                   label={t('connect.webim2.input_label_redirect')}>
            <Input disabled={!isManageChannels || isRequest}/>
        </Form.Item>

        <Form.Item rules={[required]} name="max_message_len"
                   label={t('connect.webim2.max_message_len')}>
            <InputNumber min={2} disabled={!isManageChannels || isRequest}/>
        </Form.Item>

        <Form.Item rules={[required, maxLength128, startsWithQaIntentValidator]} name="long_text_intent"
                   label={t('connect.webim2.long_text_intent')}>
            <Input disabled={!isManageChannels || isRequest}/>
        </Form.Item>
    </Form>
});
