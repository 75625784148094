import React, { FC, useEffect, useState } from 'react';
import { Navigate } from 'react-router';
import { useTranslation } from 'react-i18next';

import { inject, observer } from 'mobx-react';
import qs from 'query-string';

import cn from './TeamPage.module.scss';
import { TeamPageStore } from '@/app/team/TeamPage.store';
import { Page } from '@/common/components/page/Page';
import { TeamPageCard } from '@/app/team/TeamPageCard';
import { TeamUser, UpdateCreateTeamUser } from '@/common/models/team-user';
import { Loader } from '@/common/components/Loader';
import { TeamPageModal } from '@/app/team/TeamPageModal';
import { UserStore } from '@/core/stores/user.store';
import { useLocation, useNavigate } from 'react-router-dom/dist';

type TeamPageProps = {
    teamPageStore?: TeamPageStore;
    user?: UserStore;
}

export const TeamPage: FC<TeamPageProps> = inject('teamPageStore', 'user')(observer(({
                                                                                         teamPageStore,
                                                                                         user
                                                                                     }: TeamPageProps) => {
    const {users, roles, projects} = teamPageStore;
    const {lang} = user;
    const {t} = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();

    const [showEditModal, setShowEditModal] = useState(false);
    const [modalData, setModalData] = useState(null);
    const [modalIsFetching, setModalIsFetching] = useState(false);
    const [modalError, setModalError] = useState('');

    useEffect(() => {
        const {invite} = qs.parse(location.search);
        teamPageStore.load();
        if (showEditModal) {
            navigate(location.pathname, {replace: true});
        }
        setShowEditModal(typeof invite !== 'undefined');
    }, []);

    const openInviteModal = () => {
        setShowEditModal(true);
        setModalData(null);
    }

    const openEditUser = (user: TeamUser) => {
        setShowEditModal(true)
        setModalData(user);
    }

    const closeEditModal = () => {
        setShowEditModal(false);
        setModalData(null);
        setModalIsFetching(false);
        setModalError('');
        navigate(location.pathname, {replace: true});
    }

    const editUser = async (user: UpdateCreateTeamUser) => {
        setModalIsFetching(true);

        try {
            await teamPageStore[user.id ? 'updateUser' : 'inviteUser'](user);
            closeEditModal();
        } catch (e) {
            const errors = Object.values(e)
                .filter(v => Array.isArray(v))
                .reduce((arr, v) => arr.concat(v), []);
            setModalError(errors && errors.length ?
                errors.join(', ') :
                t('team_page.modal.default_error'));
        } finally {
            setModalIsFetching(false);
        }
    }

    if (!user.permissions.isManageTeam) {
        return <Navigate to={'/'}/>;
    }

    if (teamPageStore.isLoading) {
        return <div className={cn.loaderWrapper}><Loader/></div>;
    }

    return <>
        <Page editorPadding={false}>
            <article className={cn.container}>
                <h1 className={cn.title}>
                    {t('team_page.title')}
                    <a className={cn.titleLink} onClick={openInviteModal}>
                        {t('team_page.invite_link')}
                    </a>
                </h1>

                <ul className={cn.usersList}>
                    {users.map(user => <li key={user.id} className={cn.usersListItem}>
                        <TeamPageCard
                            user={user}
                            defaultRole={teamPageStore.getRoleByKey(user.role)}
                            userProjects={teamPageStore.getUserProjects(user)}
                            onEdit={openEditUser}
                        />
                    </li>)}
                </ul>
            </article>
        </Page>

        {showEditModal && <TeamPageModal
            isAdmin={user.permissions.isAdmin}
            user={modalData}
            roles={roles}
            projects={projects}
            closeModal={closeEditModal}
            onSubmit={editUser}
            isLoading={modalIsFetching}
            errorMsg={modalError}
            lang={lang}
        />}
    </>


}));
