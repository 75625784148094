import React from 'react';

export const ChannelsIcon: React.FunctionComponent = () => <svg width="27" height="24" viewBox="0 0 27 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M8.3125 16H4.3125C3.2075 16 2.3125 15.105 2.3125 14V10C2.3125 8.895 3.2075 8 4.3125 8H8.3125C9.4175 8 10.3125 8.895 10.3125 10V14C10.3125 15.105 9.4175 16 8.3125 16Z" fill="currentColor"/>
    <path d="M22.3125 6H24.3125C25.4175 6 26.3125 5.105 26.3125 4V2C26.3125 0.895 25.4175 0 24.3125 0H22.3125C21.2075 0 20.3125 0.895 20.3125 2V4C20.3125 5.105 21.2075 6 22.3125 6Z" fill="currentColor"/>
    <path d="M22.3125 15H24.3125C25.4175 15 26.3125 14.105 26.3125 13V11C26.3125 9.895 25.4175 9 24.3125 9H22.3125C21.2075 9 20.3125 9.895 20.3125 11V13C20.3125 14.105 21.2075 15 22.3125 15Z" fill="currentColor"/>
    <path d="M22.3125 24H24.3125C25.4175 24 26.3125 23.105 26.3125 22V20C26.3125 18.895 25.4175 18 24.3125 18H22.3125C21.2075 18 20.3125 18.895 20.3125 20V22C20.3125 23.105 21.2075 24 22.3125 24Z" fill="currentColor"/>
    <path d="M11.3125 12H19.3125" stroke="currentColor" strokeWidth="2" strokeMiterlimit="10"/>
    <path d="M19.3125 3H16.3125C15.2075 3 14.3125 3.895 14.3125 5V19C14.3125 20.105 15.2075 21 16.3125 21H19.3125" stroke="currentColor" strokeWidth="2" strokeMiterlimit="10"/>
</svg>
