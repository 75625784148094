import React from 'react';
import { Navigate, Route, Routes } from 'react-router';

import { WithTranslation, withTranslation } from 'react-i18next';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend'

import { reaction } from 'mobx';
import { inject, observer } from 'mobx-react';

import { Auth } from './auth/Auth';
import { App } from './app/App';
import { UserStore } from './core/stores/user.store';
import { BootstrapService } from './core/bootstrap.service';
import { Loader } from '@/common/components/Loader';
import { NotFound } from '@/app/not-found/NotFound';
import { NeedAccess } from '@/app/need-access/NeedAccess';
import { PROJECT_ID_KEY } from '@/common/models/router.constants';
import { RouteComponentProps, withRouter } from './common/utils/withRouter';
import { FlowDiagramPageComponent } from '@/app/scripts/components/FlowDiagram/FlowDiagramPage.component';


type RootProps = WithTranslation & RouteComponentProps & {
    user?: UserStore;
    bootstrap?: BootstrapService;
}

window.commitHash = process.env.REACT_APP_VERSION;

@inject('user', 'bootstrap')
@observer
class RootComp extends React.Component<RootProps> {
    componentDidMount() {
        this.props.bootstrap!.bootstrap();
    }

    constructor(props: RootProps) {
        super(props);
        this.props.i18n.changeLanguage(this.props.user!.lang || 'ru');

        reaction(
            () => this.props.user!.userInfo,
            () => {
                setTimeout(() => {
                    if (this.props.user && this.props.user.userInfo) {
                        this.props.i18n.changeLanguage(this.props.user!.userInfo!.language)
                    }
                });
            }
        );

        reaction(
            () => this.props.user!.lang,
            lang => {
                if (lang != null) {
                    this.props.i18n.changeLanguage(lang!)
                }
            }
        );
    }

	getDefaultProjectUrl(): string {
		return `/${this.props.user?.currentChoosedProjectId}/flows`
	}

    renderProject = () => {
        return this.props.user!.isAuthenticate ? <App /> : <Navigate to={'/auth'}/>;
    }

    renderAuth = () => {
        return !this.props.user!.isAuthenticate ? <Auth /> : <Navigate to={`/app${this.getDefaultProjectUrl()}`}/>;
    }

    render() {
        const { bootstrap } = this.props;
        return <DndProvider backend={HTML5Backend}>
            <div className={'theme-light'}>
                <div className="wrapper">
                    {
                        bootstrap!.isLoaded ?
                            <Routes>
                                <Route index element={this.renderAuth()} />
                                <Route path={'/auth/*'} element={this.renderAuth()} />
                                {/*<Route path={`/diagram/${PROJECT_ID_KEY}/flows/:id`} element={*/}
                                {/*    <FlowDiagramPageComponent/>*/}
                                {/*} />*/}

                                <Route path={'/diagram/*'}>

                                    {/*<Route path={`${this.props.user?.currentChoosedProjectId}/flows/*`}>*/}
                                    {/*    <Route path={':id'} element={<FlowDiagramPageComponent/>}/>*/}
                                    {/*</Route>*/}

                                    <Route path={`${PROJECT_ID_KEY}/flows/*`} element={
                                        <FlowDiagramPageComponent/>
                                    }>
                                        <Route path={':id'} element={<FlowDiagramPageComponent/>}/>
                                    </Route>



                                </Route>
                                <Route path={'/app/*'}>
									<Route index element={<Navigate to={this.getDefaultProjectUrl()}/>} />
                                    <Route path={`${PROJECT_ID_KEY}/*`} element={this.renderProject()}/>
                                </Route>
                                <Route path={'/404'} element={<NotFound />} />
                                <Route path={'/need-access'} element={<NeedAccess />} />
                                <Route path={'*'} element={<Navigate to={'/404'}/>} />
                            </Routes> : <div className="load loaded"><Loader /></div>
                    }
                </div>
            </div>
        </DndProvider>;
    }
}

export const Root = withTranslation()(withRouter(RootComp));

