import { UserSendTrigger } from './UserSendTrigger';
import { makeObservable } from 'mobx';

export class FileTrigger extends UserSendTrigger {
    static className = 'FileTrigger';
    constructor() {
        super();
        makeObservable(this);
    }
}
