import React from 'react';
import { inject, observer } from 'mobx-react';

import cn from './MinimalPage.module.scss';
import Header from '@/app/components/header/Header';
import { UserStore } from '@/core/stores/user.store';

interface MinimalPageProps {
    user?: UserStore;
    withHeader?: boolean;
    children: React.ReactNode;
}

@inject('user')
@observer
export class MinimalPage extends React.Component<MinimalPageProps> {
    render() {
        return (
            <div className={cn.minimalPage}>
                {this.props.withHeader && <Header user={this.props.user!} isMinimal={true} />}
                <div className={cn.content}>{this.props.children}</div>
            </div>
        );
    }
}
