import React from 'react';
import { useNavigate, useLocation, useParams, Params, useResolvedPath } from 'react-router-dom';

export type RouteComponentProps<T extends string | Record<string, string> = ''> = {
    location?: ReturnType<typeof useLocation>;
    history?: {
        push: (path: string, params?: Record<string, string>) => ReturnType<ReturnType<typeof useNavigate>>;
        replace: (path: string, params?: Record<string, string>) => ReturnType<ReturnType<typeof useNavigate>>;
    }
    params?: ReturnType<typeof useParams<T>>;
    match?: {
        params: Readonly<Params<string>>;
        url: string;
        path: string;
        parentPath: string;
    }
};

type TWithRouter = <
	RouteParams extends string | Record<string, string>,
	C extends React.ComponentType<React.ComponentProps<any> & RouteComponentProps<RouteParams>>
>(component: C) => React.ComponentType<React.ComponentProps<any> & RouteComponentProps<RouteParams>>

export const withRouter: TWithRouter = (Component) => {
	return (props) => {
        const navigate = useNavigate();
		const location = useLocation();
        const params = useParams();
        const path = useResolvedPath('');
        const history: RouteComponentProps['history'] = {
            push: (path: string, params?: Record<string, string>) => navigate(path, { state: params }), 
            replace: (path: string, params?: Record<string, string>) => navigate(path, { replace: true, state: params }),
        };
        const match = {
            params,
            url: location.pathname,
            parentPath: path.pathname,
            get path() {
                console.log('match.path, найти замену!!!');
                return '';
            }
        };

        return (
            <Component
                history={history}
                location={location}
                params={params}
                match={match}
                {...props}
            />
        );
    };
}
