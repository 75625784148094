import React from 'react';
import { Navigate, Route, Routes } from 'react-router';
import { QAStore } from '../qa.store';
import { inject, observer, Provider } from 'mobx-react';
import { QA } from './qa';
import { RootStore } from '@/core/stores/root.store';
import { Loader } from '@/common/components/Loader';
import { ZeroScreen } from '@/common/components/zero-screen/ZeroScreen';
import { Page } from '@/common/components/page/Page';
import { TestChat } from '../../chat/components/TestChat';
import cn from './qa-page.module.scss';
import { WithTranslation, withTranslation } from 'react-i18next';
import * as i18next from 'i18next';
import { SideMenuRight } from '@/common/side-menu-right/SideMenuRight';
import { QaMenu } from './qa-menu';
import { UserStore } from '@/core/stores/user.store';
import { RouteComponentProps, withRouter } from '@/common/utils/withRouter';

interface _QAPageProps {
    root: RootStore;
    qa: QAStore;
    t: i18next.TFunction;
    user?: UserStore;
}

type QAPageProps = _QAPageProps & RouteComponentProps & WithTranslation;

@inject('root', 'qa', 'user')
@observer
export class QaPageComp extends React.Component<QAPageProps> {
    render() {
        if (!this.props.user.permissions.isViewQa) {
            return <Navigate to={'/'}/>;
        }
        return <Provider qa={this.props.qa}>
            <>
                <SideMenuRight>
                    <QaMenu qaStore={this.props.qa}/>
                </SideMenuRight>
            {
                this.props.qa.isLoaded
                    ? <Routes>
                        <Route path={'/'} element={
                            <Page rightBar={<TestChat/>}>
                                <ZeroScreen title={this.props.t('qa.create_new_qa_title')}
                                            subtitle={<>
                                                {this.props.t('zero_screens.subtitle')}
                                                <br/> {this.props.t('qa.create_new_qa_subtitle_suffix')}
                                            </>}/>
                            </Page>
                        }>
                        </Route>
                        <Route path={':id'} element={<QA />}/>
                        <Route element={<Navigate to={'/'}/>}/>
                    </Routes>
                    :
                    <div className={cn.loader}><Loader/></div>
            }
            </>
        </Provider>;
    }
}

export const QAPage = withTranslation()(withRouter(QaPageComp));
