import { ModelsFactory } from './ModelsFactory';
import { Trigger } from '../models/triggers/Trigger';
import { EntitySettingsParams, IntentTrigger } from '../models/triggers/IntentTrigger';
import { EntityTrigger } from '../models/triggers/EntityTrigger';
import { State } from '../models/State';
import { AllowOutTrigger } from '../models/triggers/AllowOutTrigger';
import { AudioTrigger } from '../models/triggers/AudioTrigger';
import { FallbackTrigger } from '../models/triggers/FallbackTrigger';
import { FileTrigger } from '../models/triggers/FileTrigger';
import { ImageTrigger } from '../models/triggers/ImageTrigger';
import { LocationTrigger } from '../models/triggers/LocationTrigger';
import { StartTrigger } from '../models/triggers/StartTrigger';
import { StickerTrigger } from '../models/triggers/StickerTrigger';
import { VideoTrigger } from '../models/triggers/VideoTrigger';
import { NoEntityCondition } from '../models/Condition';
import { Branch } from '../models/Branch';

type IntentTriggerParams = {
    intentIds: Array<number>,
    entitySettings: Array<{ entityId: number, extraction: 'required' | 'optional' }>;
}

type EntityTriggerParams = {
    entityId: number;
}

type AllowOutTriggerParams =
    { allow: 'out' | 'qa' | 'flow' };


export type  TriggersFactoryTriggerParams =
    object
    | IntentTriggerParams
    | EntityTriggerParams
    | AllowOutTriggerParams;

interface TriggersFactoryBuildParams {
    parent: State;
    params: TriggersFactoryTriggerParams;
    type: string;
    branches: Branch[];
}

const dtoTypeToClassType: Record<string, string> = {
    'intent': IntentTrigger.type,
    'entity': EntityTrigger.type,
    'out': AllowOutTrigger.type,
    'audio': AudioTrigger.type,
    'fallback': FallbackTrigger.type,
    'file': FileTrigger.type,
    'image': ImageTrigger.type,
    'location': LocationTrigger.type,
    'start': StartTrigger.type,
    'sticker': StickerTrigger.type,
    'video': VideoTrigger.type,
};


export class TriggersFactory extends ModelsFactory {
    static build(params: TriggersFactoryBuildParams): Trigger {
        const classType = dtoTypeToClassType[params.type] || params.type;
        switch (classType) {
            case IntentTrigger.type:
                return this.buildIntentTrigger(params);
            case EntityTrigger.type:
                return this.buildEntityTrigger(params);
            case AllowOutTrigger.type:
                return this.buildAllowOutTrigger(params);
            case AudioTrigger.type:
                return this.buildSimpleTrigger<AudioTrigger>(params, AudioTrigger);
            case FallbackTrigger.type:
                return this.buildSimpleTrigger<FallbackTrigger>(params, FallbackTrigger);
            case FileTrigger.type:
                return this.buildSimpleTrigger<FileTrigger>(params, FileTrigger);
            case ImageTrigger.type:
                return this.buildSimpleTrigger<ImageTrigger>(params, ImageTrigger);
            case LocationTrigger.type:
                return this.buildSimpleTrigger<LocationTrigger>(params, LocationTrigger);
            case StartTrigger.type:
                return this.buildSimpleTrigger<StartTrigger>(params, StartTrigger);
            case StickerTrigger.type:
                return this.buildSimpleTrigger<StickerTrigger>(params, StickerTrigger);
            case VideoTrigger.type:
                return this.buildSimpleTrigger<VideoTrigger>(params, VideoTrigger);
            default:
                throw new Error('Bad type of trigger');
        }
    }

    private static buildAllowOutTrigger(params: TriggersFactoryBuildParams): AllowOutTrigger {
        const trigger = new AllowOutTrigger();
        trigger.parent = params.parent;
        if (params.branches.length > 0) {
            const [branch] = params.branches;
            if (branch.next) {
                trigger.next = branch.next;
                branch.next.parent = trigger;
            }
        }
        trigger.allow = (params.params as AllowOutTriggerParams).allow;
        return trigger;
    }

    private static buildSimpleTrigger<T extends Trigger>(params: TriggersFactoryBuildParams, TriggerConstructor: new () => T): T {
        const trigger = new TriggerConstructor();
        trigger.parent = params.parent;
        if (params.branches.length > 0) {
            const [branch] = params.branches;
            if (branch.next) {
                trigger.next = branch.next;
                branch.next.parent = trigger;
            }
        }
        return trigger;
    }

    private static buildIntentTrigger(params: TriggersFactoryBuildParams): IntentTrigger {
        const trigger = new IntentTrigger();

        const intentTriggerParams = params.params as IntentTriggerParams;
        if (intentTriggerParams) {
            if (intentTriggerParams.intentIds) {
                trigger.intents = intentTriggerParams.intentIds.map(i => ({id: i}));
            }

            if (intentTriggerParams.entitySettings) {
                // @ts-ignore
                trigger.entitySettings = intentTriggerParams.entitySettings.map(es => {
                    return {
                        settings: es.extraction as EntitySettingsParams,
                        entity: {id: es.entityId, name}
                    }
                });
            }

            const allEntitiesBranch = this.findAllEntitiesBranch(params.branches);
            if (allEntitiesBranch && allEntitiesBranch.next) {
                trigger.next = allEntitiesBranch.next;
                trigger.next.parent = trigger;
            }

            const noEntityBranches = params.branches.filter(b => !!b.condition && b.condition.type === 'no-entity');
            if (noEntityBranches.length > 0) {
                noEntityBranches.forEach((b) => {
                    const condition = b.condition as NoEntityCondition;
                    trigger.noEntityBranches.push({
                        name: b.name,
                        entity: {id: condition.params.entity_id, name: ''},
                        next: b.next,
                    });
                    if (b.next) {
                        b.next.parent = trigger;
                    }
                })
            }
        }

        trigger.parent = params.parent;
        return trigger;
    }

    private static buildEntityTrigger(params: TriggersFactoryBuildParams): EntityTrigger {
        const trigger = new EntityTrigger();

        const entityTriggerParams = params.params as EntityTriggerParams;
        if (entityTriggerParams) {
            if (entityTriggerParams.entityId) {
                trigger.entity = {id: entityTriggerParams.entityId, name: ''};
            }

            const allEntitiesBranch = this.findAllEntitiesBranch(params.branches);
            if (allEntitiesBranch && allEntitiesBranch.next) {
                trigger.next = allEntitiesBranch.next;
                trigger.next.parent = trigger;
            }

            if (trigger.entity) {
                const noEntityBranches = params.branches.find(b => !!b.condition && b.condition.type === 'no-entity');
                if (noEntityBranches && noEntityBranches.next) {
                    trigger.noEntityNext = noEntityBranches.next;
                    trigger.noEntityNext.parent = trigger;
                }
            }
        }

        trigger.parent = params.parent;
        return trigger;
    }

    private static findAllEntitiesBranch(branches: Array<Branch>): Branch | undefined {
        return branches.find(b => !b.condition
            || (b.condition.type === 'all-entities' || b.condition.type === 'true'));
    }
}
