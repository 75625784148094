import React, { KeyboardEvent, useState } from 'react';
import { TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';

interface QAFormProps {
    onAddAnswer: (answer: string) => void
}

export const QAForm = ({ onAddAnswer }: QAFormProps) => {
    const [answer, onChangeAnswer] = useState('');
    const { t } = useTranslation();
    const onKeyDown = (event: KeyboardEvent<HTMLDivElement>) => {
        if (event.key === 'Enter' && !event.ctrlKey && !event.shiftKey) {
            event.preventDefault();
            if (answer) {
                onAddAnswer(answer);
                onChangeAnswer('');
            }
        }
    };

    return <form className="material-form" onSubmit={e => {
        e.preventDefault();
        if (answer) {
            onAddAnswer(answer);
            onChangeAnswer('');
        }
    }}>
        <div className="form__form-group" style={{ margin: 0 }}>
            <span className="form__form-group-label">{ t('qa.add_new_answer') }</span>
            <div className="form__form-group-field">
                <div className="form__form-group-input-wrap">
                    <TextField className="material-form__field"
                               value={answer}
                               multiline={true}
                               rows={1}
                               maxRows={4}
                               onKeyDown={onKeyDown}
                               onChange={e => onChangeAnswer(e.target.value)}/>
                </div>
            </div>
        </div>
    </form>
};
