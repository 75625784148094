import React, { FC, useEffect } from 'react';
import cnForm from '@/common/scss/form.module.scss';
import cn from './Connection.module.scss';
import { Channel } from '../../models/channel';
import { observer } from 'mobx-react';
import classnames from 'classnames';
import { ChatIcon } from '@/common/svg-icons/ChatIcon';
import CopyToClipboard from 'react-copy-to-clipboard';
import { useTranslation } from 'react-i18next';
import { ChannelEditStore } from '@/app/connects/components/ConnectPageContent';
import { Button, Form, Space, Input as Input1, Radio } from 'antd';
import { getCurrentDomain } from '@/common/utils/get-current-domain';
import { useMaxLength, useRegExp } from '@/common/utils/form-validators';
import { CopyOutlined } from '@ant-design/icons';

type WidgetProps = {
    channel: Channel;
    channelEditStore: ChannelEditStore;
    isManageChannels: boolean;
    onFinish: (values: any) => void;
    isRequest: boolean;
}

export const Widget: FC<WidgetProps> = observer(({
                                                                                 channel,
                                                                                 isManageChannels,
                                                                                 channelEditStore,
                                                                                 onFinish,
                                                                                 isRequest
                                                                             }) => {
    const {t} = useTranslation();
    const [form] = Form.useForm();
    channelEditStore.setCurrentEditForm(form);
    const script = `<script id="lia-widget" src="${getCurrentDomain()}/widget/widget.js?api_key=${channel.api_key}"></script>`;
    const demoUrl = `${getCurrentDomain()}/widget/demo/${channel.api_key}`;

    const onFinishForm = (values: any) => {
        onFinish(values);
    }

    useEffect(() => form.resetFields(), [channel.id]);

    const color = Form.useWatch('color', form);
    const text = Form.useWatch('text', form);
    const button = Form.useWatch('button', form);

    const maxLength = useMaxLength(4096);
    const hexValidator = useRegExp(/^#([0-9a-fA-F]{3}){1,2}$/, '#000 / #000000');

    const initialValues = {
        color: channel.params.color ?? '#d620fa',
        button: channel.params.button ?? 'button',
        text: channel.params.text,
        welcome: channel.params.welcome,
    };

    return <Form form={form} style={{maxWidth: '600px'}} layout="vertical" onFinish={onFinishForm}
                 initialValues={initialValues}>
        <Form.Item label={t('channels.widget_code')}>
            <Space.Compact
                style={{
                    width: '100%',
                }}
            >
                <Input1 value={script}/>
                <CopyToClipboard text={script} onCopy={() => alert(t('actions.copied'))}>
                    <Button type="default" icon={<CopyOutlined/>}/>
                </CopyToClipboard>
            </Space.Compact>

            <Button href={demoUrl} target="_blank" type="link">{t('channels.widget_link')}</Button>
        </Form.Item>

        <Form.Item help={t('channels.message_desc')} rules={[maxLength]} name="welcome"
                   label={t('channels.welcome_message')}>
            <Input1 disabled={!isManageChannels || isRequest}/>
        </Form.Item>

        <Form.Item name="color"
                   rules={[hexValidator]}
                   label={t('channels.main_color')}>
            <Input1 disabled={!isManageChannels || isRequest}/>
        </Form.Item>

        <div className={cnForm.formGroupTitle}>{t('channels.appearance')}</div>

        <Form.Item name="button" label={t('channels.button_mode')}>
            <Radio.Group disabled={!isManageChannels || isRequest}>
                <Radio value="button">{t('channels.block')}</Radio>
                <Radio value="button-circle">{t('channels.bubble')}</Radio>
                <Radio value="text">{t('channels.text')}</Radio>
            </Radio.Group>
        </Form.Item>

        {button === 'text' && <div>
            <div className={cn.chatText} style={{backgroundColor: color}}>
                <span className={cn.chatHeaderName}>{text}</span>
                <span className={cn.chatHeaderIcon}><ChatIcon className={cn.chatTextIcon}/></span>
            </div>

            <Form.Item style={{maxWidth: '286px', marginTop: '10px'}} rules={[maxLength]} name="text">
                <Input1 disabled={!isManageChannels || isRequest}/>
            </Form.Item>
        </div>}

        {button === 'button-circle' && <div style={{backgroundColor: color}}
                                            className={classnames(cn.chatClosedButtonIcon, cn.chatClosedButtonCircleIcon)}>
            <ChatIcon className={cn.chatIcon}/>
        </div>}

        {button === 'button' && <div style={{backgroundColor: color}} className={cn.chatClosedButtonIcon}>
            <ChatIcon className={cn.chatIcon}/>
        </div>}
    </Form>
});
