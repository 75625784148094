import React from 'react';
import cn from './ChatMessageMedia.module.scss';

interface ChatMessageFileProps {
    url: string;
    caption: string;
}
export class ChatMessageFile extends React.Component<ChatMessageFileProps> {
    render() {
        return <div>
            <a target="_blank" rel="noopener noreferrer" href={this.props.url}>File</a>
            <div className={cn.caption}>{this.props.caption}</div>
        </div>
    }
}
