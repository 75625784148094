import React, { ChangeEvent } from 'react';
import cn from './ConnectMenu.module.scss';
import classnames from 'classnames';
import { Link } from 'react-router-dom/dist';
import cnMenu from '@/common/side-menu-right/SideMenuRight.module.scss';
import {
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Popover,
    PopoverBody,
    PopoverHeader
} from 'reactstrap';
import { inject, observer } from 'mobx-react';
import { makeObservable, observable } from 'mobx';
import cnCommon from '@/common/scss/form.module.scss';
import { ConnectType } from '../models/connect-type';
import { ConnectStore } from '../connect.store';
import { PublishPageStore } from '@/app/publish/PublishPage.store';
import { PlusIcon } from '@/common/svg-icons/PlusIcon';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Tracker } from '@/core/analytics/tracker';
import { PermissionsView } from '@/app/permissions/Permissions';
import { Permission } from '@/core/stores/user.store';
import { RouteComponentProps, withRouter } from '@/common/utils/withRouter';

interface ConnectMenuProps extends RouteComponentProps<{ projectId: string }>, WithTranslation {
    connectStore: ConnectStore;
    publishPageStore?: PublishPageStore;
}

@inject('connectStore', 'publishPageStore')
@observer
class ConnectMenuComponent extends React.Component<ConnectMenuProps> {
    static icons = {
        api: cn.liaIcon,
        widget: cn.liaIcon
    };
    @observable isOpen: boolean = false;
    @observable isDisabledPopoverVisible = false;
    selectedValues: ConnectType[] = [];
    dropdown = React.createRef();
    constructor(props: ConnectMenuProps) {
        super(props);
        makeObservable(this);
    }
    toggle = () => {
        this.isOpen = !this.isOpen;
    };

    createNewChannel = (channel: ConnectType) => () => {
        this.props.connectStore.createNewConnection(channel)
    };

    isActiveRoute = (route: string) => this.props.location.pathname === route;

    stopPropagation = (e: MouseEvent) => {
        e.stopPropagation();
    };

    onChangeIntegration = (integration: ConnectType) => (e: ChangeEvent, checked: boolean) => {
        if (checked) {
            this.selectedValues.push(integration);
        } else {
            const i = this.selectedValues.findIndex(v => v.key === integration.key);
            if (i !== -1) this.selectedValues.splice(i, 1);
        }
    };

    onAddChannelHover = () => {
        this.isDisabledPopoverVisible = true;
    }

    onAddChannelBlur = () => {
        this.isDisabledPopoverVisible = false;
    }

    renderDisabledAddChannelPopover = () => (
        <Popover isOpen={this.isDisabledPopoverVisible} placement="right-start" target="addChannelPopover"
                 id="popover-channel">
            <PopoverHeader>Add channel</PopoverHeader>
            <PopoverBody>
                <div>{this.props.t('connect.menu.disabled_add_channel_msg')}</div>
                <Link
                    to={`/app/${this.props.match.params.projectId}/publish`}
                    onClick={this.onAddChannelBlur}
                >{this.props.t('project_header.disabled_share_link')}</Link>
            </PopoverBody>
        </Popover>
    );

    render() {
        const {versions} = this.props.publishPageStore!;
        const {channels} = this.props.connectStore!;
        const isCreateChannelsEnabled = (versions && versions.length) || (channels && channels.length);
        return <div ref={this.dropdown as React.RefObject<HTMLDivElement>} className={cn.menu}>
            <PermissionsView permission={Permission.MANAGE_CHANNELS}>
                <Dropdown className={cnCommon.dropdownSmall} toggle={this.toggle} isOpen={this.isOpen}
                          onMouseEnter={this.onAddChannelHover} onMouseLeave={this.onAddChannelBlur}>
                    <DropdownToggle tag="div" disabled={!isCreateChannelsEnabled} className={cnMenu.dropDownDisabled}>
                        <div onClick={() => Tracker.trackEvent('Add', {Object: 'connect'})} className={cnMenu.addGroup}>
                            <PlusIcon className={cnMenu.addGroupPlus}/> {this.props.t('actions.add')}</div>
                    </DropdownToggle>
                    {!isCreateChannelsEnabled && this.renderDisabledAddChannelPopover()}
                    <DropdownMenu>
                        {this.props.connectStore.connectTypes.map(integration => <DropdownItem className={cn.label}
                                                                                               tag="label"
                                                                                               onClick={this.createNewChannel(integration)}
                                                                                               key={integration.key}
                        >
                            <img src={integration.logo_url}
                                 className={cn.connectIcon}/>
                            {integration.name}
                        </DropdownItem>)}

                    </DropdownMenu>
                </Dropdown>
            </PermissionsView>
            <ul className={cn.list}>
                {this.props.connectStore.channels.map(channel => {
                    const {params: {projectId}} = this.props.match;
                    return <li className={cn.item} key={channel.id}>
                        <Link
                            className={
                                classnames(
                                    cn.link,
                                    this.isActiveRoute(`/app/${projectId}/connect/channels/${channel.id}/${channel.channel_type}`) ? cn.activeLink : ''
                                )
                            }
                            to={`/app/${projectId}/connect/channels/${channel.id}/${channel.channel_type}`}>
                            <div className={cn.itemLeft}>
                                <img className={cn.channelIcon}
                                     src={this.props.connectStore.iconUrls[channel.channel_type!]}/>
                                <span className={cn.title}>{channel.name}</span>
                            </div>
                            <div className={channel.is_active ? cn.connected : cn.disconnected}/>
                        </Link>
                    </li>
                })}
            </ul>
        </div>
    }
}

export const ConnectMenu = withTranslation()(withRouter(ConnectMenuComponent));
