import React from 'react';
import cn from './MarkupElement.module.scss';
import cns from 'classnames';
import { MarkupType, WithNameAndType } from './models';
import { observer } from 'mobx-react';
import { SelectedIcon } from '../../svg-icons/SelectedIcon';

interface MarkupElementProps {
    element: WithNameAndType;

    selected: boolean;

    onSelectMarkupElement: (element: WithNameAndType) => void;
}

const TypeReduction: Record<MarkupType, string> = {
    [MarkupType.QA]: 'QA',
    [MarkupType.Intent]: 'In.',
};

@observer
export class MarkupElement extends React.Component<MarkupElementProps> {
    get selectedTypeClass() {
        return this.props.element.type === MarkupType.Intent ? cn.selectedIntent : cn.selectedQA;
    }

    render(): React.ReactNode {
        return <div onClick={() => this.props.onSelectMarkupElement(this.props.element)} className={cns(cn.element, { [cn.selected]: this.props.selected, [this.selectedTypeClass]: this.props.selected })}>
            {this.props.element.name}
            { this.props.selected }
            <span className={cns(cn.type, { [cn.qa]: this.props.element.type === MarkupType.QA })}>
                {TypeReduction[this.props.element.type]}
            </span>
            { this.props.selected && <SelectedIcon className={cn.checkbox}/>}
        </div>
    }
}
