import { MenuTypes } from './menu-types.enum';
import { LeftMenuGroup } from '@/app/components/sidebar/models/left-menu';
import { FlowsIcon } from '@/app/components/sidebar/menu-icons/FlowsIcon';
import { IntentsIcon } from '@/app/components/sidebar/menu-icons/IntentsIcon';
import { EntityIcon } from '@/app/components/sidebar/menu-icons/EntityIcon';
import { QaIcon } from '@/app/components/sidebar/menu-icons/QaIcon';
import { SnippetsIcon } from '@/app/components/sidebar/menu-icons/SnippetsIcon';
import React from 'react';

export interface DefaultLeftMenuItem {
    name: string;
    type: MenuTypes;
    route: (id: number) => string;
    analyticName: string;
    groups: LeftMenuGroup[];
    exportable: boolean;
    icon: React.FC;
    routeName: string;
}

export const defaultLeftMenuItems: DefaultLeftMenuItem[] = [{
    name: 'menu.flows',
    type: MenuTypes.FLOWS,
    route: (id: number) => `/app/${id}/flows`,
    routeName: 'flows',
    analyticName: 'flows',
    groups: [],
    exportable: false,
    icon: FlowsIcon
},{
    name: 'menu.intents',
    type: MenuTypes.INTENTS,
    route: (id: number) => `/app/${id}/intent`,
    analyticName: 'intent',
    routeName: 'intent',
    groups: [],
    exportable: true,
    icon: IntentsIcon
}, {
    name: 'menu.entities',
    type: MenuTypes.ENTITIES,
    route: (id: number) => `/app/${id}/entities`,
    analyticName: 'entities',
    routeName: 'entities',
    groups: [],
    exportable: false,
    icon: EntityIcon
}, {
    name: 'menu.qa',
    type: MenuTypes.QA,
    route: (id: number) => `/app/${id}/qa`,
    analyticName: 'qa',
    routeName: 'qa',
    groups: [],
    exportable: true,
    icon: QaIcon
}, {
    name: 'menu.snippets',
    type: MenuTypes.SNIPPETS,
    route: (id: number) => `/app/${id}/snippets`,
    analyticName: 'snippets',
    routeName: 'snippets',
    groups: [],
    exportable: false,
    icon: SnippetsIcon
}];
