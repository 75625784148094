import {API} from "@/common/api";
import {ScriptDTO} from "@/app/scripts/dtos/script.dto";
import {Pagination, PaginationResponse} from '@/common/models/pagination';
import {Project} from '@/common/models/project';
import {Label} from "../models/Label";
import {Group} from "../../../common/models/group";

interface ScriptFilter {
    script_group_id: number;
}

export class ScriptsAPI extends API {
    static async getAll(
        project: Project, pagination?: Pagination, filter?: ScriptFilter
    ): Promise<PaginationResponse<ScriptDTO>> {
        return this.get<PaginationResponse<ScriptDTO>>(
            `/projects/${project.id}/scripts`,
            Object.assign(pagination, filter)
        );
    }

    static async getScript(
        project: Project,
        script: Pick<ScriptDTO, 'id'>,
    ): Promise<ScriptDTO> {
        return this.get<ScriptDTO>(
            `/projects/${project.id}/scripts/${script.id}`
        );
    }

    static patchScript(project: Project, script: Partial<ScriptDTO>) {
        return this.patch<Partial<ScriptDTO>, ScriptDTO>(`/projects/${project.id}/scripts/${script.id}`, script);
    }

    static deleteScript(project: Project, script: Pick<ScriptDTO, 'id'>) {
        return this.delete(`/projects/${project.id}/scripts/${script.id}`);
    }

    static createScript(project: Project, scriptDTO: ScriptDTO) {
        return this.post<ScriptDTO>(`/projects/${project.id}/scripts`, scriptDTO);
    }

    static updateScript(project: Project, scriptDTO: ScriptDTO) {
        return this.put<ScriptDTO>(`/projects/${project.id}/scripts/${scriptDTO.id}`, scriptDTO);
    }

    static async getLabels(project: Project, script: Pick<ScriptDTO, 'id'>): Promise<Label[]> {
        return this.get<Label[]>(`/projects/${project.id}/scripts/${script.id}/labels`);
    }

    static async getScriptLabels(project: Project, script: Pick<ScriptDTO, 'id'>): Promise<Label[]> {
        return this.get<Label[]>(`/projects/${project.id}/scripts-labels?from_script=${script.id}`)
    }


    static async getGroups(project: Project, pagination?: Pagination): Promise<Array<Group>> {
        return this.get<Array<Group>>(`/projects/${project.id}/script-groups`, pagination);
    }

    static async createGroup(project: Project, group: Group): Promise<Group> {
        return this.post<Group>(`/projects/${project.id}/script-groups`, group);
    }


    static async updateGroup(project: Project, group: Group): Promise<Group> {
        return this.put<Group>(`/projects/${project.id}/script-groups/${group.id}`, group);
    }

    static async removeGroup(project: Project, group: Group): Promise<void> {
        return this.delete<Group>(`/projects/${project.id}/script-groups/${group.id}`);
    }

}
