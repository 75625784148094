import { User } from '@/common/models/user';

export interface ProjectLastActivity {
    user: User;
    component: string;
    item: string;
    action: string;
    date: Date;
}

export class Project {
    id: number;
    name: string = '';
    current_version?: { id: number; date_from: string; date_to: string; name: string; };
    language?: string = '';
    intent_classification_threshold?: number;
    lialang_version?: string = '';
    last_activity: ProjectLastActivity;

    constructor(id?: number, name = '') {
        this.id = id;
        this.name = name;
    }
}
