export const stringsToDatesInObj = <T>(obj: T): T => {
    Object.keys(obj).forEach(key => {
        // @ts-ignore
        if (Array.isArray(obj[key])) {
            // @ts-ignore
            // eslint-disable-next-line @typescript-eslint/no-use-before-define
            obj[key] = processArray(obj[key]);
            // @ts-ignore
        } else if (typeof obj[key] === 'object' && obj[key] !== null) {
            // @ts-ignore
            obj[key] = stringsToDatesInObj(obj[key]);
        } else if (key.includes('date')) {
            // @ts-ignore
            obj[key] = new Date(obj[key]);
        }
    });

    return obj;
};

function processArray(arr: any[]) {
    return arr.map(item => {
        if (typeof item === 'object' && !Array.isArray(item) && item !== null) {
            return stringsToDatesInObj(item);
        }

        return item;
    });
}
