import {Mapper} from "./Mapper";
import {MapperFlow0_1} from "./Mapper.flow0_1";


interface Typed {
    type: string;
}

export class MapperFactory {
    static buildMapper(dto: Typed): Mapper {
        if (dto.type === "flow0.1") {
            return new MapperFlow0_1();
        } else {
            throw new Error("MapperFactory error, bad type of flow: " + dto.type);
        }
    }
}
