import { IReactionDisposer, makeObservable, observable, reaction } from 'mobx';
import { format } from 'date-fns';

import { LoadableStore } from '@/common/store';
import { PublishPageApi } from '@/app/publish/PublishPage.api';
import { ProjectStore } from '@/core/stores/project.store';
import { AppStore } from '@/app/stores/app.store';

import { PublishRestoreState, PublishVersion } from './PublishPage.model';
import { downloadFile } from '@/common/utils/download-file';
import { ConnectStore } from '@/app/connects/connect.store';
import { RootStore } from '@/core/stores/root.store';
import { UserStore } from '@/core/stores/user.store';
import { User } from '@/common/models/user';
import { ProjectLastActivity } from '@/common/models/project';

export class PublishPageStore extends LoadableStore {
    @observable productionVersion: PublishVersion;
    @observable versions: PublishVersion[] = [];
    @observable isLoading: boolean = true;

    private projectStore: ProjectStore;
    private userStore: UserStore;

    constructor(
        rootStore: RootStore,
        private connectStore: ConnectStore,
        private appStore: AppStore
    ) {
        super();
        makeObservable(this);
        this.projectStore = rootStore.projectStore;
        this.userStore = rootStore.userStore;
    }

    get projectID(): number {
        return this.projectStore.choosenProject.id;
    }

    get draftLastActivity(): ProjectLastActivity {
        return this.projectStore.choosenProject.last_activity;
    }

    get currentUser(): User {
        return this.userStore.userInfo;
    }

    async export(ver: PublishVersion) {
        const data = await PublishPageApi.exportVersion(this.projectID, ver.id);
        downloadFile(`${ver.name}.json`, JSON.stringify(data), 'application/json');
    }

    async exportDraft() {
        const date = format(new Date(), 'dd.MM.yyyy_HH-mm-ss');
        const data = await PublishPageApi.exportCurrentVersion(this.projectID);
        downloadFile(`draft-${date}.json`, JSON.stringify(data), 'application/json');
    }

    async editName(ver: PublishVersion, name: string) {
        await PublishPageApi.editVersion(this.projectID, ver.id, { name });
        const verIndex = this.versions.findIndex(v => v.id === ver.id);
        this.versions[verIndex] = await PublishPageApi.getVersion(this.projectID, ver.id);
        this.versions[verIndex].processState = PublishRestoreState.INIT;
        this.versions = this.versions.slice();
        this.setProduction();
        if (verIndex === 0) {
            this.productionVersion = this.versions[verIndex];
        }
    }

    async remove(ver: PublishVersion) {
        await PublishPageApi.removeVersion(this.projectID, ver.id);
        this.versions = this.versions.filter(v => v.id !== ver.id);
    }

    async publish() {
        await this.projectStore.publish(this.projectStore.choosenProject);

        let dispose: IReactionDisposer;

        await new Promise((resolve, reject) => {
            this.projectStore.onNextProdStatusCheck(() => {
                dispose = reaction(
                    () => this.projectStore.updateStatusProd,
                    status => {
                        if (!status.finished) return;
                        dispose();
                        if (status.success) resolve(true);
                        else reject();
                    }
                )
            });
        });
        await this.connectStore.loadChannels();
        await this.loadVersions();
    }

    async restore(ver: PublishVersion) {
        ver.processState = PublishRestoreState.PROGRESS;
        await PublishPageApi.restoreVersion(this.projectID, ver.id);
        ver.processState = PublishRestoreState.DONE;
        this.appStore.clearApp();
        setTimeout(() => this.loadVersions(), 2000);
    }

    async _load(): Promise<void> {
        this.isLoading = true;
        this.loadVersions();
        setTimeout(() => { this.isLoading = false; }, 500);
    }

    private async loadVersions() {
        const versions = await PublishPageApi.getVersions(this.projectID) || [];
        this.productionVersion = versions[0];
        this.versions = versions;
        this.versions.forEach(version => { version.processState = PublishRestoreState.INIT; });
        this.setProduction();
    }

    private setProduction(): void {
        this.versions.forEach(v => {
            if (v.id === this.productionVersion.id) {
                v.isProdVersion = true;
            }
        });
    }
}
