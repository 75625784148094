import React from 'react';
import cn from './Tag.module.scss';
import cns from 'classnames';

interface TagProps {
    onMouseEnter?: () => void;
    onMouseLeave?: () => void;
    onClick?: (e: MouseEvent) => void;
    className?: string;
    children: React.ReactNode;
}

export class Tag extends React.Component<TagProps> {
    render() {
        const className = cns(cn.tag, this.props.className);
        const props = Object.assign({}, this.props);
        // @ts-ignore
        return <span {...props} className={className}>{ this.props.children }</span>
    }
}
