import React from 'react';
import { inject } from 'mobx-react';
import { Navigate } from 'react-router';

import { Permission, UserStore } from '@/core/stores/user.store';

interface PermissionsProps {
    user?: UserStore;
    permission: Permission;
    children: React.ReactNode;
}

@inject('user')
export class PermissionsView extends React.Component<PermissionsProps> {
    render() {
        return this.props.user.permissions.get(this.props.permission) ? this.props.children : null;
    }
}

export const withPermission = (permission: Permission) =>
    (OuterComponent: any) => {
        const Component = inject('user')(({ user }: { user?: UserStore }) => {
            return user.permissions.get(permission) ? OuterComponent : <Navigate to='/need-access' />;
        });

        return <Component  />
    };

