import React, { useState } from 'react';
import qs from 'query-string';
import cns from 'classnames';

import { Loader } from '@/common/components/Loader';
import { AuthAPI } from '../../auth.api';

import cn from './EmailActivation.module.scss';
import cnLogin from '../login/Login.module.scss';
import { inject, observer } from 'mobx-react';
import { useNavigate, useLocation } from 'react-router-dom/dist';
import { Button, Input } from 'antd';

type EmailActivationFormInputs = 'password' | 'repeatPassword';


interface EmailActivationState {
    errors: string[];
    password: string;
    repeatPassword: string;
    formErrors: Record<EmailActivationFormInputs, string>;
    isTokenValid: boolean | null;
    isSubmitted: boolean;
}

const initialState: EmailActivationState = {
    errors: [],
    password: '',
    repeatPassword: '',
    formErrors: {
        password: '',
        repeatPassword: ''
    },
    isTokenValid: null,
    isSubmitted: false
};

const inputErrors = {
    password: {
        required: 'Please enter new password',
        useStrongPassword: 'Please use strong password'
    },
    repeatPassword: {
        required: 'Please repeat new password',
        notEqual: 'Рasswords do not match'
    }
};

const validateForm = (password: string, repeatPassword: string): Record<EmailActivationFormInputs, string> => {
    const res = {
        password: '',
        repeatPassword: ''
    };
    const strongPassword = new RegExp('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,250})');
    if (!strongPassword.test(password)) res.password = inputErrors.password.useStrongPassword;
    if (!password) res.password = inputErrors.password.required;
    if (!repeatPassword) res.repeatPassword = inputErrors.repeatPassword.required;
    if (password && repeatPassword && password !== repeatPassword) {
        res.repeatPassword = inputErrors.repeatPassword.notEqual;
    }

    return res;
}

const _EmailActivation = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const {token} = qs.parse(location.search);
    const [state, setState] = useState<EmailActivationState>(initialState);
    const isInitPhase = state.isTokenValid === null;

    const sendActivationEmail = async () => {
        try {
            await AuthAPI.sendActivationMail();
            navigate('/');
        } catch (e) {
            console.log(e);
        }
    };
    const activateEmail = async () => {
        const formErrors = validateForm(state.password, state.repeatPassword);

        if (formErrors.password || formErrors.repeatPassword) {
            return setState(Object.assign({}, state, {formErrors}));
        }

        try {
            await AuthAPI.activateAccount({password: state.password, token: token as string});
            // await props.user.auth(userInfo);
            navigate('/auth/login');
        } catch (e) {
            console.log(e);
        }
    };
    const onInputChange = (name: EmailActivationFormInputs, value: string) => {
        setState(Object.assign({}, state, {[name]: value}));
    };
    const renderInput = (name: EmailActivationFormInputs, placeholder: string) => (
        <div className={cns('form__form-group-field', cn.formInputWrapper)}>
            <div className="form__form-group-input-wrap">
                <Input
                    size={'large'}
                    name={name}
                    value={state[name]}
                    onChange={e => onInputChange(name, e.target.value)}
                    type="password"
                    placeholder={placeholder}
                />
                <div className={cn.inputErrors}>
                    {state.formErrors[name] || ''}
                </div>
            </div>
        </div>
    );
    const renderNewPasswordForm = () => (
        <form className="form">
            {renderInput('password', 'New password')}
            {renderInput('repeatPassword', 'Confirm new password')}
        </form>
    );
    const renderEmailActivationContent = () => (
        <article className={cns('account__card card-body', cn.cardBody)}>
            <div className={cns('card__title', cn.title)}>
                <h4>{state.isTokenValid ? 'Activate your account' : 'Error while activating your account'}</h4>
            </div>
            <div className={cn.content}>
                {state.isTokenValid ? renderNewPasswordForm() : state.errors.join(', ')}
            </div>

            <div className={cns('account__btns', cn.controls)}>
                {
                    state.isTokenValid ? (
                        <>
                            <Button
                                style={{flex: 1}}
                                size={'large'}
                                onClick={() => navigate('/')}
                            >Cancel</Button>
                            <Button
                                style={{flex: 1}}
                                size={'large'}
                                type={'primary'}
                                onClick={() => activateEmail()}
                            >Activate account</Button>
                        </>
                    ) : (
                        <Button
                            size={'large'}
                            onClick={() => sendActivationEmail()}
                        >Send activation email again</Button>
                    )
                }
            </div>
        </article>
    );

    if (isInitPhase) {
        AuthAPI.validateToken(token as string, 'activate_user')
            .then(res => {
                setTimeout(() =>
                        setState(Object.assign({}, state, {isTokenValid: res.is_valid})),
                    500
                );
            })
            .catch(res => {
                setTimeout(() => {
                    setState(Object.assign(
                        {},
                        state,
                        {errors: res.non_field_errors, isTokenValid: false}
                    ));
                }, 500);
            });
    }

    return <div className="account">
        <div className={cnLogin.leftLiaBaloon}/>
        <div className={cnLogin.rightLiaBaloon}/>
        <div className={cnLogin.logo}/>
        <div className="account__wrapper">
            {isInitPhase ? <Loader/> : renderEmailActivationContent()}
        </div>
    </div>
};

export const EmailActivation = inject('user')(observer(_EmailActivation));
