export enum MenuTypes {
    FLOWS = 'flows',
    INTENTS = 'intent',
    ENTITIES = 'entities',
    QA = 'qa',
    SNIPPETS = 'snippets',
}

export const RouteToAddNew = {
    [MenuTypes.FLOWS]: '/app/flows/new',
    [MenuTypes.INTENTS]: '/app/intent/new',
    [MenuTypes.ENTITIES]: '/app/entities/new/',
    [MenuTypes.QA]: '/app/qa/new',
    [MenuTypes.SNIPPETS]: '/app/snippets/new',
};
