import { Entity } from '../../entities/models/entity';
import { uniq } from 'lodash';

export interface IntentExamplePart {
    text: string;
    entity_id?: number;
}

export interface IntentExample {
    parts: Array<IntentExamplePart>;
}

export interface Intent {
    id?: number;
    type?: string;
    name: string;
    intent_group_id?: number;
    is_active: boolean;
    date_created?: string;
    intent_examples: Array<IntentExample>;
    proba?: number;
}

export interface IntentFilter {
    intent_group_id: number;
}

export function getEntitiesFromIntent(intent: Intent, entities: Array<Entity>): Array<Entity> {
    const ids = uniq(
        intent.intent_examples.flatMap(
            (example) => example.parts.flatMap(
                part => part.entity_id
            )
        )
    );

    return entities.filter(e => ids.includes(e.id));
}
