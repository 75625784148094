import React, { FC, useEffect } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { Channel } from '../../models/channel';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { ChannelEditStore } from '@/app/connects/components/ConnectPageContent';
import { Button, Form, Input, Space } from 'antd';
import { useMaxLength, useRequired } from '@/common/utils/form-validators';
import { CopyOutlined } from '@ant-design/icons';

type JivoChatProps = {
    channel: Channel;
    channelEditStore: ChannelEditStore;
    isManageChannels: boolean;
    onFinish: (values: any) => void;
    isRequest: boolean;
}

export const JivoChat: FC<JivoChatProps> = observer(({
                                                                                 channel,
                                                                                 isManageChannels,
                                                                                 channelEditStore,
                                                                                 onFinish,
                                                                                 isRequest
                                                                             }) => {
    const {t} = useTranslation();
    const [form] = Form.useForm();
    channelEditStore.setCurrentEditForm(form);
    const onFinishForm = (values: any) => {
        onFinish(values);
    }

    useEffect(() => form.resetFields(), [channel.id]);

    const required = useRequired();
    const maxLength = useMaxLength(4096);

    const initialValues = {
        jivochat_provider_id: channel.params.jivochat_provider_id
    };

    return <Form form={form} style={{maxWidth: '600px'}} layout="vertical" onFinish={onFinishForm}
                 initialValues={initialValues}>
        <Form.Item label={t('connect.jivochat.input_label_api_key')}>
            <Space.Compact
                style={{
                    width: '100%',
                }}
            >
                <Input value={channel.api_key} readOnly/>
                <CopyToClipboard text={channel.api_key} onCopy={() => alert(t('actions.copied'))}>
                    <Button type="default" icon={<CopyOutlined/>}/>
                </CopyToClipboard>
            </Space.Compact>
        </Form.Item>

        <Form.Item rules={[required, maxLength]} name="jivochat_provider_id"
                   label={t('connect.jivochat.input_label_provider_id')}>
            <Input disabled={!isManageChannels || isRequest}/>
        </Form.Item>
    </Form>
});


// export const ConnectionWithJivoChatj = withTranslation()(ConnectionWithJivoChatComp);
