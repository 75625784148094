import * as React from 'react';
import { SnippetReaction } from '../models/reactions/SnippetReaction';
import { inject, observer } from 'mobx-react';
import { SnippetStore } from '../../snippets/snippet.store';
import cn from './Params.module.scss';
import { useTranslation } from 'react-i18next';
import { FC, useState } from 'react';
import { Form, Select, Checkbox } from 'antd';
import { LabeledValue } from 'antd/es/select';
import { CopyId } from '@/app/scripts/components/CopyId/CopyId.component';

type SnippetReactionParamsEditorProps = {
    reaction: SnippetReaction;
    snippetStore?: SnippetStore;
}

export const SnippetReactionParamsEditor: FC<SnippetReactionParamsEditorProps> = inject('snippetStore')(observer(({
                                                                                                                      reaction,
                                                                                                                      snippetStore
                                                                                                                  }) => {
    const {t} = useTranslation();
    const snippetLabel = snippetStore.snippets.find(snippet => snippet.id === reaction.snippetId);
    const snippet = snippetLabel ? {
        value: reaction.id, label: snippetLabel?.name
    } : null;
    const [selectedSnippet, setSelectedSnippet] = useState<LabeledValue>(snippet);

    const stopPropagation = (e: React.MouseEvent) => {
        e.stopPropagation();
    };

    const onChange = (e: LabeledValue) => {
        setSelectedSnippet(e);
        reaction.snippetId = +e.value;
    }

    const onCheck = () => {
        reaction.fail = !reaction.fail;
    }

    return <div className={cn.editor}>
        <div className={cn.group}>
            <h6 className={cn.groupTitle}>
                {t('flows.right_editor.text')}
            </h6>
            <Form.Item>
                <Select
                    onClick={stopPropagation}
                    showSearch
                    placeholder={t('flows.select_snippet')}
                    optionFilterProp="children"
                    notFoundContent={t('flows.not_found_snippet')}
                    onChange={onChange}
                    labelInValue
                    value={selectedSnippet}
                    filterOption={(input, option) => {
                        return (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                    }}
                    options={snippetStore.snippets.map(snippet => ({value: `${snippet.id}`, label: snippet.name}))}/>
            </Form.Item>

            <Checkbox checked={reaction.fail}
                      value={reaction.fail}
                      onChange={onCheck}/> {t('flows.fail')}
        </div>
        <CopyId id={reaction.id}/>
    </div>
}));
