import { action, computed, IObservableArray, makeObservable, observable, reaction } from 'mobx';
import { ChatEvent } from './models/chat-event';
import { ChatApi } from './chat.api';
import { RootStore } from '@/core/stores/root.store';
import { v4 } from 'uuid';
import { ChatButton } from '@/common/components/chat/messages/ChatMessageSelected';
import { Tracker } from '@/core/analytics/tracker';
import { getMessageDelay } from '@/common/utils/message-delay';
import * as LocalForage from 'localforage';

export class ChatStore {
    private static LAST_MESSAGES_KEY = 'lastMessages';
    @observable _events: Array<ChatEvent> = [];
    @observable userId: string = v4();
    @observable isUpdateNow: boolean = false;
    @observable quick_replies: IObservableArray<ChatButton> = observable([]);
    @observable queue: { event: ChatEvent, delay: number, fn: any }[] = observable([]);
    @observable _lastMessages: IObservableArray<string> = observable([]);
    constructor(private rootStore: RootStore) {
        makeObservable(this);
        reaction(() => this.rootStore.projectStore.updateStatus, () => {
            this.isUpdateNow = this.rootStore.projectStore.isUpdateNow;
            if (!this.rootStore.projectStore.prevUpdateStatus.finished &&
                this.rootStore.projectStore.updateStatus.finished) {
                this._events = [];
                this.userId = v4();
            }
        });
        LocalForage.getItem(ChatStore.LAST_MESSAGES_KEY).then(result => {
            this._lastMessages.replace(Array.isArray(result) ? result : []);
        });
    }

    @action.bound
    newUser() {
        this.userId = v4();
    }



    @computed get lastMessages() {
        return this._lastMessages;
    }

    @computed get events() {
        return this._events;
    }

    @computed
    get currentProject() {
        return this.rootStore.projectStore.choosenProject;
    }

    @action.bound
    replaceMessage(index: number) {
        LocalForage.getItem(ChatStore.LAST_MESSAGES_KEY).then(result => {
            const list = Array.isArray(result) ? result : [];
            const saved = list[index];
            list.splice(index, 1);
            list.push(saved);
            this._lastMessages.replace(list);
            LocalForage.setItem(ChatStore.LAST_MESSAGES_KEY, list);
        });
    }

    @action.bound
    pushLastMessage(text: string) {
        LocalForage.getItem(ChatStore.LAST_MESSAGES_KEY).then(result => {
            const list = Array.isArray(result) ? result : [];
            if (list.length === 21) {
                list.splice(0, 1);
                this._lastMessages.splice(0, 1);
            }

            list.push(text);
            this._lastMessages.push(text);
            LocalForage.setItem(ChatStore.LAST_MESSAGES_KEY, list);
        });
    }

    @action.bound
    async shareLocation(lat: number, lng: number, formatted_address: string) {
        try {
            const response = await ChatApi.sendLocation(this.currentProject, this.userId, lat, lng, formatted_address);
            this.addEvents(response.incoming_event, response.outcoming_events);
            if (response.outcoming_events.length)
                this.quick_replies.replace(response.outcoming_events[response.outcoming_events.length - 1].params.quick_replies! || []);
        } catch (e) {
            Tracker.trackEvent('Dialog', {Type: 'Error', ErrorEvent: 'shareLocation'});
        }
    }

    @action.bound
    async sendIntent(intent_id: string) {
        try {
            const response = await ChatApi.sendIntent(this.currentProject, this.userId, intent_id);
            this.addEvents(response.incoming_event, response.outcoming_events);
            if (response.outcoming_events.length)
                this.quick_replies.replace(response.outcoming_events[response.outcoming_events.length - 1].params.quick_replies! || []);
        } catch (e) {
            Tracker.trackEvent('Dialog', {Type: 'Error', ErrorEvent: 'sendIntent'});
        }
    }

    @action.bound
    async start() {
        try {
            const response = await ChatApi.sendStartEvent(this.currentProject, this.userId);
            this.addEvents(response.incoming_event, response.outcoming_events);
            if (response.outcoming_events.length)
                this.quick_replies.replace(response.outcoming_events[response.outcoming_events.length - 1].params.quick_replies! || []);
        } catch (e) {
            Tracker.trackEvent('Dialog', {Type: 'Error', ErrorEvent: 'Start'});
        }
    }

    @action.bound
    async sendFirstIntent(id: string, facts: string, type: string) {
        try {
            let resultFacts = {};
            try {
                resultFacts = typeof facts === 'string' && facts ? JSON.parse(facts) : {};
            } catch(e) {
                console.warn('Error with facts parse');
            }
            const key = type === 'intent' ? 'intent_id' : 'qa_id';
            console.warn('зачем тут key?', key);
            const response = await ChatApi.sendIntent(this.currentProject, this.userId, id, resultFacts);
            this.addEvents(response.incoming_event, response.outcoming_events);
            if (response.outcoming_events.length)
                this.quick_replies.replace(response.outcoming_events[response.outcoming_events.length - 1].params.quick_replies! || []);
        } catch (e) {
            Tracker.trackEvent('Dialog', {Type: 'Error', ErrorEvent: 'Start'});
        }
    }

    @action.bound
    async send(text: string) {
        Tracker.trackEvent('Dialog', {Type: 'Text', Text: text});
        try {
            let resultFacts = {};
            try {
                const facts = await LocalForage.getItem('facts');
                resultFacts = typeof facts === 'string' && facts ? JSON.parse(facts) : {};
            } catch(e) {
                console.warn('Error with facts parse');
            }
            const response = await ChatApi.test(this.currentProject, this.userId, text, resultFacts);
            this.addEvents(response.incoming_event, response.outcoming_events);
            if (response.outcoming_events.length)
                this.quick_replies.replace(response.outcoming_events[response.outcoming_events.length - 1].params.quick_replies! || []);
        } catch (e) {
            Tracker.trackEvent('Dialog', {Type: 'Error', ErrorEvent: 'Send'});
        }
    }

    @action.bound
    addEvents(incoming: ChatEvent, events: Array<ChatEvent>) {
        this._events.push(incoming);
        events.forEach((event) => {
            let lastDelay = 0;
            if (this.queue.length) {
                lastDelay = this.queue[this.queue.length - 1].delay;
            }
            const messageDelay = getMessageDelay(event.type === 'text' ? event.params.text.length : 3);
            this.queue.push({
                event, delay: messageDelay + lastDelay, fn: (() => {
                    setTimeout(() => {
                        this.events.push(event);
                        this.queue.pop();
                    }, (messageDelay + lastDelay) * 1000)
                })()
            })
        });
    }

    reset() {
        this.userId = v4();
        this._events = [];
        this.quick_replies.replace([]);
    }
}
