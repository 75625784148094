import React from 'react';
import { ReactionNode, ReactionNodeProps } from './ReactionNode';
import { ImageReaction } from '../models/reactions/ImageReaction';
import { inject, observer } from 'mobx-react';
import cn from './Reactions.module.scss';
import { ScriptPageStore } from '../stores/script-page.store';


interface ImageReactionViewProps extends ReactionNodeProps<ImageReaction> {
    scriptPageStore?: ScriptPageStore;
}

@inject('scriptPageStore')
@observer
export class ImageReactionView extends ReactionNode<ImageReaction, ImageReactionViewProps> {
    onClick(): void {
        this.props.scriptPageStore!.select(this.props.reaction);
    }

    onDelete(): void {
        this.props.reaction.removeSelf();

        this.props.scriptPageStore!.select();
    }

    renderBody(): React.ReactNode {
        return <div className={cn.media}>
            <img src={this.props.reaction.url}
                 alt={this.props.reaction.caption || this.props.reaction.url}/>
        </div>
    }

}
