import React, { FC } from 'react';
import cn from './ZeroScreen.module.scss';

type ZeroScreenProps = {
    title: string;
    subtitle: JSX.Element;
}

export const ZeroScreen: FC<ZeroScreenProps> = ({
    title, subtitle
                                                 }) => {
    return <div className={cn.zeroScreenWrapper}>
        <div className={cn.zeroScreenIcon}/>
        <div className={cn.zeroScreenTitle}>{title}</div>
        <div className={cn.zeroScreenSubTitle}>{subtitle}</div>
    </div>
}
