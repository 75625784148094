import React, { FC } from 'react';
import { ZeroScreen } from '@/common/components/zero-screen/ZeroScreen';
import { useTranslation } from 'react-i18next';

export const SnippetZero: FC = () => {
    const { t } = useTranslation();
    return <ZeroScreen title={t('snippets.create_new_snippet_title')}
                       subtitle={<>{t('zero_screens.subtitle')}<br/>
                           {t('snippets.create_new_snippet_subtitle_suffix')}</>} />
}
