import * as React from 'react';
import { FC } from 'react';
import {
    LeftMenu,
    LeftMenuLink,
} from '@/app/stats/StatsPage.styled';
import { observer } from 'mobx-react';
import { ProjectStore } from '@/core/stores/project.store';
import { SideMenuRight } from '@/common/side-menu-right/SideMenuRight';
import { BaseAnalyticsPage } from '@/app/stats/BaseAnalytics/BaseAnalyticsPage.component';
import { Navigate, Route, Routes, useLocation, useParams } from 'react-router';
import { IntentAnalyticsPage } from '@/app/stats/IntentAnalytics/IntentAnalyticsPage.component';
import { useTranslation } from 'react-i18next';

export const StatsPage: FC<{ projectStore?: ProjectStore }> = observer(() => {
    const {t} = useTranslation();
    const { projectId } = useParams();
    const {pathname} = useLocation();

    return <> <SideMenuRight>
        <LeftMenu>
            <LeftMenuLink isActive={pathname.includes('base')} to={`/app/${projectId}/stats/base`}>
                Общие метрики
            </LeftMenuLink>
            <LeftMenuLink isActive={pathname.includes('intents')} to={`/app/${projectId}/stats/intents`}>
                {t('menu.intents')}
            </LeftMenuLink>
        </LeftMenu>
    </SideMenuRight>
        <Routes>
            <Route
                path="*"
                element={<Navigate to={`/app/${projectId}/stats/base`} replace={true} />}
            />
            <Route path={'/base'} element={<BaseAnalyticsPage/>}/>
            <Route path={'/intents'} element={<IntentAnalyticsPage/>}/>
        </Routes>
    </>
});
