import { API } from '@/common/api';
import { Project } from '../../common/models/project';
import { ConnectType } from './models/connect-type';
import { Channel } from './models/channel';

export class ConnectAPI extends API {
    static getChannelTypes(): Promise<ConnectType[]> {
        return ConnectAPI.get<ConnectType[]>('/channel-types');
    }

    static createNewChannel(project: Project, channel: Channel) {
        return ConnectAPI.post<Channel>(`/projects/${project.id}/channels`, channel);
    }

    static getChannels(project: Project) {
        return ConnectAPI.get<Channel[]>(`/projects/${project.id}/channels`);
    }

    static saveChannel(project: Project, channel: Channel) {
        return ConnectAPI.put<Channel>(`/projects/${project.id}/channels/${channel.id}`, channel);
    }

    static deleteChannel(project: Project, channel: Channel) {
        return ConnectAPI.delete(`/projects/${project.id}/channels/${channel.id}`);
    }
}
