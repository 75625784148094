import { action, computed, IObservableArray, makeObservable, observable, runInAction } from 'mobx';
import * as LocalForage from 'localforage';

import { Group } from '@/common/models/group';
import { SnippetDto } from './models/snippet.dto';
import { RootStore } from '@/core/stores/root.store';
import { SnippetsAPI } from './snippets.api';
import { JsTemplate } from './models/js-template';
import { Tracker } from '@/core/analytics/tracker';
import { DragSidemenuItem } from '@/app/components/sidebar/models/drag-sidemenu-item';

export class SnippetStore {
    @observable _snippets: IObservableArray<SnippetDto> = observable([]);
    @observable _groups: IObservableArray<Group> = observable([]);
    @observable _jsTemplates: IObservableArray<JsTemplate> = observable([]);
    @observable isLoaded: boolean = false;
    @observable facts = '';

    constructor(private rootStore: RootStore) {
        makeObservable(this);
    }

    @computed get snippets() {
        return this._snippets;
    }

    @computed get groups() {
        return this._groups;
    }

    @computed get jsTemplates() {
        return this._jsTemplates;
    }

    @computed
    get currentProject() {
        return this.rootStore.projectStore.choosenProject;
    }

    @computed
    get defaultGroup() {
        return this.groups[0];
    }

    static emptySnippet(): SnippetDto {
        return {
            fact_group_id: 0,
            name: '',
        };
    }

    @action.bound clearStore() {
        this._snippets.replace([]);
        this._groups.replace([]);
        this.isLoaded = false;
        this.load();
    }

    @action
    async load() {
        const [groups, snippets, templates] = await Promise.all([
            SnippetsAPI.getGroups(this.currentProject), SnippetsAPI.getAll(this.currentProject, {
                page: 1,
                // Disgusting hack, this method doesnt do pagination, only got 1st page
                // Rewrite to proper pagination
                per_page: 300
            }),
            SnippetsAPI.getTemplates()
        ]);
        runInAction(() => {
            this._groups.replace(groups);
            this._snippets.replace(snippets.results);
            this._jsTemplates.replace(templates);
            this.isLoaded = true;
        });
    }

    @action.bound
    setFacts(facts: string) {
        this.facts = facts;
        LocalForage.setItem('facts', facts);
    }

    @action.bound
    getFacts() {
        return LocalForage.getItem<string>('facts').then(result => {
            this.facts = result || '';
            return result;
        })
    }

    @action.bound
    executeSnippet(params: { code: string, facts: Record<string, string> }) {
        return SnippetsAPI.executeSnippet(this.currentProject, params);
    }


    @action.bound
    patchSnippet(snippet: any): Promise<any> {
        return SnippetsAPI.patchSnippet(this.currentProject, snippet);
    }

    @action.bound
    async saveSnippet(snippet: SnippetDto): Promise<SnippetDto> {
        if (snippet.id) {
            return this.updateSnippet(snippet);
        } else {
            return this.createSnippet(snippet);
        }
    }

    @action.bound
    async createSnippet(snippet: SnippetDto) {
        if (!this.groups.length) {
            const group = await SnippetsAPI.createGroup(this.currentProject, {name: 'Default group'});
            this.groups.push(group);
        }

        const snippetWithGroup = Object.assign({}, snippet, {
            name: snippet.name,
            fact_group_id: this.defaultGroup.id!
        });

        const _snippet = await SnippetsAPI.createSnippet(this.currentProject, snippetWithGroup);
        this._snippets.push(_snippet);
        return _snippet;
    }

    @action.bound
    async updateSnippet(snippet: SnippetDto) {
        const _snippet = await SnippetsAPI.updateSnippet(this.currentProject, snippet);
        Object.assign(snippet, _snippet);
        return snippet;
    }

    @action
    async removeGroup(groupId: number) {
        await SnippetsAPI.removeGroup(this.currentProject, groupId);
        const index = this.groups.findIndex(_group => _group.id === groupId);
        this.groups.splice(index, 1);
    }

    getSnippetById(snippetId: number) {
        return this._snippets.find(_snippet => _snippet.id === snippetId);
    }

    getGroupById(groupId: number) {
        return this._groups.find(_group => _group.id === groupId);
    }

    @action.bound
    async removeSnippet(snippet: SnippetDto) {
        await SnippetsAPI.deleteSnippet(this.currentProject, snippet);
        const index = this.snippets.findIndex(_snippet => _snippet.id === snippet.id);
        this.snippets.splice(index, 1);
    }

    @action.bound
    async createGroupWithSnippets(name = 'New group', snippetIds: number[]) {
        const group = await SnippetsAPI.createGroup(this.currentProject, {name});
        this._groups.push(group);
        const updateSnippets: Promise<SnippetDto>[] = [];
        snippetIds.forEach(
            id => {
                const snippet = this._snippets.find(_snippet => _snippet.id === id);
                if (snippet) {
                    snippet.fact_group_id = group.id!;
                    updateSnippets.push(this.updateSnippet(snippet));
                }
            }
        );
        await Promise.all(updateSnippets);
        return group;
    }

    @action
    async saveGroup(group: Group) {
        const updatedGroup = await SnippetsAPI.updateGroup(this.currentProject, group);
        const mergeGroup = this.getGroupById(group.id!);
        if (mergeGroup) {
            mergeGroup.name = updatedGroup.name;
        }
    }

    async replaceElementsInGroup(from: DragSidemenuItem, to: DragSidemenuItem) {
        if (from.id === to.id) return;
        if (from.groupId === to.groupId) {
            Tracker.trackEvent('Group', {Type: 'Create'});
            await this.createGroupWithSnippets(`New group ${this.groups.length + 1}`, [from.id, to.id]);
        } else {
            const intent = this.getSnippetById(from.id);
            if (intent) {
                intent.fact_group_id = to.groupId;
                await this.updateSnippet(intent);
            }
        }
    }
}
