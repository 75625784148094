import React from 'react';
import cnCommon from '@/common/scss/form.module.scss';
import cn from './EntityCommon.module.scss';
import { DropdownItem } from 'reactstrap';
import { makeObservable, observable } from 'mobx';
import { EntityMultiInputs } from './components/EntityMultiInputs';
import { observer } from 'mobx-react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { cloneDeep } from 'lodash-es';
import { Form, Input, InputNumber, Select } from 'antd';

interface HttpModelToSave {
    method: string;
    url: string;
    headers: Array<string[]>,
    body?: string;
    params?: Record<string, string>;
    timeout?: number;
}

interface EntityHttpRequestProps extends WithTranslation {
    onChange: (modelToSave: HttpModelToSave) => void;
    modelToSave: HttpModelToSave;
    canEdit: boolean;
}

@observer
export class EntityHttpRequestComp extends React.PureComponent<EntityHttpRequestProps> {
    @observable methods = ['GET', 'POST', 'PUT', 'PATCH', 'DELETE'];
    @observable protocols = ['http://', 'https://'];
    @observable protocol = this.protocols[0];

    @observable url: string = '';
    @observable output: HttpModelToSave = {
        method: this.methods[0],
        params: {'': ''},
        headers: [[]],
        url: '',
    };

    constructor(props: EntityHttpRequestProps) {
        super(props);
        makeObservable(this);
        this.onChangeProps();
    }

    UNSAFE_componentWillReceiveProps(props: EntityHttpRequestProps) {
        this.onChangeProps(props);
    }

    onChangeProps(props?: EntityHttpRequestProps) {
        if (!props) {
            props = this.props;
        }


        if (props.modelToSave) {
            this.output = props.modelToSave;
            try {
                const parsedUrl = new URL(this.output.url);
                this.url = `${parsedUrl.host}${parsedUrl.pathname}${parsedUrl.search || ''}`;
                this.protocol = parsedUrl.protocol + '//';
            } catch (e) {
                console.error(e);
            }
            this.onChange();
        }
    }

    onChangeProtocol = (protocol: string) => {
        this.protocol = protocol;
        this.onChange();
    }

    onChangeMethod =(method: string) => {
        this.output.method = method;
        this.onChange();
    }


    onChange = () => {
        this.output.url = this.protocol + this.url;
        const output = cloneDeep(this.output);
        if (this.output.timeout) {
            output.timeout = parseFloat(this.output.timeout.toString());
        }
        this.props.onChange(output);
    };

    renderMethodItems = () => {
        return this.methods.map(method =>
            <DropdownItem key={method} onClick={() => {
                this.output.method = method;
                this.onChange();
            }}>{method}</DropdownItem>
        );
    };


    renderProtocolItems = () => {
        return this.protocols.map(
            protocol =>
                <DropdownItem key={protocol} onClick={() => {
                    this.protocol = protocol;
                    this.onChange();
                }}>{protocol}</DropdownItem>
        );
    };

    onChangeRequest = (params: Array<string[]> | Record<string, string>) => {
        // @ts-ignore
        this.output.params = params;

        this.onChange();
    };

    onChangeHeaders = (headers: Array<string[]> | Record<string, string>) => {
        // @ts-ignore
        this.output.headers = headers;
        this.onChange();
    };

    render() {
        return <div>
            <div className={cnCommon.formField}>
                <span className={cnCommon.formFieldLabel}>method</span>
                <div className="form__form-group-field">
                    {/*<UncontrolledDropdown className={cnCommon.formGroupDropdown}>*/}
                    {/*    <DropdownToggle disabled={!this.props.canEdit}*/}
                    {/*                    outline*/}
                    {/*                    color="primary">*/}
                    {/*        <p>{this.output.method}<ChevronDownIcon/></p>*/}
                    {/*    </DropdownToggle>*/}
                    {/*    <DropdownMenu>*/}
                    {/*        {this.renderMethodItems()}*/}
                    {/*    </DropdownMenu>*/}
                    {/*</UncontrolledDropdown>*/}

                    <Form.Item style={{width: '100%'}}>
                        <Select value={this.output.method} onChange={this.onChangeMethod} >
                            {this.methods.map(protocol => <Select.Option value={protocol}
                                                                           key={protocol}>{protocol}</Select.Option>)}
                        </Select>
                    </Form.Item>
                </div>
            </div>

            <div className={cnCommon.formField}>
                <span className={cnCommon.formFieldLabel}>url</span>
                <Input addonBefore={
                    <Form.Item noStyle>
                        <Select value={this.protocol} onChange={this.onChangeProtocol} style={{width: 100}}>
                            {this.protocols.map(protocol => <Select.Option value={protocol}
                                                                           key={protocol}>{protocol}</Select.Option>)}
                        </Select>
                    </Form.Item>} disabled={!this.props.canEdit} value={this.url}
                       onChange={e => {
                           this.url = e.target.value;
                           this.onChange()
                       }}/>
            </div>

            <EntityMultiInputs disabled={!this.props.canEdit} modelToSave={this.output.headers} isArraysFormat={true}
                               onChange={this.onChangeHeaders} label="headers"/>

            <EntityMultiInputs disabled={!this.props.canEdit} modelToSave={this.output.params} isArraysFormat={true}
                               onChange={this.onChangeRequest} label="params"/>

            {this.output.method !== 'GET' ? <div className={cnCommon.formField}>
                <label className={cnCommon.formFieldLabel} htmlFor="entity-regexp">body</label>
                <Input disabled={!this.props.canEdit} type="textarea" value={this.output.body} onChange={e => {
                    this.output.body = e.target.value;
                    this.onChange();
                }}/>
            </div> : ''}
            <div className={cnCommon.formField}>
                <label className={cnCommon.formFieldLabel}>timeout</label>
                <InputNumber className={cn.formFieldTimeout} value={this.output.timeout}
                             disabled={!this.props.canEdit}
                             onChange={value => {
                                 this.output.timeout = value;
                                 this.onChange();
                             }}/>
            </div>
            <span className={cn.timeoutDesc}>0.0 - 60.0 seconds</span>
        </div>
    }
}

export const EntityHttpRequest = withTranslation()(EntityHttpRequestComp);
