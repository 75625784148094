import React from 'react';

import { format } from 'date-fns';
import { Button } from 'reactstrap';
import { WithTranslation, withTranslation } from 'react-i18next';
import cns from 'classnames';

import cn from './TeamPageCard.module.scss';
import { ReactComponent as CheckIcon } from './assets/check.svg';
import { TeamUser } from '@/common/models/team-user';

interface TeamPageCardProps extends WithTranslation {
    user: TeamUser;
    defaultRole: string;
    userProjects: string[];
    onEdit: (user: TeamUser) => void;
}

const TeamPageCardComp = (props: TeamPageCardProps) => {
    const editUser = () => props.onEdit(props.user);
    const { first_name, last_name, email, role, avatar_url, is_active, is_activated, last_activity } = props.user;
    const roleTrans = props.t(`roles.${role}`);
    const userRole = roleTrans === `roles.${role}` ? props.defaultRole : roleTrans;
    const projects = props.userProjects.length ?
        props.userProjects[0] === 'all_projects' ?
            [props.t('team_page.card.all_projects')] :
            props.userProjects :
        [props.t('team_page.card.no_projects')];
    const getUserStatus = () => {
        if (!is_active) return props.t('team_page.card.no_access');
        if (!is_activated) return props.t('team_page.card.waiting_access');
        return last_activity ? props.t('team_page.card.last_activity', {
            date: format(new Date(last_activity.date), 'MMMM dd, yyyy')
        }) :  props.t('team_page.card.no_activity');
    };

    const num = (Math.abs(props.user.id) % 8) + 1;
    const userInfoClassname = cn[`userInfoAvatar--bg-${num}`];
    const firstName = props.user.first_name;
    const lastName = props.user.last_name;
    const smallName = `${firstName && firstName[0]}${lastName && lastName[0]}`;
    return (
        <div className={cn.user}>
            <div className={cn.userInfo}>
                <div className={cn.userInfoAvatar}>
                    {
                        avatar_url ?
                            <img className={cn.userInfoAvatarImg} src={avatar_url} /> :
                            <div className={cns(cn.userInfoDefault, userInfoClassname)}>{ smallName }</div>
                    }
                </div>
                <div className={cn.userInfoContent}>
                    <div className={cn.userInfoName}>{`${first_name} ${last_name}`}</div>
                    <div className={cn.userInfoEmail}>{email}</div>
                </div>
            </div>
            <div className={cn.userRole}>{userRole}</div>
            <div className={cn.userProjects}>{projects.join(', ')}</div>
            <div className={cn.userControls}>
                <div className={cn.userStatus}>{getUserStatus()}</div>
                <Button outline className={cns(cn.userEdit, 'icon')} onClick={editUser}>
                    <CheckIcon />
                    {props.t('team_page.card.edit_button')}
                </Button>
            </div>
        </div>
    );
};

export const TeamPageCard = withTranslation()(TeamPageCardComp);
