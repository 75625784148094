import React from 'react';

export const FiaLogo = () => <svg width="61" height="26" viewBox="0 0 61 26" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M38.453 20.2563C38.453 20.1281 38.5065 19.9798 38.6134 19.8115L42.0192 14.6958C39.6911 13.71 38.5312 12.5663 38.5394 10.202C38.5394 8.94615 39.1728 7.56363 40.4397 6.58585C41.7148 5.60807 43.3107 5.11918 45.2275 5.11918H54.8894C55.1938 5.11918 55.4529 5.22738 55.6668 5.44377C55.8889 5.66017 56 5.92465 56 6.23722V19.8355C56 20.1481 55.8848 20.4126 55.6545 20.629C55.4324 20.8374 55.1609 20.9416 54.8401 20.9416H52.5079C52.1871 20.9416 51.9115 20.8334 51.6811 20.617C51.459 20.3926 51.348 20.1241 51.348 19.8115V15.1887H46.9304L43.9688 19.8355C43.5493 20.5729 42.9529 20.9416 42.1796 20.9416H39.07C38.6587 20.9416 38.453 20.7131 38.453 20.2563ZM45.2275 8.52138C43.8619 8.52138 43.1791 8.69528 43.1791 10.1058C43.1791 11.5164 43.8619 11.6903 45.2275 11.6903H51.348V8.52138H45.2275Z" fill="url(#paint0_linear_403_3785)"/>
    <path d="M38.453 20.2563C38.453 20.1281 38.5065 19.9798 38.6134 19.8115L42.0192 14.6958C39.6911 13.71 38.5312 12.5663 38.5394 10.202C38.5394 8.94615 39.1728 7.56363 40.4397 6.58585C41.7148 5.60807 43.3107 5.11918 45.2275 5.11918H54.8894C55.1938 5.11918 55.4529 5.22738 55.6668 5.44377C55.8889 5.66017 56 5.92465 56 6.23722V19.8355C56 20.1481 55.8848 20.4126 55.6545 20.629C55.4324 20.8374 55.1609 20.9416 54.8401 20.9416H52.5079C52.1871 20.9416 51.9115 20.8334 51.6811 20.617C51.459 20.3926 51.348 20.1241 51.348 19.8115V15.1887H46.9304L43.9688 19.8355C43.5493 20.5729 42.9529 20.9416 42.1796 20.9416H39.07C38.6587 20.9416 38.453 20.7131 38.453 20.2563ZM45.2275 8.52138C43.8619 8.52138 43.1791 8.69528 43.1791 10.1058C43.1791 11.5164 43.8619 11.6903 45.2275 11.6903H51.348V8.52138H45.2275Z" fill="url(#paint1_linear_403_3785)"/>
    <path d="M38.453 20.2563C38.453 20.1281 38.5065 19.9798 38.6134 19.8115L42.0192 14.6958C39.6911 13.71 38.5312 12.5663 38.5394 10.202C38.5394 8.94615 39.1728 7.56363 40.4397 6.58585C41.7148 5.60807 43.3107 5.11918 45.2275 5.11918H54.8894C55.1938 5.11918 55.4529 5.22738 55.6668 5.44377C55.8889 5.66017 56 5.92465 56 6.23722V19.8355C56 20.1481 55.8848 20.4126 55.6545 20.629C55.4324 20.8374 55.1609 20.9416 54.8401 20.9416H52.5079C52.1871 20.9416 51.9115 20.8334 51.6811 20.617C51.459 20.3926 51.348 20.1241 51.348 19.8115V15.1887H46.9304L43.9688 19.8355C43.5493 20.5729 42.9529 20.9416 42.1796 20.9416H39.07C38.6587 20.9416 38.453 20.7131 38.453 20.2563ZM45.2275 8.52138C43.8619 8.52138 43.1791 8.69528 43.1791 10.1058C43.1791 11.5164 43.8619 11.6903 45.2275 11.6903H51.348V8.52138H45.2275Z" fill="url(#paint2_radial_403_3785)"/>
    <path d="M5.54569 20.9416H7.3347C7.43702 20.9802 7.55557 20.9995 7.69035 20.9995H10.1953C10.5161 20.9995 10.8205 20.8953 11.1084 20.6869C11.3964 20.4705 11.5814 20.198 11.6637 19.8694L14.4895 10.8186C14.6951 10.0813 15.09 9.49619 15.6741 9.0634C16.2664 8.6226 16.9574 8.4022 17.7472 8.4022H19.849V19.8694C19.849 20.182 19.96 20.4505 20.1821 20.6749C20.4125 20.8913 20.6881 20.9995 21.0089 20.9995C21.0089 20.9995 22.5161 20.9995 23.3658 20.9995C24.2154 20.9995 24.205 20.999 24.4537 20.9995C24.7023 21 25.1651 20.9995 25.1651 20.9995C25.4859 20.9995 25.8191 20.8913 26.1646 20.6749C26.5101 20.4505 26.7775 20.182 26.9667 19.8694L33.408 10.1301V19.8694C33.408 20.182 33.519 20.4505 33.7412 20.6749C33.9715 20.8913 34.2471 20.9995 34.5679 20.9995H36.7397C37.0605 20.9995 37.3361 20.8913 37.5664 20.6749C37.7968 20.4505 37.9119 20.182 37.9119 19.8694V6.31921C37.9119 6.00664 37.8009 5.73815 37.5788 5.51374C37.3567 5.28933 37.0852 5.17713 36.7644 5.17713H33.1242C32.8445 5.17713 32.5401 5.28933 32.211 5.51374C31.882 5.73815 31.6228 6.00664 31.4336 6.31921L24.501 16.7783V6.14208C24.501 5.84554 24.3982 5.58106 24.1925 5.34863C23.9951 5.11621 23.7524 5 23.4645 5H18.2037C13.5887 5.00801 10.8616 6.57086 10.0225 9.68854L8.01266 16.2942L7.33305 16.2942C7.17678 16.2942 7.03631 16.2839 6.91105 16.267C6.51046 16.2129 5.97999 16.4304 5.88422 16.8132L5.01577 20.2843C4.93205 20.6189 5.19217 20.9416 5.54569 20.9416Z" fill="url(#paint3_linear_403_3785)"/>
    <path d="M5.54569 20.9416H7.3347C7.43702 20.9802 7.55557 20.9995 7.69035 20.9995H10.1953C10.5161 20.9995 10.8205 20.8953 11.1084 20.6869C11.3964 20.4705 11.5814 20.198 11.6637 19.8694L14.4895 10.8186C14.6951 10.0813 15.09 9.49619 15.6741 9.0634C16.2664 8.6226 16.9574 8.4022 17.7472 8.4022H19.849V19.8694C19.849 20.182 19.96 20.4505 20.1821 20.6749C20.4125 20.8913 20.6881 20.9995 21.0089 20.9995C21.0089 20.9995 22.5161 20.9995 23.3658 20.9995C24.2154 20.9995 24.205 20.999 24.4537 20.9995C24.7023 21 25.1651 20.9995 25.1651 20.9995C25.4859 20.9995 25.8191 20.8913 26.1646 20.6749C26.5101 20.4505 26.7775 20.182 26.9667 19.8694L33.408 10.1301V19.8694C33.408 20.182 33.519 20.4505 33.7412 20.6749C33.9715 20.8913 34.2471 20.9995 34.5679 20.9995H36.7397C37.0605 20.9995 37.3361 20.8913 37.5664 20.6749C37.7968 20.4505 37.9119 20.182 37.9119 19.8694V6.31921C37.9119 6.00664 37.8009 5.73815 37.5788 5.51374C37.3567 5.28933 37.0852 5.17713 36.7644 5.17713H33.1242C32.8445 5.17713 32.5401 5.28933 32.211 5.51374C31.882 5.73815 31.6228 6.00664 31.4336 6.31921L24.501 16.7783V6.14208C24.501 5.84554 24.3982 5.58106 24.1925 5.34863C23.9951 5.11621 23.7524 5 23.4645 5H18.2037C13.5887 5.00801 10.8616 6.57086 10.0225 9.68854L8.01266 16.2942L7.33305 16.2942C7.17678 16.2942 7.03631 16.2839 6.91105 16.267C6.51046 16.2129 5.97999 16.4304 5.88422 16.8132L5.01577 20.2843C4.93205 20.6189 5.19217 20.9416 5.54569 20.9416Z" fill="url(#paint4_linear_403_3785)"/>
    <path d="M5.54569 20.9416H7.3347C7.43702 20.9802 7.55557 20.9995 7.69035 20.9995H10.1953C10.5161 20.9995 10.8205 20.8953 11.1084 20.6869C11.3964 20.4705 11.5814 20.198 11.6637 19.8694L14.4895 10.8186C14.6951 10.0813 15.09 9.49619 15.6741 9.0634C16.2664 8.6226 16.9574 8.4022 17.7472 8.4022H19.849V19.8694C19.849 20.182 19.96 20.4505 20.1821 20.6749C20.4125 20.8913 20.6881 20.9995 21.0089 20.9995C21.0089 20.9995 22.5161 20.9995 23.3658 20.9995C24.2154 20.9995 24.205 20.999 24.4537 20.9995C24.7023 21 25.1651 20.9995 25.1651 20.9995C25.4859 20.9995 25.8191 20.8913 26.1646 20.6749C26.5101 20.4505 26.7775 20.182 26.9667 19.8694L33.408 10.1301V19.8694C33.408 20.182 33.519 20.4505 33.7412 20.6749C33.9715 20.8913 34.2471 20.9995 34.5679 20.9995H36.7397C37.0605 20.9995 37.3361 20.8913 37.5664 20.6749C37.7968 20.4505 37.9119 20.182 37.9119 19.8694V6.31921C37.9119 6.00664 37.8009 5.73815 37.5788 5.51374C37.3567 5.28933 37.0852 5.17713 36.7644 5.17713H33.1242C32.8445 5.17713 32.5401 5.28933 32.211 5.51374C31.882 5.73815 31.6228 6.00664 31.4336 6.31921L24.501 16.7783V6.14208C24.501 5.84554 24.3982 5.58106 24.1925 5.34863C23.9951 5.11621 23.7524 5 23.4645 5H18.2037C13.5887 5.00801 10.8616 6.57086 10.0225 9.68854L8.01266 16.2942L7.33305 16.2942C7.17678 16.2942 7.03631 16.2839 6.91105 16.267C6.51046 16.2129 5.97999 16.4304 5.88422 16.8132L5.01577 20.2843C4.93205 20.6189 5.19217 20.9416 5.54569 20.9416Z" fill="url(#paint5_radial_403_3785)"/>
    <defs>
        <linearGradient id="paint0_linear_403_3785" x1="-11.0902" y1="12.9708" x2="38.8166" y2="12.9708" gradientUnits="userSpaceOnUse">
            <stop stopColor="#2178C9"/>
            <stop offset="0.548752" stopColor="#0A37D8"/>
            <stop offset="0.98778" stopColor="#071D69"/>
        </linearGradient>
        <linearGradient id="paint1_linear_403_3785" x1="69.088" y1="12.9708" x2="19.9993" y2="12.9708" gradientUnits="userSpaceOnUse">
            <stop stopColor="#2178C9"/>
            <stop offset="0.548752" stopColor="#0A37D8"/>
            <stop offset="0.98778" stopColor="#071D69"/>
        </linearGradient>
        <radialGradient id="paint2_radial_403_3785" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(23.4537 21.5615) rotate(32.8335) scale(8.33047 6.62707)">
            <stop stopColor="#2185CE"/>
            <stop offset="1" stopColor="#9F1CCD" stopOpacity="0"/>
        </radialGradient>
        <linearGradient id="paint3_linear_403_3785" x1="-11.0902" y1="12.9708" x2="38.8166" y2="12.9708" gradientUnits="userSpaceOnUse">
            <stop stopColor="#2178C9"/>
            <stop offset="0.548752" stopColor="#0A37D8"/>
            <stop offset="0.98778" stopColor="#071D69"/>
        </linearGradient>
        <linearGradient id="paint4_linear_403_3785" x1="69.088" y1="12.9708" x2="19.9993" y2="12.9708" gradientUnits="userSpaceOnUse">
            <stop stopColor="#2178C9"/>
            <stop offset="0.548752" stopColor="#0A37D8"/>
            <stop offset="0.98778" stopColor="#071D69"/>
        </linearGradient>
        <radialGradient id="paint5_radial_403_3785" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(23.4537 21.5615) rotate(32.8335) scale(8.33047 6.62707)">
            <stop stopColor="#2185CE"/>
            <stop offset="1" stopColor="#9F1CCD" stopOpacity="0"/>
        </radialGradient>
    </defs>
</svg>
