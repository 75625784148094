import React from 'react';
import { NavLink } from 'react-router-dom/dist';
import classnames from 'classnames';
import cn from './Sidebar.module.scss';
import { Tooltip } from 'react-tooltip'
import cns from 'classnames';

type SidebarLinkProps = {
    title: string;
    route?: string;
    onClick?: () => void;
    className?: string;
    target?: string;
    href?: string;
    isActive: boolean;
}

type SidebarInsideProps = {
    className?: string;
    title: string;
    children?: React.ReactNode;
}

const SidebarInside: React.FC<SidebarInsideProps> = (params) => (
    <li className={classnames('sidebar__link', params.className)}>
        <Tooltip offset={28} place={'right'} className={cn.tooltip} delayHide={50}
                 id={params.title}>
            {params.title}
        </Tooltip>
        <p data-tooltip-id={params.title} data-offset={{right: 18}} className="sidebar__link-title">
            <span className={cn.sidebar__icon}>{params.children}</span>
        </p>
    </li>
);

const SidebarLink: React.FC<React.PropsWithChildren<SidebarLinkProps>> = ({
                                                                              route,
                                                                              className,
                                                                              onClick,
                                                                              isActive,
                                                                              title,
                                                                              children,
                                                                              href,
                                                                              target
                                                                          }) => (
    route ? <NavLink
            to={route}
            onClick={onClick && onClick}
            className={cns(isActive && 'sidebar__link-active')}
        >
            <SidebarInside title={title} className={className}>
                {children}
            </SidebarInside>
        </NavLink> :
        <a href={href} target={target}> <SidebarInside title={title} className={className}/></a>
);

export default SidebarLink;
