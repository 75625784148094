import { action, IObservableArray, observable } from 'mobx';
import { Button } from './Button';
import { t } from 'i18next';

export class QuickReplies {
    constructor(buttons?: Array<Button>) {
        if (buttons) {
            this._buttons.replace(buttons.map((b, i) => {
                b.name = `${t('flows.reactions.button')} ${i + 1}`;
                return b;
            }));
        }
    }

    private _buttons: IObservableArray<Button> = observable([]);

    public get buttons(): Array<Button> {
        return this._buttons;
    }

    public get nextIndex(): number {
        return Array.from({length: 20}).map((_, i) => i + 1).find(n => {
            return !this.buttons.some(b => b.name.includes(`${n}`));
        });
    }

    @action.bound addButton = (button: Button) => {
        button.name = `${t('flows.reactions.button')} ${this.nextIndex}`;
        if (this._buttons.length < 20) {
            this._buttons.push(button);
        } else {
            throw new Error('Max buttons size');
        }
    }

    @action.bound deleteButton = (button: Button) => {
        const idx = this._buttons.indexOf(button);
        if (idx >= 0) {
            this._buttons.splice(idx, 1);
        }
    }

    @action.bound replaceButtons = (buttons: Array<Button>) => {
        if (buttons.length <= 20) {
            this._buttons.replace(buttons);
        } else {
            throw new Error('Max buttons size');
        }
    }

    @action.bound moveButton = (from: number, to: number) => {
        const button = this.buttons[from];

        this._buttons.splice(from, 1);
        this._buttons.splice(to, 0, button);
    }
}
