import { UserStore } from './user.store';
import { ProjectStore } from './project.store';

export class RootStore {
    userStore: UserStore;
    projectStore: ProjectStore;
    constructor() {
        this.projectStore = new ProjectStore();
        this.userStore = new UserStore(this.projectStore);
    }

    update() {
        return this.projectStore.updateStoreCurrentProject();
    }
}

export const rootStore = new RootStore();
//@ts-ignore
window.rootStore = rootStore;
