import { API } from '@/common/api';
import { Project } from '@/common/models/project';
import { ChatEvent } from './models/chat-event';

interface TestResponse {
    incoming_event: ChatEvent;
    outcoming_events: ChatEvent[];
}

export class ChatApi extends API {
    static async test(project: Project, user_id: string, message: string, facts: any): Promise<TestResponse> {
        return ChatApi.post<TestResponse>(`/projects/${project.id}/test/handling`, {
            type: 'text', params: { text: message }, user_id, facts
        })
    }

    static sendStartEvent(project: Project, user_id: string): Promise<TestResponse> {
        return ChatApi.post<TestResponse>(`/projects/${project.id}/test/handling`, {
            type: 'start', params: { }, user_id
        })
    }

    static sendIntent(project: Project, user_id: string, intent_id: string, facts?: Record<string, any>) {
        return ChatApi.post<TestResponse>(`/projects/${project.id}/test/handling`, {
            type: 'intent', params: { intent_id }, user_id, facts
        })
    }

    static sendQA(project: Project, user_id: string, intent_id: string, facts?: Record<string, any>) {
        return ChatApi.post<TestResponse>(`/projects/${project.id}/test/handling`, {
            type: 'intent', params: { intent_id }, user_id, facts
        })
    }

    static sendLocation(project: Project, user_id: string, lat: number, lng: number, formatted_address?: string): Promise<TestResponse> {
        return ChatApi.post<TestResponse>(`/projects/${project.id}/test/handling`, {
            type: 'location', params: {
                formatted_address,
                location: {
                    lat,
                    lng
                }
            }, user_id
        })
    }
}
