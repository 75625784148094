import React from 'react';
import { SmartNode } from './Node';
import { Reaction } from '../models/reactions/Reaction';
import { DropItem } from '../models/DropItem';
import { Observer } from 'mobx-react';
import { StateOrReaction } from './StateOrReaction';
import { DropFactory } from '../factories/DropFactory';
import { computed } from 'mobx';


export interface ReactionNodeProps<T> {
    reaction: T;
}

export interface Dropable {
    onDrop?: (dropItem: DropItem) => void;
}

export abstract class ReactionNode<T extends Reaction, P extends ReactionNodeProps<T>>
    extends React.Component<P> implements Dropable { // T is Reaction Type

    @computed get reaction(): T {
        return this.props.reaction;
    }

    onDrop = (dropItem: DropItem): void => {
        const next = DropFactory.build(dropItem, this.reaction);
        this.reaction.insertNext(next);
        next.parent = this.reaction;
    };

    abstract onClick(): void;

    onDelete(): void {
        this.reaction.removeSelf();
    }

    abstract renderBody(): React.ReactNode;

    alwaysShowBranches: boolean = false;

    renderNext() {
        return this.reaction.next && <StateOrReaction next={this.reaction.next}/>;
    }

    render() {
        return <>
            <Observer>{
                () => <SmartNode title={this.reaction.name}
                                 type={'reaction'}
                                 onDelete={this.onDelete.bind(this)} onClick={this.onClick.bind(this)}
                                 onDrop={this.onDrop && this.onDrop.bind(this)}>
                    {this.renderBody()}
                </SmartNode>
            }</Observer>
            {this.renderNext()}
        </>
    }
}
