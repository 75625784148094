export function animatedScrollTo(
    scrollTo: HTMLElement | number,
    duration: number,
    scrollContainer?: HTMLElement
) {
    const container = scrollContainer ?? window;
    // ???: Типы косячные, сделать ок
    // @ts-ignore
    const start = container.scrollY || container.pageYOffset || scrollContainer.scrollTop;
    const end = typeof scrollTo === 'number' ? scrollTo : scrollTo.offsetTop;
    let currentTime = 0;
    const increment = 16;

    const animateScroll = () => {
        currentTime += increment;
        const scrollVal = interpolate(currentTime, start, end, duration);
        scrollContainer.scrollTo(0, scrollVal);
        if (currentTime < duration) {
            window.requestAnimationFrame(animateScroll);
        }
    };

    animateScroll();
}

// t = current time
// b = start value
// c = end value
// d = duration
function interpolate(t: number, b: number, c: number, d: number) {
    const val = (t / d * (c - b)) + b;
    return (c > b ? val > c : val < c) ? c : val;
}
