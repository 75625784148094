import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

export const StatsWrapper = styled.div`
  padding: 26px;
`;

export const TableWrapper = styled.div`
  margin-top: 40px;
`;

export const FilterWrapper = styled.div`
  margin-bottom: 40px;
`;

export const ChartWrapper = styled.div`
  margin-left: -30px;
  min-height: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const CsvWrapper = styled.div`
  display: flex;
  justify-content: right;
`;

export const LeftMenu = styled.div`
  padding-left: 20px;
  padding-top: 20px;
`;

export const LeftMenuLink = styled(Link)<{ isActive?: boolean; }>`
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  font-size: 14px;
  color: #444;
  ${props => props.isActive && css`
    color: #1677ff;
    font-weight: 700;

    &:after {
      content: '';
      position: absolute;
      width: 2px;
      height: 20px;
      background: #1677ff;
      right: 0;
    }`}
`;
