import { useTranslation } from 'react-i18next';

export const useRequired = () => {
    const {t} = useTranslation();
    return {
        required: true,
        message: t('basic_validations.required'),
    }
}

export const useMaxLength = (max: number) => {
    const {t} = useTranslation();
    return {
        max,
        message: t('basic_validations.maxLength', {max}),
    }
}

export const useRegExp = (regExp: RegExp, pattern?: string) => {
    const {t} = useTranslation();
    return {
        pattern: regExp,
        message: t('basic_validations.pattern', {pattern}),
    }
}

export const useEmail = () => {
    const {t} = useTranslation();
    return {
        pattern: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
        message: t('basic_validations.email'),
    }
}
