import React from 'react';
import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import { inject, observer } from 'mobx-react';
import { Link } from 'react-router-dom/dist';

import { ReactionNode, ReactionNodeProps } from './ReactionNode';
import { JumpToReaction } from '../models/reactions/JumpToReaction';
import { ScriptPageStore } from '../stores/script-page.store';
import { LabelsStore } from '@/app/scripts/stores/labels.store';

import cn from './Links.module.scss';
import { generatePath } from 'react-router';
import { withRouter, RouteComponentProps } from '@/common/utils/withRouter';
import { WithTranslation, withTranslation } from 'react-i18next';

type JumpToReactionNodeProps = ReactionNodeProps<JumpToReaction> & RouteComponentProps & WithTranslation & {
    scriptPageStore?: ScriptPageStore;
    labels?: LabelsStore;
};

@inject('scriptPageStore', 'labels')
@observer
export class JumpToReactionNodeComp extends ReactionNode<JumpToReaction, JumpToReactionNodeProps> {
    @observable isLoaded: boolean = false;

    constructor(props: JumpToReactionNodeProps) {
        super(props);
        makeObservable(this);
    }

    componentDidMount() {
        this.load();
    }

    @action.bound
    private load = async () => {
        await this.props.labels!.load();
        runInAction(() => {
            this.isLoaded = true;
        });
    }

    @action.bound
    onClick = () => {
        this.props.scriptPageStore!.select(this.props.reaction);
    }

    @action.bound
    onDelete = () => {
        this.props.reaction.removeSelf();

        this.props.scriptPageStore!.select();
    }

    @computed
    get label() {
        return this.props.labels!.get(this.props.reaction.jumpNext || '');
    }

    renderBody(): React.ReactNode {
        if (!this.isLoaded) {
            return this.props.t('flows.reactions_body.loading');
        }

        if (this.props.reaction.jumpNext) {
            const {params: {projectId}} = this.props.match;
            if (this.label) {
                const replacePath = generatePath('/app/:projectId/flows/:id', {projectId, id: this.label.script!.id.toString()});
                return <>
                    {this.props.t('flows.reactions.jump_to')} <Link to={replacePath}
                                  className={cn.linkDark}>{this.label.script!.name}</Link>.{this.label.name}
                </>;
            } else {
                return `⚠️ ${this.props.t('flows.reactions_body.delete_node')}`;
            }
        } else {
            return this.props.t('flows.reactions_body.select_node');
        }
    }
}

export const JumpToReactionNode = withRouter(withTranslation()(JumpToReactionNodeComp));
