import * as LocalForage from 'localforage';

LocalForage.config({
    driver      : LocalForage.LOCALSTORAGE, // Force WebSQL; same as using setDriver()
    name        : 'lia',
    version     : 1.0,
    size        : 4980736, // Size of database, in bytes. WebSQL-only for now.
    storeName   : 'lia', // Should be alphanumeric, with underscores.
    description : 'Lia widget store'
});
