import { translateTitle } from '@/app/scripts/components/Node';
import { t } from 'i18next';
import { LabelsStore } from '@/app/scripts/stores/labels.store';
import { IntentStore } from '@/app/intents/intent.store';
import { SnippetStore } from '@/app/snippets/snippet.store';
import { ProjectStore } from '@/core/stores/project.store';
import { EntityStore } from '@/app/entities/entity.store';
import { EdgeData, Tree } from './types';
import {
    autoLayouting,
    getLabel,
    getNodeId, getNodeType,
    isAllowOutTrigger, isAudioReaction, isAudioTrigger, isButtonsReaction,
    isEntityTrigger, isFallbackTrigger, isFileReaction, isFileTrigger, isImageReaction, isImageTrigger, isIntentTrigger,
    isJumpToReaction, isLocationReaction, isLocationTrigger, isRepeatReaction, isResetReaction,
    isSnippets, isStartTrigger,
    isState, isStickerTrigger, isTerminateReaction, isTextReaction, isTriggers, isVideoReaction, isVideoTrigger,
    typeOfNode
} from './helpers';

import {
    Node,
    Edge,
    MarkerType
} from 'reactflow';

export class TreeTransformer {
    getNodeData(node: Tree) {
        const nodeType = typeOfNode(node);
        if (isSnippets(node)) {
            const translated = translateTitle(node.name, nodeType);
            const snippet = this.snippetStore.getSnippetById(node.snippetId);
            return {
                title: `${t(translated.title)} ${translated.value}`,
                id: node.snippetId,
                projectId: this.projectStore.choosenProject.id,
                isEmpty: !snippet
            };
        }

        if (isJumpToReaction(node)) {
            const translated = translateTitle(node.name, nodeType);
            const label = this.labelsStore.get(node.jumpNext);
            const script = label?.script;

            return {
                title: `${t(translated.title)} ${translated.value}`,
                id: script?.id,
                projectId: this.projectStore.choosenProject.id
            }
        }

        if (isEntityTrigger(node)) {
            const entity = this.entityStore!.entities.find(e => node.entity?.id === e.id);
            const link = `/app/${this.projectStore.choosenProject.id}/entities/${entity?.id}/${entity?.extractor_type}`;

            return {
                title: t('flows.entity'),
                list: entity ? [{name: entity?.name, link}] : [],
                emptyTitle: `⚠️  ${t('flows.select_entity')}`
            }
        }

        if (isState(node)) {
            const [, value] = node.name.split(' ');
            return {title: `${t('flows.state')} ${value}`}
        }

        if (isIntentTrigger(node)) {
            const intents = node.intents.map(intent => {
                const link = `/app/${this.projectStore.choosenProject.id}/intent/${intent.id}`;
                const storeIntent = this.intentStore.getIntentById(intent.id);
                return {name: storeIntent?.name, id: storeIntent?.id, link}
            });

            return {
                title: `${node.intents.length} ${t(node.intents.length > 1 ? 'flows.intents' : 'flows.intent')}`,
                list: intents,
                isEmpty: node.intents.length === 0,
                emptyTitle: `⚠️  ${t('flows.select_intents')}`
            }
        }

        if (isImageReaction(node)) {
            const translated = translateTitle(node.name, nodeType);
            return {title: `${t(translated.title)} ${translated.value}`, caption: node.caption, url: node.url};
        }

        if (isVideoReaction(node)) {
            const translated = translateTitle(node.name, nodeType);
            return {title: `${t(translated.title)} ${translated.value}`, caption: node.caption, url: node.url};
        }
        if (isAudioReaction(node)) {
            const translated = translateTitle(node.name, nodeType);
            return {title: `${t(translated.title)} ${translated.value}`, caption: node.caption, url: node.url};
        }

        if (isLocationReaction(node)) {
            const translated = translateTitle(node.name, nodeType);
            return {title: t(translated.title)}
        }

        if (isFileReaction(node)) {
            const translated = translateTitle(node.name, nodeType);
            return {title: `${t(translated.title)} ${translated.value}`, caption: node.caption, url: node.url};
        }

        if ('name' in node) {
            const translated = translateTitle(node.name, nodeType);
            return {title: `${t(translated.title)} ${translated.value}`};
        }

        return {};

    }


    constructor(private labelsStore: LabelsStore,
                private intentStore: IntentStore,
                private snippetStore: SnippetStore,
                private projectStore: ProjectStore,
                private entityStore: EntityStore) {
    }

    getNodeContent(node: Tree) {
        if (isJumpToReaction(node)) {
            if (node.jumpNext) {
                const label = this.labelsStore.get(node.jumpNext);
                if (!label) {
                    return `⚠️ ${t('flows.reactions_body.delete_node')}`
                }
                const script = label?.script;
                return label ? ` ${t('flows.reactions.jump_to')} ${script?.name} ${label.name}` : t('flows.reactions_body.delete_node');
            } else {
                return `⚠️ ${t('flows.reactions_body.select_node')}`;
            }
        }
        if (isEntityTrigger(node)) {
            return this.entityStore!.entities.find(e => node.entity?.id === e.id)?.name;
        } else if (isTextReaction(node)) {
            return node.texts.join(', ');
        } else if (isIntentTrigger(node)) {
            return node.intents.map(intent => this.intentStore.getIntentById(intent.id)?.name).join(` ${t('flows.or')} `);
        } else if (isSnippets(node)) {
            return this.snippetStore.getSnippetById(node.snippetId)?.name;
        } else if (isState(node)) {
            return '';
        } else if (isTerminateReaction(node)) {
            return t('flows.reactions_body.terminate')
        } else if (isAllowOutTrigger(node)) {
            return t('flows.triggers.allow_out');
        }
        if (isFallbackTrigger(node)) {
            return t('flows.triggers.fallback');
        }
        if (isStartTrigger(node)) {
            return t('flows.triggers.start');
        }
        if (isStickerTrigger(node)) {
            return `${t('flows.user_send')} ${t('flows.triggers.sticker')}`;
        } else if (isImageTrigger(node)) {
            return `${t('flows.user_send')} ${t('flows.triggers.image')}`;
        } else if (isAudioTrigger(node)) {
            return `${t('flows.user_send')} ${t('flows.triggers.audio')}`;
        } else if (isVideoTrigger(node)) {
            return `${t('flows.user_send')} ${t('flows.triggers.video')}`;
        } else if (isFileTrigger(node)) {
            return `${t('flows.user_send')} ${t('flows.triggers.file')}`;
        } else if (isLocationTrigger(node)) {
            return `${t('flows.user_send')} ${t('flows.triggers.location')}`;
        }
        if (isImageReaction(node)) {
            return ''
        }
        if (isButtonsReaction(node)) {
            return node.text ?? t('flows.reactions_body.config_buttons');
        }
        if (isRepeatReaction(node)) {
            return t('flows.reactions_body.repeat');
        }
        if (isResetReaction(node)) {
            return t('flows.reactions_body.reset');
            /**
             * На всякий случай отобразить дефолт
             */
        } else if ('name' in node) {
            return node.name;
        } else if (node.type) {
            return node.type;
        }


    }

    private treeTraversal(node: Tree, flow: { nodes: Node[], edges: Edge[] } = {
        nodes: [],
        edges: []
    }) {
        let current = node;

        while (current) {
            let currentNode: Node = null;
            currentNode = {
                id: getNodeId(current),
                position: {
                    x: 0,
                    y: 0
                },
                type: getNodeType(current),
                data: {renderData: this.getNodeData(current), label: this.getNodeContent(current)},
                width: 300,
                height: 100
            };

            flow.nodes.push(currentNode);

            if (current.parent) {
                const data: EdgeData = {}
                if (isJumpToReaction(current) && current.jumpNext) {
                    data.jumpNext = current.jumpNext;
                }

                const id = getNodeId(current.parent);

                if (id) {
                    flow.edges.push({
                        id: `${id} ### ${currentNode.id}`,
                        source: id,
                        target: currentNode.id,
                        label: getLabel(current),
                        data,
                        markerEnd: {
                            type: MarkerType.ArrowClosed,
                        },
                    });
                }
            }

            if (isTriggers(current)) {
                current.triggers.forEach((trigger) => {
                    this.treeTraversal(trigger, flow);
                });
            }

            if (isSnippets(current)) {
                if (current.fail) {
                    this.treeTraversal(current.nextFail, flow);
                }
            }

            if ('fallback' in current) {
                current = current.fallback;
            } else {
                current = current.next;
            }
        }

        return flow;
    }

    treeTraversalAuto(node: Tree, flow: { nodes: Node[], edges: Edge<EdgeData>[] } = {
        nodes: [],
        edges: []
    }) {
        return autoLayouting(this.treeTraversal(node, flow))

    }
}
