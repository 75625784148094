import React from 'react';
import { ButtonsReaction } from '../models/reactions/ButtonsReaction';
import cn from './Params.module.scss';
import { action } from 'mobx';
import { Button as ButtonModel } from '../models/Button';
import { observer } from 'mobx-react';
import { ButtonEditor } from './ButtonEditor';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Input, Button, Space } from 'antd';
import { CopyId } from '@/app/scripts/components/CopyId/CopyId.component';

interface ButtonsReactionParamsEditorProps extends WithTranslation {
    reaction: ButtonsReaction;
}

@observer
export class ButtonsReactionParamsEditorComp extends React.Component<ButtonsReactionParamsEditorProps> {
    @action.bound
    onTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.props.reaction.text = e.target.value;
    }

    @action.bound
    onAddButton = () => {
        // @ts-ignore
        this.props.reaction.buttons.addButton({
            type: 'text',
            title: '',
            params: {
                text: ''
            },
        });
    }

    render() {
        const buttons = this.props.reaction.buttons;

        return <div className={cn.editor}>
            <div className={cn.group}>
                <h6 className={cn.groupTitle}>
                    {this.props.t('flows.right_editor.text')}
                </h6>
                <Input onChange={this.onTextChange} value={this.props.reaction.text}/>
            </div>
            <div className={cn.group}>
                <div className={cn.buttons}>
                    {buttons.buttons.map((button: ButtonModel, i: number) =>
                        <ButtonEditor button={button} key={i} index={i} maxIndex={buttons.buttons.length - 1}
                                      onSort={direction => {
                                          buttons.moveButton(i, i + direction)
                                      }} sortable={buttons.buttons.length > 1}
                                      onRemove={() => buttons.deleteButton(button)}/>)}
                </div>
            </div>


            {buttons.buttons.length <= 5 &&
                <Space align="center" direction="vertical"><Button type="primary" block={true}
                                                                   onClick={this.onAddButton}>
                    {this.props.t('flows.add_button')}
                </Button></Space>}
            <CopyId id={this.props.reaction.id}/>
        </div>
    }
}

export const ButtonsReactionParamsEditor = withTranslation()(ButtonsReactionParamsEditorComp);
