import { UserSendTrigger } from './UserSendTrigger';
import { makeObservable } from 'mobx';

export class AudioTrigger extends UserSendTrigger {
    static className = 'AudioTrigger';
    constructor() {
        super();
        makeObservable(this);
    }
}
