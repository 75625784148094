import { Group } from '@/common/models/group';

export const DragSidemenuType = 'SidebarSubMenuLink';
export interface DragSidemenuItem {
    groupId: number;
    id?: number;
	title?: string;
}

export interface DropTargetItem extends Partial<DragSidemenuItem> {
    group?: Group;
}
