import { makeObservable, observable, runInAction } from 'mobx';

import { LoadableStore } from '@/common/store';
import { TeamPageApi } from '@/app/team/TeamPage.api';
import { TeamUser, TeamUserRole, UpdateCreateTeamUser } from '@/common/models/team-user';
import { Project } from '@/common/models/project';
import { UserStore } from '@/core/stores/user.store';

export class TeamPageStore extends LoadableStore {
    @observable isLoading: boolean = false;
    @observable projects: Project[] = [];
    @observable roles: TeamUserRole[] = [];
    @observable users: TeamUser[] = [];

    constructor(private readonly user: UserStore) {
        super();
        makeObservable(this);
    }

    async _load(): Promise<void> {
        this.isLoading = true;
        await this.loadRoles();
        await this.loadProjects();
        await this.loadTeam();
        setTimeout(() => { this.isLoading = false; }, 500);
    }

    getRoleByKey(key: string): string {
        return this.roles.find(r => r.key === key).name;
    }

    getUserProjects(user: TeamUser): string[] {
        const projectNames = user.project_ids
            .map(id => {
                const project = this.projects.find(p => p.id === id);
                return project ? project.name : ''
            })
            .filter(Boolean);

        return projectNames.length === this.projects.length ? ['all_projects'] : projectNames;
    }

    async updateUser(user: UpdateCreateTeamUser) {
        const { id, ...updateData } = user;
        const needProjectsUpdate = this.user.userInfo.id === id &&
            updateData.project_ids.length !== this.user.projects.length;
        const updatedUser = await TeamPageApi.updateUser(id, updateData);
        const existingIndex = this.users.findIndex(u => u.id === id);
        this.users.splice(existingIndex, 1, updatedUser);
        this.users = this.users.slice();

        if (needProjectsUpdate) await this.user.load();
    }

    async inviteUser(user: UpdateCreateTeamUser) {
        const newUser = await TeamPageApi.inviteUser(user);
        this.users.unshift(newUser);
        this.users = this.users.slice();
    }

    private async loadRoles() {
        this.roles = await TeamPageApi.getRoles();
    }

    private async loadProjects() {
        this.projects = await TeamPageApi.getProjects();
    }

    private async loadTeam() {
        const users = await TeamPageApi.getTeam();
        runInAction(() => {
            this.users = users;
        });
    }
}
