export abstract class LoadableStore {
    private _promise?: Promise<void>;

    abstract _load(): Promise<void>;

    public async load() {
        if (!this._promise) {
            this._promise = this._load();
        }

        return this._promise
    }

    public async reload() {
        this._promise = undefined;

        return this.load();
    }

    public markAsNotLoad() {
        this._promise = undefined;
    }
}
