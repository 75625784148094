import { SearchKey } from '@/app/stats/Types';
import { addDays, format } from 'date-fns';
import { ru } from 'date-fns/locale';
import { capitalizeFirstLetter } from '@/app/stats/BaseAnalytics/helpers';

export function kFormatter(num: number | string): string {
    // @ts-ignore
    return Math.abs(num) > 999 ? Math.sign(num) * ((Math.abs(num) / 1000).toFixed(1)) + 'k' : Math.sign(num) * Math.abs(num)
}

export function kPercentageFormatter(num: number | string): string {
    return `${num} %`;
}

export function xFormatter(date: number, searchKey: SearchKey) {
    const dateFormat = searchKey === 'day' ? 'EEEEEE dd.MM' : searchKey === 'month' ? 'LLLL' : 'dd MMM';
    const currentDate = format(new Date(date), dateFormat, {locale: ru});
    const nextWeek = format(addDays(new Date(date), 7), dateFormat, {locale: ru});
    return searchKey === 'week' ? `${capitalizeFirstLetter(currentDate)} - ${capitalizeFirstLetter(nextWeek)}` : capitalizeFirstLetter(currentDate);
}
