import React from 'react';

export const FlowArrow = (props: Record<string, string>) => (
    <svg width={43} height={49} fill="none" {...props}>
        <path
            d="M21.5 46.658V25m0 21.658l6-5.446m-6 5.446l-6-5.446"
            stroke="#116FFC"
            strokeWidth={2}
        />
        <g filter="url(#prefix__filter0_d)">
            <rect x={15} y={15} width={13} height={13} rx={6.5} fill="#116FFC" />
            <rect
                x={16.5}
                y={16.5}
                width={10}
                height={10}
                rx={5}
                stroke="#fff"
                strokeWidth={3}
            />
        </g>
        <defs>
            <filter
                id="prefix__filter0_d"
                x={0}
                y={0}
                width={43}
                height={43}
                filterUnits="userSpaceOnUse"
                colorInterpolationFilters="sRGB"
            >
                <feFlood floodOpacity={0} result="BackgroundImageFix" />
                <feColorMatrix
                    in="SourceAlpha"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                />
                <feOffset />
                <feGaussianBlur stdDeviation={7.5} />
                <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
                <feBlend in2="BackgroundImageFix" result="effect1_dropShadow" />
                <feBlend in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
            </filter>
        </defs>
    </svg>
)
