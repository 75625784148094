import React from 'react';
import { ReactionNode, ReactionNodeProps } from './ReactionNode';
import { AudioReaction } from '../models/reactions/AudioReaction';
import { inject, observer } from 'mobx-react';
import cn from './Reactions.module.scss';
import {ScriptPageStore} from "../stores/script-page.store";


interface AudioReactionViewProps extends ReactionNodeProps<AudioReaction> {
    scriptPageStore?: ScriptPageStore;
}

@inject('scriptPageStore')
@observer
export class AudioReactionView extends ReactionNode<AudioReaction, AudioReactionViewProps> {
    onClick(): void {
        this.props.scriptPageStore!.select(this.props.reaction);
    }

    onDelete(): void {
        this.props.reaction.removeSelf();

        this.props.scriptPageStore!.select();
    }

    renderBody(): React.ReactNode {
        return <div className={cn.media}>
            <audio controls src={this.props.reaction.url || ''}/>
        </div>
    }

}
