import { withTranslation, WithTranslation } from 'react-i18next';
import { SidebarAddButton, SidebarGroup, SidebarSubMenuLink, SidebarSearch } from '../components/sidebar';
import { action, makeObservable, observable } from 'mobx';
import CollapseComponent from '../../common/components/Collapse';
import { SidebarListMenu } from '../components/sidebar/models/sidebar-list-menu';
import { Group } from '@/common/models/group';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { ScriptsStore } from './stores/scripts.store';
import { IntentStore } from '../intents/intent.store';
import cn from '@/app/components/sidebar/sidebar-menu/sidebar-menu.module.scss';

import { ScriptDTO } from './dtos/script.dto';
import ChevronDownIcon from 'mdi-react/ChevronDownIcon';
import { DragSidemenuItem } from '@/app/components/sidebar/models/drag-sidemenu-item';
import { Permission, UserStore } from '@/core/stores/user.store';
import { PermissionsView } from '@/app/permissions/Permissions';
import { withRouter, RouteComponentProps } from '@/common/utils/withRouter';

interface ScriptsMenuProps extends WithTranslation, RouteComponentProps {
    scriptsStore: ScriptsStore;
    intentStore: IntentStore;
    user?: UserStore;
}

@inject('user')
@observer
export class ScriptsMenuComponent extends React.Component<ScriptsMenuProps> implements SidebarListMenu<ScriptDTO> {
    @observable editGroupId = -1;
    @observable searchResult: ScriptDTO[] = this.props.scriptsStore.scripts;
    @observable searchTerm: string = '';
    @observable searchByIntentTerm: string = '';
    @observable searchTerms: { basicTerm: string; intentTerm: string } = {
        basicTerm: '',
        intentTerm: '',
    };

    constructor(props: ScriptsMenuProps) {
        super(props);
        makeObservable(this);
    }

    @action.bound
    onRemoveGroup = (group: Group) => {
        this.props.scriptsStore.removeGroup(group);
    }

    @action.bound
    onStartNameGroupEdit = (group: Group) => {
        this.editGroupId = group.id!;
    }

    @action.bound
    onSaveGroup = (group: Group) => {
        this.editGroupId = -1;
        this.props.scriptsStore.saveGroup(group);
    }

    @action.bound
    onToggleGroup = (group: Group, state: boolean) => {
        const scripts = this.filterItemsByGroup(group);
        if (state && scripts.length < 2) {
            this.props.scriptsStore.getMoreElements(group);
        }
    }

    @action.bound
    getNextItems = (group: Group) => {
        this.props.scriptsStore.getMoreElements(group!);
    }

    renderGroup = (group: Group, i: number) => {
        const items = this.getItemsByGroup(group);
        return (
            items.length > 0 && (
                <CollapseComponent
                    key={group.id}
                    className={cn.collapse}
                    onToggle={(state: boolean) => this.onToggleGroup(group, state)}
                    isOpen={!i || this.searchResult.length !== this.props.scriptsStore.scripts.length}
                    title={
                        <SidebarGroup
                            canDrop={true}
                            canEdit={this.props.user.permissions.isEditScripts}
                            onSave={this.onSaveGroup}
                            onStartEdit={this.onStartNameGroupEdit}
                            currentEditedGroupId={this.editGroupId}
                            group={group}
                            onRemove={this.onRemoveGroup}
                        />
                    }
                >
                    <>
                        {items}

                        {this.props.scriptsStore.isLoaded && !this.props.scriptsStore.isEndPagination(group) && (
                            <div
                                onClick={() => {
                                    this.getNextItems(group);
                                }}
                                className={cn.loadMore}
                            >
                                <ChevronDownIcon/>
                            </div>
                        )}
                    </>
                </CollapseComponent>
            )
        );
    };

    onDragEnd = (from: DragSidemenuItem, to: DragSidemenuItem) => {
        if (from.groupId === to.groupId) {
            const group = this.props.scriptsStore.groups.find((_group: Group) => _group.id === from.groupId);
            if (group && this.filterItemsByGroup(group).length === 2) {
                return;
            }
        }

        this.props.scriptsStore.replaceElementsInGroup(from, to);
    };

    isActiveRoute = (route: string) => {
        // TODO: will using matchRoutes after refactoring to FC component
        return this.props.location.pathname.endsWith(route);
    };

    renderGroupItem = (script: ScriptDTO) => {
        return (
            <SidebarSubMenuLink
                key={script.id}
                groupId={script.script_group_id}
                id={script.id}
                isActive={script.is_active!}
                onDragEnd={this.onDragEnd}
                canDrop={true}
                noDragging={!this.props.user.permissions.isEditScripts}
                route={script.id.toString()}
                analyticName={'flows'}
                isSelected={this.isActiveRoute(script.id.toString())}
                title={script.name!}
            />
        );
    };

    filterItemsByGroup(group: Group) {
        return this.searchResult.filter(script => script.script_group_id === group.id);
    }

    getItemsByGroup(group: Group) {
        const scripts = this.filterItemsByGroup(group);
        return scripts.map(this.renderGroupItem);
    }

    onSearchItem = async (term: string) => {
        this.searchTerms.basicTerm = term;
        this.onSearchHelp();
    };

    onSearchIntentsItem = async (term: string) => {
        this.searchTerms.intentTerm = term;
        this.onSearchHelp();
    };

    onSearchHelp = () => {
        const {basicTerm, intentTerm} = this.searchTerms;
        const termIntentIds = this.props.intentStore.intents
            .filter(intent => intent.name.toLowerCase().includes(intentTerm.toLowerCase()))
            .map(intent => intent.id);


        this.searchResult = this.props.scriptsStore.scripts
            .filter(script => {
                const scriptIntentIds = Array.from(
                        JSON.stringify(script)
                            .toString()
                            .matchAll(/"intent_ids":\[[0-9 ,]+\]/g),
                    )
                    .map(intent => intent[0].split(':')[1].slice(1, -1))
                    .join(',')
                    .split(',')
                    .map(id => +id);
                if (termIntentIds.length)
                    return scriptIntentIds.some(id => termIntentIds.includes(id));
                else
                    return true;
            })
            .filter(script => script.name.toLowerCase().includes(basicTerm.toLowerCase()));


    };

    get isLoad() {
        return !(this.props.scriptsStore.isLoaded && this.props.intentStore.isLoaded);
    }

    render() {
        return (
            <>
                <PermissionsView permission={Permission.EDIT_SCRIPTS}>
                    <SidebarAddButton analyticName={'flows'}
                                      routeToNewItem={'new'}></SidebarAddButton>
                </PermissionsView>
                <SidebarSearch disabled={this.isLoad} className={cn.sidebarSearch} onChange={this.onSearchItem}/>
                <SidebarSearch disabled={this.isLoad} className={cn.sidebarSearchIntents} onChange={this.onSearchIntentsItem}
                               searchText="actions.search_intents"/>
                <div
                    className={cn.menuScroll}>{this.props.scriptsStore.isLoaded && this.props.scriptsStore.groups.map(this.renderGroup)}</div>
            </>
        );
    }
}

export const ScriptsMenu = withTranslation()(withRouter(ScriptsMenuComponent));
