import { API, HTTP_METHODS } from '../../common/api';
import { Project } from '../../common/models/project';
import { Pagination, PaginationResponse } from '../../common/models/pagination';
import { Group } from '../../common/models/group';
import { Intent, IntentFilter } from './models/Intent';

export class IntentAPI extends API {

    static getAll(project: Project, pagination?: Pagination, filter?: Partial<IntentFilter>): Promise<PaginationResponse<Intent>> {
        return IntentAPI.get<PaginationResponse<Intent>>(`/projects/${project.id}/intents`, Object.assign(pagination, filter));
    }

    static getIntentById(project: Project, intentId: number): Promise<Intent> {
        return IntentAPI.get<Intent>(`/projects/${project.id}/intents/${intentId}`);
    }

    static getGroups(project: Project, pagination?: Pagination): Promise<Group[]> {
        return IntentAPI.get<Group[]>(`/projects/${project.id}/intent-groups`, pagination);
    }

    static createIntent(project: Project, intent: Intent): Promise<Intent> {
        return IntentAPI.post<Intent>(`/projects/${project.id}/intents`, intent);
    }

    static createGroup(project: Project, group: Group): Promise<Group> {
        return IntentAPI.post<Group>(`/projects/${project.id}/intent-groups`, group);
    }

    static updateGroup(project: Project, group: Group): Promise<Group> {
        return IntentAPI.put<Group>(`/projects/${project.id}/intent-groups/${group.id}`, group);
    }

    static updateIntent(project: Project, intent: Intent): Promise<Intent> {
        return IntentAPI.put<Intent>(`/projects/${project.id}/intents/${intent.id}`, {...intent, id: undefined });
    }

    static patchIntent(project: Project, intent: any): Promise<any> {
        return IntentAPI.patch<any, any>(`/projects/${project.id}/intents/${intent.id}`, {...intent, id: undefined });
    }

    static removeGroup(project: Project, groupId: number): Promise<void> {
        return IntentAPI.delete<Group>(`/projects/${project.id}/intent-groups/${groupId}`);
    }

    static async deleteIntent(project: Project, intent: Intent): Promise<void> {
        return IntentAPI.delete(`/projects/${project.id}/intents/${intent.id}`);
    }

    static async uploadIntent(project: Project, file: File, replace: boolean): Promise<void> {
        const data = {
            'intent_file': file,
            'replace': replace ? 'True' : 'False',
        };
        return IntentAPI.formData<void>(HTTP_METHODS.PUT, `/projects/${project.id}/intents-upload`, data);
    }

    static download(project: Project): Promise<string> {
        return IntentAPI.get<string>(`/projects/${project.id}/intents-download`);
    }
}
