import React, { FC, useEffect, useRef, useState } from 'react';
import {
    VersionDate,
    VersionLabelStyled,
    VersionLabelStyledContent, VersionLabelTitle,
} from '@/app/stats/VersionLabel/VersionLabel.styled';
import { format } from 'date-fns';

export const VersionLabel: FC<{ label: string; x: number; y: number; offset: number }> = ({label, x, y, offset}) => {
    const [date, version] = label.split('###');
    const dateFormatted = format(+date, 'dd.MM.yyyy');
    const ref = useRef<SVGGElement>();
    const [visibleLabel, setVisibleLabel] = useState(false);
    const WIDTH = 62;
    const setVisible = () => {
        setVisibleLabel(true);
    }

    const unsetVisible = () => {
        setVisibleLabel(false);
    }

    useEffect(() => {
        ref.current?.parentElement.addEventListener('mouseenter', setVisible);
        ref.current?.parentElement.addEventListener('mouseleave', unsetVisible)
        return () => {
            ref.current?.parentElement.removeEventListener('mouseenter', setVisible);
            ref.current?.parentElement.removeEventListener('mouseleave', unsetVisible)
        }
    }, [ref.current]);
    return <VersionLabelStyled ref={ref} x={x} y={y}>
        <foreignObject x={x - (WIDTH / 2)} y={y + offset} width={WIDTH} height={38}>
            {visibleLabel && <VersionLabelStyledContent>
                <VersionDate>{dateFormatted}</VersionDate>
                <VersionLabelTitle>{version}</VersionLabelTitle>
            </VersionLabelStyledContent>}
        </foreignObject>
    </VersionLabelStyled>
}
