import React from 'react';
import { ReactionNode, ReactionNodeProps } from './ReactionNode';
import { SnippetStore } from '../../snippets/snippet.store';
import { SnippetReaction } from '../models/reactions/SnippetReaction';
import { inject, observer } from 'mobx-react';
import { Loader } from '@/common/components/Loader';
import { ScriptPageStore } from '../stores/script-page.store';
import { SnippetReactionBranches } from './SnippetReactionBranches';
import cn from '@/app/scripts/components/Links.module.scss';
import { Link } from 'react-router-dom/dist';
import { RouteComponentProps, withRouter } from '@/common/utils/withRouter';
import { WithTranslation, withTranslation } from 'react-i18next';

interface SnippetReactionViewProps extends ReactionNodeProps<SnippetReaction>, RouteComponentProps<{ projectId: string }>, WithTranslation {
    snippetStore?: SnippetStore;
    scriptPageStore?: ScriptPageStore;
}

@inject('snippetStore', 'scriptPageStore')
@observer
export class SnippetReactionViewComp extends ReactionNode<SnippetReaction, SnippetReactionViewProps> {
    constructor(props: SnippetReactionViewProps) {
        super(props);

        this.alwaysShowBranches = true;
        // @ts-ignore
        this.onDrop = undefined;
    }


    componentDidMount() {
        this.props.snippetStore!.load();
    }

    onClick(): void {
        this.props.scriptPageStore!.select(this.reaction);
    }

    onDelete(): void {
        this.props.reaction.removeSelf();

        this.props.scriptPageStore!.select();
    }

    renderNext() {
        return <SnippetReactionBranches reaction={this.reaction}/>;
    }

    renderBody(): React.ReactNode {
        if (this.props.reaction.snippetId === SnippetReaction.EMPTY_SNIPPET_ID) {
            return this.props.t('flows.reactions_body.select_snippet');
        }

        const snippet = this.props.snippetStore!.getSnippetById(this.props.reaction.snippetId);

        if (snippet) {
            return <Link to={`/app/${this.props.params.projectId}/snippets/${snippet.id}`}
                         className={cn.linkDark}>{snippet.name}</Link>;
        }

        return <Loader/>;
    }
}

export const SnippetReactionView = withRouter(withTranslation()(SnippetReactionViewComp));
