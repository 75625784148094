import React from 'react';
import { inject, observer } from 'mobx-react';
import { Chat } from '@/common/components/chat';
import { ChatStore } from '../chat.store';
import { action, makeObservable } from 'mobx';
import cn from './TestChat.module.scss';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Loader } from '@/common/components/Loader';

type TestChatProps = {
    chatStore?: ChatStore;
} & WithTranslation;

@inject('chatStore')
@observer
export class TestChatComponent extends React.Component<TestChatProps> {
    constructor(props: TestChatProps) {
        super(props);
        makeObservable(this);
    }
    @action.bound
    onSend(text: string) {
        this.props.chatStore.send(text);
    }

    @action.bound
    reset() {
        this.props.chatStore.reset();
    }

    @action.bound
    start() {
        this.props.chatStore.start();
    }

    @action.bound
    facts() {
        // this.props.facts();
    }

    @action.bound
    shareLocation(lat: number, lon: number, formatted_address: string) {
        this.props.chatStore.shareLocation(lat, lon, formatted_address);
    }

    @action.bound
    onSendFirst(selected: any, facts: any) {
        this.props.chatStore.sendFirstIntent(selected.id, facts, selected.type)
    }

    render() {
        return <div className={cn.chatWrapper}>
            {
                this.props.chatStore.isUpdateNow &&
                <div className={cn.chatUpdateNow}>
                  <div className={cn.chatUpdateNowMessage}>
                    <Loader className={cn.chatUpdateNowIcon}/>
                      {this.props.t('chat.training')}
                  </div>
                </div>
            }
            <Chat lastMessages={this.props.chatStore.lastMessages}
                  facts={this.facts}
                  replaceMessage={this.props.chatStore.replaceMessage}
                  pushLastMessage={this.props.chatStore.pushLastMessage}
                  sendFirstIntent={this.onSendFirst}
                  sendIntent={this.props.chatStore.sendIntent} typing={this.props.chatStore.queue.length > 0}
                  messages={this.props.chatStore.events} shareLocation={this.shareLocation} start={this.start}
                  reset={this.reset} onSend={this.onSend}/>
        </div>
    }
}

export const TestChat = withTranslation()(TestChatComponent);
